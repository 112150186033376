<template>
    

<svg  :width="width"
:height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#FFDE59"/>
<path d="M17.5 29.325V35.575C17.5 38.4375 19.8125 40.75 22.675 40.75H37.3125C40.175 40.75 42.5 38.425 42.5 35.5625V29.325C42.5 28.4875 41.825 27.8125 40.9875 27.8125H19.0125C18.175 27.8125 17.5 28.4875 17.5 29.325ZM25 36.5625H22.5C21.9875 36.5625 21.5625 36.1375 21.5625 35.625C21.5625 35.1125 21.9875 34.6875 22.5 34.6875H25C25.5125 34.6875 25.9375 35.1125 25.9375 35.625C25.9375 36.1375 25.5125 36.5625 25 36.5625ZM33.125 36.5625H28.125C27.6125 36.5625 27.1875 36.1375 27.1875 35.625C27.1875 35.1125 27.6125 34.6875 28.125 34.6875H33.125C33.6375 34.6875 34.0625 35.1125 34.0625 35.625C34.0625 36.1375 33.6375 36.5625 33.125 36.5625Z" fill="#36373F"/>
<path d="M31.875 20.763V24.4255C31.875 25.263 31.2 25.938 30.3625 25.938H19.0125C18.1625 25.938 17.5 25.238 17.5 24.4005C17.5125 22.988 18.075 21.7005 19.0125 20.763C19.95 19.8255 21.25 19.2505 22.675 19.2505H30.3625C31.2 19.2505 31.875 19.9255 31.875 20.763Z" fill="#36373F"/>
<path d="M39.9625 17.5H36.2875C34.7 17.5 33.75 18.45 33.75 20.0375V23.7125C33.75 25.3 34.7 26.25 36.2875 26.25H39.9625C41.55 26.25 42.5 25.3 42.5 23.7125V20.0375C42.5 18.45 41.55 17.5 39.9625 17.5ZM40.7875 20.5625L37.825 24.025C37.7125 24.1625 37.5375 24.25 37.3625 24.25C37.35 24.25 37.35 24.25 37.3375 24.25C37.1625 24.25 37 24.1875 36.875 24.0625L35.5 22.7125C35.25 22.4625 35.25 22.05 35.5 21.8C35.75 21.55 36.1625 21.5375 36.4125 21.8L37.2875 22.6625L39.7875 19.7375C40.025 19.4625 40.425 19.4375 40.7 19.6625C40.9875 19.8875 41.0125 20.3 40.7875 20.5625Z" fill="#36373F"/>
</svg>


</template>
<script>
export default {
  name: "PrakerjaIcon",
  props: {
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    }
  },
};
</script>