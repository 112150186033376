<template>
  <div>
    <Loading v-if="isLoading" />
    <Popup
      message="Yay, Kode Voucher dan Kode Redeem telah diterapkan. selanjutnya pilih kelas pakerja yang kamu inginkan"
      :isError="false" v-if="isPopup" @close="() => (isPopup = false)" />
    <Modal v-if="isModal" @close="() => (isModal = false)" width="580px">
      <div style="text-align: center">
        <h2>Konfirmasi Kode Voucher</h2>
        <p class="voucher-desc">
          Masukkan Kode Voucher Kelas Prakerja yang telah kamu dapatkan dari
          Mitra Platform Digital kami di sini
        </p>
        <div class="input-box">
          <p>Kode Voucher Kelas Prakerja</p>
          <v-text-field :disabled="confirmVoucher" outlined v-model="codeVoucher" class="form-input"
            placeholder="Masukkan Kode Voucher" dense clearable></v-text-field>
          <p>Kode Redeem Prakerja</p>
          <v-text-field :disabled="confirmVoucher" outlined v-model="codeRedeem" class="form-input"
            placeholder="Masukkan Kode Voucher" dense clearable></v-text-field>
        </div>
        <div class="alert-notif" v-if="isError == true || isError == false">
          <span style="color: #b80f0a" v-if="isError == true">Kode Voucher tidak valid!</span>
          <span style="color: #01a368" v-if="isError == false">Kode Voucher valid!</span>
        </div>
        <div class="button-confirm">
          <Button name="Konfirmasi" :disabled="(!codeVoucher && !codeRedeem) || confirmVoucher" height="45px"
            width="300px" @click="onCheckVaidVoucher" />
        </div>
      </div>
    </Modal>
    <div class="banner">
      <div class="banner-content">
        <h2 class="title-banner">
          Wujudkan Mimpimu tanpa Mikirin Biaya dengan Kartu Prakerja
        </h2>
        <p class="sub-title-banner">
          Kembangkan kompetensi kerja & kewirausahaan dengan ikut Kelas Prakerja
          HiColleagues sebagai mitra resmi Lembaga Pelatihan Kartu Prakerja
          <span style="font-weight: bolder">#SiapDariSekarang</span>
        </p>
        <v-btn depressed color="#FFDE59" class="button-daftar" @click="scrollToClass">
          <span class="text-capitalize font-weight-bold blue-text font-poppins">LIHAT KELAS</span>
        </v-btn>
        <!-- <p class="spacing-change-voucher">
          Sudah beli kelas Prakerja di Mitra Digital Platform HiColleagues?
        </p>
        <div class="d-flex bg-yellow justify-center" @click="openModalTukarVoucher">
          <img src="/img/other/ic_ticket.png" width="30" height="24" />
          <span class="exhange-voucher">TUKAR KODE VOUCHER</span>
        </div> -->
      </div>
    </div>
    <div class="container text-center" style="margin-top: 40px">
      <h3 class="sub-title-span">Yuk Gabung Kelas Prakerja di HiColleagues!</h3>
      <span>Rekomendasi kelas yang cocok untuk tingkatkan kompetensi kerja &
        kewirausahaan HiColers!</span>
      <h2 class="text-left mt-7">Kelas Webinar (Daring)</h2>
      <v-row class="mt-5">
        <v-col v-for="n in listOfPrakerja" :key="n.id" style="flex-grow: 0" cols="3">
          <v-card class="black--text prakerja-card-2" :title="n.name">
            <div class="box-img-class" @click="
      $router.push(
        `/class/prakerja/${n.slug_name}?tipe_kelas=prakerja`
      )
      ">
              <img src="/img/prakerja/label-prakerja.png" alt="Label Prakerja" class="box-img-label" />
              <img :src="n.image_url" class="box-img-ctn" />
            </div>
            <div class="class-name">
              <p @click="
      $router.push(
        `/class/prakerja/${n.slug_name}?tipe_kelas=prakerja`
      )
      ">
                {{ n.name }}
              </p>
              <Button name="DAFTAR KELAS" bold width="100%" class="my-4" @click="() => toCheckout(n)" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div style="margin-top: 110px">
      <div class="blue-section">
        <h1 class="text-center head-pra" style="padding-top: 60px">
          Apa itu Kartu Prakerja?
        </h1>
        <div class="head-pra-first">
          <div class="left">
            <img src="/img/prakerja/Prakerja.png" alt="Prakerja Banner" />
          </div>
          <div class="right">
            <p style="font-size: 16px">
              Program Kartu Prakerja adalah program pengembangan kompetensi
              kerja dan kewirausahaan yang ditujukan untuk pencari kerja,
              pekerja/buruh yang terkena pemutusan hubungan kerja, dan/atau
              pekerja/buruh yang membutuhkan peningkatan kompetensi, termasuk
              pelaku usaha mikro dan kecil. Kami percaya bahwa masyarakat
              Indonesia sesungguhnya ingin selalu meningkatkan kemampuannya.
              Program ini didesain sebagai sebuah produk dan dikemas sedemikian
              rupa agar memberikan nilai bagi pengguna sekaligus memberikan
              nilai bagi sektor swasta.
            </p>
            <p style="font-size: 16px">
              Jalan digital melalui marketplace dipilih untuk memudahkan
              pengguna mencari, membandingkan, memilih dan memberi evaluasi.
              Hanya dengan cara ini, produk bisa terus diperbaiki, tumbuh dan
              relevan. Menggandeng pelaku usaha swasta, program ini adalah wujud
              kerjasama pemerintah dan swasta dalam melayani masyarakat dengan
              semangat gotong royong demi SDM unggul, Indonesia maju.
            </p>
          </div>
        </div>
        <div class="container" style="margin-top: 40px">
          <div class="prakerja-info">
            <div class="left">
              <h2 class="mb-6">Syarat Mengikuti Kartu Prakerja</h2>
              <div v-for="(item, idx) in requrementsPrakerjaProgram" :key="idx" class="list">
                <img src="/img/icons/check.png" style="width: 28px; height: 28px" alt="Check" />
                <p v-html="item" style="font-size: 16px"></p>
              </div>
            </div>
            <div class="right">
              <div class="roadmap-flow-content">
                <h2>Roadmap Kelas Prakerja</h2>
                <div class="roadmap-line">
                  <img src="/img/prakerja/line-vertikal.png" alt="line prakerja" />
                  <div class="raoadmap-content">
                    <div class="roadmap-info" v-for="(item, idx) in roadmap" :key="idx">
                      <div class="rounded-blue-out">
                        <div class="rounded-blue">
                          <span style="font-size: 15px">{{ idx + 1 }}</span>
                        </div>
                      </div>
                      <div>
                        <h6 class="title">
                          {{ item.title }}
                        </h6>
                        <span style="margin-top: 6px">{{ item.desc }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-blue button-other mb-10" @click="goTo('https://www.prakerja.go.id/')">
            <span style="font-size: 17px">PELAJARI LEBIH LANJUT</span>
            <Arrow direct="left" />
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center" style="margin: 40px auto">
      <h2 class="sub-title-span mb-6">Kenapa Harus Beli Kelas HiColleagues?</h2>
      <div class="mb-10 card-bootcamp">
        <div v-for="n in benefits" :key="n.id" class="content-card mr-2 ml-2">
          <div class="left-span">
            <img :src="n.image" width="120" height="120" style="border-radius: 50%" />
          </div>
          <div class="title-2 mt-2">{{ n.title }}</div>
          <div class="desc mt-2" style="font-family: 'Poppins', sans-serif">
            {{ n.desc }}
          </div>
        </div>
      </div>
      <h3 class="sub-title-span text-center mb-6" style="margin-top: 60px">
        Apa Kata Alumni?
      </h3>
      <alumni />
    </div>
    <div class="faq" style="margin-top: 60px;">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header" style="margin-bottom: 20px;">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: -10px;">
            <v-expansion-panels flat class="faq-card" style="padding: 20px; background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow style="margin-right: 35px;" />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" style="margin-right: 35px;" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div style="padding: 25px 30px;">
            <div class="btn-stoke-blue cursor-pointer" @click="$router.push('/faq')"  style="margin: 0">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ExchangeVoucher from "@/components/prakerja/ExchangeVoucher"
import Button from "@/components/others/Button.vue";
import Modal from "@/components/others/Modal.vue";
import Alumni from "@/components/prakerja/Alumni";
import Popup from "@/components/others/Popup.vue";
import Loading from "../../ilustration/Loading.vue";
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import Arrow from '@/assets/icons/Arrow.vue';

export default {
  components: {
    ArrowLong,
    Alumni,
    Modal,
    Button,
    Popup,
    Arrow,
    Loading,
  },
  data() {
    return {
      roadmap: [
        {
          title: "Pendaftaran Kartu Prakerja",
          desc: "Lakukan pendaftaran peserta melalui Website Kartu Prakerja.",
          id: 1,
        },
        {
          title: "Seleksi Kartu Prakerja",
          desc: "Ikuti Tes Motivasi & Kemampuan Dasar untuk mendapatkan Unique ID Kartu Prakerja per Batch-nya.",
          id: 2,
        },
        {
          title: "Pilih Lembaga Pelatihan HiColleagues",
          desc: "Jelajahi Kelas Prakerja di website HiColleagues. Atau beli Kelas Prakerja yang kamu minati di Mitra Kartu Prakerja HiColleagues dan dapatkan Kode Voucher kelas di setiap pembeliannya.",
          id: 3,
        },
        {
          title: "Tukar Kode Voucher",
          desc: "Tukarkan Kode Voucher di HiColleagues. Dapatkan Email Konfirmasi untuk Login dalam LMS dan ikuti Kelas Pelatihan Webinar via Zoom.",
          id: 4,
        },
        {
          title: "Ikuti Pelatihan",
          desc: "Ikuti dan selesaikan Kelas Pelatihan untuk mendapatkan sertifikat sebagai bukti bahwa kamu telah menyelesaikan kelas yang kamu minati dengan baik.",
          id: 5,
        },
        {
          title: "Berikan Ulasan & Rating",
          desc: "Berikan Ulasan & Rating pada Kelas Pelatihan yang telah diikuti di form HiColleagues & platform Mitra Kartu Prakerja.",
          id: 6,
        },
        {
          title: "Isi Survey & Dapatkan Insentif",
          desc: "Isi survey yang tersedia di dashboard Kartu Prakerja dan dapatkan insentif dari pemerintah.",
          id: 7,
        },
      ],
      requrementsPrakerjaProgram: [
        "WNI berusia paling rendah 18 tahun dan paling tinggi 64 tahun",
        "Tidak sedang menempuh pendidikan formal",
        "Sedang mencari kerja, pekerja/buruh yang terkena PHK, atau pekerja/buruh yang membutuhkan peningkatan kompetensi kerja, seperti pekerja/buruh yang dirumahkan dan pekerja",
        "bukan penerima upah, termasuk pelaku usaha mikro & kecil. <br/> Bukan Pejabat Negara, Pimpinan dan Anggota DPRD, Aparatur",
        "Sipil Negara, Prajurit TNI, Anggota Polri, Kepala Desa dan perangkat desa dan Direksi/Komisaris/Dewan Pengawas pada BUMN atau BUMD<br>Maksimal 2 NIK dalam 1 KK yang menjadi Penerima Kartu Prakerja",
      ],
      whyHicolleagues: [
        {
          image: "/img/prakerja/ic_mentor_berpengalaman.png",
          title: "Instruktur Berpengalaman",
          desc: "Pelatihan akan dibimbing oleh instruktur yang berpengalaman di bidangnya.",
        },
        {
          image: "/img/prakerja/ic_lms.png",
          title: "Menggunakan LMS",
          desc: "LMS (Learning Management System) upaya mendukung kegiatan Kelas Prakerja berjalan optimal.",
        },
        {
          image: "/img/prakerja/ic_materi_relevan.png",
          title: "Materi Relevan",
          desc: "Materi yang dipelajari dibutuhkan industri saat ini & potensional untuk masa depan.",
        },
      ],
      partnerships: [
        {
          image: "/img/partner/tokopedia.png",
          link: "https://www.tokopedia.com/kartu-prakerja/partner/hicolleagues",
        },
        {
          image: "/img/partner/bukalapak.png",
          link: "https://www.bukalapak.com/kartu-prakerja/pencarian?source=dope&source=navbar&from=omnisearch&from_keyword_history=false&search_source=omnisearch_virtual_products&keyword=hicolleagues",
        },
        {
          image: "/img/partner/pintaria.png",
          link: "https://pintar.co/kartuprakerja/HiColleagues",
        },
        {
          image: "/img/partner/kariermu.png",
          link: "https://www.karier.mu/program?search=hicolleagues",
        },
        {
          image: "/img/partner/pijar.png",
          link: "https://pijarmahir.id/category/kartu-prakerja?lp=776291",
        },
      ],
      prakerjaClass: [
        { message: "WNI berusia 18 tahun ke atas" },
        { message: "Tidak sedang menempuh pendidikan formal" },
        {
          message:
            "Sedang mencari kerja, pekerja/buruh yang terkena PHK, atau pekerja/buruh yang membutuhkan peningkatan kompetensi kerja, seperti pekerja/buruh yang dirumahkan dan pekerja bukan penerima upah, termasuk pelaku usaha mikro & kecil. Bukan penerima bantuan sosial lainnya selama pandemi COVID-19.",
        },
        {
          message:
            "Bukan Pejabat Negara, Pimpinan dan Anggota DPRD, ASN, Prajurit TNI, Anggota Polri, Kepala Desa dan perangkat desa dan Direksi/Komisaris/Dewan Pengawas pada BUMN atau BUMD.",
        },
        {
          message:
            "Maksimal 2 NIK dalam 1 KK yang menjadi Penerima Kartu Prakerja.",
        },
      ],
      listOfPrakerja: [],
      benefits: [
        {
          image: "/img/event/narasumber-profesional.png",
          title: "Instruktur Berpengalaman",
          desc: "Pelatihan akan dibimbing oleh instruktur yang berpengalaman di bidangnya",
        },
        {
          image: "/img/prakerja/ic_sertifikat_completion.png",
          title: "Learning Management System",
          desc: "Mendukung penuh kegiatan Kelas Kartu Prakerja berjalan optimal melalui fitur LMS",
        },
        {
          image: "/img/prakerja/ic_focus.png",
          title: "Materi Relevan & Mudah Dipahami",
          desc: "Materi pelatihan sesuai perkembangan industri saat ini & penyampaian mudah diterima peserta",
        },
        {
          image: "/img/prakerja/ic_sertifikat_kompetensi.png",
          title: "Penerbitan Sertifikat Aktual",
          desc: "Jaminan penerbitan Sertifikat Kartu Prakerja dilakukan setelah seluruh rangkaian mekanisme selesai",
        },
        {
          image: "/img/prakerja/ic_cs.png",
          title: "CS Responsif",
          desc: "Tim yang selalu siap melayani & merespon kebutuhan peserta",
        },
      ],
      questions: [],
      codeVoucher: "",
      codeRedeem: "",
      isError: null,
      isPopup: false,
      confirmVoucher: false,
      isModal: false,
      isLoading: false,
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
    };
  },
  computed: {
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },
  methods: {
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=PRAKERJA&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    async getClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class/category/4")
        .then((response) => {
          response.data.data.classes.forEach((element) => {
            this.listOfPrakerja.push({
              id: parseInt(element.id),
              name: element.name,
              image_url: element.thumbnail_url,
              description: element.description,
              level: element.level,
              slug_name: element.slug_name,
              instructor: element.instructor,
            });
          });
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
    async onCheckVaidVoucher() {
      this.isError = null;
      try {
        await this.$http.post(
          `/v1/voucher-prakerja?redeem_code=${this.codeRedeem}&voucher_code=${this.codeVoucher}`
        );
        sessionStorage.setItem("voucher_code", this.codeRedeem);
        sessionStorage.setItem("redeem_code", this.codeRedeem);
        this.confirmVoucher = true;
        this.isError = false;
      } catch {
        this.isError = true;
      }
    },
    async toCheckout(data) {
      this.isLoading = true;
      await this.$http
        .get("/v1/class-detail/" + data.slug_name)
        .then((response) => {
          const classDetail = response.data.data.class_detail;
          const reqCheckout = JSON.stringify({
            class_id: classDetail.class_id,
            class_name: classDetail.name,
            price: classDetail.price,
            level: classDetail.level,
            session: classDetail.session_total,
            hour: classDetail.hours,
            image_url: classDetail.small_image_url,
            class_type: "PRAKERJA_CLASS",
            days: classDetail.days,
            methode: classDetail.method,
            category_id: classDetail.category_id,
          });

          localStorage.clear();
          localStorage.setItem("checkout", reqCheckout);
          this.isLoading = false;
          window.location.href = "/checkout";
        })
        .catch((err) => {
          this.isLoading = false;
          console.error(err.response);
        });
    },
    openModalTukarVoucher() {
      if (this.confirmVoucher) {
        this.isPopup = true;
        return;
      }
      this.isModal = true;
    },
    scrollToClass() {
      window.scrollTo(0, 400);
    },
    goToPrakerja() {
      window.open(
        "https://www.prakerja.go.id/tentang-prakerja",
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    redirectReload(e) {
      if (e.slice(0, 5) == "https") {
        window.open(e, "_blank");
      } else {
        this.$router.push({ path: e }).then(() => {
          this.$router.go();
        });
      }
    },
    goTo(e) {
      window.open(
        e,
        "_blank" // <- This is what makes it open in a new window.
      );
    },
    handleView() {
      this.mobileView = window.innerWidth <= 990;
      if (
        sessionStorage.getItem("voucher_code") &&
        sessionStorage.getItem("redeem_code")
      ) {
        this.confirmVoucher = true;
        this.isError = false;
        this.codeVoucher = sessionStorage.getItem("voucher");
        this.codeRedeem = sessionStorage.getItem("redeem_code");
      }
    },
  },
  mounted() {
    this.handleView();
    this.getClass();
    this.getFaq()
    this.$gtag.pageview(this.$route);
  },
};
</script>

<style src="./prakerja.css" scoped></style>