<template>
    <svg :width="width" :height="height" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48 8H16C10.1067 8 5.33337 12.7467 5.33337 18.5867V45.4133C5.33337 51.2533 10.1067 56 16 56H48C53.8934 56 58.6667 51.2533 58.6667 45.4133V18.5867C58.6667 12.7467 53.8934 8 48 8ZM22.6667 19.12C26.0534 19.12 28.8267 21.8933 28.8267 25.28C28.8267 28.6667 26.0534 31.44 22.6667 31.44C19.28 31.44 16.5067 28.6667 16.5067 25.28C16.5067 21.8933 19.28 19.12 22.6667 19.12ZM32.9867 44.4267C32.7467 44.6933 32.3734 44.8533 32 44.8533H13.3334C12.96 44.8533 12.5867 44.6933 12.3467 44.4267C12.1067 44.16 11.9734 43.7867 12 43.4133C12.4534 38.9333 16.0267 35.3867 20.5067 34.96C21.92 34.8267 23.3867 34.8267 24.8 34.96C29.28 35.3867 32.88 38.9333 33.3067 43.4133C33.36 43.7867 33.2267 44.16 32.9867 44.4267ZM50.6667 44.6667H45.3334C44.24 44.6667 43.3334 43.76 43.3334 42.6667C43.3334 41.5733 44.24 40.6667 45.3334 40.6667H50.6667C51.76 40.6667 52.6667 41.5733 52.6667 42.6667C52.6667 43.76 51.76 44.6667 50.6667 44.6667ZM50.6667 34H40C38.9067 34 38 33.0933 38 32C38 30.9067 38.9067 30 40 30H50.6667C51.76 30 52.6667 30.9067 52.6667 32C52.6667 33.0933 51.76 34 50.6667 34ZM50.6667 23.3333H37.3334C36.24 23.3333 35.3334 22.4267 35.3334 21.3333C35.3334 20.24 36.24 19.3333 37.3334 19.3333H50.6667C51.76 19.3333 52.6667 20.24 52.6667 21.3333C52.6667 22.4267 51.76 23.3333 50.6667 23.3333Z" fill="#162CA2"/>
    </svg>
</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "64"
            },
            height: {
                type: String,
                default: "64" 
            }
        }
}
</script>