<template>
  <div :style="`color: ${fill}`">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4C12.4945 4 12.9778 4.14662 13.3889 4.42133C13.8001 4.69603 14.1205 5.08648 14.3097 5.54329C14.4989 6.00011 14.5484 6.50277 14.452 6.98773C14.3555 7.47268 14.1174 7.91814 13.7678 8.26777C13.4181 8.6174 12.9727 8.8555 12.4877 8.95196C12.0028 9.04843 11.5001 8.99892 11.0433 8.8097C10.5865 8.62048 10.196 8.30005 9.92133 7.88893C9.64662 7.4778 9.5 6.99445 9.5 6.5C9.5 5.83696 9.76339 5.20107 10.2322 4.73223C10.7011 4.26339 11.337 4 12 4ZM12 2.5C11.2089 2.5 10.4355 2.7346 9.77772 3.17412C9.11993 3.61365 8.60723 4.23836 8.30448 4.96927C8.00173 5.70017 7.92252 6.50444 8.07686 7.28036C8.2312 8.05629 8.61216 8.76902 9.17157 9.32843C9.73098 9.88784 10.4437 10.2688 11.2196 10.4231C11.9956 10.5775 12.7998 10.4983 13.5307 10.1955C14.2616 9.89277 14.8864 9.38008 15.3259 8.72228C15.7654 8.06448 16 7.29113 16 6.5C16 5.43913 15.5786 4.42172 14.8284 3.67157C14.0783 2.92143 13.0609 2.5 12 2.5Z"
        fill="currentColor"
      />
      <path
        d="M21.5 21.75C21.3019 21.7474 21.1126 21.6676 20.9725 21.5275C20.8324 21.3874 20.7526 21.1981 20.75 21V19C20.7474 17.6084 20.1934 16.2746 19.2094 15.2906C18.2254 14.3066 16.8916 13.7526 15.5 13.75H8.5C7.10843 13.7526 5.7746 14.3066 4.79061 15.2906C3.80662 16.2746 3.25264 17.6084 3.25 19V21C3.25 21.1989 3.17098 21.3897 3.03033 21.5303C2.88968 21.671 2.69891 21.75 2.5 21.75C2.30109 21.75 2.11032 21.671 1.96967 21.5303C1.82902 21.3897 1.75 21.1989 1.75 21V19C1.75265 17.2106 2.46465 15.4952 3.72995 14.23C4.99525 12.9647 6.7106 12.2526 8.5 12.25H15.5C17.2894 12.2526 19.0048 12.9647 20.2701 14.23C21.5353 15.4952 22.2474 17.2106 22.25 19V21C22.2474 21.1981 22.1676 21.3874 22.0275 21.5275C21.8874 21.6676 21.6981 21.7474 21.5 21.75Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "people-icon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
