<template>
    
<svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6" stroke="#162CA2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16.9996 13.4H13.7996C11.3996 13.4 10.5996 12.6 10.5996 10.2V7L16.9996 13.4Z" stroke="#162CA2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.5996 2H15.5996" stroke="#162CA2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7 5C7 3.34 8.34 2 10 2H12.62" stroke="#162CA2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.0004 8V14.19C22.0004 15.74 20.7404 17 19.1904 17" stroke="#162CA2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" stroke="#162CA2" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>


<script>
export default {
    props: {
            width: {
                type: String,
                default: "30px"
            },
            height: {
                type: String,
                default: "30px" 
            }
        }
}
</script>