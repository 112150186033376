<template>
  <div :style="`color: ${fill}`">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.35234 11.916H9.66317C10.0473 11.916 10.4276 11.9917 10.7825 12.1387C11.1374 12.2857 11.4598 12.5011 11.7315 12.7727C12.0031 13.0444 12.2185 13.3668 12.3655 13.7217C12.5125 14.0766 12.5882 14.4569 12.5882 14.841V19.4127H5.4165V14.7977C5.42791 14.0276 5.74259 13.2931 6.29224 12.7536C6.84189 12.214 7.58216 11.9131 8.35234 11.916Z"
        fill="currentColor"
      />
      <path
        d="M9.00243 10.8652C10.0914 10.8652 10.9741 9.98246 10.9741 8.89354C10.9741 7.80462 10.0914 6.92188 9.00243 6.92188C7.91351 6.92188 7.03076 7.80462 7.03076 8.89354C7.03076 9.98246 7.91351 10.8652 9.00243 10.8652Z"
        fill="currentColor"
      />
      <path
        d="M21.3958 20.1722H4.60417C4.21294 20.1722 3.82554 20.0951 3.46409 19.9454C3.10264 19.7957 2.77422 19.5762 2.49758 19.2996C2.22094 19.0229 2.00149 18.6945 1.85178 18.3331C1.70206 17.9716 1.625 17.5842 1.625 17.193V5.59049C1.625 4.80037 1.93888 4.04261 2.49758 3.48391C3.05628 2.9252 3.81404 2.61133 4.60417 2.61133H21.3958C22.186 2.61133 22.9437 2.9252 23.5024 3.48391C24.0611 4.04261 24.375 4.80037 24.375 5.59049V17.193C24.375 17.5842 24.2979 17.9716 24.1482 18.3331C23.9985 18.6945 23.7791 19.0229 23.5024 19.2996C23.2258 19.5762 22.8974 19.7957 22.5359 19.9454C22.1745 20.0951 21.7871 20.1722 21.3958 20.1722ZM4.60417 4.23633C4.24502 4.23633 3.90058 4.379 3.64663 4.63295C3.39267 4.88691 3.25 5.23135 3.25 5.59049V17.193C3.25 17.5521 3.39267 17.8966 3.64663 18.1505C3.90058 18.4045 4.24502 18.5472 4.60417 18.5472H21.3958C21.755 18.5472 22.0994 18.4045 22.3534 18.1505C22.6073 17.8966 22.75 17.5521 22.75 17.193V5.59049C22.75 5.23135 22.6073 4.88691 22.3534 4.63295C22.0994 4.379 21.755 4.23633 21.3958 4.23633H4.60417Z"
        fill="currentColor"
      />
      <path
        d="M18.4168 23.3887H7.5835C7.36801 23.3887 7.16135 23.3031 7.00897 23.1507C6.8566 22.9983 6.771 22.7917 6.771 22.5762C6.771 22.3607 6.8566 22.154 7.00897 22.0016C7.16135 21.8493 7.36801 21.7637 7.5835 21.7637H18.4168C18.6323 21.7637 18.839 21.8493 18.9914 22.0016C19.1437 22.154 19.2293 22.3607 19.2293 22.5762C19.2293 22.7917 19.1437 22.9983 18.9914 23.1507C18.839 23.3031 18.6323 23.3887 18.4168 23.3887Z"
        fill="currentColor"
      />
      <path
        d="M19.5 6.08789H14.0833C13.796 6.08789 13.5205 6.20203 13.3173 6.40519C13.1141 6.60836 13 6.88391 13 7.17122V9.96622C13 10.2535 13.1141 10.5291 13.3173 10.7323C13.5205 10.9354 13.796 11.0496 14.0833 11.0496H14.3975L14.1483 11.6129L15.3075 11.0496H19.5C19.7873 11.0496 20.0629 10.9354 20.266 10.7323C20.4692 10.5291 20.5833 10.2535 20.5833 9.96622V7.17122C20.5833 6.88391 20.4692 6.60836 20.266 6.40519C20.0629 6.20203 19.7873 6.08789 19.5 6.08789ZM15.0367 10.0637C14.893 10.0637 14.7552 10.0067 14.6537 9.90507C14.5521 9.80349 14.495 9.66572 14.495 9.52206C14.495 9.3784 14.5521 9.24062 14.6537 9.13904C14.7552 9.03746 14.893 8.98039 15.0367 8.98039C15.1803 8.98039 15.3181 9.03746 15.4197 9.13904C15.5213 9.24062 15.5783 9.3784 15.5783 9.52206C15.5783 9.66572 15.5213 9.80349 15.4197 9.90507C15.3181 10.0067 15.1803 10.0637 15.0367 10.0637ZM16.8025 10.0637C16.6588 10.0637 16.5211 10.0067 16.4195 9.90507C16.3179 9.80349 16.2608 9.66572 16.2608 9.52206C16.2608 9.3784 16.3179 9.24062 16.4195 9.13904C16.5211 9.03746 16.6588 8.98039 16.8025 8.98039C16.9453 8.98316 17.0815 9.04112 17.1824 9.14211C17.2834 9.2431 17.3414 9.37927 17.3442 9.52206C17.3442 9.59319 17.3302 9.66363 17.3029 9.72934C17.2757 9.79506 17.2358 9.85478 17.1855 9.90507C17.1352 9.95537 17.0755 9.99527 17.0098 10.0225C16.9441 10.0497 16.8736 10.0637 16.8025 10.0637ZM18.5467 10.0637C18.403 10.0637 18.2652 10.0067 18.1637 9.90507C18.0621 9.80349 18.005 9.66572 18.005 9.52206C18.005 9.3784 18.0621 9.24062 18.1637 9.13904C18.2652 9.03746 18.403 8.98039 18.5467 8.98039C18.6895 8.98316 18.8256 9.04112 18.9266 9.14211C19.0276 9.2431 19.0856 9.37927 19.0883 9.52206C19.0883 9.66572 19.0313 9.80349 18.9297 9.90507C18.8281 10.0067 18.6903 10.0637 18.5467 10.0637Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "methode-icon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
