<template>
    
<svg :width="width" :height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25" r="25" fill="#FFDE59"/>
<path d="M14.0375 11.25H24.2C25.05 11.25 25.7375 11.95 25.7375 12.8125V14.525C25.7375 15.15 25.35 15.925 24.9625 16.3125L21.6625 19.2875C21.2 19.675 20.9 20.45 20.9 21.075V24.425C20.9 24.8875 20.5875 25.5125 20.2125 25.75L19.125 26.4625C18.125 27.0875 16.7375 26.3875 16.7375 25.1375V21C16.7375 20.45 16.425 19.75 16.125 19.3625L13.2 16.2375C12.8125 15.85 12.5 15.15 12.5 14.6875V12.8875C12.5 11.95 13.1875 11.25 14.0375 11.25Z" fill="#36373F"/>
<path d="M31.25 12.5H28.25C27.9 12.5 27.625 12.775 27.625 13.125C27.625 13.725 27.625 14.525 27.625 14.525C27.625 15.7625 26.9625 16.975 26.3125 17.6375L22.9125 20.675C22.875 20.7625 22.8125 20.8875 22.775 20.9875V24.425C22.775 25.5625 22.1 26.8 21.175 27.3625L20.15 28.025C19.575 28.3875 18.9375 28.5625 18.3 28.5625C17.725 28.5625 17.15 28.4125 16.625 28.125C15.945 27.7484 15.4401 27.1703 15.1469 26.4935C15.033 26.2306 15 25.942 15 25.6555V22.7625C15 22.6 14.9375 22.4375 14.8125 22.325L13.5625 21.075C13.175 20.675 12.5 20.95 12.5 21.5125V31.25C12.5 34.7 15.3 37.5 18.75 37.5H31.25C34.7 37.5 37.5 34.7 37.5 31.25V18.75C37.5 15.3 34.7 12.5 31.25 12.5ZM32.5 32.1875H23.75C23.2375 32.1875 22.8125 31.7625 22.8125 31.25C22.8125 30.7375 23.2375 30.3125 23.75 30.3125H32.5C33.0125 30.3125 33.4375 30.7375 33.4375 31.25C33.4375 31.7625 33.0125 32.1875 32.5 32.1875ZM32.5 27.1875H26.25C25.7375 27.1875 25.3125 26.7625 25.3125 26.25C25.3125 25.7375 25.7375 25.3125 26.25 25.3125H32.5C33.0125 25.3125 33.4375 25.7375 33.4375 26.25C33.4375 26.7625 33.0125 27.1875 32.5 27.1875Z" fill="#36373F"/>
</svg>

</template>


<script>
export default {
    props: {
            width: {
                type: String,
                default: "45"
            },
            height: {
                type: String,
                default: "45" 
            }
        }
}
</script>