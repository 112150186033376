<template>
  <div class="container-size bg-success">
    <div class="s-card">
      <div class="text-center" >
        <img src="/img/other/ticket_success.png" style="width: 100%;" />
      </div>
      <div class="text-center font-weight-bold mt-10">
        <p style="font-size: 24px; max-width: 410px; margin: auto;">Kode Voucher Prakerja telah Berhasil Ditukar!</p>
      </div>
      <div class="text-center mt-5 mb-10">
        <span style="font-size: 1rem">Kelas pelatihan Prakerja telah berhasil dibeli. Default Password untuk login LMS telah kami kirimkan melalui email. Silahkan cek email di <b>inbox / spam</b> secara berkala. Gunakan kode tersebut untuk Login.</span>
      </div>
      <div class="text-center mt-5 d-flex" style="justify-content: center;">
        <Button name="Masuk" @click="() => goToHomePage()" width="270px" height="47px" />
      </div>
      <div class="text-center mt-10 mb-2">
        <span style="font-size: 1rem">Jika ada pertanyaan lebih lanjut, HiColers dapat menghubungi kami melalui :</span>
      </div>
      <div v-if="mobileView">
        <span>Email</span>
        <p><b>prakerja@hicolleagues.com</b></p>
        <span>Whatsapp</span>
        <p><b>(+62) 821-2727-4632</b></p>
      </div>
      <table style="text-align: left;" v-else>
        <tr>
            <th style="width: 100px;">Email</th>
            <td>: <b>prakerja@hicolleagues.com</b></td>
        </tr>
        <tr>
            <th>Whatsapp</th>
            <td>: <b>(+62) 821-2727-4632</b></td>
        </tr>
      </table>
      <div>

      </div>
    </div>
  </div>
</template>

<script>
import Button from '../../components/others/Button.vue';
export default {
    components: {
        Button
    },
    data() {
      return {
        mobileView: false
      }
    },
  methods: {
    goToHomePage() {
      this.$router.push("/login")
    },
  },
  mounted() {
    this.mobileView = window.innerWidth <= 990;
  }
};
</script>
<style scoped>
.s-card {
    padding: 40px 60px;
    width: 40%;
    margin: auto;
    background-color: white;
    border-radius: 36px;
}
.bg-success {
    background-color: rgb(243, 240, 240);
    padding: 30px 0;
}
@media screen and (max-width: 900px) {
    .s-card {
        width: 90%;
        padding: 30px 18px;
    }
}
</style>