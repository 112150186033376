<template>
    
<svg :width="width" :height="height" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 11.6455C12.1965 11.6455 11.4111 11.4072 10.743 10.9609C10.0749 10.5145 9.55422 9.87999 9.24674 9.13766C8.93926 8.39534 8.85881 7.5785 9.01556 6.79045C9.17231 6.00241 9.55923 5.27854 10.1274 4.71039C10.6955 4.14224 11.4194 3.75532 12.2074 3.59857C12.9955 3.44182 13.8123 3.52227 14.5547 3.82975C15.297 4.13723 15.9315 4.65793 16.3778 5.32601C16.8242 5.99408 17.0625 6.77952 17.0625 7.58301C17.0625 8.66045 16.6345 9.69376 15.8726 10.4556C15.1108 11.2175 14.0774 11.6455 13 11.6455ZM13 5.14551C12.5179 5.14551 12.0466 5.28847 11.6458 5.5563C11.245 5.82414 10.9325 6.20482 10.748 6.65022C10.5636 7.09561 10.5153 7.58571 10.6093 8.05854C10.7034 8.53137 10.9355 8.96569 11.2764 9.30658C11.6173 9.64747 12.0516 9.87962 12.5245 9.97367C12.9973 10.0677 13.4874 10.0195 13.9328 9.83497C14.3782 9.65048 14.7589 9.33806 15.0267 8.93721C15.2945 8.53637 15.4375 8.0651 15.4375 7.58301C15.4375 6.93654 15.1807 6.31656 14.7236 5.85944C14.2665 5.40232 13.6465 5.14551 13 5.14551Z" :fill="fill"/>
<path d="M17.9616 21.9375H8.03825C7.54165 21.9365 7.05306 21.8122 6.6163 21.5759C6.17954 21.3396 5.80826 20.9985 5.53575 20.5833C5.26687 20.174 5.10427 19.704 5.06266 19.216C5.02104 18.728 5.10173 18.2373 5.29742 17.7883C5.94877 16.2866 7.02488 15.0081 8.39337 14.11C9.76187 13.2119 11.363 12.7334 12.9999 12.7334C14.6368 12.7334 16.238 13.2119 17.6065 14.11C18.975 15.0081 20.0511 16.2866 20.7024 17.7883C20.8981 18.2373 20.9788 18.728 20.9372 19.216C20.8956 19.704 20.733 20.174 20.4641 20.5833C20.1916 20.9985 19.8203 21.3396 19.3835 21.5759C18.9468 21.8122 18.4582 21.9365 17.9616 21.9375ZM12.9999 14.3542C11.6806 14.3531 10.3896 14.7368 9.28511 15.4584C8.18062 16.18 7.31058 17.2081 6.78159 18.4167C6.69407 18.6192 6.65911 18.8406 6.67994 19.0603C6.70077 19.28 6.77672 19.4908 6.90075 19.6733C7.01863 19.8555 7.17858 20.0067 7.36711 20.1142C7.55565 20.2217 7.76725 20.2823 7.98408 20.2908H17.9074C18.1335 20.2911 18.356 20.2347 18.5547 20.1268C18.7534 20.0189 18.9219 19.863 19.0449 19.6733C19.1778 19.4957 19.2634 19.2872 19.2937 19.0674C19.324 18.8476 19.2981 18.6237 19.2183 18.4167C18.6893 17.2081 17.8192 16.18 16.7147 15.4584C15.6102 14.7368 14.3192 14.3531 12.9999 14.3542Z" :fill="fill"/>
</svg>

</template>
<script>
export default {
  name: "people-new-icon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "white",
    },
  },
};
</script>