<template>
  <div :style="`color: ${fill}`">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1734 24.1268C11.6166 24.1227 10.0779 23.7928 8.65622 23.1585C7.23453 22.5241 5.96131 21.5993 4.91841 20.4434C4.77723 20.2814 4.705 20.0706 4.71715 19.856C4.72929 19.6415 4.82485 19.4402 4.98341 19.2951C5.06303 19.2239 5.15592 19.1691 5.25675 19.1338C5.35759 19.0985 5.4644 19.0834 5.57106 19.0895C5.67772 19.0955 5.78214 19.1225 5.87835 19.169C5.97456 19.2154 6.06067 19.2804 6.13174 19.3601C7.19912 20.5428 8.54708 21.4378 10.0513 21.9625C11.5556 22.4873 13.1678 22.6248 14.7392 22.3626C16.3106 22.1003 17.7907 21.4466 19.043 20.4619C20.2954 19.4771 21.2796 18.1929 21.9051 16.7276C22.8801 14.42 22.9008 11.82 21.9627 9.49705C21.0246 7.17415 19.2042 5.31769 16.9001 4.33428C14.7952 3.43797 12.4376 3.33128 10.2603 4.03381C8.08308 4.73634 6.23245 6.2009 5.04841 8.15845C4.99926 8.26013 4.92944 8.35045 4.84342 8.42365C4.7574 8.49684 4.65707 8.55129 4.54882 8.58353C4.44058 8.61577 4.3268 8.62509 4.21475 8.61089C4.10271 8.59669 3.99485 8.55929 3.89807 8.50107C3.80129 8.44285 3.7177 8.36509 3.65266 8.27276C3.58762 8.18042 3.54254 8.07554 3.5203 7.96481C3.49806 7.85408 3.49915 7.73992 3.52351 7.62964C3.54786 7.51935 3.59494 7.41535 3.66174 7.32428C5.04215 5.02531 7.20644 3.30226 9.7563 2.47221C12.3062 1.64216 15.07 1.76097 17.5392 2.80678C19.8925 3.80925 21.8275 5.59341 23.0172 7.85774C24.207 10.1221 24.5785 12.7277 24.069 15.2344C23.5595 17.741 22.2003 19.9948 20.221 21.6151C18.2417 23.2353 15.7638 24.1225 13.2059 24.1268H13.1734Z"
        fill="currentColor"
      />
      <path
        d="M12.9998 6.52214C13.5981 6.52214 14.0832 6.03711 14.0832 5.4388C14.0832 4.84049 13.5981 4.35547 12.9998 4.35547C12.4015 4.35547 11.9165 4.84049 11.9165 5.4388C11.9165 6.03711 12.4015 6.52214 12.9998 6.52214Z"
        fill="currentColor"
      />
      <path
        d="M13.054 21.6549C13.6523 21.6549 14.1374 21.1699 14.1374 20.5716C14.1374 19.9733 13.6523 19.4883 13.054 19.4883C12.4557 19.4883 11.9707 19.9733 11.9707 20.5716C11.9707 21.1699 12.4557 21.6549 13.054 21.6549Z"
        fill="currentColor"
      />
      <path
        d="M20.5941 14.0612C21.1924 14.0612 21.6774 13.5762 21.6774 12.9779C21.6774 12.3796 21.1924 11.8945 20.5941 11.8945C19.9958 11.8945 19.5107 12.3796 19.5107 12.9779C19.5107 13.5762 19.9958 14.0612 20.5941 14.0612Z"
        fill="currentColor"
      />
      <path
        d="M5.4598 14.1159C6.05811 14.1159 6.54313 13.6309 6.54313 13.0326C6.54313 12.4342 6.05811 11.9492 5.4598 11.9492C4.86149 11.9492 4.37646 12.4342 4.37646 13.0326C4.37646 13.6309 4.86149 14.1159 5.4598 14.1159Z"
        fill="currentColor"
      />
      <path
        d="M15.1665 16.0113C14.9563 16.0111 14.7544 15.9296 14.6031 15.7838L12.3498 13.6171C12.2723 13.5352 12.2129 13.4379 12.1756 13.3315C12.1383 13.2251 12.1238 13.112 12.1331 12.9996V8.28711C12.1331 8.07162 12.2187 7.86496 12.3711 7.71259C12.5235 7.56021 12.7301 7.47461 12.9456 7.47461C13.1611 7.47461 13.3678 7.56021 13.5202 7.71259C13.6725 7.86496 13.7581 8.07162 13.7581 8.28711V12.6204L15.7623 14.5704C15.9145 14.7228 15.9999 14.9293 15.9999 15.1446C15.9999 15.3599 15.9145 15.5664 15.7623 15.7188C15.6887 15.8062 15.5977 15.8774 15.4952 15.9277C15.3926 15.9781 15.2806 16.0065 15.1665 16.0113Z"
        fill="currentColor"
      />
      <path
        d="M6.05584 20.4962L4.61501 21.4387C4.53994 21.4887 4.45295 21.5178 4.36292 21.5232C4.2729 21.5285 4.18307 21.5098 4.10263 21.469C4.02219 21.4283 3.95402 21.3669 3.9051 21.2911C3.85617 21.2153 3.82824 21.128 3.82417 21.0379L3.73751 19.337L3.65084 17.6145C3.64712 17.526 3.66658 17.438 3.70728 17.3593C3.74799 17.2805 3.80854 17.2138 3.88295 17.1657C3.95736 17.1175 4.04304 17.0896 4.13154 17.0848C4.22003 17.0799 4.30826 17.0982 4.38751 17.1379L5.92584 17.8962L7.45334 18.6654C7.52972 18.7103 7.59303 18.7745 7.63702 18.8514C7.68101 18.9284 7.70415 19.0155 7.70415 19.1041C7.70415 19.1927 7.68101 19.2798 7.63702 19.3568C7.59303 19.4338 7.52972 19.4979 7.45334 19.5429L6.05584 20.4962Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "date-icon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
