import { render, staticRenderFns } from "./RegistrasionForm.vue?vue&type=template&id=955e6226&scoped=true&"
import script from "./RegistrasionForm.vue?vue&type=script&lang=js&"
export * from "./RegistrasionForm.vue?vue&type=script&lang=js&"
import style0 from "./RegistrasionForm.vue?vue&type=style&index=0&id=955e6226&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "955e6226",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAlert,VAutocomplete,VBtn,VCol,VRadio,VRadioGroup,VRow,VTextField,VTextarea})
