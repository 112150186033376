<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.6666 5.83325H8.33325C7.33869 5.83325 6.38486 6.22834 5.6816 6.9316C4.97834 7.63486 4.58325 8.58869 4.58325 9.58325V28.3333H35.4166V9.58325C35.4166 8.58869 35.0215 7.63486 34.3182 6.9316C33.615 6.22834 32.6611 5.83325 31.6666 5.83325Z"
      :fill="fill"
    />
    <path
      d="M33.6332 32.9167H6.36657C5.78203 32.9161 5.20792 32.7617 4.70184 32.4692C4.19576 32.1767 3.77551 31.7562 3.48324 31.25L3.23324 30.8167C3.16031 30.6903 3.1218 30.5471 3.12158 30.4012C3.12136 30.2554 3.15943 30.112 3.23197 29.9855C3.30452 29.8589 3.40901 29.7537 3.53499 29.6802C3.66098 29.6067 3.80405 29.5675 3.9499 29.5667H36.0499C36.1958 29.5675 36.3388 29.6067 36.4648 29.6802C36.5908 29.7537 36.6953 29.8589 36.7678 29.9855C36.8404 30.112 36.8784 30.2554 36.8782 30.4012C36.878 30.5471 36.8395 30.6903 36.7666 30.8167L36.5166 31.25C36.2243 31.7562 35.804 32.1767 35.298 32.4692C34.7919 32.7617 34.2178 32.9161 33.6332 32.9167Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  name: "screen-icon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "white",
    },
  },
};
</script>
