<template>
  <div>
     <DesktopCorporate v-if="!isMobile" />
     <MobileCorporate v-if="isMobile" />
  </div>
</template>

<script>
import DesktopCorporate from '@/components/corporate/main/desktop/Corporate.vue'
  import MobileCorporate from '@/components/corporate/main/mobile/Corporate.vue'
export default {
  components: {
    DesktopCorporate,
    MobileCorporate
  },
  metaInfo() {
      const thisPath = window.location.href
      return {
        title: this.$route.meta.title,
        htmlAttrs: { lang: "id", amp: true},
        meta: [
          { charset: "utf-8" },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name:"keywords", content:"Corporate Hicolleagues,HiColleagues,Kerjasama HiColleagues,IOT, Jasa IoT, AI, Jasa Pembuataan AI"},
          { name: "description", content: this.$route.meta.description},
        ],
        link: [
          {
            rel: 'canonical',
            href: thisPath
          }
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": this.$route.meta.title,
              "url": thisPath,
              "potentialAction": {
                "@type": "SearchAction",
                "target": thisPath
              }
            }
          }
        ]
      };
    },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView();
  }
}
</script>