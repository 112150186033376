<template>
  <div>
    <div class="text-center container" style="margin-top: 128px">
      <h2 class="sub-title-span">JELAJAHI KELAS</h2>
      <span style="margin-top: 20px"
        >Temukan kelas terbaik yang cocok untuk meningkatkan kompetensi mu di
        era teknologi digital. Mulai dari sekarang!</span
      >
    </div>

    <div class="d-flex container justify-center" style="margin-top: 41px">
      <!-- <div class="inactive" @click="bootcampMenu" :class="{ active: layout === 'bootcamp' }">
                <span>BOOTCAMP</span>
            </div> -->
      <div
        class="inactive"
        @click="techMenu"
        :class="{ active: layout === 'technology' }"
      >
        <span>TEKNOLOGI</span>
      </div>
      <div
        class="inactive"
        @click="languageMenu"
        :class="{ active: layout === 'language' }"
      >
        <span>BAHASA</span>
      </div>
      <div
        class="inactive"
        @click="softSkillMenu"
        :class="{ active: layout === 'soft-skill' }"
      >
        <span>SOFT SKILL</span>
      </div>
    </div>

    <!-- <BootcampCategory v-if="layout == 'bootcamp'"/> -->
    <TechnologyCategory v-if="layout == 'technology'" />
    <LanguageCategory v-if="layout == 'language'" />
    <SoftSkillCategory v-if="layout == 'soft-skill'" />
  </div>
</template>

<script>
import TechnologyCategory from "@/components/public-training/desktop/TechnologyCategory";
// import BootcampCategory from "@/components/public-training/desktop/BootcampCategory";
import LanguageCategory from "@/components/public-training/desktop/LanguageCategory";
import SoftSkillCategory from "@/components/public-training/desktop/SoftSkillCategory";
export default {
  components: {
    TechnologyCategory,
    // BootcampCategory,
    LanguageCategory,
    SoftSkillCategory,
  },
  data() {
    return {
      layout: "technology",
    };
  },
  methods: {
    // bootcampMenu() {
    //     this.layout = "bootcamp";
    // },
    techMenu() {
      this.layout = "technology";
    },
    languageMenu() {
      this.layout = "language";
    },
    softSkillMenu() {
      this.layout = "soft-skill";
    },
  },
};
</script>

<style src="./css/publicTraining.css" scoped></style>
