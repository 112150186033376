<template>
    
<svg :width="width"
:height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M35.1502 11.6326C33.7336 10.0659 31.3836 9.28255 27.9336 9.28255H27.5336V9.21589C27.5336 6.41589 27.5336 2.94922 21.2669 2.94922H18.7336C12.4669 2.94922 12.4669 6.41589 12.4669 9.21589V9.28255H12.0669C8.61689 9.28255 6.25023 10.0659 4.85023 11.6326C3.20023 13.4826 3.25023 15.9326 3.41689 17.6159L3.43356 17.7326L3.56264 19.2385C3.57584 19.3925 3.65943 19.5317 3.78909 19.6157C4.30089 19.9475 4.85515 20.3057 5.38356 20.5992C5.61689 20.7492 5.86689 20.8826 6.11689 21.0159C8.00023 22.0492 10.0169 22.8659 12.0836 23.4326C12.1336 27.7492 15.6669 31.2492 20.0002 31.2492C24.3669 31.2492 27.9169 27.6992 27.9169 23.3326V23.2659C30.0169 22.6326 32.0336 21.7492 33.9169 20.6492C34.0169 20.5992 34.0836 20.5492 34.1669 20.4992C34.8678 20.1183 35.5965 19.612 36.2512 19.146C36.3673 19.0633 36.4423 18.9348 36.4577 18.7931L36.5836 17.6326C36.6002 17.5326 36.6002 17.4492 36.6169 17.3326C36.7502 15.6659 36.7169 13.3659 35.1502 11.6326ZM14.8502 9.21589C14.8502 6.38255 14.8502 5.31589 18.7336 5.31589H21.2669C25.1502 5.31589 25.1502 6.38255 25.1502 9.21589V9.28255H14.8502V9.21589ZM20.0002 28.7492C17.2502 28.7492 14.9669 26.6826 14.6336 24.0159C14.6002 23.7992 14.5836 23.5659 14.5836 23.3326C14.5836 20.3492 17.0169 17.9159 20.0002 17.9159C22.9836 17.9159 25.4169 20.3492 25.4169 23.3326C25.4169 23.5326 25.4002 23.7159 25.3836 23.8992V23.9159C25.0836 26.6326 22.7836 28.7492 20.0002 28.7492Z" fill="#162CA2"/>
<path d="M19.0496 26.7172C18.733 26.7172 18.4163 26.6005 18.1663 26.3505L16.5163 24.7172C16.033 24.2339 16.033 23.4339 16.5163 22.9505C16.9996 22.4672 17.7996 22.4672 18.283 22.9505L19.083 23.7505L21.7663 21.2839C22.2663 20.8172 23.0663 20.8505 23.533 21.3505C23.9996 21.8505 23.9663 22.6505 23.4663 23.1172L19.9163 26.4005C19.6496 26.6005 19.3496 26.7172 19.0496 26.7172Z" fill="#162CA2"/>
<path d="M35.1858 22.8185C35.5493 22.6075 36.0291 22.9086 35.9908 23.3272L35.3861 29.9352C35.0361 33.2685 33.6694 36.6685 26.3361 36.6685H13.6361C6.30277 36.6685 4.9361 33.2685 4.60277 29.9518L4.00973 23.6019C3.97192 23.1971 4.40718 22.9194 4.76279 23.1165C4.82242 23.1496 4.87602 23.1788 4.91943 23.2018C6.21943 23.9185 7.56943 24.5518 8.95277 25.0518C9.46943 25.2352 9.8361 25.6685 9.9861 26.2018C11.2361 30.5352 15.2694 33.7518 20.0028 33.7518C24.8194 33.7518 28.8861 30.4685 30.0694 25.9685C30.2028 25.4352 30.5694 25.0018 31.0861 24.8018C32.4861 24.2518 33.8194 23.6018 35.0694 22.8852C35.0933 22.8719 35.134 22.8485 35.1858 22.8185Z" fill="#162CA2"/>
</svg>

</template>

<script>
export default {
  name: "SkillIcon",
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 30,
    }
  },
};
</script>