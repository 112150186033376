<template>
    
<svg :width="width" :height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.6663 37.9166H8.33301C4.29967 37.9166 2.08301 35.6999 2.08301 31.6666V18.3333C2.08301 14.2999 4.29967 12.0833 8.33301 12.0833H16.6663C17.3497 12.0833 17.9163 12.6499 17.9163 13.3333V31.6666C17.9163 34.2999 19.033 35.4166 21.6663 35.4166C22.3497 35.4166 22.9163 35.9833 22.9163 36.6666C22.9163 37.3499 22.3497 37.9166 21.6663 37.9166ZM8.33301 14.5833C5.69967 14.5833 4.58301 15.6999 4.58301 18.3333V31.6666C4.58301 34.2999 5.69967 35.4166 8.33301 35.4166H16.333C15.733 34.4333 15.4163 33.1833 15.4163 31.6666V14.5833H8.33301Z" fill="#7B7E8C"/>
<path d="M16.6663 14.5834H8.33301C7.64967 14.5834 7.08301 14.0167 7.08301 13.3334V10.0001C7.08301 7.46675 9.13301 5.41675 11.6663 5.41675H16.8497C17.233 5.41675 17.5997 5.60004 17.833 5.90004C18.0664 6.21671 18.1497 6.61675 18.0497 6.98342C17.9497 7.35009 17.9163 7.76675 17.9163 8.33341V13.3334C17.9163 14.0167 17.3497 14.5834 16.6663 14.5834ZM9.58301 12.0834H15.4163V8.33341C15.4163 8.18341 15.4163 8.05008 15.4163 7.91675H11.6663C10.5163 7.91675 9.58301 8.85008 9.58301 10.0001V12.0834Z" fill="#7B7E8C"/>
<path d="M23.333 22.9166C22.6497 22.9166 22.083 22.3499 22.083 21.6666V13.3333C22.083 12.6499 22.6497 12.0833 23.333 12.0833C24.0163 12.0833 24.583 12.6499 24.583 13.3333V21.6666C24.583 22.3499 24.0163 22.9166 23.333 22.9166Z" fill="#7B7E8C"/>
<path d="M30 22.9166C29.3167 22.9166 28.75 22.3499 28.75 21.6666V13.3333C28.75 12.6499 29.3167 12.0833 30 12.0833C30.6833 12.0833 31.25 12.6499 31.25 13.3333V21.6666C31.25 22.3499 30.6833 22.9166 30 22.9166Z" fill="#7B7E8C"/>
<path d="M29.9997 37.9166H23.333C22.6497 37.9166 22.083 37.3499 22.083 36.6666V29.9999C22.083 28.3999 23.3997 27.0833 24.9997 27.0833H28.333C29.933 27.0833 31.2497 28.3999 31.2497 29.9999V36.6666C31.2497 37.3499 30.683 37.9166 29.9997 37.9166ZM24.583 35.4166H28.7497V29.9999C28.7497 29.7666 28.5663 29.5833 28.333 29.5833H24.9997C24.7663 29.5833 24.583 29.7666 24.583 29.9999V35.4166Z" fill="#7B7E8C"/>
<path d="M10 29.5834C9.31667 29.5834 8.75 29.0167 8.75 28.3334V21.6667C8.75 20.9834 9.31667 20.4167 10 20.4167C10.6833 20.4167 11.25 20.9834 11.25 21.6667V28.3334C11.25 29.0167 10.6833 29.5834 10 29.5834Z" fill="#7B7E8C"/>
<path d="M31.667 37.9166H21.667C17.6337 37.9166 15.417 35.6999 15.417 31.6666V8.33325C15.417 4.29992 17.6337 2.08325 21.667 2.08325H31.667C35.7003 2.08325 37.917 4.29992 37.917 8.33325V31.6666C37.917 35.6999 35.7003 37.9166 31.667 37.9166ZM21.667 4.58325C19.0337 4.58325 17.917 5.69992 17.917 8.33325V31.6666C17.917 34.2999 19.0337 35.4166 21.667 35.4166H31.667C34.3003 35.4166 35.417 34.2999 35.417 31.6666V8.33325C35.417 5.69992 34.3003 4.58325 31.667 4.58325H21.667Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "40"
            },
            height: {
                type: String,
                default: "40" 
            }
        }
}
</script>