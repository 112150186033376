<template>
    
<svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="32" height="32" rx="16" fill="#F5F6F9"/>
<path d="M23.5 14.047C23.5 12.9877 22.6994 12.1291 21.7128 12.1291C20.7262 12.1291 19.9271 12.9877 19.9271 14.047C19.9271 16.3697 18.1645 18.2599 16.0008 18.2599C13.8355 18.2599 12.0729 16.3697 12.0729 14.047C12.0729 11.7243 13.8355 9.83416 16.0008 9.83416C16.9873 9.83416 17.7864 8.9756 17.7864 7.91627C17.7864 6.85857 16.9873 6 16.0008 6C11.8654 6 8.5 9.60891 8.5 14.047C8.5 17.5874 10.6437 20.6005 13.6127 21.6729C13.2946 21.9781 12.8751 22.1658 12.4156 22.1658C11.429 22.1658 10.6299 23.0244 10.6299 24.0821C10.6299 25.1414 11.429 26 12.4156 26C14.8313 26 16.8798 24.278 17.5529 21.9194C20.946 21.1489 23.5 17.9122 23.5 14.047Z" fill="#FFDE59"/>
<path d="M17.6988 14.0454C17.6988 14.1645 17.6881 14.2837 17.6666 14.4012C17.645 14.5187 17.6128 14.633 17.5697 14.7424C17.5267 14.8534 17.4745 14.9578 17.413 15.0574C17.3515 15.157 17.2808 15.25 17.2009 15.3349C17.1225 15.4181 17.0365 15.4948 16.9443 15.5601C16.8521 15.627 16.7537 15.6842 16.6508 15.7299C16.5478 15.7756 16.4418 15.8099 16.3311 15.8327C16.222 15.8572 16.1114 15.8686 16.0007 15.8686C15.8886 15.8686 15.7779 15.8572 15.6688 15.8327C15.5597 15.8099 15.4537 15.7756 15.3507 15.7299C15.2477 15.6842 15.1494 15.627 15.0572 15.5601C14.9634 15.4948 14.8774 15.4181 14.799 15.3349C14.7206 15.25 14.65 15.157 14.5885 15.0574C14.5255 14.9578 14.4732 14.8534 14.4317 14.7424C14.3887 14.633 14.3564 14.5187 14.3349 14.4012C14.3134 14.2837 14.3011 14.1645 14.3011 14.0454C14.3011 13.9262 14.3134 13.8071 14.3349 13.6896C14.3564 13.572 14.3887 13.4594 14.4317 13.3484C14.4732 13.2374 14.5255 13.133 14.5885 13.0334C14.65 12.9338 14.7206 12.8408 14.799 12.7575C14.8774 12.6727 14.9634 12.596 15.0572 12.5307C15.1494 12.4637 15.2477 12.4082 15.3507 12.3609C15.4537 12.3152 15.5597 12.2809 15.6688 12.2581C15.7779 12.2352 15.8886 12.2222 16.0007 12.2222C16.1114 12.2222 16.222 12.2352 16.3311 12.2581C16.4418 12.2809 16.5478 12.3152 16.6508 12.3609C16.7537 12.4082 16.8521 12.4637 16.9443 12.5307C17.0365 12.596 17.1225 12.6727 17.2009 12.7575C17.2808 12.8408 17.3515 12.9338 17.413 13.0334C17.4745 13.133 17.5267 13.2374 17.5697 13.3484C17.6128 13.4594 17.645 13.572 17.6666 13.6896C17.6881 13.8071 17.6988 13.9262 17.6988 14.0454Z" fill="#FF1616"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "40"
            },
            height: {
                type: String,
                default: "40" 
            },
        }
}
</script>