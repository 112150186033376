<template>
  <div class="body">
    <popup :message="notif.alertErrMessage" v-if="notif.isAlertErr" @close="notif.isAlertErr = false" />

    <div class="container-custom">
      <div class="form-page" v-if="!isConfirmPayment">
        <h1 class="form-title">Form Pendaftaran</h1>
        <div class="content">
          <v-row>
            <v-col md="7" sm="12" class="order-last order-md-first">
              <div class="card-item">
                <div class="card-item-padding">
                  <!-- Metode Form -->
                  <div class="input-box">
                    <span>Metode Pembelajaran</span>
                    <v-text-field v-model="classDetail.methode" disabled outlined class="mt-2 rounded-sm"></v-text-field>
                  </div>
                  <!-- Tipe Kelas -->
                  <div class="input-box">
                    <span>Tipe Kelas</span>
                    <v-text-field value="Acceleration Program" disabled outlined class="mt-2 rounded-sm"></v-text-field>
                  </div>
                </div>
                <div class="spacing-ctn"></div>
                <div class="card-item">
                  <div class="card-item-padding">
                    <!-- Titel -->
                    <div class="input-box">
                      <span>Titel</span>
                      <v-radio-group row ref="gender" v-model="gender" :error-messages="genderErrors"
                        @input="$v.gender.$touch()" @blur="$v.gender.$touch()" required>
                        <v-radio v-for="(item, idx) in genders" :key="idx" :label="item" :value="item"></v-radio>
                      </v-radio-group>
                    </div>
                    <!-- Nama Lengkap -->
                    <div class="input-box">
                      <span>Nama Lengkap</span>
                      <v-text-field placeholder="Masukkan Nama Lengkapmu" v-model="fullName" outlined required class="mt-2 rounded-sm"
                        :error-messages="fullNameErrors" @input="$v.fullName.$touch()"
                        @blur="$v.fullName.$touch()"></v-text-field>
                    </div>
                    <!-- No. Whatsapp -->
                    <div class="input-box">
                      <span>No. Whatsapp</span>
                      <v-row no-gutters>
                        <v-col cols="12">
                          <v-text-field prefix="(+62) " placeholder="Misal : 081X-XXXX-XXXX"
                            v-model="phoneNumber" required type="number"
                            :error-messages="phoneNumberErrors" @input="$v.phoneNumber.$touch()"
                            @blur="$v.phoneNumber.$touch()" outlined class="mt-2 rounded-sm custom-text-field">
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- Email -->
                    <div class="input-box">
                      <span>Email</span>
                      <v-text-field v-model="email" placeholder="Misal: noname@hicolleagues.com" @keydown.space.prevent
                        outlined required class="mt-2 rounded-sm" :error-messages="emailErrors"
                        @input="$v.email.$touch()" @blur="$v.email.$touch()"></v-text-field>
                    </div>
                    <!-- Tanggal Lahir -->
                    <div class="input-box">
                      <span>Tanggal Lahir</span>
                      <v-dialog ref="dialog" v-model="modal" :return-value.sync="birthdate" persistent width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="birthdate" append-icon="mdi-calendar" outlined
                            placeholder="Masukkan Tanggal Lahir" v-bind="attrs" v-on="on"
                            class="mt-2 rounded-sm custom-text-field" :error-messages="birthdateErrors"
                            @input="$v.birthdate.$touch()" @blur="$v.birthdate.$touch()"></v-text-field>
                        </template>
                        <v-date-picker v-model="birthdate" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal = false">
                            Cancel
                          </v-btn>
                          <v-btn text color="primary" @click="$refs.dialog.save(birthdate)">
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </div>

                    <!-- Instansi/ Perguruan Tinggi -->
                    <div class="input-box">
                      <span>Instansi/Perguruan Tinggi</span>
                      <v-text-field v-model="corporate_name" placeholder="Masukkan Nama Instansi/Perguruan Tinggi" outlined required class="mt-2 rounded-sm" @input="$v.corporate_name.$touch()" @blur="$v.corporate_name.$touch()"></v-text-field>
                    </div>

                    <!-- Motivasi Kegiatan -->
                    <div class="input-box">
                      <span>Motivasi Mengikuti Pelatihan ini</span>
                      <v-textarea placeholder="Tulis motivasimu di sini...." outlined v-model="notes" class="mt-2 rounded-sm" name="input-7-4" dense
                        counter="200"></v-textarea>
                    </div>
                  </div>
                </div>
                <div class="spacing-ctn"></div>
                <div class="card-item">
                  <div class="card-item-padding">
                    <div class="input-box">
                      <span>Masukan kode voucher</span>
                      <v-text-field v-model="voucherCode" outlined class="mt-2 input-box rounded-sm" dense required
                        clear-icon="mdi-close-circle" clearable @click:clear="voucherCode = ''"></v-text-field>
                    </div>

                    <div class="aggrement">
                      Dengan mengisi formulir ini saya menyetujui
                      <span class="info" @click="privacyPolicy">Kebijakan Privasi</span>
                      dan
                      <span class="info" @click="termCondition">Syarat dan Ketentuan</span>
                      untuk mengikuti program yang saya daftarkan.
                    </div>
                    <div class="daftar-btn">
                      <v-btn :class="`${validationRequired ? 'btn-terapkan' : 'btn-terapkan-disabled'
                        } font-weight-bold pa-5`" width="100%" :loading="isLoading" :disabled="!validationRequired"
                        @click.prevent="showDetailInvoice" >
                        DAFTAR
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <!-- right section -->
            <v-col md="5" sm="12">
              <div class="right-section">
                <!-- Header -->
                <div class="header">
                  <h3>Detail Kelas</h3>
                </div>
                <!-- Detail Center -->
                <div class="detail-center">
                  <v-row>
                    <v-col cols="6" sm="3">
                      <div class="hero-image">
                        <img :src="classDetail.image_url" alt="" style="width: 70px; height: 70px">
                      </div>
                    </v-col>
                    <v-col cols="6" sm="9">
                      <p class="class-title">{{classDetail.class_name}}</p>
                      <div style="display:flex; flex-wrap: wrap; gap: 10px;">
                        <p class="category-text" style="background-color: #F1F6FF;">{{classDetail.type}}</p>
                        <p class="category-text" style="background-color: #FAECEB;">{{classDetail.type_class}}</p>
                        <p class="category-text" style="background-color: #FAF6E7;">{{toMethode(classDetail.methode)}}</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <!-- Class Info -->
                <div class="section-class">
                  <!-- Row Info -->
                  <v-row class="class-info">
                    <v-col cols="4" class="class-info-level">
                      <Star fill="#7B7E8C" />
                      <p>{{ classDetail.level }}</p>
                    </v-col>
                    <v-col cols="4" class="class-info-session" >
                      <PeopleNew fill="#7B7E8C" />
                      <p>{{ classDetail.schedule.total_audience }} Peserta</p>
                    </v-col>
                    <v-col cols="4" class="class-info-method">
                      <DateIc2 />
                      <p>{{ classDetail.session }} Sesi</p>
                    </v-col>
                    <!-- Row Jadwal -->
                    <v-row>
                      <v-col>
                        <div class="class-schedule">
                          <Clock />
                          <div class="schedule-detail">
                            <p>{{classDetail.days}} ({{classDetail.hour}})</p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-row>
                </div>
                <div class="section-class mt-6" v-if="classDetail.is_discount">
                  <v-row class="class-info">
                    <v-col cols="4" class="price-title" style="height:70px;">
                      <p>Early Bird Price</p>
                    </v-col>
                    <v-col cols="8" class="price-section" style="height:70px;">
                      <div class="discount-price">
                        <p style="font-size: 14px; color: #B80F0A"><del>{{ toCurrency(classDetail.price) }}</del></p>
                        <p style="color: #162CA2">{{ toCurrency(classDetail.price- classDetail.discount_price) }}</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="section-class mt-6" v-else>
                  <v-row class="class-info">
                    <v-col cols="4" class="price-title" style="height:70px;">
                      <p>Normal Price</p>
                    </v-col>
                    <v-col cols="8" class="price-section-normal" style="height:70px;">
                      <div class="normal-price-center">
                        <p style="color: #162CA2">{{toCurrency(classDetail.price)}}</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div class="collab">
                    <p>Kolaborasi dengan</p>
                    <img :src="classDetail.collaboration_feed" alt="Collaboration feed" style="width: 65px">
                  </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="checkout-event" v-else>
      <div class="card-item card-item-padding" style="margin-top: 170px">
        <h2 class="text-center" style="margin-bottom: 20px">
          Rincian Pembelian
        </h2>
        <div class="flex-head gap-2">
          <div>
            <img
              :src="classDetail.image_url"
              style="margin-right: 5px; border-radius: 15px;width: 90px; height: 90px"
            />
          </div>
          <div>
            <h3 class="class-name">{{ classDetail.class_name }}</h3>
            <div style="display:flex; gap: 10px">
              <p class="category-text" style="background-color: #F1F6FF;">{{classDetail.type}}</p>
              <p class="category-text" style="background-color: #FAECEB;">{{classDetail.type_class}}</p>
              <p class="category-text" style="background-color: #FAF6E7;">{{toMethode(classDetail.methode)}}</p>
            </div>
          </div>
        </div>
        <!-- Class Info -->
        <div class="section-class">
          <!-- Row Info -->
          <v-row class="class-info">
            <v-col cols="4" class="info-level">
              <Star fill="#7B7E8C" />
                      <p>{{ classDetail.level }}</p>
            </v-col>
            <v-col cols="4" class="info-session">
              <PeopleNew fill="#7B7E8C" />
                      <p>{{ classDetail.schedule.total_audience }} Peserta</p>
            </v-col>
            <v-col cols="4" class="info-method">
              <DateIc2 />
                      <p>{{ classDetail.session }} Sesi</p>
            </v-col>
          </v-row>
        </div>
        <div class="collab">
          <p>Kolaborasi dengan</p>
          <img :src="classDetail.collaboration_feed" alt="">
        </div>
        <hr class="mt-5" />
        <div style="margin: 15px 0">
          <p class="price-title-head">Detail Pembayaran</p>
          <div class="price-info">
            
            <span>Subtotal</span>
            <span v-if="classDetail.is_discount">{{
              (classDetail.price - classDetail.discount_price) | toCurrency
            }}</span>
            <span v-else>{{ classDetail.price | toCurrency }}</span>
          </div>
          <div class="price-info">
            <span>Potongan</span>
            <span> - {{ discount ? toCurrency(discount) : "0" }}</span>
          </div>
        </div>
        <hr />
        <div class="price-info mt-5">
          <p class="price-title-head">Total</p>
          <p class="price-total" v-if="classDetail.is_discount">
            {{
              classDetail.price - classDetail.discount_price - discount > 0 ? toCurrency(classDetail.price - classDetail.discount_price - discount): "GRATIS"
            }}
          </p>
          <p class="price-total" v-else>
            {{
              classDetail.price - discount > 0? toCurrency(classDetail.price - discount) : "GRATIS"
            }}
          </p>
        </div>
        <div class="button-checkout">
          <Button
            name="LANJUT KE PEMBAYARAN"
            width="320px"
            :loading="isLoading"
            @click="toCheckout"
          />
        </div>
      </div>
    </div>
    </div >
  </div>
</template>

<script>
import Button from "@/components/others/Button.vue";
import {validationMixin} from "vuelidate";
import {required, email} from "vuelidate/lib/validators";
import moment from "moment";
import DateIc2 from "@/assets/icons/DateIc2.vue";
import PeopleNew from "@/assets/icons/PeopleNew.vue";
import Star from "@/assets/icons/Star.vue";
import Clock from '@/assets/icons/Clock.vue';
import Popup from "@/components/others/Popup.vue";

export default {
  name: "CheckoutAccleration",
  components: {
    Button,
    DateIc2,
    PeopleNew,
    Star,
    Clock,
    Popup
  },
  mixins: [validationMixin],
  validations: {
    fullName: {required},
    phoneNumber: {required},

    birthdate: {required},
    learningMethod: {required},
    // voucherCode: { required },
    // schedule: { required },
    gender: {required},
    classType: {required},
    email: {required, email},
  },
  data() {
    return {
      isDetail: true,
      isConfirmPayment: false,
      isLoading: false,
      modal: false,
      transactionId: 0,
      notif: {
        alertErrMessage: "",
        isAlertErr: false,
      },
      classDetail: {
        category_id: 6,
        class_id: 45,
        class_name:  "",
        class_type: "ACCELERATION_CLASS",
        collaboration_feed: "",
        days: "",
        discount_price: 0,
        hour: "",
        image_url: "",
        is_discount: false,
        level: "",
        methode: "",
        price: 0,
        schedule: {
          total_audience:'',
        },
        session: 1,
        type: "",
        type_class: ""
      },
      isDoubleClick: false,
      discount: 0,
      voucherCode: "",
      email: "",
      gender: "",
      fullName: "",
      phoneNumber: "",
      birthdate: "",
      corporate_name:"",
      notes: "",
      genders: ["Tuan", "Nyonya", "Nona"],
    };
  },
  computed: {
    fullNameErrors() {
      const errors = [];
      if (!this.$v.fullName.$dirty) return errors;
      !this.$v.fullName.required &&
        errors.push("Nama lengkap tidak boleh kosong.");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.gender.$dirty) return errors;
      !this.$v.gender.required && errors.push("Titel tidak boleh kosong");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid email");
      !this.$v.email.required && errors.push("Email tidak boleh kosong");
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.required &&
        errors.push("Nomor telepon tidak boleh kosong.");
      return errors;
    },
    birthdateErrors() {
      const errors = [];
      if (!this.$v.birthdate.$dirty) return errors;
      !this.$v.birthdate.required &&
        errors.push("Tanggal lahir tidak boleh kosong.");
      return errors;
    },
    validationRequired() {
        if (
          this.schedule != "" &&
          this.learningMethod != "" &&
          this.classType != "" &&
          this.gender != "" &&
          this.phoneNumber != "" &&
          this.email != "" &&
          this.birthdate != "" &&
          this.fullName != ""
        ) {
          return true;
        } else {
          return false;
        }
    },
  },
  methods: {
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    async checkDiscountVoucher() {
      try {
        const resp = await this.$http.get(
          "/v1/voucher/" + this.voucherCode
        );
        this.discount = resp.data.data.voucher.discount;
        return true;
      } catch (err) {
        this.isLoading = false;
        this.notif.isAlertErr = true;
        this.notif.alertErrMessage = "Voucher tidak di temukan";
        return false;
      }
    },
    // show detail invouce
    async showDetailInvoice() {
      this.$v.$touch();
      if (this.phoneNumber.length < 10) {
        this.notif.isAlertErr = true
        this.notif.alertErrMessage = "Nomor telepon tidak valid"
        return;
      }      
      if (this.voucherCode) {
        const confirmVoucher = await this.checkDiscountVoucher();
        if (!confirmVoucher) {
          return;
        }  
      }
      this.isConfirmPayment = true;
    },
    // post request traansaction
    async toCheckout() {
      this.isLoading = true;
      if (this.isDoubleClick) {
        setTimeout(() => {
          this.isDoubleClick = false
        }, 3000)
        return
      }
      this.isDoubleClick = true
      try {
        let discount_price = 0;
        if (this.classDetail.is_discount) {
          discount_price = this.classDetail.discount_price;
        }
        const total_price = this.classDetail.price - discount_price - this.discount
        const resp = await this.$http.post("/v1/transaction", {
            class_id: this.classDetail.class_id,
            schedule: moment(this.classDetail.schedule.start_date).format("DD MMM YYYY") + " - " + moment(this.classDetail.schedule.end_date).format("DD MMM YYYY"),
            learning_method: this.classDetail.methode,
            class_type: "ACCELERATION_CLASS",
            full_name: this.fullName,
            phone_number: this.phoneNumber,
            email: this.email,
            gender: this.gender,
            birthday: this.birthdate,
            motivation_note: this.notes,
            voucher_code: this.voucherCode,
            discount: this.discount,
            corporate_name: this.corporate_name,
            price: this.classDetail.price,
            total_price: total_price,
            status: "CREATED",
        })
        if (total_price > 0) {
          window.open(resp.data.data.transaction.url);
        }
        this.isLoading = false;
        this.$router.push("/corporate/success");
      } catch (err) {
        this.isLoading = false;
        this.notif.isAlertErr = true;
        this.notif.alertErrMessage = err.response.data.data;
      }
    },
    toMethode(value) {
      if (!value) return
      if (value.toLowerCase() === "online") {
        return "Daring"
      } else if (value.toLowerCase() === "offline") {
        return "Luring"
      } else {
        return value
      }
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
          maximumFractionDigits: 0
      });
      return formatter.format(value);
    },
    privacyPolicy() {
      this.$router.push("/privacy-policy");
    },
    termCondition() {
      this.$router.push("/term-condition");
    },
    mappingDataChechkout() {
      const checkout = localStorage.getItem('checkout')
      if (!checkout) return
      this.classDetail = JSON.parse(checkout)
    }
  },
  mounted() {
    this.mappingDataChechkout()
    this.$utils.scrollTop()
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.checkout-event {
  width: 50%;
  margin: 50px auto;
}

.price-info {
  display: flex;
  justify-content: space-between;
}

.body {
  background: #f5f6fa;
}

.form-title {
  text-align: left;
  margin-top: 80px;
  margin-bottom: 5px;
  font-size: 32px;
  font-weight: bolder;
}

.form-page {
  height: auto;
  padding: 0px 35px 0px 35px;
  background: #f5f6fa;
  margin-bottom: 20px;
}

.form-page .content {
  margin: auto;
  background: #f5f6fa;
}

.form-default {
  width: 800px;
  background: #000080;
}

.detail-class {
  height: auto;
  background: rgb(1, 255, 103);
  padding: 0px 35px 0px 35px;
  margin: auto;
}

.hero-image {
  height: 80px;
  width: 80px;
  background: #acafbf;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: #F5F6FA;
}

.class-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.class-item-class {
  background: yellow;
}

.detail-center {
  display: flex;
  justify-content: space-between;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.schedule-detail {
  font-size: 13px;
}

.c-detail {
  font-weight: bolder;
  font-size: 17px;
}

.class-name {
  font-weight: 600 !important;
  line-height: 28px;
  font-size: 16px;
  margin-bottom: 8px;
}
span {
  font-family: "Poppins", sans-serif !important;
  font-size: 15px;
  font-weight: 500;
}

.input-box span {
  display: block;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: -3px;
  font-family: "Poppins", sans-serif !important;
}

.spacing-ctn {
  margin-top: -20px;
  background-color: #f5f6fa;
  height: 10px;
}

.btn-terapkan {
  width: 100%;
  color: #fff;
  border-radius: 13px;
  background-color: #000080 !important;
}

.btn-terapkan-disabled {
  width: 100%;
  color: #fff;
  border-radius: 13px;
  background-color: #acafbf !important;
}

.checkout-page {
  font-family: "Poppins" !important;
}

.title-info {
  font-size: 23px;
  font-weight: 900 !important;
}

.card-item {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 30px 30px 30px 30px !important;
}

.card-item-padding {
  padding: 20px 40px;
}

.right-section {
  background: #ffffff;
  margin-top: 20px;
  border-radius: 30px 30px 30px 30px !important;
  padding: 20px 40px;
}

.card-item .harga {
  margin: 0;
  margin-top: 10px;
  color: #7b7e8c;
}

.card-item .price {
  font-weight: bolder;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px;
}


.class-info {
  margin-top: 20px;
  display: flex;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.class-info-level {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 0;
  border: #E7E8F0 solid 1px;
  border-radius: 10px 0px 0px 0px;
}

.class-info-session {
  display: flex;
  margin: 0;
  gap: 10px;
  align-items: center;
  border: #E7E8F0 solid 1px;
}

.class-info-method {
  display: flex;
  margin: 0;
  gap: 10px;
  align-items: center;
  border: #E7E8F0 solid 1px;
  border-radius: 0px 10px 0px 0px;
}

.info-level {
  display: flex;
  margin: 0;
  border: #E7E8F0 solid 1px;
  border-radius: 10px 0px 0px 10px;
}

.info-session {
  display: flex;
  margin: 0;
  border: #E7E8F0 solid 1px;
}

.info-method {
  display: flex;
  margin: 0;
  border: #E7E8F0 solid 1px;
  border-radius: 0px 10px 10px 0px;
}

p {
  margin-bottom: 0px;
}

.class-info-content {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.class-info-content span {
  font-family: "Poppins", sans-serif !important;
}

.class-info-content .mob {
  display: flex;
  align-items: center;
}

.class-info-detail {
  display: flex;
  flex-direction: column;
}

.class-info-detail .name {
  color: #575966;
  font-size: 16px;
}

.container-custom {
}

.checkout-box {
  display: flex;
  gap: 30px;
}

.checkout-box-left {
  width: 65%;
  position: relative;
}

.rounded-lg {
  border-radius: 30px !important;
}

.aggrement {
  font-size: 15px;
  width: 90%;
  max-width: 550px;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  font-family: "Poppins", sans-serif !important;
}

.aggrement .info {
  color: #000080;
  font-weight: bold;
  cursor: pointer;
  font-family: "Poppins", sans-serif !important;
  background-color: white !important;
}

.min-t {
  margin-top: -20px;
}

.daftar-btn {
  max-width: 240px;
  width: 100%;
  margin: 30px auto;
}

.b-spacing {
  height: 2px;
}

.rounded-sm {
  border-radius: 11px !important;
}

.checkout-box-right {
  width: 35%;
}

.gray--text {
  color: gray;
}

.disouned {
  text-decoration: line-through;
  color: rgb(252, 31, 31);
}

::v-deep .v-text-field .v-text-field__prefix {
  margin-right: 30px;
}

.category-text {
  width: auto;
  height: 22px;
  background: #D3D6E1;
  margin-right: 10px;
  padding: 2px 10px 2px 10px;
  border-radius: 30px;
  margin-top: 5px;
  font-size: 12px;
}

.class-schedule {
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid #E7E8F0;
  padding: 12px;
  border-radius: 0px 0px 10px 10px;
}

.class-detail {
  display: flex;
}

.normal-price {
  font-weight: 400;
  font-size: 15px;
  text-decoration: line-through;
}

.discount-price {
  font-weight: 700;
  font-size: 20px;
}


.price-section {
  border-radius: 0px 10px 10px 0px;
  border: #E7E8F0 solid 1px;
}

.price-title-head {
    font-size: 15px;
    font-weight: 600;
}

.price-total {
  color: #b80f0a;
  font-size: 16px;
  font-weight: 700;
}

.price-title {
  border-radius: 10px 0px 0px 10px;
  border: #E7E8F0 solid 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.price-section-normal {
  display: flex;
  align-items: center;
  border: #E7E8F0 solid 1px;
  border-radius: 0px 10px 10px 0px;
}

.normal-price-center {
  font-weight: 700;
font-size: 20px;
}

.collab {
  margin-top: 30px;
}

.collab p{
  font-size: 13px;
}

.button-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 18px;
}
.flex-head {
  display: flex;
}
@media screen and (max-width: 550px) {
  .container-custom {
    margin: 0 !important;

  }
  .checkout-event {
  width: 90%;
  margin: 50px auto;
  }
  .collab img {
    margin-top: 10px;
    border-radius: 8px;
    width: 100px;
  }
  .flex-head {
    display: block;
  }
}


</style>
