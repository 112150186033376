<template>
    <div class="container-size">
        <div class="text-center mb-10" style="margin-top:150px"><img src="/img/other/ic_success.png" height="300"></div>
        <div class="text-center font-weight-bold mt-10"><span style="font-size:2.0rem">Selamat!! Akumu telah aktif.</span></div>
        <div class="text-center mt-5 mb-10"><span style="font-size:1.0rem">Kami mengirimkan default password. Mohon cek email kamu secara berkala di <b>inbox/spam.</b></span></div>
        <div class="text-center mt-5 mb-15">
            <v-btn depressed color="#000080" class="pa-5" @click="goToLMS">
                <span class="text-capitalize white--text font-weight-bold">Masuk LMS</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import setHeader from '@/utils/auth.js'
export default {
    methods: {
        async getVerification() {
            // this.spin_load.val = true
            await this.$http
            .put("/v1/account/active/"+this.$route.params.token)
            .then(() => {
                setHeader(this.$route.params.token)
                this.sendDefaultPassword()
            })
            .catch((err) => console.error(err.response))
            // .finally(() => this.spin_load.val = false)
        },
        async sendDefaultPassword() {
            // this.spin_load.val = true
            await this.$http
            .post("/v1/account/default-password")
            .then(() => {

            })
            .catch((err) => console.error(err.response))
            // .finally(() => this.spin_load.val = false)
        },
        goToLMS() {
            window.open(
                'https://www.hicolleagues.com/login',
                '_blank' // <- This is what makes it open in a new window.
            )
        },
    },
    mounted() {
        this.getVerification()
    }
}
</script>