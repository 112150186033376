<template>
    
<svg :width="width" :height="height" :style="`transform: rotate(${direct ? (direct == 'left'? 90 : 270) : (!down ? 180 : 0)}deg)`"  viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.5999 7.95834L11.1666 13.3917C10.5249 14.0333 9.4749 14.0333 8.83324 13.3917L3.3999 7.95834" stroke="currentColor" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>
<script>
export default {
    props: {
            width: {
                type: Number,
                default: 25
            },
            height: {
                type: Number,
                default: 25 
            },
            direct: {
                type: String,
            },
            down: {
                type: Boolean,
                default: false
            },    
        }
}
</script>