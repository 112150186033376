<template>
    <div style="margin-top: 20px">
      <div style="padding: 20px" class="container">
        <div style="margin-bottom: 10px" class="navigation">
          <span
            @click="$router.push('/alumni-stories')"
            class="nav-active font-poppins"
            >Beranda</span
          >
          <Arrow :width="20" direct="left" />
          <span
            @click="$router.push('/alumni-stories')"
            class="nav-active font-poppins"
            >Portfolio</span
          >
          <Arrow :width="20" direct="left" />
          <span @click="$router.push('/alumni-stories')" class="nav font-poppins"
            >Detail Portfolio</span
          >
        </div>
        <img loading="lazy" :src="portfolio.image_url" class="img-main-dekstop" />
  
        <button
          style="
            background-color: #f1f6ff;
            padding: 4px 12px;
            border-radius: 30px;
            margin: 10px 0px;
            font-size: 12px;
          "
        >
          {{ categoryList[portfolio.category] }}
        </button>
  
        <h2 style="margin: 0; font-size: 22px">{{portfolio.title}}-{{ portfolio.program }}</h2>
        <br />
        <div
          class="text-justify"
          style="font-size: 14px; line-height: 28px"
          v-html="portfolio.description"
        ></div>
        <div>
          <h2>Scope of Project</h2>
          <div class="grid-2">
            <div class="m-auto w-full text-center image-scope rounded-lg" v-if="portfolio.scope_project[0].val">
              <img
                style="margin-top: 15px"
                loading="lazy"
                class="rounded-xl"
                src="/img/portfolio/it_consulting.png"
                alt="IT Consulting"
              />
              <p>IT Consulting</p>
            </div>
            <div class="m-auto w-full text-center image-scope rounded-lg" v-if="portfolio.scope_project[1].val">
              <img
                style="margin-top: 15px"
                loading="lazy"
                class="rounded-xl"
                src="/img/portfolio/it_sourcing.png"
                alt=""
              />
              <p>IT Sourcing</p>
            </div>
            <div class="m-auto w-full text-center image-scope rounded-lg" v-if="portfolio.scope_project[2].val">
              <img
                style="margin-top: 15px"
                loading="lazy"
                class="rounded-xl"
                src="/img/portfolio/it_implementation.png"
                alt=""
              />
              <p>IT Implementation</p>
            </div>
            <div class="m-auto w-full text-center image-scope rounded-lg" v-if="portfolio.scope_project[3].val">
              <img
                style="margin-top: 15px"
                loading="lazy"
                class="rounded-xl"
                src="/img/portfolio/it_support.png"
                alt=""
              />
              <p>IT Support</p>
            </div>
          </div>
        </div>
        <div>
          <h2 style="color: #7b7e8c">Key Feature</h2>
          <div class="flex-column" style="margin: 0px 0px 80px 0px" v-for="(item, idx) in portfolio.key_feature" :key="idx">
            <img :src="item.img" alt="" />
            <h3 class="h3-box-detail-portfolio">
              {{ item.title }}
            </h3>
            <p class="pragraf-box-detail-portfolio" v-html="item.description"> </p>
          </div>
        </div>
        <div style="height: 30px"></div>
      </div>
      <v-card elevation="0" class="call-center-container">
        <v-row style="padding: 20px 8px">
          <v-col cols="12">
            <div class="call-center-title">
              Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam
              Transformasi Digital Berkelanjutan
            </div>
            <div class="call-center-desc">
              Dapatkan berbagai informasi yang kamu butuhkan melalui layanan
              konsultasi 24/7 Customer Service HiColleagues
            </div>
          </v-col>
          <v-col cols="12" class="call-center-button" @click="sendWa">
            <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </template>
  
  <script>
  import Arrow from "@/assets/icons/Arrow.vue";

  export default {
    name: "MobileDetailAlumniStories",
    components: {
      Arrow,
    },
    data() {
      return {
        portfolio: {
          image_url: "",
          title: "",
          label: "",
          body: "",
          program: "",
          description: "",
          category: "SERVICES",
          scope_project: [],
                key_feature: [
                    {val: false},
                    {val: false},
                    {val: false},
                    {val: false},
                ]
        },
        categoryList: {
                COLLABORATION: "Corporate Collaboration",
                TRAINING: "Corporate Training",
                SERVICES: "Technology Service",
                KAMPUS_MERDEKA: "Kampus Merdeka"
            },
      };
    },
    methods: {
      sendWa() {
        this.$router.push("/corporate");
      },
      toRedirect(to) {
        window.open(to);
      },
      async getPortfolioDetail() {
        try {
            const resp = await this.$http.get(`/v1/project-portfolio/${this.$route.params.id}`)
            this.portfolio = resp.data.data.project_portfolio
        } catch (err) {
            console.log(err);
        }
      },
    },
    mounted() {
      this.getPortfolioDetail();
    },
  };
  </script>
  <style scoped src="./detail.css"></style>
  