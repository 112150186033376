<template>
  <v-app-bar fixed color="#162CA2" class="custom-header">
    <v-container d-flex>
      <v-toolbar flat style="background-color: #162CA2 !important;">
        <v-toolbar-title class="text-uppercase blue--text logo-ctn">
          <Logo @click="goBackHome" />
          <div class="line"></div>
          <span class="corporate" @click="$router.push('/corporate')">CORPORATE SERVICE</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <div class="mr-5">
          <div class="hc-nav-container">
            <div
              :class="`hc-nav-content ${link.active ? 'active' : ''}`"
              v-for="link in links"
              :key="link.text"
              @click="linkedNav(link.route)"
            >
              <span>{{ link.text }} </span>
              <!-- <div v-if="link.active" class="line"></div> -->
            </div>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn depressed color="#FFDE59" @click="goToLoginPage" class="btn-login-rounded" style="margin-left: 30px; width:110px; height: 40px">
          <span class="text-capitalize font-weight-bold">MASUK</span
          >
        </v-btn>
      </v-toolbar>
    </v-container>
  </v-app-bar>
</template>

<script>
import Logo from '../assets/icons/Logo.vue';
export default {
  name: "HeaderCorporate",
  data() {
    return {
      is_login_show: true,
      is_profile_show: true,
      isSubMenuActive: false,
      full_name: "",
      token: "",
      links: [
        {
          text: "Acceleration Program",
          route: "/corporate/acceleration",
          active: false,
          type: null,
        },
        {
          text: "Corp Collaboration",
          route: "/corporate/collaboration", // /corporate/collaboration
          active: false,
          type: null,
        },
        {
          text: "Corp Training",
          route: "/corporate/training",
          active: false,
          type: null,
        },
        {
          text: "Talent as a Service",
          route: "/corporate/talent-service", // /corporate/talent-service
          active: false,
          type: null,
        },
        {
          text: "Tech Service",
          route: "/corporate/services", // 
          active: false,
          type: null,
        },
      ],
    };
  },
  components: {
    Logo
  },
  watch: {
    $route() {
      this.activeNav();
    },
  },
  methods: {
    activeNav() {
      this.links.forEach((item) => (item.active = false));
      if (this.$route.path === '/corporate') return
      try {
        let beforActiveIdx;
        const validPath = this.links.find(
          (item) => item.route === this.$route.path
        );
        if (validPath) {
          this.links.find(
            (item) => item.route === this.$route.path
          ).active = true;
          let beforActiveIdx = this.links.findIndex(
            (item) => item.active == true
          );
          this.isSubMenuActive = false;
          if (beforActiveIdx < 0) return;
          sessionStorage.setItem("nav-item-active-corp", beforActiveIdx);
        } else {
          if (!beforActiveIdx) {
            beforActiveIdx = sessionStorage.getItem("nav-item-active-corp");
          }
          this.links[beforActiveIdx].active = true;
        }
      } catch {
        undefined;
      }
    },
    goToLoginPage() {
      this.$router.push("/login")
    },
    linkedNav(link) {
      if (!link) {
        this.links.forEach((item) => (item.active = false));
        this.links[0].active = true;
        this.isSubMenuActive = !this.isSubMenuActive;
        return;
      }
      this.$router.push({ path: link})
    },
    goBackHome() {
      window.open("/")
    },
    
    backToHome() {
      window.open("/")
    }
  },
  mounted() {
    this.activeNav();
  },
};
</script>

<style src="./css/headerCorporate.css" scoped></style>
