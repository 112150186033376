<template>
    <div class="background">
        <div class="outer-container-portfolio">
            <div class="navigation">
                <span @click="$router.push('/')" class="nav-active font-poppins">Home</span>
                <Arrow :width="20" direct="left" />
                <span @click="$router.push('/portfolio')" class="nav-active font-poppins">Portfolio</span>
                <Arrow :width="20" direct="left" />
                <span class="font-poppins nav-inactive">Detail portfolio</span>
            </div>
            <div class="container-portfolio">
                <img loading="lazy" :src="portfolio.image_url" class="portfolio-main-img" />
                <div class="container-portfolio-content">
                    <div class="container-portfolio-content-info">
                        <p class="portfolio-category">{{categoryList[portfolio.category]}}</p>
                        <div class="container-portfolio-content-description">
                            <h2>{{portfolio.title}}-{{ portfolio.program }}</h2>
                            <p v-html="portfolio.body"></p>
                        </div>
                    </div>
                    <div class="container-portfolio-sop">
                        <h3>Scope of Project</h3>
                        <div class="container-sop-list">
                            <div class="sop-box" v-if="portfolio.scope_project[0].val">
                                <img src="/img/portfolio/it_consulting.png" alt="">
                                <p>IT Consulting</p>
                            </div>
                            <div class="sop-box" v-if="portfolio.scope_project[1].val">
                                <img src="/img/portfolio/it_sourcing.png" alt="">
                                <p>IT Sourcing</p>
                            </div>
                            <div class="sop-box" v-if="portfolio.scope_project[2].val">
                                <img src="/img/portfolio/it_implementation.png" alt="">
                                <p>IT Implementation</p>
                            </div>
                            <div class="sop-box" v-if="portfolio.scope_project[3].val">
                                <img src="/img/portfolio/it_support.png" alt="">
                                <p>IT Support</p>
                            </div>
                        </div>
                    </div>
                    <div class="container-portfolio-kf">
                        <h4>Key Feature</h4>
                        <div class="container-kf-list">
                            <div class="kf-item" v-for="(item, idx) in portfolio.key_feature" :key="idx">
                                <img :src="item.img" alt="">
                                <div class="kf-item-details">
                                    <h3>{{ item.title }}</h3>
                                    <p v-html="item.description"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-card elevation="0" class="call-center-container">
            <v-row style="padding: 40px">
                <v-col cols="8">
                    <div class="call-center-title">
                        Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam Transformasi Digital
                        Berkelanjutan
                    </div>
                    <div class="call-center-desc">
                        Dapatkan berbagai informasi yang kamu butuhkan melalui layanan konsultasi 24/7 Customer Service
                        HiColleagues
                    </div>
                </v-col>
                <v-col cols="1" />
                <v-col cols="3" class="call-center-button" @click="handleToCorporatePage">
                    <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import Arrow from "@/assets/icons/Arrow.vue";

export default {
    name: "DekstopPortfolioDetail",
    components: {
        Arrow,
    },
    data() {
        return {
            portfolio: {
                image_url: "",
                title: "",
                label: "",
                body: "",
                program: "",
                description: "",
                category: "SERVICES",
                scope_project: [],
                key_feature: [
                    {val: false},
                    {val: false},
                    {val: false},
                    {val: false},
                ]
            },
            categoryList: {
                COLLABORATION: "Corporate Collaboration",
                TRAINING: "Corporate Training",
                SERVICES: "Technology Service",
                KAMPUS_MERDEKA: "Kampus Merdeka"
            },
        }
    },
    methods: {
        handleToCorporatePage() {
            return this.$router.push('/corporate');
        },
        toRedirect(to) {
            window.open(to)
        },
        async getPortfolioDetail() {
            try {
                const resp = await this.$http.get(`/v1/project-portfolio/${this.$route.params.id}`)
                this.portfolio =  resp.data.data.project_portfolio
            } catch (err) {
                console.log(err);
            }
        }
    },
    mounted() {
        this.getPortfolioDetail()
    }
}
</script>

<style scoped src="./detail.css"></style>