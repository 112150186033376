<template>
  <div class="mt-10">
    <popup
      :message="notif.alertErrMessage"
      v-if="notif.isAlertErr"
      @close="notif.isAlertErr = false"
    />
    <div class="head-class">
      <div class="head-class-ctn">
        <div class="left">
          <p class="text-banner">
            Temukan Event Menarik & <br>
            Buat Digital Upskilling-mu Makin Seru!
          </p>
          <p class="description-class">
            Ikuti event yang HiColers minati dan siap melesat bersama
            HiColleagues!
          </p>
        </div>
      </div>
    </div>
    <div class="container" style="text-align: center; margin-top: 70px">
      <h1 class="sub-title-span" style="margin: 0;">Kenapa Harus Ikut Event HiColleagues?</h1>
      <span class="font-poppins">Berbagai benefit yang akan HiColers dapatkan dalam Event
        HiColleagues</span>
      <div class="mb-10 card-bootcamp">
        <div v-for="n in benefits" :key="n.id" class="content-card mr-2 ml-2">
          <div class="left-span">
            <img :src="n.image" width="120" height="120" />
          </div>
          <div class="title-2">{{ n.title }}</div>
          <div class="desc mt-2" style="font-family: 'Poppins', sans-serif;">{{ n.desc }}</div>
        </div>
      </div>
      <div class="mb-10">
        <h1 class="sub-title-span" style="margin: 0;">Rekomendasi Event HiColleagues</h1>
        <span>Event menarik yang tersedia untuk HiColers</span>
        <div class="event-list mt-10">
          <div class="event-box" v-for="(item, idx) in events" :key="idx" :title="item.name">
            <img
              :src="item.img_urls.desktop.thumbnail"
              alt="Content"
              class="event-box-img"
              @click="toDetail(item)"
            />
            <div class="event-ctn">
              <div @click="toDetail(item)">
                <h3 class="judul-event">{{ item.name }}</h3>
                <div class="event-ctn-label">
                  <span>{{ item.category.name }}</span>
                </div>
                <div class="event-ctn-info">
                  <DateIcon fill="#575966" />
                  <span class="name">{{ moment(item.session_schedules[0].date_start) }}</span>
                </div>
                <div class="event-ctn-info">
                  <clock fill="#575966" />
                  <span class="name"
                    >{{ item.session_schedules[0].start_time }} - {{ item.session_schedules[0].end_time }} WIB</span
                  >
                </div>
                <div class="event-ctn-info">
                  <methode fill="#575966" />
                  <span class="name">{{ item.method | toMethode }}</span>
                </div>
                <div class="event-ctn-info" v-if="item.instructors.length">
                  <speaker fill="#575966" />
                  <span class="name"
                    >{{ item.instructors[0].name
                    }}<span v-if="item.instructors.length > 1"
                      >, {{ item.instructors.length - 1 }}+ lainnya</span
                    ></span>
                </div>
                <div style="height: 35px" v-else></div>
                <div style="height: 50px;">
                  <p class="event-price" v-if="!checkIsEarlyBird(item)">
                    {{ item.price ? toCurrency(item.price) : "GRATIS" }}
                  </p>
                  <div v-else>
                    <span class="event-price mr-2">{{ (item.price - item.discount_price) ? toCurrency(item.price - item.discount_price) : "GRATIS" }}</span><del class="discount-price">{{ toCurrency(item.price) }}</del><br>
                    <span style="font-size: 14px;"><b style="color: #162ca2; font-family: 'Poppins';">Early Bird Promo</b>! Diskon {{discountPercent(item)}}%</span>
                  </div>
                </div>
                <div style="height: 60px; margin-top: 20px;">
                  <div class="event-due-date" v-if="item.expired.length">
                    <div class="event-due-date-ctn">
                      <span>{{ item.expired[0] }}</span>
                      <span>Hari</span>
                    </div>
                    <span>:</span>
                    <div class="event-due-date-ctn">
                      <span>{{ item.expired[1] }}</span>
                      <span>Jam</span>
                    </div>
                    <span>:</span>
                    <div class="event-due-date-ctn">
                      <span>{{ item.expired[2] }}</span>
                      <span>Menit</span>
                    </div>
                    <span>:</span>
                    <div class="event-due-date-ctn">
                      <span>{{ item.expired[3] }}</span>
                      <span>Detik</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="display: flex; justify-content: center; margin: 10px 0"
              >
                <Button
                  :disabled="!item.expired.length"
                  name="DAFTAR"
                  width="250px"
                  @click="() => toCheckout(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg mb-10">
        <div class="box-content">
          <div>
            <span class="title font-poppins"
              >Masih Bingung untuk Gabung Event HiColleagues?</span
            >
            <div>
              <span class="description font-poppins"
                >Sesuaikan minatmu dengan konsultasi bersama HiColleagues</span
              >
            </div>
          </div>
          <div>
            <div class="d-flex bg-yellow justify-center" @click="contactMe">
              <span class="exhange-voucher title font-poppins">KONSULTASI GRATIS</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="faq" style="margin-top: 60px;">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header" style="margin-bottom: 20px;">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: -10px;">
            <v-expansion-panels flat class="faq-card" style="padding: 20px; background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow style="margin-right: 35px;" />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" style="margin-right: 35px;" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div style="padding: 25px 30px;">
            <div class="btn-stoke-blue cursor-pointer" @click="$router.push('/faq')"  style="margin: 0;">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Button from "../../others/Button.vue";
import Clock from "@/assets/icons/Clock.vue";
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import DateIcon from "@/assets/icons/Date.vue";
import Speaker from "@/assets/icons/Speaker.vue";
import Methode from "@/assets/icons/Methode.vue";
import Popup from "@/components/others/Popup.vue";

export default {
  name: "EventDesktop",
  data() {
    return {
      benefits: [
        {
          image: "/img/event/narasumber-profesional.png",
          title: "Narasumber Profesional",
          desc: "Event semakin menarik karena diisi oleh narasumber profesional & pengalaman di bidangnya",
        },
        {
          image: "/img/event/topik-ter-update.png",
          title: "Topik Ter-update",
          desc: "Topik hangat dan menarik tersedia untuk melengkapi wawasan, informasi & keterampilan HiColers",
        },
        {
          image: "/img/event/interaktif-sesi.png",
          title: "Interaktif Sesi",
          desc: "Komunikasi 2 arah antara narasumber dan peserta selama pelaksanaan event berlangsung",
        },
        {
          image: "/img/event/e-certificate.png",
          title: "E-Certificate",
          desc: "Peserta mendapatkan sertifikat Partisipan atau Penyelesain setelah event berlangsung ",
        },
        {
          image: "/img/event/harga_terjangkau.png",
          title: "Harga Terjangkau",
          desc: "Tidak perlu risau karena harga event terjangkau dan terdapat event gratis",
        },
      ],
      notif: {
        alertErrMessage: "",
        isAlertErr: false,
      },
      events: [],
      questions: [],
      isAlertErr: false,
    };
  },
  components: {
    Button,
    ArrowLong,
    Arrow,
    DateIcon,
    Popup,
    Clock,
    Methode,
    Speaker,
  },
  methods: {
    contactMe() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    moment(date) {
      return moment(date).format("dddd, DD MMMM YYYY");
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=EVENT&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    async getTotalRegistran(id) {
      try {
        const resp = await this.$http.get(`/v1/event/${id}/registrant`);
        return resp.data.data.total_registrants  
      } catch (error) {
        console.log(error);
        return 0
      }
    },
    async toCheckout(item) {
      const total_registrants = await this.getTotalRegistran(item.id)
      if (total_registrants >= item.participant_quota && item.participant_quota !== 0) {
        this.notif.isAlertErr = true
        this.notif.alertErrMessage = "Kouta pendaftaran sudah penuh"
        return
      }
      this.$router.push("/checkout/event/" + item.id);
    },
    toDetail(item) {
      this.$router.push("/event/detail/" + item.id);
    },
    discountPercent(event) {
      return Math.round(((event.price - (event.price - event.discount_price))/(event.price))*100)
    },
    countDownTimer() {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let days, hours, minutes, seconds;
      const showRemaining = () => {
        this.events.forEach((item) => {
          const end = new Date(item.registration_close_date);
          const now = new Date();
          const distance = end - now;
          if (distance < 0) {
            item.expired = [];
            return;
          }
          days = Math.floor(distance / _day);
          hours = Math.floor((distance % _day) / _hour);
          minutes = Math.floor((distance % _hour) / _minute);
          seconds = Math.floor((distance % _minute) / _second);
          item.expired = [days, hours, minutes, seconds];
        });
      };
      setInterval(showRemaining, 1000);
    },
    async getDataEvents() {
      try {
        const resp = await this.$http.get("/v1/event?is_active=true");
        this.events = resp.data.data.events.map((item) => ({
          ...item,
          session_schedules: item.session_schedules ? item.session_schedules  
            ? item.session_schedules
            : [
                {
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                },
              ] : [{
                  date_end: "",
                  date_start: "",
                  end_time: "",
                  start_time: "",
                }],
          img_urls: Object.keys(item.img_urls).length
            ? item.img_urls
            : {
                desktop: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
                mobile: {
                  product_detail: "",
                  purchase_detail: "",
                  thumbnail: "",
                },
              },
          expired: [],
        }));
      } catch (err) {
        console.log(err);
      }
    },
    checkIsEarlyBird(item) {
      if (new Date(item.start_discount_date) <= new Date() && new Date(item.end_discount_date) >= new Date()) {
        return true
      } else {
        return false
      }
    },
    toCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
      return formatter.format(value);
    }
  },
  mounted() {
    this.getDataEvents();
    this.countDownTimer();
    this.getFaq()
    window.scrollTo(0, 0);
  },
};
</script>

<style src="./event.css" scoped></style>