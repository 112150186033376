<template>
  <div style="padding-top: 60px">
    <div class="head-class">
      <div class="left">
        <p class="text-banner">
          Akselerasi Skill Digital melalui Intensive Program Bootcamp!
        </p>
        <p class="description-class">
          Perluas potensi diri dengan pelatihan konstruktif dan kredibel sebagai solusi penunjang profesionalisme karir bersama HiColleagues #CiptakanMasaDepan
        </p>
        <div class="buttons-bootcamp">
          <v-btn depressed color="#FFDE59" class="button-daftar" @click="scrollToClass">
            <span class="text-capitalize font-weight-bold blue-text font-poppins">LIHAT KELAS</span>
          </v-btn>
          <v-btn depressed color="white" class="button-daftar" @click="sendToWa">
            <span class="text-capitalize font-weight-bold blue-text font-poppins">KONSULTASI GRATIS</span>
          </v-btn>
        </div>
      </div>
      <img src="/img/bootcamp/main.png" class="img-banner" />
    </div>
    <div class="container" style="text-align: center; margin-top: 80px">
      <h3 class="sub-title-span">
        Bootcamp HiColleagues Pilihan Tepat Akselerasi Skill Digital
      </h3>
      <span>Dapatkan benefit dalam percepatan skill digital melalui intensive
        program Bootcamp HiColleagues</span>
      <div class="mb-10 mt-10 card-bootcamp">
        <div v-for="n in benefits" :key="n.id" class="content-card mr-2 ml-2">
          <div class="left-span">
            <img :src="n.image" width="100" height="100" />
          </div>
          <div>
            <div class="title-2" style="text-align: left">{{ n.title }}</div>
            <div class="desc mt-2" style="text-align: left">{{ n.desc }}</div>
          </div>
        </div>
      </div>
      <h3 class="sub-title-span" style="margin-top: 70px;">Program Akselerasi Digital</h3>
      <span>Percepat akselarasi skill digital HiColers melalui program Bootcamp
        pilihan!</span>
      <div class="prakerja-list">
        <div v-for="(item, idx) in bootcampClass" :key="idx" class="items" @click="goToClassBootcamp(item.slug_name)">
          <div style="height: 240px">
              <img :src="item.image_url" alt="" style="width: 100%; height: 100%; border-radius: 20px 20px 0 0" />
            </div>
            <div class="content">
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.level">
                  <p>{{ item.level }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>{{ toMethode(item.method) }}</p>
                </div>
              </div>
              <h2 style="text-align: left;">{{ item.name }}</h2>
              <p class="description-class">{{ item.description }}</p>
              <div style="height: 60px">
                <p style="text-align: left; color: #b80f0a; font-size: 16px; margin: 0" v-if="item.is_discount">
                  <del>{{ item.price | toCurrency }}</del>
                </p>
                <h3 style="text-align: left; color: #162ca2; margin: 0" v-if="!item.is_discount">
                  {{ item.price | toCurrency }}
                </h3>
                <h3 style="text-align: left; color: #162ca2; margin: 0" v-else>
                  {{ toDiscount(item) }}
                </h3>
              </div>
            </div>
            <div class="due-date">
              <DateIc2 />
              <span style="font-size: 15px">{{
            item.registration_close_date | formatDateFull
          }} WIB</span>
            </div>
        </div>
      </div>
      <div class="container bg mt-10">
        <div class="box-content">
          <div style="width: 55%;">
            <span class="title font-poppins">Masih Bingung untuk update skillmu melalui BootcampHiColleagues?</span>
            <div>
              <span class="description font-poppins">Perdalam minatmu dengan konsultasi bersama HiColleagues</span>
            </div>
          </div>
          <div>
            <div class="d-flex bg-blue justify-center"
              @click="window.open('https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya')">
              <span class="exhange-voucher font-poppins">KONSULTASI GRATIS</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="list-of-acceleration" style="background-color: white;">
      <div class="container">
        <div style="display: flex; gap: 30px; ">
          <div style="max-width: 40%;">
            <p class="margin-0">Testimoni</p>
            <h1 class="margin-0" style="line-height: 35px;">Cerita Alumni Menuju Talenta Digital Kompeten</h1>
            <div class="btn-stoke-blue" style="margin: 20px 0 0 0; width: 300px;" @click="$router.push('/testimony')">
              <span class="font-poppins">LIHAT SELENGKAPNYA</span>
              <ArrowLong />
            </div>
          </div>
          <div style="width: 60%;" class="masonry">
            <div class="card-2" v-for="(item, idx) in testimonies" :key="idx">
              <p class="text-center">{{ item.feedback }}</p>
              <div class="profile mt-5 d-flex" style="align-items: center">
                <div class="avatar">
                  <v-img :src="$utils.loadImage(item.image_url)" alt="" width="60" height="60" class="avatar-image" />
                </div>
                <div class="testi-profile ml-5">
                  <div class="name">{{ item.name }}</div>
                  <div class="description">
                    {{ item.job }} {{ item.place }}
                  </div>
                  <v-img v-if="item.linkedin" src="/img/home/linkedin.png" alt="" width="23" height="23"
                    @click="redirectReload(item.linkedin)" class="linkedin-image cursor-pointer" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="faq" style="margin-top: 60px;">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header" style="margin-bottom: 20px;">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: -10px;">
            <v-expansion-panels flat class="faq-card" style="padding: 20px; background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" >
                      <Arrow :down="!item.active" style="margin-right: 35px;" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div style="margin-left: 20px;">
            <div class="btn-stoke-blue cursor-pointer mt-5" @click="$router.push('/faq')" >
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import moment from 'moment';
import DateIc2 from "../../home/icons/Date.vue";


export default {
  name: "Bootcamp",
  components: {
    ArrowLong,
    Arrow,
    DateIc2
  },
  data() {
    return {
      benefits: [
        {
          title: "Intensive Program",
          desc: "Durasi program 2-3 bulan yang mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri",
          image: "/img/bootcamp/ic_intensive_program.png",
        },
        {
          title: "Competent Trainer",
          desc: "Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan",
          image: "/img/bootcamp/ic_support_3.png",
        },
        {
          title: "Learning Management System",
          desc: "Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat",
          image: "/img/bootcamp/ic_support_1.png",
        },
        {
          title: "E-Certificate",
          desc: "Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir",
          image: "/img/bootcamp/ic_sertifikat_kompetensi.png",
        },
        {
          title: "Job Connector",
          desc: "Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta Bootcamp",
          image: "/img/bootcamp/ic_support_2.png",
        },
        {
          title: "Installment Option Available",
          desc: "Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta",
          image: "/img/bootcamp/ic_installment.png",
        },
      ],
      bootcampClass: [],
      currentPage: 1,
      total: 1,
      questions: [],
      testimonies: [],
    };
  },
  methods: {
    async getBootcampClass() {
      try {
        const resp = await this.$http.get("/v1/class/category/5");
        this.bootcampClass = resp.data.data.classes;
      } catch (err) {
        console.log(err);
      }
    },
    goToClassBootcamp(slug) {
      this.$router.push('/class/bootcamp/' + slug)
    },
    minimize(text) {
      return text.length > 290 ? text.slice(0, 290) + "..." : text;
    },
    scrollToClass() {
      window.scrollTo(0, 1000);
    },
    page(act) {
      if (act === "prev") {
        if (this.currentPage <= 1) return;
        this.currentPage--;
      } else {
        if (this.currentPage >= this.total) return;
        this.currentPage++;
      }
      this.getCareer()
    },
    moment(date) {
      return moment(date).format("DD MMMM YYYY")
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=BOOTCAMP&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        this.isErr = true
        this.errMessage = err.response.data.data
      }
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get('/v1/testimony?category=BOOTCAMP&is_active=true&page=1&per_page=4');
        this.testimonies = resp.data.data.testimonies;
      } catch (err) {
        this.isErr = true
        this.errMessage = err.response.data.data
      }
    },
    toMethode(value) {
      if (!value) return;
      if (value.toLowerCase() === "online") {
        return "Daring";
      } else if (value.toLowerCase() === "offline") {
        return "Luring";
      } else {
        return value;
      }
    },
    toDiscount(item) {
      const total_price = item.price - item.discount;
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
    redirectReload(e) {
      if (e.slice(0, 5) == "https") {
        window.open(e, '_blank')
      } else {
        this.$router
          .push({ path: e })
          .then(() => { this.$router.go() })
      }
    },
  },
  mounted() {
    this.getBootcampClass();
    this.getFaq()
    this.getTestimoni()
  },
};
</script>

<style src="./bootcamp.css" scoped></style>