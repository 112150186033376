<template>
  
<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5 9.85401C10.8201 9.85401 10.1555 9.6524 9.59023 9.27468C9.02494 8.89697 8.58434 8.3601 8.32417 7.73198C8.06399 7.10386 7.99592 6.41269 8.12855 5.74588C8.26119 5.07907 8.58858 4.46657 9.06932 3.98583C9.55007 3.50508 10.1626 3.17769 10.8294 3.04506C11.4962 2.91242 12.1874 2.98049 12.8155 3.24067C13.4436 3.50085 13.9805 3.94144 14.3582 4.50673C14.7359 5.07203 14.9375 5.73663 14.9375 6.41651C14.9375 7.32819 14.5753 8.20253 13.9307 8.84719C13.286 9.49184 12.4117 9.85401 11.5 9.85401ZM11.5 4.35401C11.0921 4.35401 10.6933 4.47497 10.3541 4.7016C10.015 4.92823 9.75061 5.25035 9.5945 5.62722C9.43839 6.00409 9.39755 6.41879 9.47713 6.81888C9.55671 7.21897 9.75315 7.58647 10.0416 7.87491C10.33 8.16336 10.6975 8.35979 11.0976 8.43938C11.4977 8.51896 11.9124 8.47811 12.2893 8.32201C12.6662 8.1659 12.9883 7.90155 13.2149 7.56237C13.4415 7.22319 13.5625 6.82443 13.5625 6.41651C13.5625 5.8695 13.3452 5.34489 12.9584 4.9581C12.5716 4.5713 12.047 4.35401 11.5 4.35401Z" fill="#36373F"/>
    <path d="M15.6984 18.5625H7.30173C6.88152 18.5616 6.4681 18.4565 6.09854 18.2565C5.72897 18.0565 5.41481 17.768 5.18423 17.4167C4.95671 17.0703 4.81913 16.6726 4.78392 16.2597C4.74871 15.8468 4.81698 15.4316 4.98256 15.0517C5.5337 13.781 6.44426 12.6992 7.60222 11.9392C8.76017 11.1793 10.115 10.7744 11.5001 10.7744C12.8851 10.7744 14.24 11.1793 15.3979 11.9392C16.5559 12.6992 17.4664 13.781 18.0176 15.0517C18.1831 15.4316 18.2514 15.8468 18.2162 16.2597C18.181 16.6726 18.0434 17.0703 17.8159 17.4167C17.5853 17.768 17.2712 18.0565 16.9016 18.2565C16.532 18.4565 16.1186 18.5616 15.6984 18.5625ZM11.5001 12.1458C10.3837 12.1449 9.29134 12.4696 8.35677 13.0802C7.42219 13.6908 6.686 14.5607 6.2384 15.5833C6.16435 15.7547 6.13476 15.9421 6.15239 16.1279C6.17002 16.3138 6.23428 16.4923 6.33923 16.6467C6.43898 16.8008 6.57431 16.9288 6.73384 17.0197C6.89337 17.1106 7.07242 17.1619 7.2559 17.1692H15.6526C15.8439 17.1694 16.0322 17.1216 16.2003 17.0304C16.3684 16.9391 16.511 16.8072 16.6151 16.6467C16.7275 16.4963 16.7999 16.3199 16.8256 16.1339C16.8512 15.948 16.8293 15.7585 16.7617 15.5833C16.3141 14.5607 15.5779 13.6908 14.6434 13.0802C13.7088 12.4696 12.6164 12.1449 11.5001 12.1458Z" fill="#36373F"/>
    </svg>
    
</template>

<script>
export default {
    name: "Person"
}
</script>

<style>

</style>