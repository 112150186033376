<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M22.19 11.7098C22.1521 11.6206 22.0978 11.5392 22.03 11.4698L15.03 4.46985C14.8878 4.33737 14.6998 4.26524 14.5055 4.26867C14.3112 4.2721 14.1258 4.35081 13.9884 4.48822C13.851 4.62564 13.7723 4.81102 13.7688 5.00532C13.7654 5.19963 13.8375 5.38767 13.97 5.52985L19.69 11.2498H3.5C3.30109 11.2498 3.11032 11.3289 2.96967 11.4695C2.82902 11.6102 2.75 11.8009 2.75 11.9998C2.75 12.1988 2.82902 12.3895 2.96967 12.5302C3.11032 12.6708 3.30109 12.7498 3.5 12.7498H19.69L13.97 18.4698C13.8295 18.6105 13.7507 18.8011 13.7507 18.9998C13.7507 19.1986 13.8295 19.3892 13.97 19.5298C14.1106 19.6703 14.3012 19.7492 14.5 19.7492C14.6988 19.7492 14.8894 19.6703 15.03 19.5298L22.03 12.5298C22.0978 12.4605 22.1521 12.3791 22.19 12.2898C22.2296 12.1983 22.2501 12.0996 22.2501 11.9998C22.2501 11.9001 22.2296 11.8014 22.19 11.7098Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowRight",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
