<template>
    
<svg :width="width" :height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.9999 13.183C29.9499 13.183 29.9165 13.183 29.8665 13.183H29.7832C26.6332 13.083 24.2832 10.6496 24.2832 7.64961C24.2832 4.58295 26.7832 2.09961 29.8332 2.09961C32.8832 2.09961 35.3832 4.59961 35.3832 7.64961C35.3665 10.6663 33.0165 13.0996 30.0165 13.1996C30.0165 13.1829 30.0165 13.183 29.9999 13.183ZM29.8332 4.58295C28.1499 4.58295 26.7832 5.94962 26.7832 7.63295C26.7832 9.28295 28.0665 10.6163 29.7165 10.683C29.7332 10.6663 29.8665 10.6663 30.0165 10.683C31.6332 10.5996 32.8832 9.26629 32.8999 7.63295C32.8999 5.94962 31.5332 4.58295 29.8332 4.58295Z" fill="#7B7E8C"/>
<path d="M30.0165 25.466C29.3665 25.466 28.7165 25.416 28.0665 25.2993C27.3832 25.1827 26.9332 24.5327 27.0498 23.8494C27.1665 23.166 27.8165 22.716 28.4998 22.8327C30.5498 23.1827 32.7165 22.7994 34.1665 21.8327C34.9498 21.316 35.3665 20.666 35.3665 20.016C35.3665 19.366 34.9332 18.7327 34.1665 18.216C32.7165 17.2493 30.5165 16.866 28.4498 17.2327C27.7665 17.366 27.1165 16.8993 26.9998 16.216C26.8832 15.5327 27.3332 14.8827 28.0165 14.766C30.7332 14.2827 33.5498 14.7993 35.5498 16.1327C37.0165 17.116 37.8665 18.516 37.8665 20.016C37.8665 21.4993 37.0332 22.916 35.5498 23.916C34.0332 24.916 32.0665 25.466 30.0165 25.466Z" fill="#7B7E8C"/>
<path d="M9.94981 13.1833C9.93314 13.1833 9.91647 13.1833 9.91647 13.1833C6.91647 13.0833 4.56647 10.6499 4.5498 7.64991C4.5498 4.58325 7.04981 2.08325 10.0998 2.08325C13.1498 2.08325 15.6498 4.58325 15.6498 7.63326C15.6498 10.6499 13.2998 13.0833 10.2998 13.1833L9.94981 11.9333L10.0665 13.1833C10.0331 13.1833 9.98314 13.1833 9.94981 13.1833ZM10.1165 10.6833C10.2165 10.6833 10.2998 10.6832 10.3998 10.6999C11.8831 10.6332 13.1831 9.29991 13.1831 7.64991C13.1831 5.96658 11.8165 4.59991 10.1331 4.59991C8.44981 4.59991 7.08314 5.96658 7.08314 7.64991C7.08314 9.28325 8.3498 10.5999 9.96647 10.6999C9.98314 10.6832 10.0498 10.6833 10.1165 10.6833Z" fill="#7B7E8C"/>
<path d="M9.93301 25.466C7.88301 25.466 5.91634 24.916 4.39967 23.916C2.93301 22.9327 2.08301 21.516 2.08301 20.016C2.08301 18.5327 2.93301 17.116 4.39967 16.1327C6.39967 14.7993 9.21634 14.2827 11.933 14.766C12.6163 14.8827 13.0663 15.5327 12.9497 16.216C12.833 16.8993 12.183 17.366 11.4997 17.2327C9.43301 16.866 7.24968 17.2493 5.78301 18.216C4.99968 18.7327 4.58301 19.366 4.58301 20.016C4.58301 20.666 5.01634 21.316 5.78301 21.8327C7.23301 22.7994 9.39967 23.1827 11.4497 22.8327C12.133 22.716 12.783 23.1827 12.8997 23.8494C13.0163 24.5327 12.5663 25.1827 11.883 25.2993C11.233 25.416 10.583 25.466 9.93301 25.466Z" fill="#7B7E8C"/>
<path d="M19.9999 25.6341C19.9499 25.6341 19.9165 25.6341 19.8665 25.6341H19.7832C16.6332 25.5341 14.2832 23.1008 14.2832 20.1008C14.2832 17.0341 16.7832 14.5508 19.8332 14.5508C22.8832 14.5508 25.3832 17.0508 25.3832 20.1008C25.3665 23.1175 23.0165 25.5508 20.0165 25.6508C20.0165 25.6341 20.0165 25.6341 19.9999 25.6341ZM19.8332 17.0341C18.1499 17.0341 16.7832 18.4008 16.7832 20.0841C16.7832 21.7341 18.0665 23.0675 19.7165 23.1341C19.7332 23.1175 19.8665 23.1175 20.0165 23.1341C21.6332 23.0508 22.8832 21.7175 22.8999 20.0841C22.8999 18.4175 21.5332 17.0341 19.8332 17.0341Z" fill="#7B7E8C"/>
<path d="M19.9995 37.933C17.9995 37.933 15.9995 37.4163 14.4495 36.3663C12.9828 35.383 12.1328 33.983 12.1328 32.483C12.1328 30.9996 12.9661 29.5663 14.4495 28.583C17.5661 26.5163 22.4495 26.5163 25.5495 28.583C27.0161 29.5663 27.8661 30.9663 27.8661 32.4663C27.8661 33.9496 27.0328 35.383 25.5495 36.3663C23.9995 37.3996 21.9995 37.933 19.9995 37.933ZM15.8328 30.683C15.0495 31.1996 14.6328 31.8496 14.6328 32.4996C14.6328 33.1496 15.0661 33.783 15.8328 34.2996C18.0828 35.8163 21.8995 35.8163 24.1495 34.2996C24.9328 33.783 25.3495 33.133 25.3495 32.483C25.3495 31.833 24.9161 31.1996 24.1495 30.683C21.9162 29.1663 18.0995 29.183 15.8328 30.683Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "40"
            },
            height: {
                type: String,
                default: "40" 
            }
        }
}
</script>