import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/main/Home.vue";
import About from "../views/main/About.vue";
import ContactUs from "../views/main/ContactUs.vue";
import Blog from "../views/main/Blog.vue";
import DetailBlog from "../views/blog/DetailBlog.vue";
import Event from "../views/event/Event.vue";
import EventDetail from "../views/event/EventDetail.vue";
import EventSuccess from "../views/event/Success.vue";
import Prakerja from "../views/prakerja/Prakerja.vue";
import DetailClassPrakerja from "../views/prakerja/DetailClass.vue";

import KampusMerdeka from "../views/main/KampusMerdeka.vue";
// import TechnologyClass from '../views/public-training/TechnologyClass.vue'
// import LanguageClass from '../views/public-training/LanguageClass.vue'
// import SoftSkillClass from '../views/public-training/SoftSkillClass.vue'
import DetailClass from '../views/public-training/DetailClass.vue'
import DetailClassBootcamp from '../views/public-training/DetailClassBootcamp.vue'
import Bootcamp from "../views/bootcamp/Bootcamp.vue"
import Checkout from '../views/transaction/Checkout.vue'
import CheckoutEvent from '../views/transaction/CheckoutEvent.vue'
import Login from '../views/auth/Login.vue'
import VerifiedUser from '../views/auth/VerifiedUser.vue'
import CorpotateForm from '../views/corporate/RegistrasionForm.vue'
import SuccessMessage from '../views/success-page/SuccessMessage.vue'
import SuccessCoporateRegistration from '../views/success-page/SuccessCorporateRegistration.vue'
import PageNotFound from '../views/PageNotFound.vue'
import PublicBootcamp from '../views/public-bootcamp/PublicBootcamp.vue'
import PrivacyPolicy from '../views/other/PrivacyPolicy.vue'
import TermCondition from '../views/other/TermCondition.vue'
import PublicTraining from '../views/public-training/PublicTraining.vue'
import Testimony from '../views/testimony/Testimony.vue'
import AlumniStories from '../views/alumni-stories/AlumniStories.vue'
import AlumniStoriesDetail from '../views/alumni-stories/Detail.vue'
import Portfolio from '../views/portfolio/Portfolio.vue'
import PortfolioDetail from '../views/portfolio/Detail.vue'

import MainTemplate from '../templates/MainTemplate.vue'
import BlogTemplate from '../templates/BlogTemplate.vue'
import CorporateTemplate from '../templates/CorporateTemplate.vue'

import Corporate from '../views/corporate/Corporate.vue'
import CheckoutAcceleration from '../views/transaction/CheckoutAcceleration.vue'
import DetailClassAcceleration from '../views/corporate/DetailClassAcceleration.vue'
import CorporateTraining from "../views/corporate/CorporateTraining.vue";
import AccelerationProgram from '../views/corporate/AccelerationProgram.vue'
import CorporateCollaboration from "../views/corporate/CorporateCollaboration.vue";
import TechService from '../views/corporate/TechService.vue'
import TalentAsService from '../views/corporate/TalentAsService.vue'
import CorporateSuccess from '../views/corporate/Success.vue'
import FaqTemplate from '@/templates/FaqTemplate.vue';
import FAQ from "@/views/faq/FAQ.vue";

import Career from "../views/career/Career.vue";
import CareerDetail from "../views/career/Detail.vue";
import CareerApplicant from "../views/career/Applicant.vue";
import CareerSuccess from "../views/career/Success.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: MainTemplate,
    meta: {},
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
          title: 'HiColleagues | Ciptakan Masa Depan menjadi Digital Talent Profesional',
          description: 'HiColleagues adalah sebuah Lembaga Pelatihan dan Konsultan IT yang menyediakan Variasi program pembelajaran dan layanan jasa solusi transformasi digital'
        }
      },
      {
        path: "/class/public-training",
        name: "PublicTraining",
        component: PublicTraining,
        meta: {
          title: 'Bootcamp, Training & Outsourcing',
          description: 'Belajar dan beli kelas coding, bahasa, softskill, coding bootcamp hanya di HiColleagues. HiColleagues menjadi solusi dan membantu banyak perusahaan sebagai...'
        }
      },
      {
        path: "/class/bootcamp",
        name: "Bootcamp",
        component: Bootcamp,
        meta: {
          title: 'Fullstack Bootcamp',
          description: 'Intensif Bootcamp Fullstack Web Development with Vue.js & Golang...'
        }
      },
      {
        path: "/class/bootcamp/:slug_name",
        name: "DetailClassBootcamp",
        component: DetailClassBootcamp,
      },
      {
        path: "/contact-us",
        name: "ContactUs",
        component: ContactUs,
      },
      {
        path: "/about",
        name: "About",
        component: About,
      },
      {
        path: "/event",
        name: "Event",
        component: Event,
      },
      {
        path: "/event/detail/:id",
        name: "EventDetail",
        component: EventDetail,
      },
      {
        path: "/event/success",
        name: "EventSuccess",
        component: EventSuccess,
      },
      {
        path: "/prakerja",
        name: "Prakerja",
        component: Prakerja,
        meta: {
          title: 'Kelas Prakerja',
          description: 'Kembangkan kompetensi kerja & kewirausahaan dengan ikut Kelas Prakerja HiColleagues...'
        }
      },
      {
        path: "/kampusmerdeka",
        name: "KampusMerdeka",
        component: KampusMerdeka,
        meta: {
          title: 'Program Magang MSIB',
          description: 'Kampus Merdeka merupakan cara terbaik mewujudkan karir di masa depan melalui...'
        }
      },
      // {
      //   path: '/class/language',
      //   name: 'LanguageClass',
      //   component: LanguageClass
      // },
      // {
      //   path: '/class/soft-skill',
      //   name: 'SoftSkillClass',
      //   component: SoftSkillClass
      // },
      // {
      //   path: '/class/technology',
      //   name: 'TechnologyClass',
      //   component: TechnologyClass
      // },
      {
        path: "/portfolio",
        name: "Portfolio",
        component: Portfolio
      },
      {
        path: "/portfolio/:id",
        name: "PortfolioDetail",
        component: PortfolioDetail
      },
      {
        path: "/alumni-stories",
        name: "AlumniStories",
        component: AlumniStories
      },
      {
        path: "/alumni-stories/:id",
        name: "AlumniStoriesDetail",
        component: AlumniStoriesDetail
      },
      {
        path: "/testimony",
        name: "Testimony",
        component: Testimony
      },
      {
        path: "/faq",
        name: "FAQ",
        component: FAQ
      },
      {
        path: "/class/public-training/:slugname",
        name: "DetailClassPublic",
        component: DetailClass,
      },
      {
        path: "/class/prakerja/:slugname",
        name: "DetailClassPrakerja",
        component: DetailClassPrakerja,
      },
      {
        path: "/checkout",
        name: "Checkout",
        component: Checkout,
      },
      {
        path: '/checkout/event/:id',
        name: 'CheckoutEvent',
        component: CheckoutEvent
      },
      {
        path: "/account/verify/:token",
        name: "VerifiedUser",
        component: VerifiedUser,
      },
      {
        path: "/success",
        name: "SuccessMessage",
        component: SuccessMessage,
      },
      {
        path: "/registration/success",
        name: "SuccessCoporateRegistration",
        component: SuccessCoporateRegistration,
      },
      {
        path: "/public-bootcamp",
        name: "PublicBootcamp",
        component: PublicBootcamp,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
      {
        path: "/term-condition",
        name: "TermCondition",
        component: TermCondition,
      },
      {
        path: "/career",
        name: "Career",
        component: Career,
        meta: {
          title: 'Karir HiColleagues',
          description: 'Sambut peluang masa depan dan tingkatkan karir cemerlang bersama HiColleagues'
        }
      },
      {
        path: "/career/:id",
        name: "CareerDetail",
        component: CareerDetail,
      },
      {
        path: "/career/applicant/:id",
        name: "CareerApplicant",
        component: CareerApplicant,
      },
      {
        path: "/career-success",
        name: "CareerSuccess",
        component: CareerSuccess,
      },
    ],
  },
  {
    path: "/blog",
    component: BlogTemplate,
    children: [
      {
        path: "",
        name: "Blog",
        component: Blog,
        meta: {
          title: 'Artikel HiColleagues',
          description: 'Tambah wawasanmu dengan artikel-artikel yang terbaru dan kece...'
        }
      },
      {
        path: ":slugname",
        name: "DetailBlog",
        component: DetailBlog,
      },
    ],
  },
  {
    path: "/corporate",
    component: CorporateTemplate,
    children: [
      {
        path: "",
        name: "Corporate",
        component: Corporate,
        meta: {
          title: 'Corporate HiColleagues',
          description: 'Perluas skala bisnis perusahaan Anda dalam dinamika transformasi digital...'
        }
      },
      {
        path: "collaboration",
        name: "Collaboration",
        component: CorporateCollaboration,
      },
      {
        path: "talent-service",
        name: "TalentService",
        component: TalentAsService,
      },
      {
        path: "services",
        name: "Services",
        component: TechService,
        meta: {
          title: 'Technology Service',
          description: 'Menawarkan solusi dan inovasi terbaik untuk mendorong transformasi digital...'
        }
      },
      {
        path: 'acceleration/detail/:slug_name',
        name: 'DetailClassAcceleration',
        component: DetailClassAcceleration
      },
      {
        path: 'acceleration/checkout',
        name: 'CheckoutAcceleration',
        component: CheckoutAcceleration
      },
      {
        path: 'acceleration',
        name: 'AccelerationProgram',
        component: AccelerationProgram,
        meta: {
          title: 'Digital Akselerasi Program',
          description: 'Beragam program Pelatihan dan sertifikasi IT nasional maupun internasional...'
        }
      },
      {
        path: "training",
        name: "Training",
        component: CorporateTraining,
        meta: {
          title: 'Corporate Training',
          description: 'Program pilihan untuk meningkatkan skill karyawan Anda..'
        }
      },
      {
        path: "success",
        name: "Success",
        component: CorporateSuccess, 
      }
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/corporate/form",
    name: "CorporateForm",
    component: CorpotateForm,
  },
  {
    path: "/blog/mobile/:slugname",
    name: "DetailMobileBlog",
    component: DetailBlog,
  },
  {
    path: "/privacy-policy/mobile",
    name: "PrivacyPolicyMobile",
    component: PrivacyPolicy,
  },
  {
    path: "/term-condition/mobile",
    name: "TermConditionMobile",
    component: TermCondition,
  },
  {
    path: "/berita",
    component: FaqTemplate,
    children: []
  },
  { path: "/:pathMatch(.*)*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const metaDescription = to.meta.description || "Hello Hicol";
  const metaTag = document.querySelector('meta[name="description"]');
  if (metaTag) {
    metaTag.setAttribute('content', metaDescription);
  }
  next();
});

export default router;
