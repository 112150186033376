<template>
  <div>
    <div
      v-for="i in subCategories"
      :key="i.name"
      :style="'background:' + i.color"
      class="bg"
    >
      <div class="container">
        <h4 class="title-sub-category">{{ i.name }}</h4>
        <div>
          <v-row class="mt-5">
            <v-col v-for="n in i.recommendationClass" :key="n.id" sm="4" lg="3">
              <v-card
                class="black--text category-list-class"
                router
                :to="'/class/public-training/' + n.slug_name"
              >
                <div
                  style="
                    justify-content: center;
                    align-items: center;
                    display: flex;
                  "
                >
                  <img :src="n.thumbnail_url" class="class-list-img" />
                </div>
                <div class="class-list-name">
                  <span>
                    {{ n.name }}
                  </span>
                </div>

                <div class="info-dev">
                  <div class="level-div">
                    <img src="/img/home/ic_level.png" class="icon-class" />
                    <span>Dasar</span>
                  </div>
                  <div class="method-div">
                    <img src="/img/home/ic_method.png" class="icon-class" />
                    <span>Online / Offline</span>
                  </div>
                </div>
                <div class="desc-class">
                  <p>{{ n.description.substring(0, 130) + ".." }}</p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subCategories: [
        {
          name: "KELAS PROGRAMMING",
          color: "#fff",
          recommendationClass: [],
        },
        {
          name: "KELAS DATA",
          color: "#F1F6FF",
          recommendationClass: [],
        },
        {
          name: "KELAS LAINNYA",
          color: "#fff",
          recommendationClass: [],
        },
      ],
    };
  },
  methods: {
    async getClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class/category/1")
        .then((response) => {
          response.data.data.classes.forEach((element) => {
            if (element.class_sub_category.id == 1) {
              this.subCategories[0].recommendationClass.push({
                id: parseInt(element.id),
                name: element.name,
                thumbnail_url: element.thumbnail_url,
                description: element.description,
                level: element.level,
                slug_name: element.slug_name,
              });
            } else if (element.class_sub_category.id == 2) {
              this.subCategories[1].recommendationClass.push({
                id: parseInt(element.id),
                name: element.name,
                thumbnail_url: element.thumbnail_url,
                description: element.description,
                level: element.level,
                slug_name: element.slug_name,
              });
            } else {
              this.subCategories[2].recommendationClass.push({
                id: parseInt(element.id),
                name: element.name,
                thumbnail_url: element.thumbnail_url,
                description: element.description,
                level: element.level,
                slug_name: element.slug_name,
              });
            }
          });
        })
        .catch((err) => console.error(err.response));
      // .finally(() => this.spin_load.val = false)
    },
  },
  mounted() {
    this.getClass();
  },
};
</script>

<style src="./css/technologyCategory.css" scoped></style>
