<template>
    
<svg :width="width" :height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.83301 31.2499C5.14967 31.2499 4.58301 30.6833 4.58301 29.9999V11.6666C4.58301 4.31659 6.81634 2.08325 14.1663 2.08325H25.833C33.183 2.08325 35.4163 4.31659 35.4163 11.6666V28.3333C35.4163 28.5999 35.4163 28.8499 35.3997 29.1166C35.3497 29.7999 34.733 30.3333 34.0663 30.2833C33.383 30.2333 32.8497 29.6333 32.8997 28.9499C32.9163 28.7499 32.9163 28.5333 32.9163 28.3333V11.6666C32.9163 5.71659 31.7997 4.58325 25.833 4.58325H14.1663C8.19967 4.58325 7.08301 5.71659 7.08301 11.6666V29.9999C7.08301 30.6833 6.51634 31.2499 5.83301 31.2499Z" fill="#7B7E8C"/>
<path d="M28.333 37.9167H11.6663C7.76634 37.9167 4.58301 34.7333 4.58301 30.8333V29.75C4.58301 26.4333 7.28301 23.75 10.583 23.75H34.1663C34.8497 23.75 35.4163 24.3167 35.4163 25V30.8333C35.4163 34.7333 32.233 37.9167 28.333 37.9167ZM10.583 26.25C8.64967 26.25 7.08301 27.8167 7.08301 29.75V30.8333C7.08301 33.3667 9.13301 35.4167 11.6663 35.4167H28.333C30.8663 35.4167 32.9163 33.3667 32.9163 30.8333V26.25H10.583Z" fill="#7B7E8C"/>
<path d="M26.6663 12.9167H13.333C12.6497 12.9167 12.083 12.3501 12.083 11.6667C12.083 10.9834 12.6497 10.4167 13.333 10.4167H26.6663C27.3497 10.4167 27.9163 10.9834 27.9163 11.6667C27.9163 12.3501 27.3497 12.9167 26.6663 12.9167Z" fill="#7B7E8C"/>
<path d="M21.6663 18.75H13.333C12.6497 18.75 12.083 18.1833 12.083 17.5C12.083 16.8167 12.6497 16.25 13.333 16.25H21.6663C22.3497 16.25 22.9163 16.8167 22.9163 17.5C22.9163 18.1833 22.3497 18.75 21.6663 18.75Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "40"
            },
            height: {
                type: String,
                default: "40" 
            }
        }
}
</script>