<template>
  <div>
    <div class="hero">
      <div class="hero-ctn">
        <h1>Portfolio</h1>
        <p class="text-center">
          Berbagai proyek kemitraan dalam transformasi digital berkelanjutan
          yang telah berhasil HiColleagues selenggarakan
        </p>
      </div>
    </div>
    <div style="background-color: #f5f6fa">
      <div class="container">
        <div class="card" style="padding-bottom: 60px">
          <EmptyData
            v-if="!portfolio || !portfolio.length"
            message="Belum ada portofolio saat ini"
          />
          <div
            class="items"
            v-for="(item, idx) in portfolio"
            :key="idx"
            @click="$router.push(`/portfolio/${item.id}`)"
          >
            <div style="height: 240px">
              <img
                :src="item.image_url"
                alt=""
                style="width: 100%; height: 100%; border-radius: 20px 20px 0 0"
              />
            </div>
            <div class="content">
              <div style="margin: 10px 0px">
                <h4 style="color: #7b7e8c; font-weight: 500">{{ item.title }}</h4>
                <h2 class="mt-2">{{ item.program }}</h2>
              </div>
              <p class="description-class">{{ item.description }}</p>
              <button class="btn-label-corp">
                {{ categoryList[item.category] }}
              </button>
            </div>
          </div>
          <div
            style="display: flex; justify-content: center"
            v-if="currentPage < total"
          >
            <Button
              name="MUAT LEBIH BANYAK"
              width="440px"
              @click="() => page()"
            />
          </div>
        </div>
      </div>
    </div>
    <v-card elevation="0" class="call-center-container">
      <v-row style="padding: 20px 8px">
        <v-col cols="12">
          <div class="call-center-title">
            Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam
            Transformasi Digital Berkelanjutan
          </div>
          <div class="call-center-desc">
            Dapatkan berbagai informasi yang kamu butuhkan melalui layanan
            konsultasi 24/7 Customer Service HiColleagues
          </div>
        </v-col>
        <v-col cols="12" class="call-center-button" @click="sendWa">
          <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Button from "../../others/Button.vue";
import EmptyData from "../../others/EmptyData.vue";
export default {
  name: "MobileAlumniStories",
  components: {
    Button,
    EmptyData,
  },
  data() {
    return {
      portfolio: [],
      currentPage: 1,
      total: 1,
      category: "COLLABORATION",
      categoryList: {
                COLLABORATION: "Corporate Collaboration",
                TRAINING: "Corporate Training",
                SERVICES: "Technology Service",
                KAMPUS_MERDEKA: "Kampus Merdeka"
            },
    };
  },
  methods: {
    sendWa() {
      this.$router.push("/corporate");
    },
    page() {
      this.currentPage++;
      this.getPortfolio("page");
    },
    async getPortfolio(val) {
      if (val === "new") {
        this.category = val;
        this.portfolio = [];
      }
      try {
        const resp = await this.$http.get(
          `/v1/project-portfolio?page=${this.currentPage}&per_page=9`
        );
        this.portfolio = this.portfolio.concat(
          resp.data.data.project_portfolios
        );

        if (resp.data.data.total) {
          this.total = parseInt(resp.data.data.total / 9) + 1;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getPortfolio("new");
  },
};
</script>

<style scoped src="./portfolio.css"></style>
