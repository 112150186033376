<template>
    <div>
      <DesktopApplicant v-if="!isMobile"/>
      <MobileApplicant v-if="isMobile"/>
    </div>
  </template>
  <script>
  import DesktopApplicant from "@/components/career/applicant/desktop/Applicant.vue";
  import MobileApplicant from  "@/components/career/applicant/mobile/Applicant.vue"
  
  export default {
    components: {
        DesktopApplicant,
        MobileApplicant,
    },
    data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      handleView() {
        this.isMobile = screen.width <= 990;
      },
    },
    mounted() {
      this.$gtag.pageview(this.$route);
      this.$utils.scrollTop()
    },
    beforeMount() {
      this.handleView();
    }
  };
  </script>
  