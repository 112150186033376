<template>
  <div :style="`color: ${fill}`">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.704 3.442C14.895 3.668 15 3.954 15 4.25V19.752C15 19.9905 14.9319 20.224 14.8035 20.425C14.6752 20.626 14.4921 20.786 14.2757 20.8863C14.0594 20.9866 13.8188 21.0229 13.5825 20.991C13.3462 20.959 13.124 20.8601 12.942 20.706L7.975 16.5H4.25C3.65326 16.5 3.08097 16.263 2.65901 15.841C2.23705 15.419 2 14.8467 2 14.25V9.75C2 9.15327 2.23705 8.58097 2.65901 8.15901C3.08097 7.73706 3.65326 7.5 4.25 7.5H7.975L12.943 3.296C13.1961 3.08209 13.5237 2.97741 13.8539 3.00498C14.1841 3.03254 14.4899 3.19009 14.704 3.443V3.442ZM13.5 4.79L8.525 9H4.25C4.05109 9 3.86032 9.07902 3.71967 9.21967C3.57902 9.36033 3.5 9.55109 3.5 9.75V14.25C3.5 14.665 3.836 15 4.25 15H8.525L13.5 19.213V4.79ZM17.104 8.641C17.2738 8.53771 17.4776 8.50602 17.6707 8.5529C17.8638 8.59977 18.0305 8.72138 18.134 8.891C18.708 9.831 18.996 10.883 18.996 12.031C18.996 13.18 18.708 14.232 18.134 15.172C18.0841 15.2589 18.0174 15.3349 17.9376 15.3956C17.8579 15.4563 17.7668 15.5004 17.6698 15.5253C17.5727 15.5502 17.4717 15.5554 17.3726 15.5405C17.2735 15.5257 17.1783 15.4912 17.0928 15.439C17.0073 15.3868 16.9331 15.318 16.8746 15.2367C16.8161 15.1553 16.7745 15.0631 16.7522 14.9654C16.7299 14.8677 16.7275 14.7666 16.745 14.6679C16.7624 14.5693 16.7995 14.4751 16.854 14.391C17.282 13.689 17.496 12.908 17.496 12.031C17.496 11.155 17.282 10.374 16.854 9.672C16.7507 9.50223 16.719 9.2984 16.7659 9.10528C16.8128 8.91216 16.9344 8.74554 17.104 8.642V8.641Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "SepakerIcon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
