<template>
    <div class="container-size" style="padding:0px 16px;">
        <div class="text-center mb-10" ><img src="/img/other/ic_coming_soon.png" height="300"></div>
        <div class="text-center font-weight-bold mt-10"><span style="font-size:2.0rem">Kelas Soft Skill</span></div>
        <div class="text-center mt-2 mb-10"><span style="font-size:1.2rem">Softskill apa nih yang ingin kamu pelajari lebih dalam lagi?</span></div>
        <div class="text-center font-weight-bold mt-10"><span style="font-size:1.0rem">Ikuti Kami:</span></div>
        <div class="text-center mt-2 mb-10">
            <span><img src="/img/sosmed/ic_instagram.png" style="margin-right:5px;cursor:pointer" class="center" @click="goToInstagram" width="24px" height="24px"></span>
            <span><img src="/img/sosmed/ic_facebook.png" style="margin-right:5px;cursor:pointer" class="center" @click="goToFacebook" width="24px" height="24px"></span>
            <span><img src="/img/sosmed/ic_tiktok.png" style="margin-right:5px;cursor:pointer" class="center" @click="goToTikTok" width="24px" height="24px"></span>
            <span><img src="/img/sosmed/ic_linkedin.png" style="margin-right:5px;cursor:pointer" class="center" @click="goToLinkedin" width="24px" height="24px"></span>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToLinkedin() {
            window.open(
                'https://www.linkedin.com/company/hicolleagues/',
                '_blank' // <- This is what makes it open in a new window.
            )
        },
        goToInstagram() {
            window.open(
                'https://www.instagram.com/hicolleagues/',
                '_blank' // <- This is what makes it open in a new window.
            )
        },
        goToFacebook() {
            window.open(
                'https://www.facebook.com/hicolleagues',
                '_blank' // <- This is what makes it open in a new window.
            )
        },
        goToTikTok() {
            window.open(
                'https://www.tiktok.com/@hicolleagues',
                '_blank' // <- This is what makes it open in a new window.
            )
        },
    },
    mounted () {
        this.$gtag.pageview(this.$route)
    }
}
</script>