<template>
    <div class="container-size">
        <v-card fixed class="text-left header-mobile-custom pl-2 pt-3" style="top:0" v-if="mobileView">
            <!-- <v-icon @click="backToPreviousPage">mdi-arrow-left</v-icon> -->
            <span class="ml-2 font-weight-bold">Bootcamp</span>
        </v-card>

        <div class="banner">
            <v-container>
                <h1 class="white--text text-banner">
                    Kembangkan potensimu dengan Bootcamp
                </h1>
                <div class="mt-10">
                    <v-btn class="text-capitalize pa-7" @click="$vuetify.goTo(1300, options)"><span class="font-weight-bold">Cari Pelatihan</span></v-btn>
                </div>
            </v-container>
        </div>

        <div class="mt-10 mb-10">
            <v-container>
                <v-row>
                    <v-col>
                        <div class="title-span">
                            <h2>Tentang Bootcamp</h2>
                        </div>

                        <div class="sub-title-span mt-2 text-justify">
                            <span >Bootcamp merupakan program pelatihan yang berlangsung secara intensif dan dirancang dengan kurikulum pembelajaran yang relevan dengan karier di bidang tertentu. Tujuan akhirnya adalah mencetak Sumber Daya Manusia (SDM) unggul yang memiliki kompetensi dan siap kerja di industri yang diinginkan. Akselerasi digital di berbagai bidang berdampak pada permintaan talenta digital yang sangat tinggi.</span>
                            <br> <br>
                            <span>Berdasarkan data dari Kementerian Kominfo Republik Indonesia, kebutuhan talenta digital di Indonesia per tahunnya mencapai 600 ribu orang. Permasalahan kualitas dan kompetensi SDM masih menjadi isu skills gap di dunia kerja. Bootcamp menjadi salah satu solusi untuk melahirkan Sumber Daya Manusia (SDM) yang kompetitif dan kompeten. Aspek kompetensi yang dinilai adalah pengetahuan (knowledge), keterampilan (skill), dan sikap (attitude).</span>
                        </div>

                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="pb-10">
            <v-container >
                <v-card class="mt-10">
                    <v-toolbar
                        flat
                        color="#FFDE59"
                        dark
                        >
                        <v-toolbar-title >
                            <h3 style="color:#000080">Syarat Mendaftar Program Bootcamp HiColleagues</h3>
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-tabs vertical color="#000080">
                        <v-tab v-for="item in requirementPublicBootcamp" :key="item.requirement" style="color:#000080" class="text-capitalize font-weight-bold text-left">
                            {{item.requirement}}
                        </v-tab>
                    

                        <v-tab-item v-for="item in requirementPublicBootcamp" :key="item.requirement">
                            <v-card flat>
                            <v-card-text>
                                <span v-html="item.description"></span>
                            </v-card-text>
                            </v-card>
                        </v-tab-item>
                        
                    </v-tabs>
                </v-card>
            </v-container>
        </div>

        <div style="background-color:#302d52;" class="pb-10">
            <v-container>
                <div class="title-span pt-10 text-center white--text">
                    <h3>Pilihan Kelas Bootcamp</h3>
                </div>

                <v-row no-gutters class="mt-10">
                    <template v-for="n in listOfPrakerja">
                        <v-col :key="n.id">
                        <v-card
                            style="height:300px"
                            class="mt-5 prakerja-class"
                            router :to="'/class/detail/'+n.slug_name+'?tipe_kelas=public-bootcamp'"
                        >
                            <img :src="n.image_url" width="200" height="150">
                            <div class="pa-5">
                                <div class="sub-title-span">
                                    <span>{{n.name}}</span>
                                </div>

                                <!-- <div class="text-decoration-line-through grey-text mt-2" style="font-size:12px">
                                    <span>IDR 750.000</span>
                                </div>

                                <div class="font-weight-bold">
                                    <span style="color:#000080">IDR 500.000</span>
                                </div> -->
                            </div>
                        </v-card>
                        </v-col>
                        <v-responsive
                            v-if="n === 5"
                            :key="`width-${n}`"
                            width="100%">
                        </v-responsive>
                    </template>
                </v-row>
            </v-container>
        </div>

        <!-- <div style="background-color:#f1f1f1;">
            <v-container>
                <div class="title-span pt-5 text-center">
                <h3>Beli Pelatihanmu di Mitra Prakerja</h3>
                </div>
                <v-row class="mt-5 mb-2">
                <v-col>
                    <img src="/img/partner/pijar.png" width="80" height="60" class="center" style="margin-left:auto;margin-right:auto;display:block">
                </v-col>
                <v-col>
                    <div class="text-center">
                    <img src="/img/partner/sekolahmu.png" style="width:150px;height:100px;margin-top:-20px" class="center">
                    </div>
                </v-col>
                <v-col>
                    <div class="text-center">
                    <img src="/img/partner/pintaria.png" style="width:80px;height:60px;" class="center">
                    </div>
                </v-col>
                <v-col>
                    <div class="text-center">
                    <img src="/img/partner/tokopedia.png" style="width:80px;height:60px;" class="center">
                    </div>
                </v-col>
                <v-col>
                    <div class="text-center">
                    <img src="/img/partner/bukalapak.png" style="width:80px;height:60px;" class="center">
                    </div>
                </v-col>
                </v-row>
            </v-container>
        </div> -->

        <!-- <v-container class="mb-10 container-custom">
            <div class="title-span pt-10 mb-10 text-center">
                <h3>Cara Membeli Pelatihan Bootcamp</h3>
            </div>

            <v-timeline>
                <v-timeline-item
                v-for="(item, i) in steps"
                :key="i"
                :color="item.color"
                small
                >
                <template v-slot:opposite>
                    <span
                    :class="`headline font-weight-bold ${item.color}--text`"
                    v-text="item.step"
                    ></span>
                </template>
                <div class="py-4">
                    <div>
                    {{item.description}}
                    </div>
                </div>
                </v-timeline-item>
            </v-timeline>
        </v-container> -->

        <div class="mb-15">
            <v-container class="container-custom">
                <div class="title-span pt-10 mb-10 text-center">
                    <h3>Frequently Asked Questions</h3>
                </div>

                <v-list
                v-for="item in faq"
                    :key="item.title"
                    :value="true">
                <v-list-group
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title class="item-list-custom font-weight-bold" v-text="item.title" style="font-weight:500"></v-list-item-title>
                    </v-list-item-content>
                    </template>

                    <v-list-item
                        v-for="child in item.items"
                        :key="child.title"
                        style="padding-left:20px !important"
                    >
                        <v-list-item-title class="item-list-custom" v-text="child.title" ></v-list-item-title>
                    </v-list-item>


                </v-list-group>
                

                    <v-divider v-show="item !== 0"></v-divider>
                </v-list>
            </v-container>
        </div>
    </div>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
export default {
    data() {
        return {
            mobileView: false,
            faq: [
                {
                action: 'mdi-ticket',
                items: [
                    { title: 'Dengan mengikuti Bootcamp waktu belajar relatif lebih singkat dan intensif serta anda tidak hanya akan belajar namun sekaligus membangun portofolio. Sehingga ketika anda lulus dari Bootcamp anda akan memiliki kemampuan yang siap digunakan untuk bekerja.' },
                ],
                title: 'Mengapa saya harus mengikuti Bootcamp?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Bootcamp ini terbuka bagi mahasiswa tingkat akhir, fresh graduate dari perguruan tinggi, karyawan dengan minimal pengalaman kerja 1 - 3 tahun, diutamakan bagi orang-orang yang memiliki latar belakang IT dan berusia 21 - 30 tahun serta memiliki komitmen tinggi untuk mengikuti dan menyelesaikan Bootcamp.' },
                ],
                title: 'Apakah ada kriteria untuk mendaftar Bootcamp?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Untuk saat ini pelatihan akan dilaksanakan secara online dengan menggunakan Zoom meeting baik untuk sesi materi, praktikal, dan mentoring. Pelatihan juga akan direkam sehingga peserta dapat menonton kembali pelatihan jika ingin mereview kembali materi.' },
                ],
                title: 'Pelatihan akan dilaksanakan secara online atau offline?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Ya, kami menyediakan 2 pilihan yaitu full-time dan part-time. Jika anda memilih full-time pelatihan akan dilaksanakan pada pukul 09:00 - 16:00 WIB, sedangkan jika adan memilih part-time pelatihan akan dilaksanakan pada pukul 19:00 - 20:00 WIB' },
                ],
                title: 'Apakah saya dapat memilih jadwal pelatihan?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Kami menyediakan beberapa pilihan metode pembayaran, diantaranya upfront cash (bank transfer / e-wallet), cash bertahap, dan cicilan melalui kartu kredit.' },
                ],
                title: 'Apa saja pilihan metode pembayarannya?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Kami menyediakan rekomendasi job opportunity kepada perusahaan mitra kami bagi para lulusan yang mendapatkan nilai memuaskan.' },
                ],
                title: 'Apakah setelah mengikuti pelatihan bootcamp akan disalurkan kerja?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Syarat untuk lulus dari program ini adalah menyelesaikan rangkaian bootcamp dengan total kehadiran minimal 80 % dan nilai akhir minimal 70.' },
                ],
                title: 'Apa syarat untuk lulus dari program bootcamp ini?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Untuk mengikuti program bootcamp ini tidak harus memiliki latar belakang IT, namun diharapkan memiliki skill basic di bidang graphic design.' },
                ],
                title: 'Apakah harus memiliki latar belakang IT dan graphic design?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Bootcamp yang dilaksanakan akan belajar secara praktIk dari 80% studi kasus, 20% materi dan disertai dengan diskusi yang berupa mentoring dan konsultasi dengan mentor selama Bootcamp berlangsung. Dalam mengikuti Bootcamp ini peserta akan diberikan quiz untuk menguji pemahaman serta anda akan diarahkan untuk membuat sebuah final project yang akan dipresentasikan di akhir Bootcamp nanti.' },
                ],
                title: 'Apakah ada seleksi masuk untuk mengikuti program bootcamp?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Ya, setelah mengikuti pelatihan hingga selesai akan mendapatkan e-certificate yang dapat dijadikan bukti kompetensi atau portofolio Anda.' },
                ],
                title: 'Apakah setelah pelatihan akan mendapatkan sertifikat?',
                },
                {
                action: 'mdi-silverware-fork-knife',
                active: true,
                items: [
                    { title: 'Anda harus memiliki laptop / PC yang dilengkapi dengan webcam dan tambahan headset + mic untuk menunjang pembelajaran. Selain itu sebelum Bootcamp dilaksanakan peserta harus mempersiapkan tools-tools seperti software yang akan digunakan saat Bootcamp berlangsung nantinya.' },
                ],
                title: 'Apa yang harus dipersiapkan sebelum mengikuti Bootcamp?',
                },
            ],
            steps: [
                {
                    color: 'cyan',
                    step: 'Step 1',
                    description: 'Kamu dapat membeli voucher HiColleagues di Pintaria, Pijarmahir, Sekolahmu, Tokopedia, & Bukalapak'
                },
                {
                    color: 'green',
                    step: 'Step 2',
                    description: 'Buka tab baru dan masukan tautan hicolleagues.com/prakerja.'
                },
                {
                    color: 'pink',
                    step: 'Step 3',
                    description: 'Setelah itu, kamu dapat memilih pelatihan sesuai yang dibeli dari Mitra Platform sebelumnya dan Tukarkan Voucher'
                },
                {
                    color: 'amber',
                    step: 'Step 4',
                    description: 'Kamu akan mendapatkan email konfirmasi dari HiColleagues. Mengikuti pelatihan Webinar via Zoom'
                },
                {
                    color: 'orange',
                    step: 'Step 5',
                    description: 'Setelah mengikuti pelatihan, berikan rating dan ulasan di Form HiColleagues dan Mitra Platform Prakerja'
                },
                {
                    color: 'yellow',
                    step: 'Step 6',
                    description: 'Jangan lupa untuk mengisi 3 survei di Dashboard Prakerja. Kemudian kamu akan mendapatkan insentif dari pemerintah'
                },
            ],
            requirementPublicBootcamp: [
                {   requirement: "Syarat Bootcamp", 
                    description: 
                        `<ul>
                            <li>Mahasiswa S1 aktif di Perguruan Tinggi</li>
                            <li>Memiliki latar belakang IT (diutamakan)</li>
                            <li>Usia 20 - 25 tahun</li>
                            <li>Memiliki komitmen tinggi untuk mengikuti dan menyelesaikan bootcamp</li>
                            <li>Peserta yang memiliki ketertarikan untuk mempelajari bidang IT / digital</li>
                        </ul>`
                }
            ],
            listOfPrakerja: [],
            duration: 300,
            offset: 0,
            easing: 'easeInOutCubic',
        }
    },
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
        scrollTo = to.hash
        } else if (savedPosition) {
        scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    computed: {
        options () {
            return {
            duration: this.duration,
            offset: this.offset,
            easing: this.easing,
            }
        },
    },
    methods: {
        async getClass() {
            // this.spin_load.val = true
            await this.$http
            .get("/v1/class/category/5")
            .then((response) => {
                response.data.data.classes.forEach(element => {
                    this.listOfPrakerja.push({ 
                        "id": parseInt(element.id),
                        "name": element.name,
                        "image_url": element.thumbnail_url,
                        "description": element.description,
                        "level": element.level,
                        "slug_name": element.slug_name,
                    })
                });
            })
            .catch((err) => console.error(err.response))
            // .finally(() => this.spin_load.val = false)
        },
        handleView() {
            this.mobileView = window.innerWidth <= 990
        },
    },
    mounted() {
        this.handleView()
        this.getClass()
        this.$gtag.pageview(this.$route)
    }
}
</script>

<style scoped>
@media only screen and (max-width: 900px) {
    .text-banner {
        margin-top:0px;
        padding-top: 0px;
        font-size: 42px;
    }

    .banner {
        height: 400px !important;
        margin-top: -20px;
    }

    .card-prakerja {
        margin-left: 0px !important;
        border-radius:20px;
        width:auto !important;
        height:250px !important;
    }

    .img-prakerja {
        width: 351px !important;
        height: 250px !important;
    }
}

.img-prakerja {
    width: 400px;
    height: 300px;
}

.card-prakerja {
        margin-left: 40px;
        border-radius:20px;
        width:400px;
        height:300px;
    }

.text-banner {
        margin-top:40px;
    }

.title-span {
  font-weight: 800;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.2rem;
}

.sub-title-span {
  font-family: 'Quicksand', sans-serif;
  font-size: 1.0rem;
}

.banner {
    background: lightblue url('/img/prakerja/banner_prakerja.png') no-repeat;
    background-size:cover;
    height: 500px;
}

h1 {
    font-size:55px;
    max-width:800px;
    padding-top: 100px;
}

.prakerja-class {
    width: 200px;
}

.container-custom {
  max-width: 900px;
}

.item-list-custom{
    white-space: pre-wrap;
}
</style>