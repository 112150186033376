<template>
    
<svg :width="width" :height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.4002 5.16602C24.3835 5.16602 21.6835 6.63268 20.0002 8.88268C18.3168 6.63268 15.6168 5.16602 12.6002 5.16602C7.4835 5.16602 3.3335 9.33268 3.3335 14.4827C3.3335 16.466 3.65016 18.2993 4.20016 19.9993C6.8335 28.3327 14.9502 33.316 18.9668 34.6827C19.5335 34.8827 20.4668 34.8827 21.0335 34.6827C25.0502 33.316 33.1668 28.3327 35.8002 19.9993C36.3502 18.2993 36.6668 16.466 36.6668 14.4827C36.6668 9.33268 32.5168 5.16602 27.4002 5.16602Z" fill="#162CA2"/>
</svg>

</template>

<script>
export default {
  name: "HeartIcon",
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 30,
    }
  },
};
</script>