<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 22C2 20.8954 2.89543 20 4 20H8C9.10457 20 10 20.8954 10 22V28C10 29.1046 9.10457 30 8 30H4C2.89543 30 2 29.1046 2 28V22Z"
      :fill="fill"
    />
    <path
      d="M12 14C12 12.8954 12.8954 12 14 12H18C19.1046 12 20 12.8954 20 14V28C20 29.1046 19.1046 30 18 30H14C12.8954 30 12 29.1046 12 28V14Z"
      :fill="fill"
    />
    <path
      d="M22 4C22 2.89543 22.8954 2 24 2H28C29.1046 2 30 2.89543 30 4V28C30 29.1046 29.1046 30 28 30H24C22.8954 30 22 29.1046 22 28V4Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  name: "bar-icon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "white",
    },
  },
};
</script>
