<template>
    
<svg :width="width" :height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.4334 36.6673C25.3334 36.6673 25.2168 36.6507 25.1168 36.6173C21.7668 35.6673 18.2501 35.6673 14.9001 36.6173C14.2834 36.784 13.6334 36.434 13.4668 35.8173C13.2834 35.2007 13.6501 34.5507 14.2668 34.384C18.0334 33.3173 22.0001 33.3173 25.7668 34.384C26.3834 34.5673 26.7501 35.2007 26.5668 35.8173C26.4001 36.334 25.9334 36.6673 25.4334 36.6673Z" fill="#162CA2"/>
<path d="M32.0167 10.5992C30.2834 7.09916 26.9334 4.51583 23.05 3.66583C18.9834 2.76583 14.8167 3.7325 11.6334 6.29916C8.43337 8.84916 6.6167 12.6658 6.6167 16.7492C6.6167 21.0658 9.20003 25.5825 13.1 28.1992V29.5825C13.0834 30.0492 13.0667 30.7658 13.6334 31.3492C14.2167 31.9492 15.0834 32.0158 15.7667 32.0158H24.3167C25.2167 32.0158 25.9 31.7658 26.3667 31.2992C27 30.6492 26.9834 29.8158 26.9667 29.3658V28.1992C32.1334 24.7158 35.3834 17.3658 32.0167 10.5992ZM22.8667 19.3658L21.0834 22.4658C20.85 22.8658 20.4334 23.0992 20 23.0992C19.7834 23.0992 19.5667 23.0492 19.3834 22.9325C18.7834 22.5825 18.5834 21.8158 18.9167 21.2325L20.3334 18.7658H18.9334C18.1 18.7658 17.4167 18.3992 17.05 17.7825C16.6834 17.1492 16.7167 16.3825 17.1334 15.6492L18.9167 12.5492C19.2667 11.9492 20.0334 11.7492 20.6167 12.0825C21.2167 12.4325 21.4167 13.1992 21.0834 13.7825L19.6667 16.2492H21.0667C21.9 16.2492 22.5834 16.6158 22.95 17.2325C23.3167 17.8658 23.2834 18.6492 22.8667 19.3658Z" fill="#162CA2"/>
</svg>

</template>
<script>
export default {
  name: "HeartIcon",
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 30,
    }
  },
};
</script>