<template>
    
<svg :width="width" :height="height" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#FFDE59"/>
<path d="M27.9998 42.3334C20.0932 42.3334 13.6665 35.9067 13.6665 28.0001C13.6665 20.0934 20.0932 13.6667 27.9998 13.6667C35.9065 13.6667 42.3332 20.0934 42.3332 28.0001C42.3332 35.9067 35.9065 42.3334 27.9998 42.3334ZM27.9998 15.6667C21.1998 15.6667 15.6665 21.2001 15.6665 28.0001C15.6665 34.8001 21.1998 40.3334 27.9998 40.3334C34.7998 40.3334 40.3332 34.8001 40.3332 28.0001C40.3332 21.2001 34.7998 15.6667 27.9998 15.6667Z" fill="#36373F"/>
<path d="M25.187 23.4399C24.6403 23.4399 24.187 22.9865 24.187 22.4399V19.7732C24.187 19.2265 24.6403 18.7732 25.187 18.7732C25.7337 18.7732 26.187 19.2265 26.187 19.7732V22.4399C26.187 22.9865 25.747 23.4399 25.187 23.4399Z" fill="#36373F"/>
<path d="M30.813 23.4399C30.2663 23.4399 29.813 22.9865 29.813 22.4399V19.7732C29.813 19.2265 30.2663 18.7732 30.813 18.7732C31.3597 18.7732 31.813 19.2265 31.813 19.7732V22.4399C31.813 22.9865 31.3597 23.4399 30.813 23.4399Z" fill="#36373F"/>
<path d="M33.9602 26.5195H22.0269C21.4802 26.5195 21.0269 26.0662 21.0269 25.5195C21.0269 24.9729 21.4802 24.5195 22.0269 24.5195H33.9602C34.5069 24.5195 34.9602 24.9729 34.9602 25.5195C34.9602 26.0662 34.5202 26.5195 33.9602 26.5195Z" fill="#36373F"/>
<path d="M29.9998 36.56H25.9998C22.3332 36.56 19.6665 33.8933 19.6665 30.2266V26.2266C19.6665 22.56 22.3332 19.8933 25.9998 19.8933H29.9998C33.6665 19.8933 36.3332 22.56 36.3332 26.2266V30.2266C36.3332 33.8933 33.6665 36.56 29.9998 36.56ZM25.9998 21.8933C22.9998 21.8933 21.6665 24.0666 21.6665 26.2266V30.2266C21.6665 32.3866 22.9998 34.56 25.9998 34.56H29.9998C32.9998 34.56 34.3332 32.3866 34.3332 30.2266V26.2266C34.3332 24.0666 32.9998 21.8933 29.9998 21.8933H25.9998Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "54"
            },
            height: {
                type: String,
                default: "54" 
            },
        }
}
</script>