<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2329 13.9985L20.7414 8.49874C20.906 8.3341 20.9985 8.11079 20.9985 7.87794C20.9985 7.6451 20.906 7.42179 20.7414 7.25714C20.5767 7.0925 20.3534 7 20.1206 7C19.8877 7 19.6644 7.0925 19.4998 7.25714L14 12.7657L8.50024 7.25714C8.33559 7.0925 8.11228 7 7.87944 7C7.64659 7 7.42328 7.0925 7.25864 7.25714C7.09399 7.42179 7.00149 7.6451 7.00149 7.87794C7.00149 8.11079 7.09399 8.3341 7.25864 8.49874L12.7671 13.9985L7.25864 19.4983C7.17668 19.5796 7.11164 19.6763 7.06725 19.7828C7.02285 19.8894 7 20.0036 7 20.1191C7 20.2345 7.02285 20.3488 7.06725 20.4553C7.11164 20.5619 7.17668 20.6586 7.25864 20.7399C7.33992 20.8218 7.43663 20.8869 7.54318 20.9313C7.64972 20.9757 7.76401 20.9985 7.87944 20.9985C7.99486 20.9985 8.10915 20.9757 8.2157 20.9313C8.32225 20.8869 8.41895 20.8218 8.50024 20.7399L14 15.2314L19.4998 20.7399C19.581 20.8218 19.6778 20.8869 19.7843 20.9313C19.8909 20.9757 20.0051 20.9985 20.1206 20.9985C20.236 20.9985 20.3503 20.9757 20.4568 20.9313C20.5634 20.8869 20.6601 20.8218 20.7414 20.7399C20.8233 20.6586 20.8884 20.5619 20.9328 20.4553C20.9771 20.3488 21 20.2345 21 20.1191C21 20.0036 20.9771 19.8894 20.9328 19.7828C20.8884 19.6763 20.8233 19.5796 20.7414 19.4983L15.2329 13.9985Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "close-icon",
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 30,
    },
  },
};
</script>
