<template>
    
<svg :width="width"
      :height="height" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.6549 22.8364C18.4989 22.8387 18.3441 22.8092 18.1999 22.7497L12.9999 20.5072L7.79992 22.7497C7.62399 22.8258 7.43102 22.8539 7.24069 22.831C7.05037 22.8082 6.86951 22.7353 6.71659 22.6197C6.55553 22.5072 6.42811 22.353 6.34796 22.1736C6.26782 21.9942 6.23796 21.7964 6.26159 21.6014L6.78159 16.0005L3.01159 11.7755C2.8827 11.6303 2.79314 11.4545 2.75142 11.2649C2.7097 11.0752 2.71722 10.8781 2.77325 10.6922C2.83628 10.5053 2.94597 10.3375 3.09194 10.2048C3.23791 10.0721 3.41534 9.97883 3.60742 9.93386L9.13242 8.66636L12.0249 3.84552C12.1316 3.68463 12.2765 3.55265 12.4467 3.46135C12.6168 3.37005 12.8068 3.32227 12.9999 3.32227C13.193 3.32227 13.3831 3.37005 13.5532 3.46135C13.7233 3.55265 13.8682 3.68463 13.9749 3.84552L16.8674 8.66636L22.3924 9.90136C22.5953 9.92888 22.7862 10.0133 22.9431 10.1449C23.0999 10.2765 23.2163 10.4499 23.2786 10.6449C23.341 10.8399 23.3468 11.0486 23.2954 11.2468C23.244 11.445 23.1375 11.6246 22.9883 11.7647L19.2616 15.9789L19.7816 21.5797C19.7949 21.7332 19.7772 21.8877 19.7295 22.0342C19.6818 22.1807 19.6052 22.3161 19.5041 22.4323C19.403 22.5485 19.2796 22.6433 19.1411 22.7108C19.0027 22.7784 18.8521 22.8174 18.6983 22.8255L18.6549 22.8364ZM4.76659 11.3205L8.10325 15.0797C8.20694 15.1947 8.28477 15.3306 8.33149 15.4782C8.3782 15.6259 8.39273 15.7818 8.37409 15.9355L7.90825 20.9189L12.5558 18.9364C12.6964 18.8774 12.8474 18.847 12.9999 18.847C13.1524 18.847 13.3034 18.8774 13.4441 18.9364L18.0916 20.9189L17.6149 15.9355C17.6024 15.7813 17.6208 15.6261 17.6692 15.4791C17.7176 15.3321 17.7949 15.1963 17.8966 15.0797L21.2333 11.3205L16.3041 10.2372C16.0073 10.1768 15.7464 10.0015 15.5783 9.74969L12.9999 5.41636L10.4216 9.74969C10.3433 9.88418 10.237 10.0002 10.1099 10.09C9.98281 10.1797 9.83785 10.241 9.68492 10.2697L4.76659 11.3205Z" :fill="fill"/>
</svg>

</template>

<script>
export default {
  name: "star-icon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "white",
    },
  },
};
</script>