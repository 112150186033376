<template>
    <div class="outer-container" style="margin-top: 63px">
        <div class="hero">
            <div class="hero-ctn">
                <h1>Portfolio</h1>
                <p>Berbagai proyek kemitraan dalam transformasi digital berkelanjutan yang telah berhasil HiColleagues
                    selenggarakan</p>
            </div>
        </div>
        <div class="portfolio-container">
            <div class="card">
                <div class="items" v-for="(item, idx) in projectPortfolio" :key="idx"
                    @click="$router.push(`/portfolio/${item.id}`)">
                    <div style="height: 240px">
                        <img :src="item.image_url" alt=""
                            style="width: 100%; max-width: 394px; height: 100%; border-radius: 16px 16px 0 0" />
                    </div>
                    <div class="content">
                        <div class="content-detail">
                            <div class="content-title">
                                <p>{{item.title}}</p>
                                <h2>{{ item.program }}</h2>                        
                            </div>
                            <p class="description-class">{{ item.description }}</p>
                        </div>
                        <p class="portfolio-category">{{ categoryList[item.category] }}</p>
                    </div>
                </div>
            </div>
            <EmptyData v-if="!projectPortfolio || !projectPortfolio.length"
                message="Belum ada portofolio saat ini" />
            <div class="pagination" v-if="total > 1">
                <div class="btn-pagination-container">
                    <button type="button" class="btn-pagination-prev" @click="page('prev')"><img src="@/assets/icons/portfolio/arrow-left.svg" alt=""></button>
                    <button type="button" class="btn-pagination-next" @click="page('next')">SELANJUTNYA <img src="@/assets/icons/portfolio/arrow-right.svg" alt=""></button>
                </div>
                <div class="pagination-details">
                    <span>Halaman</span>
                    <div>{{ currentPage }}</div>
                    <span>dari {{ total }}</span>
                </div>
            </div>
        </div>
        <v-card elevation="0" class="call-center-container">
            <v-row style="padding: 40px">
                <v-col cols="8">
                    <div class="call-center-title">
                        Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam Transformasi Digital
                        Berkelanjutan
                    </div>
                    <div class="call-center-desc">
                        Dapatkan berbagai informasi yang kamu butuhkan melalui layanan konsultasi 24/7 Customer Service
                        HiColleagues
                    </div>
                </v-col>
                <v-col cols="1" />
                <v-col cols="3" class="call-center-button" @click="handleToCorporatePage">
                    <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import EmptyData from '../../others/EmptyData.vue';

export default {
    name: "DesktopPortfolio",
    components: {
        EmptyData,
    },
    data() {
        return {
            projectPortfolio: [],
            currentPage: 1,
            total: 1,
            categoryList: {
                COLLABORATION: "Corporate Collaboration",
                TRAINING: "Corporate Training",
                SERVICES: "Technology Service",
                KAMPUS_MERDEKA: "Kampus Merdeka"
            },
        }
    },
    methods: {
        handleToCorporatePage() {
            return this.$router.push("/corporate");
        },
        page(act) {
            if (act === 'prev') {
                if (this.currentPage <= 1) return
                this.currentPage--
            } else {
                if (this.currentPage >= this.total) return
                this.currentPage++
            }
        },
        async getPortfolio() {
            try {
                const resp = await this.$http.get(`/v1/project-portfolio?page=${this.currentPage}&per_page=12`)
                this.projectPortfolio = resp.data.data.project_portfolios;
                if (resp.data.data.total) {
                    this.total = resp.data.data.total <= 12 ? 1 : parseInt(resp.data.data.total / 12) + 1;
                }

            } catch (err) {
                console.log(err);
            }
        },
    },
    mounted() {
        this.getPortfolio()
    }
}
</script>

<style scoped src="./portfolio.css"></style>