<template>
    <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3337 10C18.3337 14.6 14.6003 18.3334 10.0003 18.3334C5.40033 18.3334 1.66699 14.6 1.66699 10C1.66699 5.40002 5.40033 1.66669 10.0003 1.66669C14.6003 1.66669 18.3337 5.40002 18.3337 10Z" stroke="#575966" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M13.0914 12.65L10.5081 11.1084C10.0581 10.8417 9.69141 10.2 9.69141 9.67503V6.25836" stroke="#575966" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "22px"
            },
            height: {
                type: String,
                default: "22px" 
            }
        }
}
</script>