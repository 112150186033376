<template>
    
<svg :width="width" :height="height" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_715_3540)">
<path d="M15.0016 3.55727e-07C12.0344 -0.000645667 9.1337 0.878636 6.66626 2.52664C4.19883 4.17465 2.27552 6.51736 1.13958 9.2585C0.00364088 11.9996 -0.293908 15.0161 0.284565 17.9263C0.863038 20.8366 2.29155 23.5099 4.38943 25.6082C6.48732 27.7066 9.16035 29.1357 12.0705 29.7148C14.9806 30.2939 17.9971 29.997 20.7385 28.8617C23.4799 27.7263 25.823 25.8035 27.4715 23.3364C29.1201 20.8694 30 17.9688 30 15.0016C30 11.0235 28.4199 7.20829 25.6073 4.39503C22.7946 1.58177 18.9797 0.000866482 15.0016 3.55727e-07ZM23.5852 13.1889C21.8541 13.264 20.319 12.6924 18.8231 11.7322C18.8035 11.9739 18.7741 12.147 18.7741 12.3234C18.7741 14.4562 18.7741 16.589 18.7741 18.7251C18.7577 22.3179 16.1611 24.9537 12.4409 25.1987C9.45235 25.3947 6.45725 22.7915 5.98691 19.5906C5.42513 15.7659 8.65866 12.1927 12.5193 12.3821C12.7283 12.3821 12.9374 12.4344 13.1725 12.4638V15.8639C12.9896 15.8639 12.8067 15.8639 12.6271 15.8639C10.8862 15.7692 9.49154 16.9777 9.42949 18.6402C9.40086 19.4022 9.67215 20.1451 10.1851 20.7093C10.6981 21.2736 11.4118 21.6142 12.1731 21.6581C13.9336 21.7235 15.289 20.4921 15.2988 18.7186C15.3217 14.4497 15.2988 10.1807 15.2988 5.91508V4.97442H18.7185C19.0452 7.8944 20.7338 9.42951 23.5852 9.83452V13.1889Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_715_3540">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "30"
            },
            height: {
                type: String,
                default: "30" 
            },
        }
}
</script>