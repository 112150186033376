<template>
    
<svg :width="width" :height="height" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 30C8.12 30 6.38 30.66 5 31.76C3.16 33.22 2 35.48 2 38C2 39.5 2.42 40.92 3.16 42.12C4.54 44.44 7.08 46 10 46C12.02 46 13.86 45.26 15.26 44C15.88 43.48 16.42 42.84 16.84 42.12C17.58 40.92 18 39.5 18 38C18 33.58 14.42 30 10 30ZM14.14 37.14L9.88 41.08C9.6 41.34 9.22 41.48 8.86 41.48C8.48 41.48 8.1 41.34 7.8 41.04L5.82 39.06C5.24 38.48 5.24 37.52 5.82 36.94C6.4 36.36 7.36 36.36 7.94 36.94L8.9 37.9L12.1 34.94C12.7 34.38 13.66 34.42 14.22 35.02C14.78 35.62 14.74 36.58 14.14 37.14Z" fill="#7B7E8C"/>
<path d="M29.7 7.89998V15.5H26.7V7.89998C26.7 7.35998 26.22 7.09998 25.9 7.09998C25.8 7.09998 25.7 7.11998 25.6 7.15998L9.74 13.14C8.68 13.54 8 14.54 8 15.68V17.02C6.18 18.38 5 20.56 5 23.02V15.68C5 13.3 6.46 11.18 8.68 10.34L24.56 4.33998C25 4.17998 25.46 4.09998 25.9 4.09998C27.9 4.09998 29.7 5.71998 29.7 7.89998Z" fill="#7B7E8C"/>
<path d="M43.0001 29V31C43.0001 31.54 42.5801 31.98 42.0201 32H39.1001C38.0401 32 37.0801 31.22 37.0001 30.18C36.9401 29.56 37.1801 28.98 37.5801 28.58C37.9401 28.2 38.4401 28 38.9801 28H42.0001C42.5801 28.02 43.0001 28.46 43.0001 29Z" fill="#7B7E8C"/>
<path d="M38.96 25.9H41C42.1 25.9 43 25 43 23.9V23.02C43 18.88 39.62 15.5 35.48 15.5H12.52C10.82 15.5 9.26 16.06 8 17.02C6.18 18.38 5 20.56 5 23.02V26.58C5 27.34 5.8 27.82 6.52 27.58C7.64 27.2 8.82 27 10 27C16.06 27 21 31.94 21 38C21 39.44 20.62 41.02 20.02 42.42C19.7 43.14 20.2 44 20.98 44H35.48C39.62 44 43 40.62 43 36.48V36.1C43 35 42.1 34.1 41 34.1H39.26C37.34 34.1 35.5 32.92 35 31.06C34.6 29.54 35.08 28.06 36.08 27.1C36.82 26.34 37.84 25.9 38.96 25.9ZM28 25.5H18C17.18 25.5 16.5 24.82 16.5 24C16.5 23.18 17.18 22.5 18 22.5H28C28.82 22.5 29.5 23.18 29.5 24C29.5 24.82 28.82 25.5 28 25.5Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "48"
            },
            height: {
                type: String,
                default: "48" 
            },
        }
}
</script>