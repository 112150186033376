<template>
  <div class="portfolio">
    <div class="portfolio-title text-center">
      <h1>{{ title }}</h1>
    </div>
    <div class="portfolio-content">
      <div
        class="portfolio-container"
        v-for="(portfolio, index) in portfolios"
        :key="index"
      >
        <div class="img-card">
          <img
            :src="portfolio.image_url"
            width="380"
            height="250"
            style="border-radius: 30px"
          />
        </div>
        <div>
          <div class="portfolio-card">
            <h3>{{ portfolio.title }}</h3>
            <p class="type" v-if="portfolio.label && portfolio.label != '-'">{{ portfolio.label }}</p>
            <p>{{ portfolio.program }}</p>
            <br />
            <p>{{ portfolio.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="portfolio-button">
      <div class="slider">
        <div
          class="indicator"
          :style="{
            width: `${sliderIndicator}%`,
            transform: `translateX(${indicatorValue}%)`,
            transition: `transform 0.5s`,
          }"
        ></div>
      </div>
      <div style="margin-right: 60px">
        <img
          class="arrow-button"
          src="/img/other/corporate-arrow-left.png"
          alt=""
          @click="scrollLeftPortfolio"
        />
        <img
          class="arrow-button"
          src="/img/other/corporate-arrow-right.png"
          alt=""
          @click="scrollRightPortfolio"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortofolioComponent",
  props: {
    portfolios: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sliderIndicator: 0,
      indicatorValue: 0,
    };
  },
  watch: {
    portfolios() {
      this.sliderIndicator = (1 / this.portfolios.length) * 100;
      if (this.sliderIndicator >= 100) {
        this.sliderIndicator = 100;
      }
    },
  },
  methods: {
    scrollRightPortfolio() {
      const container = this.$el.querySelector(".portfolio-content");
      const cardWidth = this.$el.querySelector(
        ".portfolio-container"
      ).offsetWidth;
      container.scrollLeft += cardWidth;
      this.indicatorValue +=
        this.sliderIndicator +
        ((this.portfolios.length - 1) * 100) / this.portfolios.length;
      if (this.indicatorValue >= (this.portfolios.length - 1) * 100) {
        this.indicatorValue = (this.portfolios.length - 1) * 100;
      }
    },
    scrollLeftPortfolio() {
      const container = this.$el.querySelector(".portfolio-content");
      const cardWidth = this.$el.querySelector(
        ".portfolio-container"
      ).offsetWidth;
      container.scrollLeft -= cardWidth;
      this.indicatorValue -=
        this.sliderIndicator +
        ((this.portfolios.length - 1) * 100) / this.portfolios.length;
      if (this.indicatorValue <= 0) {
        this.indicatorValue = 0;
      }
    },
  },
};
</script>

<style src="./css/portfolio.css" scoped></style>
