<template>
  <div>
    <div class="header">
      <div class="header-info"><div class="career-top">
              <span class="yellow-text font-poppins" @click="$router.push('/career')">Karir</span>
              <div>
                <Arrow :width="20" :height="20" direct="left" strokeColor="white" class="icon" />
              </div>
              <span class="font-poppins">{{ careerDetail.name }}</span>
            </div>
          </div>
      <div class="header-info" style="margin-top: 50px">
        <div class="header-left">
          <div class="career">
            <div class="career-info">
              <div class="career-title">
                <h1>{{ careerDetail.name }}</h1>
              </div>
              <div class="career-info-ctn">
                <span class="font-poppins">{{ careerDetail.company }}</span>
                <Bullet style="margin-left: 7px; margin-right: 7px"  />
                <span class="font-poppins">{{ careerDetail.location }}</span>
              </div>
            </div>
            <div class="career-data">
              <div class="label">
                <div style="background-color: #f1f6ff" v-if="careerDetail.contract_type">
                  <clock-ic />
                  <p>{{ careerDetail.contract_type }}</p>
                </div>
                <div style="background-color: #faeceb" v-if="careerDetail.work_type">
                  <location-ic  />
                  <p>{{ careerDetail.work_type }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="careerDetail.level">
                  <level-ic  />
                  <p>{{ careerDetail.level }}</p>
                </div>
              </div>
            </div>
            <div class="career-info" style="margin-top: 40px;">
              <div class="career-info-ctn">
                <span class="font-poppins">Ditutup pada <b class="font-poppins">{{ careerDetail.closed_at }}</b></span>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right">
          <Button name="LAMAR PEKERJAAN" width="350px" height="55px" @click="() => toCheckout()" />
            <div class="ic-copy" @click="onCopyUrl">
                  <CopyIcon :width="25" :height="25" />
                </div>
                <v-alert class="alert-info" type="success" :value="show_alert">Copied</v-alert>
        </div>
      </div>
    </div>
    <div style="background-color: rgba(245, 246, 250, 1);">
      <div class="career-detail">
        <div class="career-detail-item">
          <h1>Siapa yang Kami Cari</h1>
          <ul>
            <li v-for="(responsibility, idx) in careerDetail.career_detail
                  .responsibilities" :key="idx">
              <div v-html="responsibility"></div>
            </li>
          </ul>
        </div>
        <div class="career-detail-item">
          <h1>Apa Saja yang Akan Kamu Lakukan</h1>
          <ul>
            <li v-for="(requirement, idx) in careerDetail.career_detail.requirements" :key="idx">
              <div v-html="requirement"></div>
            </li>
          </ul>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 90px">
          <Button name="LAMAR PEKERJAAN" width="290px" height="55px" @click="() => toCheckout()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/others/Button.vue";
import Arrow from "@/assets/icons/Arrow.vue";
import CopyIcon from "@/assets/icons/career/Copy.vue";
import Bullet from "@/assets/icons/Bullet.vue";
import ClockIc from '@/assets/icons/career/Clock.vue';
import LevelIc from '@/assets/icons/career/Level.vue';
import LocationIc from '@/assets/icons/career/Location.vue';
export default {
  name: "DesktopCareerDetail",
  components: {
    Button,
    ClockIc,
    LevelIc,
    LocationIc,
    Arrow,
    CopyIcon,
    Bullet
  },
  data() {
    return {
      careerDetail: null,
      show_alert: false,
    };
  },
  watch: {
    show_alert() {
      if (!this.show_alert) return;
      setTimeout(() => {
        this.show_alert = false;
      }, 3000);
    },
  },
  methods: {
    async getCareerDetail() {
      await this.$http
        .get(`/v1/career/${this.$route.params.id}`)
        .then((response) => {
          this.careerDetail = response.data.data;
          const options = { year: "numeric", month: "long", day: "numeric" };
          const formatter = new Intl.DateTimeFormat("id-ID", options);

          const startDate = new Date(this.careerDetail.started_at);
          this.careerDetail.started_at = formatter.format(startDate);

          const closedAt = new Date(this.careerDetail.closed_at);
          this.careerDetail.closed_at = formatter.format(closedAt);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.$router.push("/not-found");
        });
    },
    onCopyUrl() {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;
      clipboardData.writeText(window.location.href);
      this.show_alert = true;
    },
    toCheckout() {
      if (new Date() > new Date(this.careerDetail.closed_at)) return
      this.$router.push(`/career/applicant/${this.careerDetail.id}`);
    }
  },
  mounted() {
    // Make a GET request to the API endpoint
    this.getCareerDetail();
  },
};
</script>

<style src="./detail.css" scoped></style>