<template>
    <div class="step-collaboration">
        <h1>{{ title }}</h1>
        <svg width="100%" height="1" viewBox="0 0 1400 1" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.5" x1="0.5" y1="0.5" x2="1400" y2="0.5" stroke="#36373F" stroke-linecap="round"
                stroke-dasharray="15 15" />
        </svg>
        <div class="step-container">
            <div class="step-card" :style="`position: relative; background-color: ${bgColor};`" v-for="(step, index) in steps" :key="index">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                    style="position: absolute; top: -54px; left: 50%">
                    <circle cx="15" cy="15" r="15" fill="#162CA2" />
                    <circle cx="15" cy="15" r="5" fill="#FFDE59" />
                </svg>

                <div class="card-container">
                    <div class="card-image">
                        <img :src="step.image_url" alt="" />
                    </div>
                    <div class="content">
                        <h3>{{ step.title }}</h3>
                        <p>{{ step.description }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="indicator-container">
            <div style="width: 10px; height: 10px"></div>
            <div class="indicator">
                <svg :style="{ transform: `translateX(${indicator * 15}px)` }" width="25" height="10"
                    viewBox="0 0 25 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="25" height="10" rx="5" fill="#162CA2" />
                </svg>
                <svg :style="{ transform: `translateX(${-indicator2 * 30}px` }" width="10" height="10"
                    viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
                </svg>
                <svg :style="{ transform: `translateX(${-indicator3 * 30}px` }" width="10" height="10"
                    viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
                </svg>
            </div>
            <div class="btn">
                <div class="btn-card">
                    <img src="/img/other/corporate-arrow-left.png" alt="" @click="scrollLeftStep" />
                </div>
                <div class="btn-card">
                    <img src="/img/other/corporate-arrow-right.png" alt="" @click="scrollRightStep" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StepCollaboration",
    props: {
        steps: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        },
        bgColor: {
            type: String, 
            default: '#f1f6ff'
        }
    },
    data() {
        return {
            indicator: 0,
            indicator2: 0,
            indicator3: 0,
        }
    },
    methods: {
        scrollRightStep() {
            const container = this.$el.querySelector(".step-container");
            const cardWidth = this.$el.querySelector(".step-card").offsetWidth;
            container.scrollLeft += cardWidth * 2.1;
            if (this.indicator < 2) {
                this.indicator++;
                if (this.indicator2 < 1) {
                    this.indicator2++;
                }
            }
            if (this.indicator === 2 && this.indicator3 < 1) {
                this.indicator3++;
            }
        },
        scrollLeftStep() {
            const container = this.$el.querySelector(".step-container");
            const cardWidth = this.$el.querySelector(".step-card").offsetWidth;
            container.scrollLeft -= cardWidth * 2.1;
            if (this.indicator === 1) {
                this.indicator2--;
            }
            if (this.indicator > 0) {
                this.indicator--;
            }
            if (this.indicator3 < 2 && this.indicator > 0) {
                this.indicator3--;
            }
        },
    }
}
</script>

<style src="./css/stepCollaboraron.css" scoped></style>