<template>

<svg :width="width"
:height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#FFDE59"/>
<path d="M42.5 18.4375H17.5C16.9875 18.4375 16.5625 18.0125 16.5625 17.5C16.5625 16.9875 16.9875 16.5625 17.5 16.5625H42.5C43.0125 16.5625 43.4375 16.9875 43.4375 17.5C43.4375 18.0125 43.0125 18.4375 42.5 18.4375Z" fill="#36373F"/>
<path d="M35.8373 42.925C35.6748 43.25 35.3373 43.4375 34.9998 43.4375C34.8623 43.4375 34.7123 43.4 34.5873 43.3375L29.9998 41.05L25.4123 43.3375C25.2873 43.4 25.1373 43.4375 24.9998 43.4375C24.6623 43.4375 24.3248 43.25 24.1623 42.925C23.9248 42.45 24.1123 41.8875 24.5873 41.6625L29.0623 39.425V36.25H30.9373V39.425L35.4123 41.6625C35.8873 41.8875 36.0748 42.45 35.8373 42.925Z" fill="#36373F"/>
<path d="M18.75 17.5V32.625C18.75 35 20 36.25 22.375 36.25H37.625C40 36.25 41.25 35 41.25 32.625V17.5H18.75ZM36.225 25.725L32.2875 29.0125C31.925 29.3125 31.4625 29.4375 31.025 29.3625C30.575 29.2875 30.1875 29.0125 29.95 28.6125L28.6375 26.425L24.975 29.475C24.8 29.625 24.5875 29.6875 24.375 29.6875C24.1125 29.6875 23.8375 29.575 23.65 29.35C23.3125 28.95 23.375 28.3625 23.775 28.025L27.7125 24.7375C28.075 24.4375 28.5375 24.3125 28.975 24.3875C29.425 24.4625 29.8125 24.7375 30.05 25.1375L31.3625 27.325L35.025 24.275C35.425 23.9375 36.0125 24 36.35 24.4C36.675 24.8 36.625 25.3875 36.225 25.725Z" fill="#36373F"/>
</svg>

</template>
<script>
export default {
  name: "PublicIcon",
  props: {
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    }
  },
};
</script>