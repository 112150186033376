<template>
<svg :width="width" :height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#FFDE59"/>
<path d="M35.2375 17.5H24.7625C20.2125 17.5 17.5 20.2125 17.5 24.7625V35.225C17.5 39.7875 20.2125 42.5 24.7625 42.5H35.225C39.775 42.5 42.4875 39.7875 42.4875 35.2375V24.7625C42.5 20.2125 39.7875 17.5 35.2375 17.5ZM24.5375 37.6875C24.5375 38.2 24.1125 38.625 23.6 38.625C23.0875 38.625 22.6625 38.2 22.6625 37.6875V35.1C22.6625 34.5875 23.0875 34.1625 23.6 34.1625C24.1125 34.1625 24.5375 34.5875 24.5375 35.1V37.6875ZM30.9375 37.6875C30.9375 38.2 30.5125 38.625 30 38.625C29.4875 38.625 29.0625 38.2 29.0625 37.6875V32.5C29.0625 31.9875 29.4875 31.5625 30 31.5625C30.5125 31.5625 30.9375 31.9875 30.9375 32.5V37.6875ZM37.3375 37.6875C37.3375 38.2 36.9125 38.625 36.4 38.625C35.8875 38.625 35.4625 38.2 35.4625 37.6875V29.9125C35.4625 29.4 35.8875 28.975 36.4 28.975C36.9125 28.975 37.3375 29.4 37.3375 29.9125V37.6875ZM37.3375 25.9625C37.3375 26.475 36.9125 26.9 36.4 26.9C35.8875 26.9 35.4625 26.475 35.4625 25.9625V24.75C32.275 28.025 28.2875 30.3375 23.825 31.45C23.75 31.475 23.675 31.475 23.6 31.475C23.175 31.475 22.8 31.1875 22.6875 30.7625C22.5625 30.2625 22.8625 29.75 23.375 29.625C27.5875 28.575 31.3375 26.3625 34.3125 23.2375H32.75C32.2375 23.2375 31.8125 22.8125 31.8125 22.3C31.8125 21.7875 32.2375 21.3625 32.75 21.3625H36.4125C36.4625 21.3625 36.5 21.3875 36.55 21.3875C36.6125 21.4 36.675 21.4 36.7375 21.425C36.8 21.45 36.85 21.4875 36.9125 21.525C36.95 21.55 36.9875 21.5625 37.025 21.5875C37.0375 21.6 37.0375 21.6125 37.05 21.6125C37.1 21.6625 37.1375 21.7125 37.175 21.7625C37.2125 21.8125 37.25 21.85 37.2625 21.9C37.2875 21.95 37.2875 22 37.3 22.0625C37.3125 22.125 37.3375 22.1875 37.3375 22.2625C37.3375 22.275 37.35 22.2875 37.35 22.3V25.9625H37.3375Z" fill="#36373F"/>
</svg>


</template>
    
<script>
export default {
  name: "CorpIcon",
  props: {
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    }
  },
};
</script>