<template>
    <div>
      <DesktopCareer v-if="!isMobile"/>
      <MobileCareer v-if="isMobile"/>
    </div>
  </template>
  <script>
  import DesktopCareer from "@/components/career/main/desktop/Career.vue";
  import MobileCareer from  "@/components/career/main/mobile/Career.vue"
  
  export default {
    components: {
      DesktopCareer,
      MobileCareer,
    },
    metaInfo() {
      const thisPath = window.location.href
      return {
        title: this.$route.meta.title,
        htmlAttrs: {
          lang: "id",
          amp: true,
        },
        meta: [
          { charset: "utf-8" },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name:"keywords", content: "Karir HiColleagues"},
          { name: "description", content: this.$route.meta.description},
        ],
        link: [
          {
            rel: 'canonical',
            href: thisPath
          }
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": this.$route.meta.title,
              "url": thisPath,
              "potentialAction": {
                "@type": "SearchAction",
                "target": thisPath
              }
            }
          }
        ]
      };
    },
    data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      handleView() {
        this.isMobile = screen.width <= 990;
      },
    },
    mounted() {
      this.$gtag.pageview(this.$route);
      this.$utils.scrollTop()
    },
    beforeMount() {
      this.handleView();
    }
  };
  </script>
  