<template>
  <footer>
    <div class="container">
      <div class="logo">
        <br>
        <LogoNav width="209" :isBlue="true" height="50" />
        <p style="margin-top: 20px;">
          <span style="font-size: 18px; font-weight: bold; font-family: 'Poppins';">PT Generasi Anak Muda Berkarya</span> <br>
            <span style="font-family: 'Poppins';">Kantorkuu Coworking & Office Space Agro Plaza Kuningan,<br />Jl. H.R Rasuna Said, RT 7/ RW 4, Kuningan Timur, Setia Budi, Jakarta 12950</span>
        </p>
        <div class="sosmed">
          <div v-for="(sosmed, idx) in soscialMedia" :key="idx" @click="toSosmed(sosmed.link)"
          style="cursor: pointer">
            <component :is="sosmed.img"></component>
          </div>
        </div>
      </div>
      <div class="links">
        <v-row dense >
          <v-col cols="12"  md="4" sm="6">
            <h4 class="mb-2">Program HiColleagues</h4>
            <div v-for="(link, index) in linkProducts" :key="index">
              <span @click="navigateTo(link.url)">{{ link.name }}</span>
            </div>
          </v-col>
          <v-col cols="12"  md="4" sm="6">
            <h4 class="mb-2">HiColleagues Bisnis</h4>
            <div v-for="(link, index) in linkInformations" :key="index">
              <span @click="navigateTo(link.url)">{{ link.name }}</span>
            </div>
          </v-col>
          <v-col cols="12"  md="4" sm="6">
            <h4 class="mb-2">Tentang HiColleagues</h4>
            <div v-for="(link, index) in linkEtcs" :key="index">
              <span @click="navigateTo(link.url)">{{ link.name }}</span>
            </div>
            <h4 class="mb-2 mt-5">Lain-lain</h4>
            <div v-for="(link, index) in linkOther" :key="index">
              <span @click="navigateTo(link.url)">{{ link.name }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <hr class="mt-4" />
    <p class="copyright">Copyright © {{ new Date().getFullYear() }} - PT Generasi Anak Muda Berkarya</p>
  </footer>
</template>
<script>
import LogoNav from '@/assets/icons/LogoNav.vue';
import InstagramCorp from '@/assets/icons/sosmed/InstagramCorp.vue';
import FacebookCorp from '@/assets/icons/sosmed/FacebookCorp.vue';
import LinkedinCorp from '@/assets/icons/sosmed/LinkedinCorp.vue';
import TiktokCorp from '@/assets/icons/sosmed/TiktokCorp.vue';

export default {
  components: {
    LogoNav,
    InstagramCorp,
    FacebookCorp,
    LinkedinCorp,
    TiktokCorp
  },
  name: "FooterCorporate",
  data() {
    return {
      footer: {
        logo: "/img/logo_footer.png",
        address:
          "PT Generasi Anak Muda Berkarya Grand Slipi Tower Jl. Letjen S Parman Kav 22-24, Palmerah, Jakarta Barat, DKI Jakarta, 11480",
      },
      soscialMedia: [
        {
          name: "instagram",
          img: InstagramCorp,
          link: "https://instagram.com/hicolleagues"
        },
        {
          name: "facebook",
          img: FacebookCorp,
          link: "https://www.facebook.com/hicolleagues/"
        },
        {
          name: "linkedin",
          img: LinkedinCorp,
          link: "https://www.linkedin.com/company/hicolleagues?originalSubdomain=id"
        },
        {
          name: "tiktok",
          img: TiktokCorp,
          link: "https://www.tiktok.com/@hicolleagues"
        },
      ],
      linkProducts: [
        {
          name: "Bootcamp",
          url: "/class/bootcamp",
        },
        {
          name: "Public Training",
          url: "/class/public-training",
        },
        {
          name: "Prakerja",
          url: "/prakerja",
        },
        {
          name: "Kampus Merdeka",
          url: "/kampusmerdeka",
        },
        {
          name: "Event",
          url: "/event",
        },
      ],
      linkInformations: [
        {
          name: "Corporate Service",
          url: "/corporate",
        },
        {
          name: "Acceleration Program",
          url: "/corporate/acceleration",
        },
        {
          name: "Corporate Collaboration",
          url: "/corporate/collaboration",
        },
        {
          name: "Corporate Training",
          url: "/corporate/training",
        },
        {
          name: "Talent as a Service",
          url: "/corporate/talent-service",
        },
        {
          name: "Technolgy Service",
          url: "/corporate/services",
        },
      ],
      linkEtcs: [
        {
          name: "Tentang Kami",
          url: "/about",
        },
        {
          name: "Hubungi Kami",
          url: "/contact-us",
        },
        {
          name: "Portofolio",
          url: "/portfolio",
        },
        {
          name: "Artikel",
          url: "/blog",
        },
        {
          name: "FAQ",
          url: "/faq",
        },
      ],
      linkOther: [
        {
          name: "Kebijakan Privasi",
          url: "/privacy-policy",
        },
        {
          name: "Syarat & Ketentuan",
          url: "/term-condition",
        },
      ]
    };
  },
  methods: {
    toSosmed(link) {
      window.open(link)
    },
    navigateTo(link) {
      this.$router.push(link)
    }
  }
};
</script>

<style src="./css/footerCorporate.css" scoped></style>