<template>
    
<svg :width="width" :height="height" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.002 3.55727e-07C12.0348 -0.000645667 9.13406 0.878636 6.66663 2.52664C4.19919 4.17465 2.27589 6.51736 1.13995 9.2585C0.00400709 11.9996 -0.293541 15.0161 0.284931 17.9263C0.863404 20.8366 2.29191 23.5099 4.3898 25.6082C6.48769 27.7066 9.16072 29.1357 12.0708 29.7148C14.981 30.2939 17.9975 29.997 20.7388 28.8617C23.4802 27.7263 25.8233 25.8035 27.4719 23.3364C29.1204 20.8694 30.0004 17.9688 30.0004 15.0016C30.0004 11.0235 28.4203 7.20829 25.6076 4.39503C22.795 1.58177 18.9801 0.000866482 15.002 3.55727e-07ZM23.5855 13.1889C21.8545 13.264 20.3193 12.6924 18.8234 11.7322C18.8038 11.9739 18.7744 12.147 18.7744 12.3234C18.7744 14.4562 18.7744 16.589 18.7744 18.7251C18.7581 22.3179 16.1615 24.9537 12.4413 25.1987C9.45271 25.3947 6.45761 22.7915 5.98728 19.5906C5.42549 15.7659 8.65903 12.1927 12.5197 12.3821C12.7287 12.3821 12.9377 12.4344 13.1729 12.4638V15.8639C12.99 15.8639 12.8071 15.8639 12.6275 15.8639C10.8866 15.7692 9.49191 16.9777 9.42985 18.6402C9.40123 19.4022 9.67251 20.1451 10.1855 20.7093C10.6984 21.2736 11.4122 21.6142 12.1735 21.6581C13.9339 21.7235 15.2894 20.4921 15.2992 18.7186C15.3221 14.4497 15.2992 10.1807 15.2992 5.91508V4.97442H18.7189C19.0455 7.8944 20.7342 9.42951 23.5855 9.83452V13.1889Z" fill="#575966"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "30"
            },
            height: {
                type: String,
                default: "30" 
            },
        }
}
</script>