<template>
<svg :width="width"
:height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#FFDE59"/>
<path d="M35.8128 19.8254H27.3878V23.6004C27.3878 24.0879 26.9878 24.4754 26.5128 24.4754C26.0378 24.4754 25.6378 24.0879 25.6378 23.6004V19.8254H24.1878C19.2503 19.8254 17.6253 21.3004 17.5128 25.9129C17.5003 26.1379 17.6003 26.3754 17.7628 26.5379C17.9253 26.7129 18.1378 26.8004 18.3878 26.8004C20.1378 26.8004 21.5753 28.2504 21.5753 30.0004C21.5753 31.7504 20.1378 33.2004 18.3878 33.2004C18.1503 33.2004 17.9253 33.2879 17.7628 33.4629C17.6003 33.6254 17.5003 33.8629 17.5128 34.0879C17.6253 38.7004 19.2503 40.1754 24.1878 40.1754H25.6378V36.4004C25.6378 35.9129 26.0378 35.5254 26.5128 35.5254C26.9878 35.5254 27.3878 35.9129 27.3878 36.4004V40.1754H35.8128C40.9378 40.1754 42.5003 38.6129 42.5003 33.4879V26.5129C42.5003 21.3879 40.9378 19.8254 35.8128 19.8254ZM38.0878 29.8754L36.9253 31.0004C36.8753 31.0379 36.8628 31.1129 36.8753 31.1754L37.1503 32.7629C37.2003 33.0504 37.0878 33.3504 36.8378 33.5254C36.6003 33.7004 36.2878 33.7254 36.0253 33.5879L34.5878 32.8379C34.5378 32.8129 34.4628 32.8129 34.4128 32.8379L32.9753 33.5879C32.8628 33.6504 32.7378 33.6754 32.6128 33.6754C32.4503 33.6754 32.3003 33.6254 32.1628 33.5254C31.9253 33.3504 31.8003 33.0629 31.8503 32.7629L32.1253 31.1754C32.1378 31.1129 32.1128 31.0504 32.0753 31.0004L30.9128 29.8754C30.7003 29.6754 30.6253 29.3629 30.7128 29.0879C30.8003 28.8004 31.0378 28.6004 31.3378 28.5629L32.9378 28.3254C33.0003 28.3129 33.0503 28.2754 33.0878 28.2254L33.8003 26.7754C33.9378 26.5129 34.2003 26.3504 34.5003 26.3504C34.8003 26.3504 35.0628 26.5129 35.1878 26.7754L35.9003 28.2254C35.9253 28.2879 35.9753 28.3254 36.0378 28.3254L37.6378 28.5629C37.9378 28.6004 38.1753 28.8129 38.2628 29.0879C38.3753 29.3629 38.3003 29.6629 38.0878 29.8754Z" fill="#36373F"/>
</svg>


</template>

<script>
export default {
  name: "EmailIcon",
  props: {
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    }
  },
};
</script>