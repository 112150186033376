<template>
  <v-container>
    <div class="footer-border-blog"></div>
    <p class="footer-title-blog">
      Copyright © {{ new Date().getFullYear() }} - PT Generasi Anak Muda
      Berkarya
    </p>
  </v-container>
</template>

<script>
export default {
  name: "FooterBlog",
};
</script>

<style src="./css/footerBlog.css" scoped></style>
