<template>
    
<svg :width="width" :height="height" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M63.236 113.53C64.1184 112.647 64.1184 111.177 63.236 110.294L56.1772 103.235L65.2949 94.1178C66.1772 93.2354 66.1772 91.7648 65.2949 90.8825C64.4125 90.0001 62.9419 90.0001 62.0596 90.8825L52.9419 100L44.7066 91.7648C43.8243 90.8825 42.3537 90.8825 41.4713 91.7648C40.589 92.6472 40.589 94.1178 41.4713 95.0001L49.7066 103.235L41.4713 111.471C40.589 112.353 40.589 113.824 41.4713 114.706C42.3537 115.588 43.8243 115.588 44.7066 114.706L52.9419 106.471L60.0007 113.53C61.1772 114.412 62.3537 114.412 63.236 113.53Z" fill="#ACAFBF"/>
<path d="M62.3536 142.647C61.4712 143.529 61.4712 145 62.6477 145.882C63.53 146.765 65.0006 146.765 65.883 145.588C80.2947 129.706 105.001 130 119.118 144.706C120.001 145.588 121.471 145.588 122.354 144.706C123.236 143.824 123.236 142.353 122.354 141.471C106.177 125 78.53 124.706 62.3536 142.647Z" fill="#ACAFBF"/>
<path d="M172.353 160.294L180 82.3531C180.294 79.1178 179.118 75.8825 177.059 73.5295C175 71.1766 171.765 69.706 168.53 69.706V49.4119C168.53 41.7648 162.647 35.5884 155 35.2942L31.1768 33.5295C23.2356 33.5295 17.3533 39.706 17.0592 47.059V52.3531C13.5297 53.2354 10.8827 55.5884 9.4121 59.1178C7.64739 61.1766 6.47092 63.8237 5.88269 65.8825C4.4121 71.1766 4.70621 76.7648 5.29445 82.3531C7.64739 108.235 10.0003 133.824 12.0592 159.706C12.9415 170.882 15.8827 175.588 32.0592 176.471C78.2356 178.824 124.412 178.53 170.589 177.647C172.353 177.647 174.412 177.647 175.883 176.765C177.647 175.882 178.824 174.118 178.53 172.353C177.647 168.53 171.177 169.118 167.353 168.824C170 167.059 171.765 164.118 172.353 160.294ZM31.1768 38.2354L155 40.0001C160 40.0001 164.412 44.4119 164.118 49.4119V70.0001H120.883C117.647 70.0001 114.412 68.5295 112.059 66.1766L105.294 58.5295C101.765 54.706 96.765 52.3531 91.4709 52.3531H21.4709V47.6472C21.4709 42.059 25.8827 37.9413 31.1768 38.2354ZM160.883 166.471H28.2356C24.7062 166.471 21.4709 163.824 21.1768 160L12.6474 64.1178C12.3533 60.0001 15.5886 56.4707 19.7062 56.4707H91.4709C95.5886 56.4707 99.4121 58.2354 102.059 61.1766L108.824 68.8237C111.765 72.3531 116.177 74.4119 120.883 74.4119H168.53C170.589 74.4119 172.353 75.2943 173.824 76.7648C175.294 78.2354 175.883 80.2943 175.589 82.059L167.941 160C167.647 163.53 164.412 166.471 160.883 166.471Z" fill="#ACAFBF"/>
<path d="M130 99.4119L136.471 92.9413C137.353 92.059 137.353 90.5884 136.471 89.706C135.589 88.8237 134.118 88.8237 133.236 89.706L126.765 96.1766L118.53 87.9413C117.648 87.059 116.177 87.059 115.295 87.9413C114.412 88.8237 114.412 90.2942 115.295 91.1766L123.53 99.4119L115.883 107.059C115 107.941 115 109.412 115.883 110.294C116.765 111.177 118.236 111.177 119.118 110.294L126.765 102.647L135 110.882C135.883 111.765 137.353 111.765 138.236 110.882C139.118 110 139.118 108.53 138.236 107.647L130 99.4119Z" fill="#ACAFBF"/>
<path d="M170 22.3531C167.353 25.8825 165.295 29.4119 163.824 33.5295C163.53 34.706 164.118 35.8825 165.295 36.1766C166.471 36.4707 167.647 35.8825 167.942 34.706C169.118 31.1766 170.883 27.6472 173.236 24.706C174.118 23.8236 173.824 22.3531 172.942 21.4707C172.059 21.4707 170.883 21.4707 170 22.3531Z" fill="#ACAFBF"/>
<path d="M188.824 30.8824C188.236 30 186.765 29.4118 185.883 30.2941L172.059 39.1177C171.177 39.7059 170.883 41.1765 171.471 42.0588C172.059 42.9412 173.53 43.5294 174.412 42.6471L188.236 33.8236C189.118 33.2353 189.412 31.7647 188.824 30.8824Z" fill="#ACAFBF"/>
<path d="M192.942 46.7649L177.354 47.9414C176.177 47.9414 175.295 49.1178 175.295 50.2943C175.295 51.4708 176.471 52.3531 177.648 52.3531L193.236 51.1767C194.413 51.1767 195.295 50.0002 195.295 48.8237C195.001 47.6472 194.118 46.7649 192.942 46.7649Z" fill="#ACAFBF"/>
</svg>

</template>

<script>
export default {
  name: "EmptyIcon",
  props: {
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
};
</script>
