<template>
    <detail-class-bootcamp-mobile v-if="isMobile" />
    <detail-class-bootcamp-dekstop v-else />
</template>

<script>
import DetailClassBootcampDekstop from '@/components/public-training/desktop/DetailClassBootcamp.vue';
import DetailClassBootcampMobile from '@/components/public-training/mobile/DetailClassBootcamp.vue';

export default {
    name: "DetailClassBootcamp",
    components: {
        DetailClassBootcampDekstop,
        DetailClassBootcampMobile
    },
    data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  mounted() {
    this.$gtag.pageview(this.$route)
    this.$utils.scrollTop()
  },
  beforeMount() {
      this.handleView()
      sessionStorage.setItem('nav-item-active', 0)
  },
}
</script>