<template>
    
<svg :width="width" :height="height" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#FFDE59"/>
<path d="M35.8268 41.1597C35.4134 41.1597 35.0001 41.053 34.6268 40.8263L29.2801 37.653C28.7201 37.6397 28.1601 37.5997 27.6268 37.5197C27.2668 37.4664 26.9601 37.2263 26.8268 36.8796C26.6934 36.533 26.7601 36.1596 27.0001 35.8796C27.8801 34.853 28.3334 33.6263 28.3334 32.3197C28.3334 29.093 25.4934 26.4663 22.0001 26.4663C20.6934 26.4663 19.4401 26.8264 18.3868 27.5197C18.0934 27.7064 17.7334 27.733 17.4134 27.5863C17.1068 27.4397 16.8801 27.1463 16.8401 26.7996C16.8001 26.4263 16.7734 26.053 16.7734 25.6663C16.7734 19.053 22.5068 13.6797 29.5468 13.6797C36.5868 13.6797 42.3201 19.053 42.3201 25.6663C42.3201 29.293 40.6401 32.6263 37.6801 34.9063L38.1334 38.533C38.2401 39.4397 37.8401 40.293 37.0801 40.7864C36.7068 41.0264 36.2668 41.1597 35.8268 41.1597ZM29.5334 35.6396C29.7201 35.6263 29.9068 35.6797 30.0668 35.7864L35.6534 39.1064C35.8001 39.1997 35.9201 39.1597 36.0001 39.1064C36.0668 39.0664 36.1734 38.9597 36.1468 38.773L35.6268 34.5597C35.5868 34.1863 35.7468 33.8264 36.0401 33.613C38.7601 31.7064 40.3201 28.7996 40.3201 25.6396C40.3201 20.133 35.4934 15.653 29.5468 15.653C23.8268 15.653 19.1334 19.813 18.7868 25.0397C19.7868 24.653 20.8668 24.4397 21.9868 24.4397C26.5868 24.4397 30.3201 27.9596 30.3201 32.293C30.3334 33.4663 30.0534 34.5996 29.5334 35.6396Z" fill="#36373F"/>
<path d="M18.1064 42.3331C17.7597 42.3331 17.4264 42.2398 17.1197 42.0398C16.5197 41.6531 16.1997 40.9865 16.2797 40.2798L16.5464 38.2264C14.7464 36.7597 13.6797 34.5864 13.6797 32.3064C13.6797 29.7064 15.0397 27.2797 17.3197 25.8264C18.693 24.9331 20.3197 24.4531 22.013 24.4531C26.613 24.4531 30.3464 27.9731 30.3464 32.3064C30.3464 34.0664 29.7064 35.7998 28.533 37.1731C27.0264 38.9998 24.773 40.0664 22.293 40.1464L19.0397 42.0798C18.7464 42.2531 18.4264 42.3331 18.1064 42.3331ZM21.9997 26.4531C20.693 26.4531 19.4397 26.8131 18.3864 27.5064C16.6797 28.5998 15.6664 30.3864 15.6664 32.3064C15.6664 34.1597 16.573 35.8531 18.173 36.9465C18.4797 37.1598 18.6397 37.5197 18.5997 37.8931L18.3064 40.1731L21.493 38.2798C21.653 38.1865 21.8264 38.1331 21.9997 38.1331C23.9597 38.1331 25.813 37.2931 26.9864 35.8665C27.8664 34.8265 28.333 33.5997 28.333 32.2931C28.333 29.0797 25.493 26.4531 21.9997 26.4531Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "54"
            },
            height: {
                type: String,
                default: "54" 
            },
        }
}
</script>