<template>
    
<svg :width="width" :height="height" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg" @click="$emit('click', '')" style="cursor: pointer;">
<path d="M26.648 13.7776C26.648 12.0297 25.2257 10.613 23.473 10.613C21.7203 10.613 20.3006 12.0297 20.3006 13.7776C20.3006 17.61 17.1693 20.7288 13.3254 20.7288C9.47873 20.7288 6.34736 17.61 6.34736 13.7776C6.34736 9.94512 9.47873 6.82637 13.3254 6.82637C15.0781 6.82637 16.4977 5.40974 16.4977 3.66184C16.4977 1.91663 15.0781 0.5 13.3254 0.5C5.97881 0.5 0 6.4547 0 13.7776C0 19.6192 3.80842 24.5908 9.08287 26.3603C8.51775 26.8639 7.77245 27.1736 6.95617 27.1736C5.20347 27.1736 3.78385 28.5903 3.78385 30.3355C3.78385 32.0834 5.20347 33.5 6.95617 33.5C11.2478 33.5 14.887 30.6587 16.0827 26.767C22.1107 25.4958 26.648 20.1551 26.648 13.7776Z" fill="#FFDE59"/>
<path d="M16.342 13.7749C16.342 13.9715 16.3229 14.1681 16.2847 14.362C16.2465 14.556 16.1892 14.7445 16.1127 14.9249C16.0363 15.1081 15.9435 15.2804 15.8343 15.4447C15.7251 15.609 15.5995 15.7625 15.4575 15.9026C15.3183 16.0399 15.1654 16.1665 15.0016 16.2742C14.8378 16.3847 14.6631 16.4789 14.4802 16.5543C14.2972 16.6297 14.1089 16.6863 13.9123 16.724C13.7185 16.7644 13.5219 16.7833 13.3253 16.7833C13.126 16.7833 12.9295 16.7644 12.7357 16.724C12.5418 16.6863 12.3534 16.6297 12.1705 16.5543C11.9876 16.4789 11.8129 16.3847 11.6491 16.2742C11.4826 16.1665 11.3297 16.0399 11.1904 15.9026C11.0512 15.7625 10.9256 15.609 10.8164 15.4447C10.7045 15.2804 10.6117 15.1081 10.538 14.9249C10.4615 14.7445 10.4042 14.556 10.366 14.362C10.3277 14.1681 10.3059 13.9715 10.3059 13.7749C10.3059 13.5783 10.3277 13.3817 10.366 13.1878C10.4042 12.9939 10.4615 12.8081 10.538 12.6249C10.6117 12.4418 10.7045 12.2694 10.8164 12.1051C10.9256 11.9408 11.0512 11.7873 11.1904 11.65C11.3297 11.5099 11.4826 11.3833 11.6491 11.2756C11.8129 11.1652 11.9876 11.0736 12.1705 10.9955C12.3534 10.9201 12.5418 10.8636 12.7357 10.8259C12.9295 10.7881 13.126 10.7666 13.3253 10.7666C13.5219 10.7666 13.7185 10.7881 13.9123 10.8259C14.1089 10.8636 14.2972 10.9201 14.4802 10.9955C14.6631 11.0736 14.8378 11.1652 15.0016 11.2756C15.1654 11.3833 15.3183 11.5099 15.4575 11.65C15.5995 11.7873 15.7251 11.9408 15.8343 12.1051C15.9435 12.2694 16.0363 12.4418 16.1127 12.6249C16.1892 12.8081 16.2465 12.9939 16.2847 13.1878C16.3229 13.3817 16.342 13.5783 16.342 13.7749Z" fill="#FF1616"/>
</svg>

</template>
<script>
export default {
    name: "date-icon",
    props: {
        width: {
            type: String,
            default: "34",
        },
        height: {
            type: String,
            default: "34",
        },
    },
};
</script>
