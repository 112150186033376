<template>
    
<svg :width="width" :height="height" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#FFDE59"/>
<path d="M34.6665 41H33.3332C32.7865 41 32.3332 40.5467 32.3332 40C32.3332 39.4533 32.7865 39 33.3332 39H34.6665C38.1065 39 40.3332 36.7733 40.3332 33.3333V22.6667C40.3332 19.2267 38.1065 17 34.6665 17H21.3332C17.8932 17 15.6665 19.2267 15.6665 22.6667V24C15.6665 24.5467 15.2132 25 14.6665 25C14.1198 25 13.6665 24.5467 13.6665 24V22.6667C13.6665 18.08 16.7465 15 21.3332 15H34.6665C39.2532 15 42.3332 18.08 42.3332 22.6667V33.3333C42.3332 37.92 39.2532 41 34.6665 41Z" fill="#36373F"/>
<path d="M28.3867 40.0796C27.8934 40.0796 27.4667 39.7063 27.4001 39.213C26.6667 33.5063 22.5067 29.333 16.7867 28.5996C16.2401 28.533 15.8534 28.0263 15.9201 27.4796C15.9867 26.9329 16.4934 26.5463 17.0401 26.6129C23.6801 27.4663 28.5334 32.3063 29.3734 38.9463C29.4401 39.4929 29.0534 39.9996 28.5067 40.0663C28.4801 40.0796 28.4267 40.0796 28.3867 40.0796Z" fill="#36373F"/>
<path d="M23.9066 41.5065C23.4133 41.5065 22.9867 41.1332 22.92 40.6398C22.4 36.5732 19.4267 33.5998 15.36 33.0798C14.8133 33.0132 14.4266 32.5065 14.4933 31.9598C14.56 31.4132 15.0667 31.0265 15.6133 31.0932C20.6133 31.7332 24.2533 35.3865 24.8933 40.3732C24.96 40.9198 24.5733 41.4265 24.0267 41.4931C23.9867 41.5065 23.9466 41.5065 23.9066 41.5065Z" fill="#36373F"/>
<path d="M18.8403 42.3601C18.347 42.3601 17.9203 41.9867 17.8536 41.4934C17.627 39.6934 16.307 38.3734 14.507 38.1467C13.9603 38.08 13.5736 37.5734 13.6403 37.0267C13.707 36.4801 14.2136 36.0934 14.7603 36.1601C17.4536 36.5067 19.4936 38.5467 19.8403 41.2401C19.907 41.7867 19.5203 42.2934 18.9736 42.3601C18.9336 42.3601 18.8936 42.3601 18.8403 42.3601Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "54"
            },
            height: {
                type: String,
                default: "54" 
            },
        }
}
</script>