<template>
    
<svg :width="width" :height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.0002" r="25" fill="#FFDE59"/>
<path d="M36.5626 33.0877L34.5001 33.5752C34.0376 33.6877 33.6751 34.0377 33.5751 34.5002L33.1376 36.3377C32.9001 37.3377 31.6251 37.6502 30.9626 36.8627L27.2251 32.5627C26.9251 32.2127 27.0876 31.6627 27.5376 31.5502C29.7501 31.0127 31.7376 29.7752 33.2001 28.0127C33.4376 27.7252 33.8626 27.6877 34.1251 27.9502L36.9001 30.7252C37.8501 31.6752 37.5126 32.8627 36.5626 33.0877Z" fill="#36373F"/>
<path d="M13.375 33.0877L15.4375 33.5752C15.9 33.6877 16.2625 34.0377 16.3625 34.5002L16.8 36.3377C17.0375 37.3377 18.3125 37.6502 18.975 36.8627L22.7125 32.5627C23.0125 32.2127 22.85 31.6627 22.4 31.5502C20.1875 31.0127 18.2 29.7752 16.7375 28.0127C16.5 27.7252 16.075 27.6877 15.8125 27.9502L13.0375 30.7252C12.0875 31.6752 12.425 32.8627 13.375 33.0877Z" fill="#36373F"/>
<path d="M25 12.5002C20.1625 12.5002 16.25 16.4127 16.25 21.2502C16.25 23.0627 16.7875 24.7252 17.7125 26.1127C19.0625 28.1127 21.2 29.5252 23.6875 29.8877C24.1125 29.9627 24.55 30.0002 25 30.0002C25.45 30.0002 25.8875 29.9627 26.3125 29.8877C28.8 29.5252 30.9375 28.1127 32.2875 26.1127C33.2125 24.7252 33.75 23.0627 33.75 21.2502C33.75 16.4127 29.8375 12.5002 25 12.5002ZM28.825 20.9752L27.7875 22.0127C27.6125 22.1877 27.5125 22.5252 27.575 22.7752L27.875 24.0627C28.1125 25.0752 27.575 25.4752 26.675 24.9377L25.425 24.2002C25.2 24.0627 24.825 24.0627 24.6 24.2002L23.35 24.9377C22.45 25.4627 21.9125 25.0752 22.15 24.0627L22.45 22.7752C22.5 22.5377 22.4125 22.1877 22.2375 22.0127L21.175 20.9752C20.5625 20.3627 20.7625 19.7502 21.6125 19.6127L22.95 19.3877C23.175 19.3502 23.4375 19.1502 23.5375 18.9502L24.275 17.4752C24.675 16.6752 25.325 16.6752 25.725 17.4752L26.4625 18.9502C26.5625 19.1502 26.825 19.3502 27.0625 19.3877L28.4 19.6127C29.2375 19.7502 29.4375 20.3627 28.825 20.9752Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "45"
            },
            height: {
                type: String,
                default: "45" 
            }
        }
}
</script>