import { render, staticRenderFns } from "./StepCollaboration.vue?vue&type=template&id=1ddcf678&scoped=true&"
import script from "./StepCollaboration.vue?vue&type=script&lang=js&"
export * from "./StepCollaboration.vue?vue&type=script&lang=js&"
import style0 from "./css/stepCollaboration.css?vue&type=style&index=0&id=1ddcf678&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ddcf678",
  null
  
)

export default component.exports