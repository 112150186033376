<template>
    <div>
        <v-row style="height:100vh;">
            <v-col cols="8" style="background:#F1F6FF;padding: 0px 100px;">
                <div class="d-flex mt-15" style="align-items: center;justify-content: center;"><img src="/img/logo.png"
                        style="width:170px;height:120px;cursor:pointer" @click="goToHomePage"></div>
                <div class="title">
                    <h3>Mulai <span class="blue-title font-poppins">Langkah Hebat</span> untuk Persiapkan Masa Depan!
                    </h3>
                </div>
                <div><img src="/img/other/line-blue.png" width="230px" style="margin-left:170px;margin-top:-20px"></div>
                <div class="sub-title"><span class="font-poppins">Siap jadi talenta profesional melalui program
                        upskilling / reskilling<br>dalam Transformasi Digital</span></div>
                <div class="d-flex" style="align-items: center;justify-content: center;"><img
                        src="/img/other/login-img.png" width="704px" height="470px"></div>
            </v-col>
            <v-col class="right-section">
                <div class="title-masuk">
                    <h3>Masuk</h3>
                </div>
                <v-form style="padding:40px 70px">
                    <v-alert type="error" class="mt-5" v-if="show_alert_error">
                        {{ error_message }}
                    </v-alert>

                    <v-text-field label="Email" @input="lowerWord" @keypress="blockSpace" v-model="email" filled
                        clearable placeholder="Email" class="poppins-font custom-text-field"
                        :rules="[rules.required, rules.emailFormat]" required>
                    </v-text-field>

                    <v-text-field :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_password ? 'text' : 'password'" label="Password" filled clearable
                        class="poppins-font custom-text-field" placeholder="Password" style="margin-top:30px"
                        :rules="[rules.required, rules.min]" @click:append="show_password = !show_password"
                        v-model="password" required>
                    </v-text-field>

                    <div class="forgot-password"><span class="font-poppins">Lupa Sandi?</span></div>
                    <v-btn color="#000080" width="100%" @click="login"
                        style="border-radius: 16px;margin-top: 40px;margin-bottom: 20px;padding: 22px;">
                        <span class="font-poppins white--text font-weight-bold">Masuk</span>
                    </v-btn>

                    <div class="notes"><span class="font-poppins">Dengan masuk ke dalam akun, kamu menyetujui <span
                                class="font-poppins" style="cursor:pointer;color:#162CA2;font-weight: 600;"
                                @click="goToTermCondition">Syarat & Ketentuan</span> dan <span class="font-poppins"
                                style="cursor:pointer;color:#162CA2;font-weight: 600;"
                                @click="goToPrivacyPolicy">Kebijakan Privasi</span> Hicolleagues.</span></div>
                </v-form>

                <div class="copyright"><span class="font-poppins">@2023 PT Generasi Anak Muda Berkarya</span></div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            password: '',
            show_password: false,
            error_message: '',
            show_alert_error: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters',
                emailFormat: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please enter a valid email address',
            },
        }
    },
    methods: {
        blockSpace(event) {
            const char = String.fromCharCode(event.keyCode);
            if (char === ' ') {
                event.preventDefault();
            }
        },
        lowerWord() {
            this.email = this.email.toLowerCase().replace(" ", "")
        },
        goToHomePage() {
            window.location.href = '/';
        },
        goToPrivacyPolicy() {
            window.location.href = '/privacy-policy';
        },
        goToTermCondition() {
            window.location.href = '/term-condition';
        },
        async login() {
            await this.$http
                .post("/v1/login", {
                    email: this.email,
                    password: this.password,
                })
                .then((response) => {
                    // this.setEvent('click-button-login', 'user', 'sign in', this.email)
                    // localStorage.setItem("user", response.data.data.user.token)
                    // setHeader(response.data.data.user.token)
                    // this.$forceUpdate()
                    this.show_alert_error = false
                    window.location.href =
                        // 'http://localhost:8081/home/'+response.data.data.user.token
                        'https://lms.hicolleagues.com/home/' + response.data.data.user.token
                })
                .catch(() => {
                    this.show_alert_error = true
                    this.error_message = 'Email/password tidak ditemukan'
                })
        },
    }
}
</script>

<style src="./login.css" scoped></style>