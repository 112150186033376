<template>
    
<svg :width="width" :height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.0001" r="25" fill="#FFDE59"/>
<path d="M32.2625 16.8626V17.7876L27.8375 15.2251C26.1625 14.2626 23.825 14.2626 22.1625 15.2251L17.7375 17.8001V16.8626C17.7375 14.0501 19.275 12.5001 22.0875 12.5001H27.9125C30.725 12.5001 32.2625 14.0501 32.2625 16.8626Z" fill="#36373F"/>
<path d="M32.3 19.9626L32.125 19.8751L30.425 18.9001L26.9 16.8626C25.825 16.2376 24.175 16.2376 23.1 16.8626L19.575 18.8876L17.875 19.8876L17.65 20.0001C15.4625 21.4751 15.3125 21.7501 15.3125 24.1126V29.7626C15.3125 32.1251 15.4625 32.4001 17.7 33.9126L23.1 37.0251C23.6375 37.3501 24.3125 37.4876 25 37.4876C25.675 37.4876 26.3625 37.3376 26.9 37.0251L32.35 33.8751C34.55 32.4001 34.6875 32.1376 34.6875 29.7626V24.1126C34.6875 21.7501 34.5375 21.4751 32.3 19.9626ZM28.4875 26.8751L27.725 27.8126C27.6 27.9501 27.5125 28.2126 27.525 28.4001L27.6 29.6001C27.65 30.3376 27.125 30.7126 26.4375 30.4501L25.325 30.0001C25.15 29.9376 24.8625 29.9376 24.6875 30.0001L23.575 30.4376C22.8875 30.7126 22.3625 30.3251 22.4125 29.5876L22.4875 28.3876C22.5 28.2001 22.4125 27.9376 22.2875 27.8001L21.5125 26.8751C21.0375 26.3126 21.25 25.6876 21.9625 25.5001L23.125 25.2001C23.3125 25.1501 23.525 24.9751 23.625 24.8251L24.275 23.8251C24.675 23.2001 25.3125 23.2001 25.725 23.8251L26.375 24.8251C26.475 24.9876 26.7 25.1501 26.875 25.2001L28.0375 25.5001C28.75 25.6876 28.9625 26.3126 28.4875 26.8751Z" fill="#36373F"/>
</svg>

</template>
<script>
export default {
    props: {
            width: {
                type: String,
                default: "45"
            },
            height: {
                type: String,
                default: "45" 
            }
        }
}
</script>