import { render, staticRenderFns } from "./DetailClass.vue?vue&type=template&id=17dc7c58&scoped=true&"
import script from "./DetailClass.vue?vue&type=script&lang=js&"
export * from "./DetailClass.vue?vue&type=script&lang=js&"
import style0 from "./detailClass.css?vue&type=style&index=0&id=17dc7c58&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17dc7c58",
  null
  
)

export default component.exports