<template>
<svg :width="width"
:height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="50" height="50" rx="25" fill="#FFDE59"/>
<path d="M29.19 15H20.81C17.17 15 15 17.17 15 20.81V29.18C15 32.83 17.17 35 20.81 35H29.18C32.82 35 34.99 32.83 34.99 29.19V20.81C35 17.17 32.83 15 29.19 15ZM30 30.25H20C19.59 30.25 19.25 29.91 19.25 29.5C19.25 29.09 19.59 28.75 20 28.75H30C30.41 28.75 30.75 29.09 30.75 29.5C30.75 29.91 30.41 30.25 30 30.25ZM30 25.75H20C19.59 25.75 19.25 25.41 19.25 25C19.25 24.59 19.59 24.25 20 24.25H30C30.41 24.25 30.75 24.59 30.75 25C30.75 25.41 30.41 25.75 30 25.75ZM30 21.25H20C19.59 21.25 19.25 20.91 19.25 20.5C19.25 20.09 19.59 19.75 20 19.75H30C30.41 19.75 30.75 20.09 30.75 20.5C30.75 20.91 30.41 21.25 30 21.25Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
  name: "BootcampIcon",
  props: {
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    }
  },
};
</script>