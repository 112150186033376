<template>
  <button
    :class="!disabled ? 'button' : 'button-disabled'"
    :style="`max-width: ${width}; height: ${height}; width: 100%; font-weight: ${bold ? 'bold': ''};`"
    @click.stop="onSubmit"
    v-if="!outline"
    :title="title"
  >
    <div v-if="!loading" class="font-poppins">
      <span v-if="icon" style="margin-right: 10px">{{ icon }}</span>
      {{ name }}
    </div>
    <v-progress-circular
      v-else
      indeterminate
      color="primary"
    ></v-progress-circular>
  </button>
  <button
    v-else
    :class="!disabled ? 'button-outline' : 'button-disabled-outline'"
    :style="`max-width: ${width}; height: ${height}; width: 100%; font-weight: ${bold ? 'bold': ''}`"
    @click.stop="onSubmit"
    :title="title"
  >
    <div v-if="!loading" class="font-poppins">
      <span v-if="icon" style="margin-right: 10px">{{ icon }}</span>
      {{ name }}
    </div>
    <v-progress-circular
      v-else
      indeterminate
      color="primary"
    ></v-progress-circular>
  </button>
</template>

<script>

export default {
  name: "ButtonComponent",
  props: {
    icon: {
      type: [String, HTMLElement],
    },
    name: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "150px",
    },
    height: {
      type: String,
      default: "45px",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    param: {
      type: [String, Number],
    },
    outline: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSubmit() {
      if (this.disabled) return;
      this.$emit("click", this.param);
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

span {
  font-family: 'Poppins' !important;
  font-weight: 600;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: 400 !important;
  cursor: pointer;
  font-size: 17px;
  color: white;
  background-color: #162ca2;
}
.button:hover {
  background-color: #f1f6ff;
  color: black;
}
.button:active {
  color: #162ca2;
}
.button-disabled {
  background-color: #acafbf !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 12px;
  font-weight: 400 !important;
  cursor: pointer;
  font-size: 17px;
  color: white;
}

.button-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: 400 !important;
  cursor: pointer;
  font-size: 17px;
  border: 1px solid #7b7e8c;
}
.button-outline:hover {
  border: 1px solid #162ca2;
}
.button-outline:active {
  color: #162ca2;
  border: 1px solid #162ca2;
}
.button-outline-disabled {
  border: 1px solid #d3d6e1;
  color: #acafbf;
}
</style>
