<template>
    
<svg  :width="width" :height="height" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_715_3538)">
<path d="M30 15C29.9989 12.1335 29.1765 9.32736 27.6302 6.91374C26.0838 4.50013 23.8783 2.58013 21.2746 1.38105C18.671 0.181976 15.7783 -0.245966 12.939 0.14789C10.0997 0.541746 7.4327 1.74091 5.25375 3.6034C3.0748 5.4659 1.47514 7.91374 0.644143 10.6571C-0.186849 13.4005 -0.214371 16.3246 0.564835 19.0831C1.34404 21.8417 2.89734 24.3192 5.04085 26.2224C7.18436 28.1256 9.82831 29.3747 12.6597 29.8219V19.333H8.84398V15H12.6597V11.693C12.6597 7.93669 14.8982 5.85924 18.3239 5.85924C19.4603 5.87385 20.594 5.97305 21.7157 6.15602V9.84454H19.7908C19.4684 9.80137 19.1404 9.83124 18.831 9.93192C18.5216 10.0326 18.2389 10.2015 18.0036 10.4262C17.7683 10.6509 17.5865 10.9256 17.4717 11.23C17.3569 11.5344 17.312 11.8607 17.3403 12.1848V15H21.5037L20.8423 19.333H17.3403V29.8219C20.8694 29.2628 24.0832 27.4628 26.4038 24.7459C28.7245 22.0289 29.9996 18.5731 30 15Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_715_3538">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "30"
            },
            height: {
                type: String,
                default: "30" 
            },
        }
}
</script>