<template>
    
<svg :width="width" :height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.0001 6.25H28.7501V5C28.7501 4.66848 28.6184 4.35054 28.384 4.11612C28.1495 3.8817 27.8316 3.75 27.5001 3.75C27.1686 3.75 26.8506 3.8817 26.6162 4.11612C26.3818 4.35054 26.2501 4.66848 26.2501 5V6.25H14.0834V5C14.0128 4.73341 13.856 4.49765 13.6375 4.32945C13.4189 4.16125 13.1509 4.07005 12.8751 4.07005C12.5993 4.07005 12.3313 4.16125 12.1127 4.32945C11.8942 4.49765 11.7374 4.73341 11.6667 5V6.25H10.0001C8.78451 6.25 7.61872 6.73289 6.75917 7.59243C5.89963 8.45197 5.41675 9.61776 5.41675 10.8333V29.1667C5.41675 30.3822 5.89963 31.548 6.75917 32.4076C7.61872 33.2671 8.78451 33.75 10.0001 33.75H30.0001C31.2157 33.75 32.3814 33.2671 33.241 32.4076C34.1005 31.548 34.5834 30.3822 34.5834 29.1667V10.8333C34.5834 9.61776 34.1005 8.45197 33.241 7.59243C32.3814 6.73289 31.2157 6.25 30.0001 6.25ZM32.0834 29.1667C32.0834 29.7192 31.8639 30.2491 31.4732 30.6398C31.0825 31.0305 30.5526 31.25 30.0001 31.25H10.0001C9.44755 31.25 8.91764 31.0305 8.52694 30.6398C8.13624 30.2491 7.91675 29.7192 7.91675 29.1667V16.25H32.0834V29.1667ZM7.91675 13.75V10.8333C7.91675 10.2808 8.13624 9.7509 8.52694 9.36019C8.91764 8.96949 9.44755 8.75 10.0001 8.75H30.0001C30.5526 8.75 31.0825 8.96949 31.4732 9.36019C31.8639 9.7509 32.0834 10.2808 32.0834 10.8333V13.75H7.91675Z" :fill="fill"/>
</svg>

</template>
<script>
export default {
    name: "date-icon-2",
    props: {
      width: {
        type: Number,
        default: 26,
      },
      height: {
        type: Number,
        default: 26,
      },
      fill: {
        type: String,
        default: "#7B7E8C",
      },
    },
  };
  </script>
  