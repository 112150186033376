<template>
<svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27.3334 4H4.66675C4.40153 4 4.14718 4.10536 3.95964 4.29289C3.7721 4.48043 3.66675 4.73478 3.66675 5C3.66675 5.26522 3.7721 5.51957 3.95964 5.70711C4.14718 5.89464 4.40153 6 4.66675 6H5.00008V19C5.00008 19.7957 5.31615 20.5587 5.87876 21.1213C6.44137 21.6839 7.20443 22 8.00008 22H15.0001V24.6667C14.6188 24.8868 14.3208 25.2266 14.1523 25.6334C13.9838 26.0401 13.9543 26.4911 14.0682 26.9164C14.1822 27.3416 14.4333 27.7174 14.7826 27.9854C15.1318 28.2534 15.5598 28.3987 16.0001 28.3987C16.4404 28.3987 16.8683 28.2534 17.2176 27.9854C17.5669 27.7174 17.818 27.3416 17.9319 26.9164C18.0459 26.4911 18.0163 26.0401 17.8478 25.6334C17.6794 25.2266 17.3814 24.8868 17.0001 24.6667V22H24.0001C24.7957 22 25.5588 21.6839 26.1214 21.1213C26.684 20.5587 27.0001 19.7957 27.0001 19V6H27.3334C27.5986 6 27.853 5.89464 28.0405 5.70711C28.2281 5.51957 28.3334 5.26522 28.3334 5C28.3334 4.73478 28.2281 4.48043 28.0405 4.29289C27.853 4.10536 27.5986 4 27.3334 4ZM25.0001 19C24.9966 19.2641 24.8902 19.5165 24.7034 19.7033C24.5166 19.8901 24.2642 19.9965 24.0001 20H8.00008C7.73594 19.9965 7.48359 19.8901 7.29679 19.7033C7.11 19.5165 7.00353 19.2641 7.00008 19V6H25.0001V19Z" :fill="fill"/>
</svg>

</template>
<script>
export default {
  name: "method-icon",
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 30,
    },
    fill: {
      type: String,
      default: "#7B7E8C",
    },
  },
};
</script>