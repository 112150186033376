<template>
    <div>
        <div class="banner">
      <img src="/img/bootcamp/main2.png" class="img-banner" />
      <div class="body-banner">
        <h2 class="title-banner">
            Temukan Peluang Besar untuk Wujudkan Karir Impian
        </h2>
        <span class="sub-title-banner"
          >Sambut peluang masa depan dan tingkatkan karir cemerlang bersama HiColleagues
        </span>
        <v-btn
          depressed
          color="#FFDE59"
          class="button-daftar"
          @click="scrollToClass"
        >
          <span class="text-capitalize font-weight-bold blue-text font-poppins"
            >INFO PEKERJAAN</span
          >
        </v-btn>
        <v-btn depressed color="white" class="button-daftar" @click="sendToWa">
          <span
            class="text-capitalize font-weight-bold blue-text font-poppins"
            >KONSULTASI GRATIS</span
          >
        </v-btn>
      </div>
        </div>
        <div class="container text-center ctn-bootcamp">
      <h3 class="sub-title-span">
        HiColleagues Pilihan Tepat dalam Wujudkan Karir Impian
      </h3>
      <div v-for="n in benefits" :key="n.id" class="content-card">
        <div class="left-span">
          <img :src="n.image" width="50" height="50" style="border-radius: 50%;" />
        </div>
        <div>
          <div class="title-2" style="text-align: left">{{ n.title }}</div>
          <div class="desc mt-2" style="text-align: left">{{ n.desc }}</div>
        </div>
      </div>
        </div>
        <div class="main-coll">
        <div class="collaboratif">
          <h1 class="pt-10">Langkah Kolaboratif Kemitraan</h1>
          <div class="desc">
            <div class="card-content" v-for="(data, index) in steps" :key="index">
              <div class="card-img">
                <img :src="data.image_url" width="300" alt="" style="margin-top: -20px" />
              </div>
              <div class="desc">
                <h3>{{ data.title }}</h3>
                <p>{{ data.description }}</p>
              </div>
            </div>
          </div>
          <div class="indicator-container">
            <div style="width: 10px; height: 10px"></div>
            <div class="indicator">
              <svg :style="{ transform: `translateX(${indicator * 15}px)` }" width="25" height="10" viewBox="0 0 25 10"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="25" height="10" rx="5" fill="#162CA2" />
              </svg>
              <svg :style="{ transform: `translateX(${-indicator2 * 30}px` }" width="10" height="10" viewBox="0 0 10 10"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
              </svg>
              <svg :style="{ transform: `translateX(${-indicator3 * 30}px` }" width="10" height="10" viewBox="0 0 10 10"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="5" fill="#7B7E8C" />
              </svg>
            </div>

            <div class="btn-arrow">
              <div class="btn-card">
                <img src="/img/other/corporate-arrow-left.png" alt="" @click="scrollLeftStep" />
              </div>
              <div class="btn-card">
                <img src="/img/other/corporate-arrow-right.png" alt="" @click="scrollRightStep" />
              </div>
            </div>
          </div>
        </div>
        <h1 class="pt-10 text-center" style="font-size: 18px;">Langkah Kolaboratif Kemitraan</h1>
        <div class="content">
            <v-row >
          <v-col cols="12">
            <div class="action-head">
              <div class="f-search">
                <div class="f-search-ic">
                  <img src="/img/icons/search.png" alt="Search Ic" />
                </div>
                <div style="width: 90%">
                  <v-text-field v-model="filter.position" type="text" class="f-input" placeholder="Cari Pekerjaan" dense
                    flat solo></v-text-field>
                </div>
              </div>
            </div>
          </v-col>
          <v-col cols='12'>
            <v-select outlined color="#ACAFBF" placeholder="Tipe Pekerjaan" v-model="filter.work_type"
              :items="workTypeList" :item-text="'name'" :item-value="'value'" style="border-radius: 10px !important; background-color: white" hide-details />
          </v-col>
          <v-col cols='12'>
            <v-select outlined color="#ACAFBF" placeholder="Level Pekerjaan" v-model="filter.level" :items="levelList"
              :item-text="'name'" :item-value="'value'" style="border-radius: 10px !important; background-color: white" hide-details />
          </v-col>
          <v-col cols='12'>
            <Button name="CARI" width="100%" height="54px" @click="() => getCareer()" />
          </v-col>
        </v-row>
        <div class="list-career">
          <div class="list-career-ctn" v-for="(data, idx) in listCareer" :key="idx" @click="$router.push(`/career/${data.id}`)">
            <img :src="data.logo ? data.logo: '/img/career/logo_sample.png'" />
            <h2 class="mb-6 mt-6">{{ data.name }}</h2>
            <p style="font-weight: 500">{{ data.company }}</p>
            <p style="font-weight: 400; ">{{ data.location }}</p>
            <div class="career-data">
              <div class="label">
                <div style="background-color: #f1f6ff" v-if="data.contract_type">
                  <clock-ic width="18px" height="18px" />
                  <p>{{ data.contract_type }}</p>
                </div>
                <div style="background-color: #faeceb" v-if="data.work_type">
                  <location-ic width="18px" height="18px"  />
                  <p>{{ data.work_type }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="data.level">
                  <level-ic width="18px" height="18px"  />
                  <p>{{ data.level }}</p>
                </div>
              </div>
            </div>
            <div class="btn-daftar">
              <div class="btn-daftar-act">
                <Button name="LAMAR SEKARANG" width="100%" @click="() => toCheckout(data)" />
              </div>
              <div class="btn-daftar-cpy">
                <div class="button-outline" @click.stop="() => onCopy(data.id)"> 
                  <CopyIc width="25px" height="25px" />
                </div>
              </div>
            </div>
            <p class="text-center">Batas Lamar <b style="font-family: 'Poppins';">{{ moment(data.closed_at) }}</b></p>
          </div>
        </div>
        <EmptyData
          v-if="!listCareer || !listCareer.length"
          message="Belum ada Karir saat ini"
        />
        </div>
        </div>
        <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
        <div class="container">
          <v-expansion-panels flat class="faq-card">
            <v-expansion-panel style="border-radius: 20px; margin-bottom: 10px;" v-for="(item, index) in questions"
              :key="index">
              <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)" style="border-radius: 20px;">
                <h4 style="line-height: 22px">{{ item.title }}</h4>
                <template v-slot:actions>
                  <div class="icon" v-if="item.active" style="margin-right: 20px;">
                    <Arrow />
                  </div>
                  <div v-else style="margin-right: 20px;">
                    <Arrow :down="!item.active" />
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="border-radius:0 0 20px 20px;">
                <div v-html="item.description"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="btn-stoke-blue cursor-pointer mt-5" @click="$router.push('/faq')" >
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import Button from '../../../others/Button.vue';
import CopyIc from '@/assets/icons/career/Copy.vue';
import EmptyData from "../../../others/EmptyData.vue";
import ClockIc from '@/assets/icons/career/Clock.vue';
import LevelIc from '@/assets/icons/career/Level.vue';
import LocationIc from '@/assets/icons/career/Location.vue';
import moment from 'moment';
import ArrowLong from '@/assets/icons/ArrowLong.vue';

export default {
    name: "MobileCareer",
    components: {
    Button,
    ArrowLong,
    ClockIc,
    LevelIc,
    LocationIc,
    CopyIc,
    EmptyData,
  },
    data() {
        return {
            benefits: [
       
        {
            image: "/img/about/c2.png",
          title: "Peluang Karir Terbaru",
          desc: "Informasi up-to-date secara berkala menyesuaikan kebutuhan industri terkini memberikan kesempatan karir bagi HiColers secara aktual",
        },
        {
          image: "/img/about/b6.png",
          title: "Kemudahan Akses",
          desc: "Platform HiColleagues memudahkan pelamar dalam mencari dan mengelola lamaran hingga proses rekrutmen selesai",
        },
        {
          image: "/img/about/b4.png",
          title: "Mitra Terpercaya",
          desc: "HiColleagues telah memiliki pengalaman rekrutmen yang kredibel, sehingga kami yakin dapat menghadirkan pengalaman terbaik bagi pelamar dalam mewujudkan impian karir",
        }
      ],
      steps: [
        {
          image_url: "/img/corporate/tech/bn1.png",
          title: "Temukan Lowongan Pekerjaan",
          description: "Jelajahi berbagai informasi lowongan pekerjaan dan sesuaikan dengan minat karir HiColers. Informasi yang aktual dan selaras dengan industri terkini akan ter-update secara berkala untuk kemudahan informasi bagi pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn2.png",
          title: "Lamar Pekerjaan",
          description: "Lakukan pendaftaran dan pastikan data yang HiColers kirim telah sesuai dengan benar. Data admistrasi akan digunakan sebagai acuan dalam proses seleksi kandidat pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn3.png",
          title: "Seleksi Administratif",
          description: "Data pelamar yang telah berhasil terkirim akan dilakukan kurasi oleh tim rekrutmen HiColleagues. Hal ini untuk memvalidasi data telah sesuai dengan kualifikasi atau kriteria pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn4.png",
          title: "Uji Keterampilan",
          description: "Merupakan tahap validasi kemampuan pelamar terhadap kebutuhan peran pekerjaan. Pelamar akan mengerjakan study case tertentu dan hasilnya akan menjadi pertimbangan sebagai standar kualifikasi keterampilan pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn5.png",
          title: "interview",
          description: "Apabila secara administratif dan Uji Keterampilan pelamar telah lolos, maka, akan dilakukan sesi interview baik oleh tim rekrutmen HiColleagues maupun Users. Hasil dari tahap ini akan digunakan sebagai Final Assessment pelamar.",
        },
        {
          image_url: "/img/corporate/tech/bn5.png",
          title: "Offering",
          description: "Setelah rangkaian seleksi dilakukan, apabila pelamar telah tervalidasi sesuai dengan kualifikasi yang dibutuhkan, maka tim rekrutmen HiColleagues akan memberikan penawaran terhadap peran atau okupasi yang dibutuhkan.",
        }
      ],
      workTypeList: [
        {
          name: "Full-Time",
          value: "Full-Time"
        },
        {
          name: "Part-Time",
          value: "Part-Time"
        },
        {
          name: "Freelance",
          value: "Freelance"
        },
        {
          name: "Internship",
          value: "Internship"
        },
      ],
      levelList: [
        {
          name: "Head",
          value: "Head"
        },
        {
          name: "Senior",
          value: "Senior"
        },
        {
          name: "Middle",
          value: "Middle"
        },
        {
          name: "Junior",
          value: "Junior"
        },
      ],
      filter: {
        position: "",
        work_type: "",
        level: "",
      },
      currentPage: 1,
      total: 1,
      questions: [],
      testimonies: [],
      listCareer: [],
        }
    },
    methods: {
        sendToWa() {
            window.open("https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya");
        },
        scrollRightStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft += cardWidth * 1.07;
      if (this.indicator < 2) {
        this.indicator++;
        if (this.indicator2 < 1) {
          this.indicator2++;
        }
      }
      if (this.indicator === 2 && this.indicator3 < 1) {
        this.indicator3++;
      }
    },
    scrollLeftStep() {
      const container = this.$el.querySelector(".desc");
      const cardWidth = this.$el.querySelector(".card-content").offsetWidth;
      container.scrollLeft -= cardWidth * 1.07;
      if (this.indicator === 1) {
        this.indicator2--;
      }
      if (this.indicator > 0) {
        this.indicator--;
      }
      if (this.indicator3 < 2 && this.indicator > 0) {
        this.indicator3--;
      }
    },
    moment(date) {
      return moment(date).format("DD MMMM YYYY")
    },
    toCheckout(data) {
      if (new Date() > new Date(data.closed_at)) return
      this.$router.push(`/career/applicant/${data.id}`)
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    onCopy(id) {
      const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;
            clipboardData.writeText(`${window.location.href}/${id}`);
            this.show_alert = true;
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=CAREER&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        this.isErr = true
        this.errMessage = err.response.data.data
      }
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get('/v1/testimony?category=CAREER&is_active=true&page=1&per_page=4');
        this.testimonies = resp.data.data.testimonies;
      } catch (err) {
        this.isErr = true
        this.errMessage = err.response.data.data
      }
    },
    redirectReload(e) {
      if (e.slice(0, 5) == "https") {
        window.open(e, '_blank')
      } else {
        this.$router
          .push({ path: e })
          .then(() => { this.$router.go() })
      }
    },
    async getCareer() {
      try {
        const res = await this.$http.get(`/v1/career?position=${this.filter.position}&level=${this.filter.level}&work_type=${this.filter.work_type}&page=${this.currentPage}&per_page=9`)
        this.listCareer = res.data.data.careers
        this.total = Math.floor(res.data.data.total / 9) + 1
      } catch (err) {
        this.isErr = true
        this.errMessage = err.response.data.data
      }
    }
    },
    mounted() {
    this.getFaq()
    this.getTestimoni()
    this.getCareer()
  }
}
</script>

<style src="./career.css" scoped></style>