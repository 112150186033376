<template>
    <DetailClassAccelerationDekstop v-if="!isMobile" />
    <DetailClassAccelerationMobile v-else />
  </template>
  
  <script>
  import DetailClassAccelerationDekstop from "@/components/corporate/acceleration-program/desktop/DetailClassAcceleration.vue";
  import DetailClassAccelerationMobile from "@/components/corporate/acceleration-program/mobile/DetailClassAcceleration.vue";
  export default {
    name: "DetailClassAcceleration",
    components: {
      DetailClassAccelerationDekstop,
      DetailClassAccelerationMobile
    },
    data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      handleView() {
        this.isMobile = screen.width <= 990;
      },
    },
    mounted() {
      this.$gtag.pageview(this.$route);
      this.$utils.scrollTop()
    },
    beforeMount() {
      this.handleView();
      sessionStorage.setItem("nav-item-active", 0);
    }
  };
  </script>