<template>
    
<svg :width="width" :height="height" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1484_7012)">
<path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#575966"/>
<path d="M19.6555 9.32792C19.4506 9.32792 19.2502 9.38881 19.0799 9.50286C18.9096 9.61691 18.7771 9.77898 18.699 9.9685C18.621 10.158 18.601 10.3665 18.6416 10.5674C18.6822 10.7683 18.7816 10.9526 18.9271 11.0969C19.0726 11.2412 19.2577 11.3391 19.459 11.378C19.6602 11.417 19.8685 11.3953 20.0573 11.3157C20.2462 11.2361 20.4072 11.1022 20.5198 10.9309C20.6325 10.7597 20.6917 10.5589 20.69 10.3539C20.69 10.2185 20.6632 10.0844 20.6111 9.95932C20.559 9.83428 20.4827 9.7208 20.3865 9.62541C20.2904 9.53003 20.1762 9.45463 20.0508 9.40357C19.9253 9.35251 19.791 9.3268 19.6555 9.32792Z" fill="#D3D6E1"/>
<path d="M15.0758 10.6502C14.2185 10.6485 13.3799 10.9012 12.6662 11.3763C11.9526 11.8514 11.3959 12.5275 11.0666 13.3191C10.7374 14.1107 10.6504 14.9821 10.8166 15.8232C10.9828 16.6643 11.3948 17.4371 12.0005 18.044C12.6061 18.6508 13.3781 19.0643 14.2189 19.2322C15.0596 19.4001 15.9313 19.3147 16.7235 18.987C17.5158 18.6593 18.193 18.104 18.6695 17.3912C19.1459 16.6785 19.4003 15.8404 19.4003 14.9831C19.4003 13.8354 18.9449 12.7346 18.1342 11.9222C17.3234 11.1099 16.2235 10.6524 15.0758 10.6502ZM15.0758 17.7559C14.525 17.7575 13.9861 17.5956 13.5275 17.2907C13.0688 16.9857 12.711 16.5514 12.4994 16.0429C12.2879 15.5343 12.2321 14.9744 12.3391 14.4341C12.4462 13.8938 12.7112 13.3975 13.1007 13.008C13.4902 12.6185 13.9865 12.3535 14.5268 12.2464C15.0671 12.1394 15.627 12.1952 16.1356 12.4067C16.6441 12.6183 17.0784 12.9761 17.3834 13.4348C17.6883 13.8934 17.8502 14.4323 17.8486 14.9831C17.8463 15.7178 17.5535 16.4218 17.034 16.9413C16.5145 17.4608 15.8105 17.7536 15.0758 17.7559Z" fill="#D3D6E1"/>
<path d="M18.5106 23.7424H11.4897C10.0893 23.7402 8.74696 23.1829 7.75676 22.1927C6.76656 21.2025 6.20927 19.8601 6.20703 18.4598V11.4728C6.20703 10.0709 6.76332 8.7264 7.75377 7.73437C8.74421 6.74233 10.0879 6.18389 11.4897 6.18164H18.5106C19.9124 6.18389 21.2561 6.74233 22.2465 7.73437C23.2369 8.7264 23.7932 10.0709 23.7932 11.4728V18.4937C23.7821 19.8881 23.2208 21.2218 22.2316 22.2047C21.2424 23.1876 19.9051 23.7402 18.5106 23.7424ZM11.4897 7.8436C10.5272 7.8436 9.60407 8.22595 8.92347 8.90656C8.24287 9.58716 7.86051 10.5103 7.86051 11.4728V18.4937C7.86051 19.4562 8.24287 20.3793 8.92347 21.0599C9.60407 21.7405 10.5272 22.1228 11.4897 22.1228H18.5106C19.4731 22.1228 20.3962 21.7405 21.0768 21.0599C21.7574 20.3793 22.1398 19.4562 22.1398 18.4937V11.4728C22.1398 10.5103 21.7574 9.58716 21.0768 8.90656C20.3962 8.22595 19.4731 7.8436 18.5106 7.8436H11.4897Z" fill="#D3D6E1"/>
</g>
<defs>
<clipPath id="clip0_1484_7012">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>

</template>


<script>
export default {
    props: {
            width: {
                type: String,
                default: "30"
            },
            height: {
                type: String,
                default: "30" 
            },
        }
}
</script>