<template>
    <div>
      <DesktopDetailClass v-if="!isMobile" />
      <MobileDetailClass v-if="isMobile" />
    </div>
  </template>
  
  <script>
  import DesktopDetailClass from '@/components/prakerja/desktop/DetailClass'
  import MobileDetailClass from '@/components/prakerja/mobile/DetailClass'
  export default {
    components: {
      MobileDetailClass,
      DesktopDetailClass,
    },
    data() {
      return{
        isMobile: false
      }
    },
    methods: {
        handleView() {
            this.isMobile = screen.width <= 990
        }
    },
    mounted() {
      this.$utils.scrollTop()
      this.$gtag.pageview(this.$route)
    },
    beforeMount() {
      this.handleView()
    }
  }
  </script>