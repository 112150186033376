<template>
    <div class="container-size mb-10">
        <v-container>
            <v-row>
                <v-col class="body-terms">
                    <h1>Syarat & Ketentuan</h1>
                    <div v-for="(section, index) in termConditionSections" :key="index">
                        <h2>{{ section.title }}</h2>
                        <div v-html="section.description"></div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            termConditionSections: [],
        };
    },
    methods: {
        async fetchTermCondition() {
            try {
                const response = await axios.get("v1/privacy-policy/syarat--ketentuan");
                const responseData = response.data.data;
                const content = responseData.privacy_policy.content;
                if (content) {
                    this.termConditionSections = content;
                } else {
                    throw new Error("Invalid term condition content");
                }
            } catch (error) {
                console.error("Error fetching term condition:", error);
            }
        },
    },
    mounted() {
        this.$gtag.pageview(this.$route);
        this.fetchTermCondition();
    },
};
</script>

<style>
.body-terms {
    margin-top: 5px;
}

@media screen and (max-width: 900px) {
    .body-privacy {
        margin-top: 10px;
    }
}
</style>