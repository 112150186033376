<template>
    
<svg :width="width" :height="height" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_715_3537)">
<path d="M20.1075 10.9125C20.6709 10.9125 21.1275 10.4558 21.1275 9.8925C21.1275 9.32917 20.6709 8.8725 20.1075 8.8725C19.5442 8.8725 19.0875 9.32917 19.0875 9.8925C19.0875 10.4558 19.5442 10.9125 20.1075 10.9125Z" fill="white"/>
<path d="M15 10.1475C14.0403 10.1475 13.1021 10.4321 12.3041 10.9653C11.5061 11.4985 10.8842 12.2564 10.5169 13.143C10.1496 14.0297 10.0535 15.0054 10.2408 15.9467C10.428 16.888 10.8902 17.7526 11.5688 18.4312C12.2474 19.1099 13.1121 19.572 14.0533 19.7593C14.9946 19.9465 15.9703 19.8504 16.857 19.4831C17.7437 19.1159 18.5015 18.4939 19.0347 17.6959C19.5679 16.8979 19.8525 15.9597 19.8525 15C19.8525 13.713 19.3413 12.4788 18.4313 11.5688C17.5212 10.6588 16.287 10.1475 15 10.1475ZM15 18.315C14.3444 18.315 13.7035 18.1206 13.1583 17.7563C12.6132 17.3921 12.1883 16.8743 11.9374 16.2686C11.6865 15.6629 11.6208 14.9963 11.7487 14.3533C11.8766 13.7102 12.1924 13.1196 12.656 12.6559C13.1196 12.1923 13.7103 11.8766 14.3533 11.7487C14.9963 11.6208 15.6629 11.6864 16.2686 11.9373C16.8744 12.1883 17.3921 12.6131 17.7563 13.1583C18.1206 13.7034 18.315 14.3444 18.315 15C18.315 15.8792 17.9658 16.7224 17.3441 17.3441C16.7224 17.9657 15.8792 18.315 15 18.315Z" fill="white"/>
<path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649926 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.472 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM24.0675 20.415C23.9509 21.3631 23.5169 22.2439 22.836 22.9139C22.1551 23.5839 21.2674 24.0037 20.3175 24.105C16.7682 24.495 13.1868 24.495 9.63751 24.105C8.68761 24.0037 7.79996 23.5839 7.11905 22.9139C6.43814 22.2439 6.00408 21.3631 5.88751 20.415C5.46752 16.8172 5.46752 13.1828 5.88751 9.585C6.00408 8.63686 6.43814 7.7561 7.11905 7.08608C7.79996 6.41607 8.68761 5.99626 9.63751 5.895C13.1868 5.50501 16.7682 5.50501 20.3175 5.895C21.2674 5.99626 22.1551 6.41607 22.836 7.08608C23.5169 7.7561 23.9509 8.63686 24.0675 9.585C24.4875 13.1828 24.4875 16.8172 24.0675 20.415Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_715_3537">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "30"
            },
            height: {
                type: String,
                default: "30" 
            },
        }
}
</script>