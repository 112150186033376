<template>
    
<svg :width="width" :height="height"  viewBox="0 0 150 35" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!isBlue">
<path d="M26.648 14.1799C26.648 12.3791 25.2257 10.9195 23.473 10.9195C21.7203 10.9195 20.3006 12.3791 20.3006 14.1799C20.3006 18.1285 17.1693 21.3418 13.3254 21.3418C9.47873 21.3418 6.34736 18.1285 6.34736 14.1799C6.34736 10.2313 9.47873 7.01808 13.3254 7.01808C15.0781 7.01808 16.4977 5.55852 16.4977 3.75765C16.4977 1.95956 15.0781 0.5 13.3254 0.5C5.97881 0.5 0 6.63515 0 14.1799C0 20.1985 3.80842 25.3209 9.08287 27.1439C8.51775 27.6628 7.77245 27.9819 6.95617 27.9819C5.20347 27.9819 3.78385 29.4415 3.78385 31.2396C3.78385 33.0404 5.20347 34.5 6.95617 34.5C11.2478 34.5 14.887 31.5726 16.0827 27.5629C22.1107 26.2532 26.648 20.7507 26.648 14.1799Z" fill="#FFDE59"/>
<path d="M16.342 14.1771C16.342 14.3797 16.3229 14.5822 16.2847 14.782C16.2465 14.9818 16.1892 15.1761 16.1127 15.362C16.0363 15.5507 15.9435 15.7283 15.8343 15.8975C15.7251 16.0668 15.5995 16.2249 15.4575 16.3692C15.3183 16.5108 15.1654 16.6412 15.0016 16.7522C14.8378 16.8659 14.6631 16.9631 14.4802 17.0407C14.2972 17.1184 14.1089 17.1767 13.9123 17.2156C13.7185 17.2572 13.5219 17.2766 13.3253 17.2766C13.126 17.2766 12.9295 17.2572 12.7357 17.2156C12.5418 17.1767 12.3534 17.1184 12.1705 17.0407C11.9876 16.9631 11.8129 16.8659 11.6491 16.7522C11.4826 16.6412 11.3297 16.5108 11.1904 16.3692C11.0512 16.2249 10.9256 16.0668 10.8164 15.8975C10.7045 15.7283 10.6117 15.5507 10.538 15.362C10.4615 15.1761 10.4042 14.9818 10.366 14.782C10.3277 14.5822 10.3059 14.3797 10.3059 14.1771C10.3059 13.9746 10.3277 13.772 10.366 13.5722C10.4042 13.3724 10.4615 13.181 10.538 12.9923C10.6117 12.8036 10.7045 12.626 10.8164 12.4567C10.9256 12.2875 11.0512 12.1293 11.1904 11.9878C11.3297 11.8435 11.4826 11.7131 11.6491 11.6021C11.8129 11.4883 11.9876 11.394 12.1705 11.3135C12.3534 11.2358 12.5418 11.1775 12.7357 11.1387C12.9295 11.0998 13.126 11.0776 13.3253 11.0776C13.5219 11.0776 13.7185 11.0998 13.9123 11.1387C14.1089 11.1775 14.2972 11.2358 14.4802 11.3135C14.6631 11.394 14.8378 11.4883 15.0016 11.6021C15.1654 11.7131 15.3183 11.8435 15.4575 11.9878C15.5995 12.1293 15.7251 12.2875 15.8343 12.4567C15.9435 12.626 16.0363 12.8036 16.1127 12.9923C16.1892 13.181 16.2465 13.3724 16.2847 13.5722C16.3229 13.772 16.342 13.9746 16.342 14.1771Z" fill="#FF1616"/>
<path d="M39.9316 9.06262V14.69H34.3241V9.06262H31.2937V23.0727H34.3241V17.4204H39.9316V23.0727H42.9401V9.06262H39.9316Z" fill="white"/>
<path d="M46.8199 12.1679C47.6935 12.1679 48.4279 11.4215 48.4279 10.5335C48.4279 9.6234 47.6935 8.89917 46.8199 8.89917C45.9463 8.89917 45.2119 9.6234 45.2119 10.5335C45.2119 11.4215 45.9463 12.1679 46.8199 12.1679ZM45.3484 23.073H48.2668V13.7329H45.3484V23.073Z" fill="white"/>
<path d="M57.2486 23.2588C59.9842 23.2588 62.3511 21.6937 63.5223 19.4073L60.9014 18.0282C60.2353 19.3601 58.8812 20.2481 57.2486 20.2481C54.9281 20.2481 53.159 18.425 53.159 16.0664C53.159 13.7078 54.9281 11.8875 57.2486 11.8875C58.8812 11.8875 60.2353 12.7754 60.9014 14.1046L63.5223 12.7283C62.3511 10.439 59.9842 8.87402 57.2486 8.87402C53.3447 8.87402 50.1724 12.0984 50.1724 16.0664C50.1724 20.0372 53.3447 23.2588 57.2486 23.2588Z" fill="white"/>
<path d="M69.5855 23.2589C72.6623 23.2589 74.6852 21.2055 74.6852 18.4029C74.6852 15.6003 72.6623 13.5442 69.5855 13.5442C66.506 13.5442 64.4612 15.6003 64.4612 18.4029C64.4612 21.2055 66.506 23.2589 69.5855 23.2589ZM69.5855 20.62C68.3215 20.5978 67.4014 19.6627 67.4014 18.4029C67.4014 17.1404 68.3215 16.208 69.5855 16.1831C70.8249 16.208 71.7668 17.1404 71.7668 18.4029C71.7668 19.6627 70.8249 20.5978 69.5855 20.62Z" fill="white"/>
<path d="M76.4055 23.0728H79.3458V7.07593H76.4055V23.0728Z" fill="white"/>
<path d="M81.6895 23.0728H84.6297V7.07593H81.6895V23.0728Z" fill="white"/>
<path d="M96.3219 18.3778C96.3219 15.4615 94.3453 13.5219 91.3805 13.5219C88.4184 13.5219 86.3271 15.4837 86.3271 18.4028C86.3271 21.3219 88.3938 23.2587 91.4733 23.2587C94.0232 23.2587 95.724 21.8824 96.1826 19.8512H93.357C93.1031 20.5977 92.437 21.0416 91.4269 21.0416C90.0946 21.0416 89.4066 20.2231 89.2893 18.8939H96.3219V18.3778ZM91.4269 15.8111C92.4152 15.8111 92.9885 16.2773 93.2888 17.0958H89.4749C89.7725 16.2995 90.3922 15.8111 91.4269 15.8111Z" fill="white"/>
<path d="M108.243 20.645C107.853 20.645 107.438 20.5035 107.438 19.7348V13.7329H104.522V14.5487C103.831 13.9188 102.958 13.5442 101.901 13.5442C99.3514 13.5442 97.353 15.6947 97.353 18.4029C97.353 21.1112 99.3514 23.2589 101.901 23.2589C103.234 23.2589 104.315 22.654 105.049 21.6717C105.622 22.629 106.635 23.2589 107.875 23.2589C108.175 23.2589 108.451 23.1673 108.655 23.073V20.645H108.243ZM102.477 20.62C101.26 20.62 100.293 19.6405 100.293 18.4029C100.293 17.1653 101.26 16.1831 102.477 16.1831C103.67 16.1831 104.659 17.1653 104.659 18.4029C104.659 19.6405 103.67 20.62 102.477 20.62Z" fill="white"/>
<path d="M119.704 13.7329H116.764V14.6652C116.005 13.966 115.019 13.5442 113.892 13.5442C111.342 13.5442 109.343 15.6697 109.343 18.4029C109.343 21.1112 111.342 23.2589 113.892 23.2589C115.041 23.2589 116.051 22.8149 116.81 22.0934V23.331C116.81 25.057 115.868 26.1086 114.375 26.1086C113.318 26.1086 112.513 25.5259 112.169 24.5908L109.48 25.6896C110.239 27.6043 111.961 28.7003 114.375 28.7003C117.73 28.7003 119.728 26.622 119.728 23.1673L119.704 13.7329ZM114.626 20.62C113.408 20.62 112.445 19.6405 112.445 18.4029C112.445 17.1653 113.408 16.1831 114.626 16.1831C115.844 16.1831 116.832 17.1653 116.832 18.4029C116.832 19.6405 115.844 20.62 114.626 20.62Z" fill="white"/>
<path d="M126.204 23.2589C128.983 23.2589 130.523 21.7883 130.523 19.499V13.7329H127.604V19.1022C127.604 19.9902 127.007 20.62 126.204 20.62C125.423 20.62 124.825 19.9902 124.825 19.1022V13.7329H121.885V19.499C121.885 21.7883 123.425 23.2589 126.204 23.2589Z" fill="white"/>
<path d="M142.032 18.3778C142.032 15.4615 140.055 13.5219 137.09 13.5219C134.128 13.5219 132.037 15.4837 132.037 18.4028C132.037 21.3219 134.104 23.2587 137.183 23.2587C139.733 23.2587 141.434 21.8824 141.893 19.8512H139.067C138.813 20.5977 138.147 21.0416 137.137 21.0416C135.805 21.0416 135.117 20.2231 134.999 18.8939H142.032V18.3778ZM137.137 15.8111C138.125 15.8111 138.698 16.2773 138.999 17.0958H135.185C135.482 16.2995 136.102 15.8111 137.137 15.8111Z" fill="white"/>
<path d="M146.669 23.2589C148.738 23.2589 150 21.9741 150 20.2232C150 18.3557 148.484 17.6093 147.174 17.096C146.508 16.8157 146.186 16.6742 146.186 16.3246C146.186 16.0221 146.418 15.7863 146.923 15.7863C147.518 15.7863 148.07 16.1137 148.345 16.4633L149.817 14.7818C149.129 14.0825 148.001 13.5442 146.715 13.5442C144.624 13.5442 143.292 14.9455 143.292 16.5355C143.292 18.4029 145.129 19.0328 146.186 19.4296C147.013 19.757 147.289 19.9901 147.289 20.3176C147.289 20.6922 146.969 20.9003 146.486 20.9003C146.003 20.9003 145.359 20.7144 144.946 19.9208L142.787 21.0168C143.338 22.4653 145.039 23.2589 146.669 23.2589Z" fill="white"/>
</svg>

<svg  :width="width" :height="height" viewBox="0 0 116 26" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
<path d="M20.9301 10.5588C20.9301 9.2346 19.8397 8.16139 18.4959 8.16139C17.1522 8.16139 16.0638 9.2346 16.0638 10.5588C16.0638 13.4621 13.6631 15.8248 10.7161 15.8248C7.76703 15.8248 5.36631 13.4621 5.36631 10.5588C5.36631 7.65539 7.76703 5.2927 10.7161 5.2927C12.0598 5.2927 13.1482 4.2195 13.1482 2.89533C13.1482 1.57321 12.0598 0.5 10.7161 0.5C5.08375 0.5 0.5 5.01114 0.5 10.5588C0.5 14.9842 3.41979 18.7506 7.46354 20.0911C7.03028 20.4727 6.45888 20.7073 5.83306 20.7073C4.48933 20.7073 3.40095 21.7805 3.40095 23.1026C3.40095 24.4268 4.48933 25.5 5.83306 25.5C9.12332 25.5 11.9133 23.3475 12.8301 20.3992C17.4515 19.4362 20.9301 15.3902 20.9301 10.5588Z" fill="url(#paint0_linear_1259_12579)"/>
<path d="M13.0288 10.5567C13.0288 10.7057 13.0142 10.8546 12.9849 11.0015C12.9556 11.1484 12.9116 11.2913 12.853 11.428C12.7944 11.5667 12.7232 11.6973 12.6395 11.8217C12.5558 11.9462 12.4595 12.0625 12.3507 12.1686C12.2439 12.2727 12.1267 12.3685 12.0011 12.4502C11.8756 12.5338 11.7416 12.6052 11.6014 12.6624C11.4611 12.7195 11.3167 12.7623 11.166 12.7909C11.0174 12.8215 10.8667 12.8358 10.716 12.8358C10.5632 12.8358 10.4125 12.8215 10.2639 12.7909C10.1153 12.7623 9.9709 12.7195 9.83067 12.6624C9.69043 12.6052 9.55648 12.5338 9.4309 12.4502C9.30322 12.3685 9.18601 12.2727 9.07927 12.1686C8.97252 12.0625 8.87624 11.9462 8.79252 11.8217C8.70671 11.6973 8.63554 11.5667 8.57903 11.428C8.52043 11.2913 8.47647 11.1484 8.44717 11.0015C8.41787 10.8546 8.40112 10.7057 8.40112 10.5567C8.40112 10.4078 8.41787 10.2589 8.44717 10.112C8.47647 9.96505 8.52043 9.82427 8.57903 9.68553C8.63554 9.54679 8.70671 9.41621 8.79252 9.29175C8.87624 9.16729 8.97252 9.05099 9.07927 8.94693C9.18601 8.84084 9.30322 8.74494 9.4309 8.66333C9.55648 8.57968 9.69043 8.51031 9.83067 8.45114C9.9709 8.39401 10.1153 8.35116 10.2639 8.3226C10.4125 8.29403 10.5632 8.27771 10.716 8.27771C10.8667 8.27771 11.0174 8.29403 11.166 8.3226C11.3167 8.35116 11.4611 8.39401 11.6014 8.45114C11.7416 8.51031 11.8756 8.57968 12.0011 8.66333C12.1267 8.74494 12.2439 8.84084 12.3507 8.94693C12.4595 9.05099 12.5558 9.16729 12.6395 9.29175C12.7232 9.41621 12.7944 9.54679 12.853 9.68553C12.9116 9.82427 12.9556 9.96505 12.9849 10.112C13.0142 10.2589 13.0288 10.4078 13.0288 10.5567Z" fill="#B80F0A"/>
<path d="M31.1146 6.79602V10.9338H26.8155V6.79602H24.4922V17.0976H26.8155V12.9415H31.1146V17.0976H33.4211V6.79602H31.1146Z" fill="#162CA2"/>
<path d="M36.3956 9.07928C37.0654 9.07928 37.6284 8.53043 37.6284 7.87753C37.6284 7.2083 37.0654 6.67578 36.3956 6.67578C35.7259 6.67578 35.1628 7.2083 35.1628 7.87753C35.1628 8.53043 35.7259 9.07928 36.3956 9.07928ZM35.2675 17.0977H37.505V10.23H35.2675V17.0977Z" fill="#162CA2"/>
<path d="M44.391 17.2344C46.4882 17.2344 48.3029 16.0836 49.2008 14.4024L47.1915 13.3884C46.6808 14.3677 45.6426 15.0206 44.391 15.0206C42.6119 15.0206 41.2556 13.6801 41.2556 11.9459C41.2556 10.2116 42.6119 8.87313 44.391 8.87313C45.6426 8.87313 46.6808 9.52604 47.1915 10.5033L49.2008 9.49135C48.3029 7.80809 46.4882 6.65735 44.391 6.65735C41.3979 6.65735 38.9658 9.0282 38.9658 11.9459C38.9658 14.8655 41.3979 17.2344 44.391 17.2344Z" fill="#162CA2"/>
<path d="M53.8495 17.2345C56.2084 17.2345 57.7593 15.7246 57.7593 13.6639C57.7593 11.6032 56.2084 10.0913 53.8495 10.0913C51.4886 10.0913 49.9209 11.6032 49.9209 13.6639C49.9209 15.7246 51.4886 17.2345 53.8495 17.2345ZM53.8495 15.2941C52.8805 15.2778 52.1751 14.5902 52.1751 13.6639C52.1751 12.7356 52.8805 12.05 53.8495 12.0317C54.7998 12.05 55.5219 12.7356 55.5219 13.6639C55.5219 14.5902 54.7998 15.2778 53.8495 15.2941Z" fill="#162CA2"/>
<path d="M59.0779 17.0976H61.3321V5.33521H59.0779V17.0976Z" fill="#162CA2"/>
<path d="M63.1289 17.0976H65.3831V5.33521H63.1289V17.0976Z" fill="#162CA2"/>
<path d="M74.3472 13.6454C74.3472 11.501 72.8318 10.0748 70.5588 10.0748C68.2878 10.0748 66.6846 11.5173 66.6846 13.6637C66.6846 15.8102 68.269 17.2343 70.6299 17.2343C72.5848 17.2343 73.8888 16.2223 74.2404 14.7288H72.0741C71.8795 15.2776 71.3688 15.6041 70.5944 15.6041C69.573 15.6041 69.0455 15.0022 68.9555 14.0249H74.3472V13.6454ZM70.5944 11.7581C71.352 11.7581 71.7916 12.1009 72.0218 12.7028H69.0978C69.326 12.1172 69.8011 11.7581 70.5944 11.7581Z" fill="#162CA2"/>
<path d="M83.4868 15.3125C83.1875 15.3125 82.8694 15.2084 82.8694 14.6433V10.23H80.634V10.8299C80.1045 10.3668 79.4347 10.0913 78.6247 10.0913C76.6698 10.0913 75.1377 11.6726 75.1377 13.6639C75.1377 15.6553 76.6698 17.2345 78.6247 17.2345C79.6461 17.2345 80.4749 16.7897 81.038 16.0674C81.4775 16.7713 82.254 17.2345 83.2043 17.2345C83.4345 17.2345 83.6459 17.1671 83.8029 17.0978V15.3125H83.4868ZM79.0663 15.2941C78.1328 15.2941 77.3919 14.5739 77.3919 13.6639C77.3919 12.7539 78.1328 12.0317 79.0663 12.0317C79.981 12.0317 80.7387 12.7539 80.7387 13.6639C80.7387 14.5739 79.981 15.2941 79.0663 15.2941Z" fill="#162CA2"/>
<path d="M92.2732 10.23H90.019V10.9156C89.4371 10.4014 88.6815 10.0913 87.8171 10.0913C85.8622 10.0913 84.3301 11.6542 84.3301 13.6639C84.3301 15.6553 85.8622 17.2345 87.8171 17.2345C88.6982 17.2345 89.4727 16.908 90.0545 16.3775V17.2875C90.0545 18.5566 89.3324 19.3299 88.1875 19.3299C87.3775 19.3299 86.7601 18.9014 86.4964 18.2138L84.4347 19.0218C85.0166 20.4296 86.3373 21.2355 88.1875 21.2355C90.7599 21.2355 92.292 19.7073 92.292 17.1671L92.2732 10.23ZM88.3801 15.2941C87.4466 15.2941 86.7078 14.5739 86.7078 13.6639C86.7078 12.7539 87.4466 12.0316 88.3801 12.0316C89.3136 12.0316 90.0713 12.7539 90.0713 13.6639C90.0713 14.5739 89.3136 15.2941 88.3801 15.2941Z" fill="#162CA2"/>
<path d="M97.2567 17.2345C99.3875 17.2345 100.568 16.1531 100.568 14.4699V10.2301H98.3305V14.1781C98.3305 14.831 97.8721 15.2942 97.2567 15.2942C96.6581 15.2942 96.1998 14.831 96.1998 14.1781V10.2301H93.9456V14.4699C93.9456 16.1531 95.126 17.2345 97.2567 17.2345Z" fill="#162CA2"/>
<path d="M109.391 13.6454C109.391 11.501 107.876 10.0748 105.603 10.0748C103.332 10.0748 101.729 11.5173 101.729 13.6637C101.729 15.8102 103.313 17.2343 105.674 17.2343C107.629 17.2343 108.933 16.2223 109.284 14.7288H107.118C106.923 15.2776 106.413 15.6041 105.638 15.6041C104.617 15.6041 104.089 15.0022 103.999 14.0249H109.391V13.6454ZM105.638 11.7581C106.396 11.7581 106.836 12.1009 107.066 12.7028H104.142C104.37 12.1172 104.845 11.7581 105.638 11.7581Z" fill="#162CA2"/>
<path d="M112.947 17.2345C114.534 17.2345 115.501 16.2898 115.501 15.0024C115.501 13.6292 114.339 13.0804 113.334 12.7029C112.824 12.4968 112.577 12.3928 112.577 12.1357C112.577 11.9133 112.754 11.7399 113.142 11.7399C113.598 11.7399 114.021 11.9806 114.232 12.2377L115.36 11.0013C114.833 10.4871 113.968 10.0913 112.983 10.0913C111.379 10.0913 110.358 11.1217 110.358 12.2908C110.358 13.6639 111.767 14.1271 112.577 14.4188C113.211 14.6596 113.422 14.831 113.422 15.0717C113.422 15.3472 113.177 15.5002 112.807 15.5002C112.436 15.5002 111.942 15.3635 111.626 14.78L109.971 15.5859C110.393 16.6509 111.697 17.2345 112.947 17.2345Z" fill="#162CA2"/>
<defs>
<linearGradient id="paint0_linear_1259_12579" x1="10.7151" y1="0.5" x2="10.7151" y2="25.5" gradientUnits="userSpaceOnUse">
<stop offset="0.365693" stop-color="#FFE57E"/>
<stop offset="1" stop-color="#EACC56"/>
</linearGradient>
</defs>
</svg>

</template>

<script>
export default {
    name: "date-icon",
    props: {
        width: {
            type: String,
            default: "180",
        },
        height: {
            type: String,
            default: "40",
        },
        isBlue: {
            type: Boolean,
            default: false
        }
    },

};
</script>
