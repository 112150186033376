<template>
<svg :width="width" :height="height" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48 5.33337H16C11.5733 5.33337 8 8.88004 8 13.2534V42.3467C8 46.72 11.5733 50.2667 16 50.2667H18.0267C20.16 50.2667 22.1867 51.0934 23.68 52.5867L28.24 57.0934C30.32 59.1467 33.7067 59.1467 35.7867 57.0934L40.3467 52.5867C41.84 51.0934 43.8933 50.2667 46 50.2667H48C52.4267 50.2667 56 46.72 56 42.3467V13.2534C56 8.88004 52.4267 5.33337 48 5.33337ZM32 15.3334C35.44 15.3334 38.2133 18.1067 38.2133 21.5467C38.2133 24.9867 35.44 27.76 32 27.76C28.56 27.76 25.7867 24.96 25.7867 21.5467C25.7867 18.1067 28.56 15.3334 32 15.3334ZM39.1467 40.16H24.8533C22.6933 40.16 21.44 37.76 22.64 35.9734C24.4533 33.28 27.9733 31.4667 32 31.4667C36.0267 31.4667 39.5467 33.28 41.36 35.9734C42.56 37.76 41.28 40.16 39.1467 40.16Z" fill="#162CA2"/>
</svg>
</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "64"
            },
            height: {
                type: String,
                default: "64" 
            }
        }
}
</script>