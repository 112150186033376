<template>
    
<svg :width="width" :height="height" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.7867 16.3933C31.7867 7.89183 24.8948 1 16.3933 1C7.89183 1 1 7.89183 1 16.3933C1 24.8948 7.89183 31.7867 16.3933 31.7867C24.8948 31.7867 31.7867 24.8948 31.7867 16.3933Z" stroke="#ACAFBF" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M16.2998 9.5C18.4998 9.5 18.7998 9.5 19.6998 9.5C20.4998 9.5 20.8998 9.7 21.1998 9.8C21.5998 10 21.8998 10.1 22.1998 10.4C22.4998 10.7 22.6998 11 22.7998 11.4C22.8998 11.7 22.9998 12.1 23.0998 12.9C23.0998 13.8 23.0998 14 23.0998 16.3C23.0998 18.6 23.0998 18.8 23.0998 19.7C23.0998 20.5 22.8998 20.9 22.7998 21.2C22.5998 21.6 22.4998 21.9 22.1998 22.2C21.8998 22.5 21.5998 22.7 21.1998 22.8C20.8998 22.9 20.4998 23 19.6998 23.1C18.7998 23.1 18.5998 23.1 16.2998 23.1C13.9998 23.1 13.7998 23.1 12.8998 23.1C12.0998 23.1 11.6998 22.9 11.3998 22.8C10.9998 22.6 10.6998 22.5 10.3998 22.2C10.0998 21.9 9.8998 21.6 9.7998 21.2C9.6998 20.9 9.5998 20.5 9.4998 19.7C9.4998 18.8 9.4998 18.6 9.4998 16.3C9.4998 14 9.4998 13.8 9.4998 12.9C9.4998 12.1 9.6998 11.7 9.7998 11.4C9.9998 11 10.0998 10.7 10.3998 10.4C10.6998 10.1 10.9998 9.9 11.3998 9.8C11.6998 9.7 12.0998 9.6 12.8998 9.5C13.7998 9.5 14.0998 9.5 16.2998 9.5ZM16.2998 8C13.9998 8 13.7998 8 12.8998 8C11.9998 8 11.3998 8.2 10.8998 8.4C10.3998 8.6 9.8998 8.9 9.3998 9.4C8.8998 9.9 8.6998 10.3 8.3998 10.9C8.1998 11.4 8.0998 12 7.9998 12.9C7.9998 13.8 7.9998 14.1 7.9998 16.3C7.9998 18.6 7.9998 18.8 7.9998 19.7C7.9998 20.6 8.1998 21.2 8.3998 21.7C8.5998 22.2 8.8998 22.7 9.3998 23.2C9.8998 23.7 10.2998 23.9 10.8998 24.2C11.3998 24.4 11.9998 24.5 12.8998 24.6C13.7998 24.6 14.0998 24.6 16.2998 24.6C18.4998 24.6 18.7998 24.6 19.6998 24.6C20.5998 24.6 21.1998 24.4 21.6998 24.2C22.1998 24 22.6998 23.7 23.1998 23.2C23.6998 22.7 23.8998 22.3 24.1998 21.7C24.3998 21.2 24.4998 20.6 24.5998 19.7C24.5998 18.8 24.5998 18.5 24.5998 16.3C24.5998 14.1 24.5998 13.8 24.5998 12.9C24.5998 12 24.3998 11.4 24.1998 10.9C23.9998 10.4 23.6998 9.9 23.1998 9.4C22.6998 8.9 22.2998 8.7 21.6998 8.4C21.1998 8.2 20.5998 8.1 19.6998 8C18.7998 8 18.5998 8 16.2998 8Z" fill="#ACAFBF"/>
<path d="M16.2998 12C13.8998 12 11.9998 13.9 11.9998 16.3C11.9998 18.7 13.8998 20.6 16.2998 20.6C18.6998 20.6 20.5998 18.7 20.5998 16.3C20.5998 13.9 18.6998 12 16.2998 12ZM16.2998 19.1C14.7998 19.1 13.4998 17.9 13.4998 16.3C13.4998 14.8 14.6998 13.5 16.2998 13.5C17.7998 13.5 19.0998 14.7 19.0998 16.3C19.0998 17.8 17.7998 19.1 16.2998 19.1Z" fill="#ACAFBF"/>
<path d="M20.6998 12.9C21.2521 12.9 21.6998 12.4523 21.6998 11.9C21.6998 11.3477 21.2521 10.9 20.6998 10.9C20.1475 10.9 19.6998 11.3477 19.6998 11.9C19.6998 12.4523 20.1475 12.9 20.6998 12.9Z" fill="#ACAFBF"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "32"
            },
            height: {
                type: String,
                default: "32" 
            },
        }
}
</script>