<template>
    
<svg :width="width" :height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25" r="25" fill="#FFDE59"/>
<path d="M30 12.5H20C15.625 12.5 13.75 15 13.75 18.75V31.25C13.75 35 15.625 37.5 20 37.5H30C34.375 37.5 36.25 35 36.25 31.25V18.75C36.25 15 34.375 12.5 30 12.5ZM20 25.3125H25C25.5125 25.3125 25.9375 25.7375 25.9375 26.25C25.9375 26.7625 25.5125 27.1875 25 27.1875H20C19.4875 27.1875 19.0625 26.7625 19.0625 26.25C19.0625 25.7375 19.4875 25.3125 20 25.3125ZM30 32.1875H20C19.4875 32.1875 19.0625 31.7625 19.0625 31.25C19.0625 30.7375 19.4875 30.3125 20 30.3125H30C30.5125 30.3125 30.9375 30.7375 30.9375 31.25C30.9375 31.7625 30.5125 32.1875 30 32.1875ZM33.125 21.5625H30.625C28.725 21.5625 27.1875 20.025 27.1875 18.125V15.625C27.1875 15.1125 27.6125 14.6875 28.125 14.6875C28.6375 14.6875 29.0625 15.1125 29.0625 15.625V18.125C29.0625 18.9875 29.7625 19.6875 30.625 19.6875H33.125C33.6375 19.6875 34.0625 20.1125 34.0625 20.625C34.0625 21.1375 33.6375 21.5625 33.125 21.5625Z" fill="#36373F"/>
</svg>

</template>
<script>
export default {
    props: {
            width: {
                type: String,
                default: "45"
            },
            height: {
                type: String,
                default: "45" 
            }
        }
}
</script>