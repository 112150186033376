<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4" cy="4" r="4" :fill="strokeColor" />
  </svg>
</template>

<script>
export default {
  name: "bullet",
  props: {
    width: {
      type: Number,
      default: 8,
    },
    height: {
      type: Number,
      default: 8,
    },
    strokeColor: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
