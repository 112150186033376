<template>
<svg :width="width"
:height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="50" height="50" rx="25" fill="#FFDE59"/>
<path d="M25.6777 32.9571C25.9524 33.021 25.9776 33.3808 25.71 33.47L24.13 33.99C20.16 35.27 18.07 34.2 16.78 30.23L15.5 26.28C14.22 22.31 15.28 20.21 19.25 18.93L19.774 18.7565C20.1768 18.6231 20.5689 19.0272 20.4545 19.4358C20.3978 19.6384 20.3433 19.8498 20.29 20.07L19.31 24.26C18.21 28.97 19.82 31.57 24.53 32.69L25.6777 32.9571Z" fill="#36373F"/>
<path d="M30.1702 16.2095L28.5002 15.8195C25.1602 15.0295 23.1702 15.6795 22.0002 18.0995C21.7002 18.7095 21.4602 19.4495 21.2602 20.2995L20.2802 24.4895C19.3002 28.6695 20.5902 30.7295 24.7602 31.7195L26.4402 32.1195C27.0202 32.2595 27.5602 32.3495 28.0602 32.3895C31.1802 32.6895 32.8402 31.2295 33.6802 27.6195L34.6602 23.4395C35.6402 19.2595 34.3602 17.1895 30.1702 16.2095ZM28.2902 26.3295C28.2002 26.6695 27.9002 26.8895 27.5602 26.8895C27.5002 26.8895 27.4402 26.8795 27.3702 26.8695L24.4602 26.1295C24.0602 26.0295 23.8202 25.6195 23.9202 25.2195C24.0202 24.8195 24.4302 24.5795 24.8302 24.6795L27.7402 25.4195C28.1502 25.5195 28.3902 25.9295 28.2902 26.3295ZM31.2202 22.9495C31.1302 23.2895 30.8302 23.5095 30.4902 23.5095C30.4302 23.5095 30.3702 23.4995 30.3002 23.4895L25.4502 22.2595C25.0502 22.1595 24.8102 21.7495 24.9102 21.3495C25.0102 20.9495 25.4202 20.7095 25.8202 20.8095L30.6702 22.0395C31.0802 22.1295 31.3202 22.5395 31.2202 22.9495Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
  name: "BootcampIcon",
  props: {
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    }
  },
};
</script>