<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4021_3025)">
      <path
        d="M17.732 4.19727L29.244 24.1359C29.4195 24.44 29.5119 24.7848 29.5119 25.1359C29.5119 25.487 29.4195 25.8319 29.244 26.1359C29.0685 26.4399 28.816 26.6924 28.512 26.868C28.2079 27.0435 27.8631 27.1359 27.512 27.1359H4.48799C4.13692 27.1359 3.79204 27.0435 3.488 26.868C3.18397 26.6924 2.9315 26.4399 2.75597 26.1359C2.58044 25.8319 2.48803 25.487 2.48804 25.1359C2.48804 24.7848 2.58045 24.44 2.75599 24.1359L14.268 4.19727C15.0373 2.86393 16.9613 2.86393 17.732 4.19727ZM16 19.9999C15.6464 19.9999 15.3072 20.1404 15.0572 20.3905C14.8071 20.6405 14.6667 20.9796 14.6667 21.3333C14.6667 21.6869 14.8071 22.026 15.0572 22.2761C15.3072 22.5261 15.6464 22.6666 16 22.6666C16.3536 22.6666 16.6927 22.5261 16.9428 22.2761C17.1928 22.026 17.3333 21.6869 17.3333 21.3333C17.3333 20.9796 17.1928 20.6405 16.9428 20.3905C16.6927 20.1404 16.3536 19.9999 16 19.9999ZM16 10.6666C15.6734 10.6666 15.3582 10.7865 15.1142 11.0036C14.8701 11.2206 14.7142 11.5196 14.676 11.8439L14.6667 11.9999V17.3333C14.667 17.6731 14.7972 18 15.0305 18.2471C15.2637 18.4942 15.5826 18.6429 15.9218 18.6628C16.2611 18.6827 16.5952 18.5724 16.8558 18.3542C17.1164 18.1361 17.2838 17.8267 17.324 17.4893L17.3333 17.3333V11.9999C17.3333 11.6463 17.1928 11.3072 16.9428 11.0571C16.6927 10.8071 16.3536 10.6666 16 10.6666Z"
        fill="#B80F0A"
      />
    </g>
    <defs>
      <clipPath id="clip0_4021_3025">
        <rect :width="width" :height="height" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "warning-icon",
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
  },
};
</script>
