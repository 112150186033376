<template>
    <div>
        <v-list>
            <div v-for="(data, idx) in navigation" :key="idx">

            <v-list-item  @click="redirectReload(data.link)" v-if="data.link">
                <v-list-item-title style="font-family: Poppins; font-weight: bold;">
                    {{data.name}}
                </v-list-item-title>
            </v-list-item>

            <v-list-group no-action v-else>
                <template v-slot:activator>
                <v-list-item-title style="font-family: Poppins; font-weight: bold;">{{ data.name }}</v-list-item-title>
                </template>

                <v-list-item v-for="(data2, idx2) in data.child" :key="idx2" @click="redirectReload(data2.link)" sub-group>
                <v-list-item-title >
                    {{data2.name}}
                </v-list-item-title>
            </v-list-item>
            </v-list-group>
        </div>
        </v-list>
       <div>
            <v-btn depressed color="#FFDE59" @click="redirectReload('/login')" style="width:100%;" class="btn-radius">
                <span class="text-capitalize font-weight-bold" style="color:#162CA2">MASUK</span>
            </v-btn>
       </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            navigation: [
                {
                    name: "Program",
                    link: '',
                    child: [
                        {
                            name: "Bootcamp",
                            link: '/class/bootcamp',
                        },
                        {
                            name: "Public Training",
                            link: '/class/public-training',
                        },
                        {
                            name: "Corporate Service",
                            link: '/corporate',
                        },
                        {
                            name: "Kartu Prakerja",
                            link: '/prakerja',
                        },
                        {
                            name: "Event",
                            link: '/event',
                        },
                    ]
                },
                {
                    name: "Literasi",
                    link: '',
                    child: [
                        {
                            name: "Artikel",
                            link: '/blog',
                        },
                        {
                            name: "FAQ",
                            link: '/faq',
                        },
                        {
                            name: "Cerita Alumni",
                            link: '/alumni-stories',
                        },
                    ]
                },
                {
                    name: "Karir",
                    link: '/career',
                    child: []
                },
                {
                    name: "Tentang Kami",
                    link: '/about',
                    child: []
                },
            ],
            menus:[
                {id: 1, name: 'Public Training', link: '/class/public-training'},
                {id: 2, name: 'Prakerja', link: '/prakerja'},
                {id: 3, name: 'Bootcamp', link: '/class/bootcamp'},
                {id: 4, name: 'Kampus Merdeka', link: '/kampusmerdeka'},
            ],
            subCorporateGroup: [
                {id: 1, name: 'Corporate Training', link: '/corporate'},
                {id: 2, name: 'IT Project', link: '/services'},
            ],
            subOtherGroup: [
                {id: 1, name: 'Tentang Kami', link: '/about'},
                {id: 2, name: 'Hubungi Kami', link: '/contact-us'},
                {id: 3, name: 'Blog', link: '/blog'},
                {id: 4, name: 'Event', link: '/event'},
            ]
        }
    },
    methods: {
        redirectReload(e) {
            this.$router
                .push({ path: e })
                .then(() => { this.$router.go() })
        },
    }
}
</script>