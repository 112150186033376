<template>
    
<svg :width="width" :height="height" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_10_53413)">
<path d="M20.1998 22.5507C20.1665 22.5507 20.1165 22.5507 20.0831 22.5507C20.0331 22.5507 19.9665 22.5507 19.9165 22.5507C16.1331 22.434 13.2998 19.484 13.2998 15.8507C13.2998 12.1507 16.3165 9.13403 20.0165 9.13403C23.7165 9.13403 26.7331 12.1507 26.7331 15.8507C26.7165 19.5007 23.8665 22.434 20.2498 22.5507C20.2165 22.5507 20.2165 22.5507 20.1998 22.5507ZM19.9998 11.6174C17.6665 11.6174 15.7831 13.5174 15.7831 15.834C15.7831 18.1174 17.5665 19.9674 19.8331 20.0507C19.8831 20.034 20.0498 20.034 20.2165 20.0507C22.4498 19.934 24.1998 18.1007 24.2165 15.834C24.2165 13.5174 22.3331 11.6174 19.9998 11.6174Z" fill="#7B7E8C"/>
<path d="M20.0001 37.9172C15.5167 37.9172 11.2334 36.2505 7.91672 33.2172C7.61672 32.9505 7.48339 32.5505 7.51672 32.1672C7.73339 30.1839 8.96672 28.3339 11.0167 26.9672C15.9834 23.6672 24.0334 23.6672 28.9834 26.9672C31.0334 28.3505 32.2667 30.1839 32.4834 32.1672C32.5334 32.5672 32.3834 32.9505 32.0834 33.2172C28.7667 36.2505 24.4834 37.9172 20.0001 37.9172ZM10.1334 31.8339C12.9001 34.1505 16.3834 35.4172 20.0001 35.4172C23.6167 35.4172 27.1001 34.1505 29.8667 31.8339C29.5667 30.8172 28.7667 29.8339 27.5834 29.0339C23.4834 26.3005 16.5334 26.3005 12.4001 29.0339C11.2167 29.8339 10.4334 30.8172 10.1334 31.8339Z" fill="#7B7E8C"/>
<path d="M19.9997 37.9166C10.1163 37.9166 2.08301 29.8833 2.08301 19.9999C2.08301 10.1166 10.1163 2.08325 19.9997 2.08325C29.883 2.08325 37.9163 10.1166 37.9163 19.9999C37.9163 29.8833 29.883 37.9166 19.9997 37.9166ZM19.9997 4.58325C11.4997 4.58325 4.58301 11.4999 4.58301 19.9999C4.58301 28.4999 11.4997 35.4166 19.9997 35.4166C28.4997 35.4166 35.4163 28.4999 35.4163 19.9999C35.4163 11.4999 28.4997 4.58325 19.9997 4.58325Z" fill="#7B7E8C"/>
</g>
<defs>
<clipPath id="clip0_10_53413">
<rect width="40" height="40" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "40"
            },
            height: {
                type: String,
                default: "40" 
            }
        }
}
</script>