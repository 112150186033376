<template>
    
<svg :width="width" :height="height" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_715_3541)">
<path d="M13.6875 12.465C13.6453 12.4397 13.5971 12.4261 13.5478 12.4256C13.4986 12.4251 13.4501 12.4377 13.4073 12.4622C13.3646 12.4866 13.3291 12.522 13.3045 12.5646C13.28 12.6073 13.2672 12.6558 13.2675 12.705V17.295C13.2672 17.3442 13.28 17.3927 13.3045 17.4354C13.3291 17.478 13.3646 17.5134 13.4073 17.5378C13.4501 17.5623 13.4986 17.5749 13.5478 17.5744C13.5971 17.5739 13.6453 17.5603 13.6875 17.535L17.52 15.24C17.5621 15.2156 17.5969 15.1806 17.6212 15.1385C17.6455 15.0964 17.6582 15.0486 17.6582 15C17.6582 14.9514 17.6455 14.9036 17.6212 14.8615C17.5969 14.8194 17.5621 14.7844 17.52 14.76L13.6875 12.465Z" fill="white"/>
<path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649926 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.3934 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.472 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0ZM24.045 19.335C23.9505 19.8911 23.6737 20.4 23.2582 20.7815C22.8427 21.163 22.3121 21.3956 21.75 21.4425L19.665 21.6075C16.5646 21.8475 13.4504 21.8475 10.35 21.6075L8.25001 21.4425C7.68655 21.3972 7.15426 21.1653 6.73728 20.7837C6.3203 20.402 6.0424 19.8923 5.94751 19.335C5.46694 16.465 5.46694 13.535 5.94751 10.665C6.0424 10.1077 6.3203 9.59799 6.73728 9.21633C7.15426 8.83466 7.68655 8.60284 8.25001 8.5575L10.335 8.3925C13.4354 8.15249 16.5496 8.15249 19.65 8.3925L21.735 8.5575C22.2985 8.60284 22.8308 8.83466 23.2477 9.21633C23.6647 9.59799 23.9426 10.1077 24.0375 10.665C24.5205 13.5346 24.5231 16.4646 24.045 19.335Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_715_3541">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "30"
            },
            height: {
                type: String,
                default: "30" 
            },
        }
}
</script>