<template>
  <div :style="`color: ${fill}`">
<svg :width="width"
:height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.575 13.3125H4.425C2.685 13.3125 1.6875 12.315 1.6875 10.575V1.5C1.6875 1.1925 1.9425 0.9375 2.25 0.9375H15.75C16.0575 0.9375 16.3125 1.1925 16.3125 1.5V10.575C16.3125 12.315 15.315 13.3125 13.575 13.3125ZM2.8125 2.0625V10.575C2.8125 11.6925 3.3075 12.1875 4.425 12.1875H13.5675C14.685 12.1875 15.18 11.6925 15.18 10.575V2.0625H2.8125Z" fill="#7B7E8C"/>
<path d="M16.5 2.0625H1.5C1.1925 2.0625 0.9375 1.8075 0.9375 1.5C0.9375 1.1925 1.1925 0.9375 1.5 0.9375H16.5C16.8075 0.9375 17.0625 1.1925 17.0625 1.5C17.0625 1.8075 16.8075 2.0625 16.5 2.0625Z" fill="#7B7E8C"/>
<path d="M6.0002 17.0625C5.7902 17.0625 5.59519 16.95 5.49769 16.755C5.35519 16.4775 5.46771 16.14 5.75271 15.9975L8.4377 14.655V12.75C8.4377 12.4425 8.6927 12.1875 9.0002 12.1875C9.3077 12.1875 9.5627 12.4425 9.5627 12.75V15C9.5627 15.21 9.44269 15.405 9.24769 15.5025L6.24769 17.0025C6.17269 17.04 6.0827 17.0625 6.0002 17.0625Z" fill="#7B7E8C"/>
<path d="M12 17.0626C11.9175 17.0626 11.8275 17.0401 11.7525 17.0026L8.75247 15.5026C8.47497 15.3601 8.36245 15.0226 8.49745 14.7451C8.63995 14.4676 8.97746 14.3551 9.25496 14.4901L12.255 15.9901C12.5325 16.1326 12.645 16.4701 12.51 16.7476C12.405 16.9501 12.2025 17.0626 12 17.0626Z" fill="#7B7E8C"/>
<path d="M5.6247 8.81202C5.4672 8.81202 5.30221 8.74453 5.18971 8.60953C4.98721 8.36953 5.02469 8.01703 5.26469 7.81453L7.62721 5.84203C7.84471 5.66203 8.1222 5.58703 8.3847 5.63203C8.6547 5.67703 8.88721 5.84201 9.02971 6.08201L9.8172 7.39451L12.0147 5.56453C12.2547 5.36953 12.6072 5.39951 12.8097 5.63951C13.0122 5.87951 12.9747 6.23201 12.7347 6.43451L10.3722 8.40701C10.1547 8.58701 9.87721 8.66201 9.61471 8.61701C9.34471 8.57201 9.11219 8.40703 8.96969 8.16703L8.1822 6.85453L5.98471 8.68451C5.87971 8.76701 5.7522 8.81202 5.6247 8.81202Z" fill="#7B7E8C"/>
</svg>

  </div>
</template>
<script>
export default {
  name: "methode-icon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
