<template>
  <div>
    <v-row class="mt-5" style="margin-bottom: 90px" v-if="!isMobile">
      <v-col
        v-for="n in benefits"
        :key="n.id"
        style="justify-content: center; align-items: center; display: flex"
      >
        <div class="content-card">
          <div class="left-span">
            <img :src="n.image" width="120" height="120" />
          </div>

          <div class="title-2">{{ n.title }}</div>
          <div class="desc d-flex">
            <span>{{ n.desc }}</span>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="scrolling-wrapper mb-10" v-if="isMobile">
      <div v-for="n in benefits" :key="n.id" class="card">
        <v-card class="content-card mr-2 ml-2">
          <div class="left-span">
            <img :src="n.image" width="120" height="120" />
          </div>

          <div class="title-2">{{ n.title }}</div>
          <div class="desc d-flex">
            <span>{{ n.desc }}</span>
          </div>
        </v-card>
      </div>
    </div>

    <!-- <span>Path Career</span>
        <h5 class="title">Potensial Karir ke Depan setelah Menguasai Skill ini</h5>
        <div class="scrolling-wrapper">
            <div class="card d-flex" style="margin-top:20px;font-size:14px;">
                <div v-for="n in careers" :key="n.id" class="btn-stroke-grey">{{n.name}}</div>
            </div>
        </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      careers: [
        { name: "Fullstack Developer", id: 1 },
        { name: "Frontend Developer", id: 2 },
        { name: "Backend Developer", id: 3 },
        { name: "Software Enginer", id: 4 },
      ],
      benefits: [
        {
          title: "Kurikulum Ter-update",
          desc: "Materi sesuai dengan perkembangan Industri Digital saat ini.",
          image: "/img/class/ic_kurikulum_terupdated.png",
          id: 1,
        },
        {
          title: "Instruktur Berpengalaman",
          desc: "Instruktur berkompetensi dan berpengalaman sesuai bidangnya.",
          image: "/img/class/ic_instruktur_berpengalaman.png",
          id: 2,
        },
        {
          title: "Base on Project",
          desc: "80% pelatihan berbasis pada praktek dan study case",
          image: "/img/class/ic_base_on_project.png",
          id: 3,
        },
        {
          title: "Portfolio Project",
          desc: "Perancangan Portfolio berdasar Project yang dikembangkan.",
          image: "/img/class/ic_portofolio.png",
          id: 4,
        },
        {
          title: "Sertifikat",
          desc: "Bukti peserta telah menyelesaikan pelatihan.",
          image: "/img/class/ic_sertifikat.png",
          id: 5,
        },
      ],
      isMobile: false,
    };
  },
  methods: {
    handleView() {
      this.isMobile = window.innerWidth <= 990;
    },
  },
  mounted() {
    this.handleView();
  },
};
</script>

<style src="./css/benefitPublicTraining.css" scoped></style>
