<template>
  <div color="white" class="custom-header">
    <v-container>
      <div class="f-container">
        <div class="f-head-left">
          <span class="head-title font-poppins">Tentang HiColleagues</span>
          <img
            src="/img/sosmed/ic_instagram_active.png"
            class="center ic-footer-ctn"
            @click="goToInstagram"
            width="24px"
          />
          <img
            src="/img/sosmed/ic_facebook_active.png"
            class="center ic-footer-ctn"
            @click="goToFacebook"
            width="24px"
          />
          <img
            src="/img/sosmed/ic_tiktok_active.png"
            class="center ic-footer-ctn"
            @click="goToTikTok"
            width="24px"
          />
          <img
            src="/img/sosmed/ic_linkedin_active.png"
            class="center ic-footer-ctn"
            @click="goToLinkedin"
            width="24px"
          />
        </div>
        <img
          src="/img/blog_logo.png"
          style="width: 170px; cursor: pointer"
          class="center"
          @click="$router.push('/')"
        />
        <div class="f-search">
          <div class="f-search-ic">
            <img
              src="/img/other/search.png"
              style="width: 100%"
              alt="Search Ic"
            />
          </div>
          <input type="text" class="f-input" placeholder="Cari Artikel" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HeaderBlog",
  methods: {
    goToHomePage() {
      window.location.href = "/";
    },
    goToLinkedin() {
      window.open("https://www.linkedin.com/company/hicolleagues/", "_blank");
    },
    goToInstagram() {
      window.open("https://www.instagram.com/hicolleagues/", "_blank");
    },
    goToFacebook() {
      window.open("https://www.facebook.com/hicolleagues", "_blank");
    },
    goToTikTok() {
      window.open("https://www.tiktok.com/@hicolleagues", "_blank");
    },
  },
};
</script>

<style src="./css/headerBlog.css" scoped></style>
