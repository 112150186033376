<template>
    
<svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1350_46223)">
<path d="M31.3933 16C31.3933 7.49852 24.5015 0.606689 16 0.606689C7.4985 0.606689 0.606674 7.49852 0.606674 16C0.606674 24.5015 7.4985 31.3933 16 31.3933C24.5015 31.3933 31.3933 24.5015 31.3933 16Z" stroke="#ACAFBF" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M13.6542 25.3023H17.3994V15.9191H20.0202L20.2953 12.7806H17.3994V10.9929C17.3994 10.2487 17.5531 9.95752 18.2649 9.95752H20.2953V6.72192H17.6987C14.9161 6.72192 13.6542 7.95145 13.6542 10.2973V12.8048H11.7048V15.9838H13.6542V25.3023Z" fill="#ACAFBF"/>
</g>
<defs>
<clipPath id="clip0_1350_46223">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "32"
            },
            height: {
                type: String,
                default: "32" 
            },
        }
}
</script>