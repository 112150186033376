import { render, staticRenderFns } from "./HeaderBlog.vue?vue&type=template&id=0d4a5257&scoped=true&"
import script from "./HeaderBlog.vue?vue&type=script&lang=js&"
export * from "./HeaderBlog.vue?vue&type=script&lang=js&"
import style0 from "./css/headerBlog.css?vue&type=style&index=0&id=0d4a5257&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d4a5257",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
