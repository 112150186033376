<template>
  <div class="partner">
    <div class="partner-title text-center">
      <h1>{{ title }}</h1>
    </div>
    <div class="partner-content">
      <div class="partner-container">
        <div
          class="card col-12"
          v-for="(partner, index) in partners"
          :key="index"
        >
          <div>
            <div class="portfolio-card">
              <h2>{{ partner.name }}</h2>
              <p class="text-justify">{{ partner.description }}</p>
            </div>
          </div>
          <div class="img-card">
            <img :src="partner.image_url" style="max-width: 100%" />
          </div>
        </div>
      </div>
    </div>
    <div class="partner-button">
      <div class="slider">
        <div
          class="indicator"
          :style="{
            width: `${sliderIndicatorTesti}%`,
            transform: `translateX(${indicatorValueTesti}%)`,
            transition: `transform 0.5s`,
          }"
        ></div>
      </div>
      <div style="margin-right: 60px">
        <img class="arrow-button" src="/img/other/corporate-arrow-left.png" alt="" @click="scrollLeftPartner" />
        <img class="arrow-button" src="/img/other/corporate-arrow-right.png" alt="" @click="scrollRightPartner" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnerComponent",
  props: {
    partners: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sliderIndicatorTesti: 0,
      indicatorValueTesti: 0,
    };
  },
  watch: {
    partners() {
      this.sliderIndicatorTesti = (1 / this.partners.length) * 100;
      if (this.sliderIndicatorTesti >= 100) {
        this.sliderIndicatorTesti = 100;
      }
    },
  },
  methods: {
    scrollRightPartner() {
      const container = this.$el.querySelector(".partner-container");
      const cardWidth = this.$el.querySelector(".partner-content").offsetWidth;
      container.scrollLeft += cardWidth;
      this.indicatorValueTesti +=
        this.sliderIndicatorTesti +
        ((this.partners.length - 1) * 100) / this.partners.length;
      if (this.indicatorValueTesti >= (this.partners.length - 1) * 100) {
        this.indicatorValueTesti = (this.partners.length - 1) * 100;
      }
    },
    scrollLeftPartner() {
      const container = this.$el.querySelector(".partner-container");
      const cardWidth = this.$el.querySelector(".partner-content").offsetWidth;
      container.scrollLeft -= cardWidth;
      this.indicatorValueTesti -=
        this.sliderIndicatorTesti +
        ((this.partners.length - 1) * 100) / this.partners.length;
      if (this.indicatorValueTesti <= 0) {
        this.indicatorValueTesti = 0;
      }
    },
  },
};
</script>

<style src="./css/partner.css" scoped></style>