<template>
    
<svg :width="width" :height="height" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#FFDE59"/>
<path d="M38.6667 17.8267C38.8436 17.8267 39.0131 17.8969 39.1382 18.0219C39.2632 18.1469 39.3334 18.3165 39.3334 18.4933V33.8267C39.3334 34.0035 39.2632 34.173 39.1382 34.2981C39.0131 34.4231 38.8436 34.4933 38.6667 34.4933H25.9601C25.2372 34.4886 24.5238 34.6578 23.8801 34.9867L17.6267 38.1067C17.5369 38.1552 17.4354 38.1783 17.3334 38.1733C17.1566 38.1733 16.987 38.1031 16.862 37.9781C16.737 37.853 16.6667 37.6835 16.6667 37.5067V18.4933C16.6667 18.3165 16.737 18.1469 16.862 18.0219C16.987 17.8969 17.1566 17.8267 17.3334 17.8267H38.6667ZM38.6667 15.8267H17.3334C16.6262 15.8267 15.9479 16.1076 15.4478 16.6077C14.9477 17.1078 14.6667 17.7861 14.6667 18.4933V37.5067C14.6667 38.2139 14.9477 38.8922 15.4478 39.3923C15.9479 39.8924 16.6262 40.1733 17.3334 40.1733C17.7458 40.1775 18.153 40.0814 18.5201 39.8933L24.7734 36.7733C25.142 36.5896 25.5482 36.4938 25.9601 36.4933H38.6667C39.374 36.4933 40.0523 36.2124 40.5524 35.7123C41.0525 35.2122 41.3334 34.5339 41.3334 33.8267V18.4933C41.3334 17.7861 41.0525 17.1078 40.5524 16.6077C40.0523 16.1076 39.374 15.8267 38.6667 15.8267Z" fill="#36373F"/>
<path d="M36 23.6667H20C19.7348 23.6667 19.4804 23.5614 19.2929 23.3739C19.1054 23.1863 19 22.932 19 22.6667C19 22.4015 19.1054 22.1472 19.2929 21.9596C19.4804 21.7721 19.7348 21.6667 20 21.6667H36C36.2652 21.6667 36.5196 21.7721 36.7071 21.9596C36.8946 22.1472 37 22.4015 37 22.6667C37 22.932 36.8946 23.1863 36.7071 23.3739C36.5196 23.5614 36.2652 23.6667 36 23.6667Z" fill="#36373F"/>
<path d="M28 29H20C19.7348 29 19.4804 28.8946 19.2929 28.7071C19.1054 28.5196 19 28.2652 19 28C19 27.7348 19.1054 27.4804 19.2929 27.2929C19.4804 27.1054 19.7348 27 20 27H28C28.2652 27 28.5196 27.1054 28.7071 27.2929C28.8946 27.4804 29 27.7348 29 28C29 28.2652 28.8946 28.5196 28.7071 28.7071C28.5196 28.8946 28.2652 29 28 29Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "54"
            },
            height: {
                type: String,
                default: "54" 
            },
        }
}
</script>