<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0002 34.125C15.5102 34.125 4.9502 27.72 4.9502 14.925C4.9502 11.4639 6.3251 8.14461 8.77245 5.69726C11.2198 3.24991 14.5391 1.875 18.0002 1.875C21.4613 1.875 24.7806 3.24991 27.2279 5.69726C29.6753 8.14461 31.0502 11.4639 31.0502 14.925C31.0502 27.72 20.4902 34.125 18.0002 34.125ZM18.0002 4.125C16.5761 4.12695 15.1665 4.41012 13.8522 4.95826C12.5379 5.5064 11.3447 6.30871 10.3413 7.31914C9.33779 8.32957 8.54376 9.52823 8.00473 10.8463C7.4657 12.1644 7.19229 13.576 7.2002 15C7.2002 26.25 16.5002 31.725 18.0002 31.875C19.5002 31.77 28.7852 26.235 28.7852 14.925C28.7812 12.0645 27.6442 9.32202 25.6229 7.29791C23.6016 5.27381 20.8607 4.13293 18.0002 4.125Z"
      fill="#7B7E8C"
    />
    <path
      d="M18 19.38C17.11 19.38 16.24 19.1161 15.4999 18.6216C14.7599 18.1272 14.1831 17.4244 13.8425 16.6021C13.5019 15.7798 13.4128 14.875 13.5865 14.0021C13.7601 13.1292 14.1887 12.3274 14.818 11.698C15.4474 11.0687 16.2492 10.6401 17.1221 10.4665C17.995 10.2928 18.8998 10.382 19.7221 10.7225C20.5443 11.0631 21.2471 11.6399 21.7416 12.3799C22.2361 13.12 22.5 13.99 22.5 14.88C22.5 16.0735 22.0259 17.2181 21.182 18.062C20.3381 18.9059 19.1935 19.38 18 19.38ZM18 12.72C17.5671 12.7203 17.1439 12.8479 16.7831 13.087C16.4223 13.3262 16.1399 13.6662 15.971 14.0648C15.8021 14.4633 15.7543 14.9028 15.8335 15.3283C15.9127 15.7539 16.1153 16.1467 16.4163 16.4579C16.7172 16.769 17.103 16.9847 17.5257 17.0781C17.9484 17.1715 18.3892 17.1384 18.7931 16.9829C19.1971 16.8275 19.5464 16.5565 19.7975 16.2039C20.0486 15.8513 20.1903 15.4326 20.205 15C20.2151 14.7042 20.1655 14.4094 20.0592 14.1331C19.9529 13.8569 19.7921 13.6049 19.5863 13.3921C19.3805 13.1794 19.134 13.0102 18.8615 12.8947C18.589 12.7793 18.296 12.7198 18 12.72Z"
      fill="#7B7E8C"
    />
  </svg>
</template>
<script>
export default {
  name: "MapIcon",
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>
