<template>
    
<svg :width="width" :height="height" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1484_7024)">
<path d="M15 0C12.0333 0 9.13319 0.879735 6.66645 2.52796C4.19972 4.17618 2.27713 6.51886 1.14181 9.25975C0.00649927 12.0006 -0.290551 15.0166 0.288228 17.9264C0.867006 20.8361 2.29562 23.5088 4.39341 25.6066C6.49119 27.7044 9.16394 29.133 12.0737 29.7118C14.9834 30.2906 17.9994 29.9935 20.7403 28.8582C23.4812 27.7229 25.8238 25.8003 27.4721 23.3336C29.1203 20.8668 30 17.9667 30 15C30 11.0218 28.4197 7.20644 25.6066 4.3934C22.7936 1.58035 18.9783 0 15 0V0ZM11.2521 22.0972H8.06388V11.8711H11.2521V22.0972ZM9.65801 10.4805C9.29205 10.4822 8.93383 10.3752 8.62873 10.1731C8.32364 9.97101 8.08539 9.6829 7.94418 9.34528C7.80297 9.00766 7.76515 8.63573 7.83552 8.2766C7.90588 7.91747 8.08126 7.5873 8.33944 7.32794C8.59761 7.06857 8.92697 6.89167 9.28577 6.81966C9.64458 6.74764 10.0167 6.78375 10.3549 6.92341C10.6932 7.06306 10.9824 7.29998 11.1859 7.60415C11.3894 7.90831 11.498 8.26604 11.498 8.632C11.498 9.12078 11.3044 9.58968 10.9596 9.9361C10.6148 10.2825 10.1468 10.4783 9.65801 10.4805ZM23.1487 22.0972H19.9774V17.1283C19.9774 15.9412 19.9774 14.4149 18.3239 14.4149C16.6704 14.4149 16.4161 15.7038 16.4161 17.0435V22.1311H13.2448V11.8711H16.2889V13.2702H16.3313C16.6301 12.7394 17.0687 12.3007 17.5994 12.0017C18.1301 11.7027 18.7326 11.5549 19.3414 11.5743C22.5551 11.5743 23.1487 13.6942 23.1487 16.45V22.0972Z" fill="#575966"/>
</g>
<defs>
<clipPath id="clip0_1484_7024">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "30"
            },
            height: {
                type: String,
                default: "30" 
            },
        }
}
</script>