<template>
    <div class="container-size">
        <div class="text-center mb-10" style="margin-top:150px"><img src="/img/other/ic_it_service.png" height="300"></div>
        <div class="text-center font-weight-bold mt-10">
            <p class="font-poppins" style="font-size:2.0rem">Page Not Found</p>
        </div>
        <div class="text-center mt-5 mb-15">
            <v-btn depressed color="#000080" class="pa-5" @click="goToHomePage">
                <span class="text-capitalize white--text font-weight-bold font-poppins">Kembali ke halaman beranda</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToHomePage() {
            window.location.href = '/';
        }
    }
}
</script>