<template>
  <div :style="`color: ${fill}`">
<svg :width="width"
:height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z" fill="#7B7E8C"/>
<path d="M11.7825 11.9473C11.685 11.9473 11.5875 11.9248 11.4975 11.8648L9.17248 10.4773C8.59498 10.1323 8.16748 9.37482 8.16748 8.70732V5.63232C8.16748 5.32482 8.42248 5.06982 8.72998 5.06982C9.03748 5.06982 9.29248 5.32482 9.29248 5.63232V8.70732C9.29248 8.97732 9.51748 9.37482 9.74998 9.50982L12.075 10.8973C12.345 11.0548 12.4275 11.3998 12.27 11.6698C12.1575 11.8498 11.97 11.9473 11.7825 11.9473Z" fill="#7B7E8C"/>
</svg>

  </div>
</template>
<script>
export default {
  name: "date-icon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
