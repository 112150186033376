<template>
    
<svg :width="width" :height="height" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34 8H14C8 8 4 11 4 18V25.12C4 25.86 4.76 26.32 5.42 26.02C7.38 25.12 9.64 24.78 12.02 25.2C17.28 26.14 21.14 31.02 21 36.36C20.98 37.2 20.86 38.02 20.64 38.82C20.48 39.44 20.98 40.02 21.62 40.02H34C40 40.02 44 37.02 44 30.02V18C44 11 40 8 34 8ZM24 29C21.24 29 19 26.76 19 24C19 21.24 21.24 19 24 19C26.76 19 29 21.24 29 24C29 26.76 26.76 29 24 29ZM38.5 28C38.5 28.82 37.82 29.5 37 29.5C36.18 29.5 35.5 28.82 35.5 28V20C35.5 19.18 36.18 18.5 37 18.5C37.82 18.5 38.5 19.18 38.5 20V28Z" fill="#7B7E8C"/>
<path d="M10 28C5.58 28 2 31.58 2 36C2 37.5 2.42 38.92 3.16 40.12C4.54 42.44 7.08 44 10 44C12.92 44 15.46 42.44 16.84 40.12C17.58 38.92 18 37.5 18 36C18 31.58 14.42 28 10 28ZM13.94 35.34L9.68 39.28C9.4 39.54 9.02 39.68 8.66 39.68C8.28 39.68 7.9 39.54 7.6 39.24L5.62 37.26C5.04 36.68 5.04 35.72 5.62 35.14C6.2 34.56 7.16 34.56 7.74 35.14L8.7 36.1L11.9 33.14C12.5 32.58 13.46 32.62 14.02 33.22C14.58 33.82 14.54 34.78 13.94 35.34Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "48"
            },
            height: {
                type: String,
                default: "48" 
            },
        }
}
</script>