<template>
    
<svg :width="width" :height="height" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#FFDE59"/>
<path d="M35.9998 22.5464C35.9598 22.5464 35.9332 22.5464 35.8932 22.5464H35.8265C33.3065 22.4664 31.4265 20.5197 31.4265 18.1197C31.4265 15.6664 33.4265 13.6797 35.8665 13.6797C38.3065 13.6797 40.3065 15.6797 40.3065 18.1197C40.2932 20.533 38.4132 22.4797 36.0132 22.5597C36.0132 22.5464 36.0132 22.5464 35.9998 22.5464ZM35.8665 15.6664C34.5198 15.6664 33.4265 16.7597 33.4265 18.1064C33.4265 19.4264 34.4532 20.493 35.7732 20.5464C35.7865 20.533 35.8932 20.533 36.0132 20.5464C37.3065 20.4797 38.3065 19.413 38.3198 18.1064C38.3198 16.7597 37.2265 15.6664 35.8665 15.6664Z" fill="#36373F"/>
<path d="M36.0133 32.373C35.4933 32.373 34.9733 32.333 34.4533 32.2396C33.9066 32.1463 33.5466 31.6263 33.6399 31.0796C33.7333 30.533 34.2533 30.173 34.7999 30.2663C36.4399 30.5463 38.1733 30.2396 39.3333 29.4663C39.9599 29.053 40.2933 28.533 40.2933 28.013C40.2933 27.493 39.9466 26.9863 39.3333 26.573C38.1733 25.7996 36.4133 25.493 34.7599 25.7863C34.2133 25.893 33.6933 25.5196 33.5999 24.973C33.5066 24.4263 33.8666 23.9063 34.4133 23.813C36.5866 23.4263 38.8399 23.8396 40.4399 24.9063C41.6133 25.693 42.2933 26.813 42.2933 28.013C42.2933 29.1996 41.6266 30.333 40.4399 31.133C39.2266 31.933 37.6533 32.373 36.0133 32.373Z" fill="#36373F"/>
<path d="M19.9599 22.5468C19.9466 22.5468 19.9332 22.5468 19.9332 22.5468C17.5332 22.4668 15.6532 20.5201 15.6399 18.1201C15.6399 15.6667 17.6399 13.6667 20.0799 13.6667C22.5199 13.6667 24.5199 15.6668 24.5199 18.1068C24.5199 20.5201 22.6399 22.4668 20.2399 22.5468L19.9599 21.5468L20.0532 22.5468C20.0266 22.5468 19.9866 22.5468 19.9599 22.5468ZM20.0932 20.5468C20.1732 20.5468 20.2399 20.5467 20.3199 20.5601C21.5066 20.5067 22.5466 19.4401 22.5466 18.1201C22.5466 16.7734 21.4532 15.6801 20.1066 15.6801C18.7599 15.6801 17.6666 16.7734 17.6666 18.1201C17.6666 19.4267 18.6799 20.4801 19.9732 20.5601C19.9866 20.5467 20.0399 20.5468 20.0932 20.5468Z" fill="#36373F"/>
<path d="M19.9467 32.373C18.3067 32.373 16.7334 31.933 15.5201 31.133C14.3467 30.3463 13.6667 29.213 13.6667 28.013C13.6667 26.8263 14.3467 25.693 15.5201 24.9063C17.1201 23.8396 19.3734 23.4263 21.5467 23.813C22.0934 23.9063 22.4534 24.4263 22.3601 24.973C22.2667 25.5196 21.7467 25.893 21.2001 25.7863C19.5467 25.493 17.8001 25.7996 16.6267 26.573C16.0001 26.9863 15.6667 27.493 15.6667 28.013C15.6667 28.533 16.0134 29.053 16.6267 29.4663C17.7867 30.2396 19.5201 30.5463 21.1601 30.2663C21.7067 30.173 22.2267 30.5463 22.3201 31.0796C22.4134 31.6263 22.0534 32.1463 21.5067 32.2396C20.9867 32.333 20.4667 32.373 19.9467 32.373Z" fill="#36373F"/>
<path d="M27.9998 32.5073C27.9598 32.5073 27.9332 32.5073 27.8932 32.5073H27.8265C25.3065 32.4273 23.4265 30.4806 23.4265 28.0806C23.4265 25.6273 25.4265 23.6406 27.8665 23.6406C30.3065 23.6406 32.3065 25.6406 32.3065 28.0806C32.2932 30.494 30.4132 32.4406 28.0132 32.5206C28.0132 32.5073 28.0132 32.5073 27.9998 32.5073ZM27.8665 25.6273C26.5198 25.6273 25.4265 26.7206 25.4265 28.0673C25.4265 29.3873 26.4532 30.454 27.7732 30.5073C27.7865 30.494 27.8932 30.494 28.0132 30.5073C29.3065 30.4406 30.3065 29.374 30.3198 28.0673C30.3198 26.734 29.2265 25.6273 27.8665 25.6273Z" fill="#36373F"/>
<path d="M27.9996 42.3462C26.3996 42.3462 24.7996 41.9329 23.5596 41.0929C22.3863 40.3062 21.7063 39.1862 21.7063 37.9862C21.7063 36.7996 22.373 35.6529 23.5596 34.8662C26.053 33.2129 29.9596 33.2129 32.4396 34.8662C33.613 35.6529 34.293 36.7729 34.293 37.9729C34.293 39.1596 33.6263 40.3062 32.4396 41.0929C31.1996 41.9196 29.5996 42.3462 27.9996 42.3462ZM24.6663 36.5462C24.0396 36.9596 23.7063 37.4796 23.7063 37.9996C23.7063 38.5196 24.053 39.0262 24.6663 39.4396C26.4663 40.6529 29.5196 40.6529 31.3196 39.4396C31.9463 39.0262 32.2796 38.5062 32.2796 37.9862C32.2796 37.4662 31.933 36.9596 31.3196 36.5462C29.533 35.3329 26.4796 35.3462 24.6663 36.5462Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "54"
            },
            height: {
                type: String,
                default: "54" 
            },
        }
}
</script>