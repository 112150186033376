<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :style="`transform: rotate(${right ? 0 : 180}deg)`"
    fill="currentColor"
    class="bi bi-arrow-left-circle-fill"
    viewBox="0 0 16 16"
  >
    <path
      d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
    />
  </svg>
</template>

<script>
export default {
  name: "RoundArrowBack",
  props: {
    width: {
      type: Number,
      default: 40,
    },
    height: {
      type: Number,
      default: 40,
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
