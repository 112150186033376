<template>
    <div class="modal" @click.self="$emit('close')">
        <div class="modal-content" :style="`width: ${width ? width: 'auto'}; height: ${height ? height: 'auto'};`">
            <div @click="$emit('close')" v-if="mobileView" class="c-close">
                <close />
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
import Close from '../../assets/icons/Close.vue';
export default {
    name: "ModalComponent",
    props: {
        width: {
            type: String,
            default: ""
        },
        height: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            mobileView: false,
        }
    },
    components: {
        Close
    },
    methods: {
        handleView() {
            this.mobileView = window.innerWidth <= 990;
        },
    },
    mounted() {
        this.handleView()
    }
}
</script>

<style scoped>
.modal {
    top: 0;
    left: 0;
    z-index: 10;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(12, 11, 11, 0.2);
    width: 100vw;
    height: 100vh;
}
.modal-content {
    background-color: white;
    border-radius: 30px;
    position: relative;
    padding: 36px 25px;
    text-align: center;
}
.c-close {
    position: absolute;
    top: -56px;
    right: 0;
    display: flex;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
}
</style>