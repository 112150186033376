<template>
    
<svg :width="width" :height="height" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#FFDE59"/>
<path d="M16.6667 37.0001C16.1201 37.0001 15.6667 36.5467 15.6667 36.0001V21.3334C15.6667 15.4534 17.4534 13.6667 23.3334 13.6667H32.6667C38.5467 13.6667 40.3334 15.4534 40.3334 21.3334V34.6667C40.3334 34.8801 40.3334 35.0801 40.3201 35.2934C40.2801 35.8401 39.7867 36.2667 39.2534 36.2267C38.7067 36.1867 38.2801 35.7067 38.3201 35.1601C38.3334 35.0001 38.3334 34.8267 38.3334 34.6667V21.3334C38.3334 16.5734 37.4401 15.6667 32.6667 15.6667H23.3334C18.5601 15.6667 17.6667 16.5734 17.6667 21.3334V36.0001C17.6667 36.5467 17.2134 37.0001 16.6667 37.0001Z" fill="#36373F"/>
<path d="M34.6667 42.3333H21.3334C18.2134 42.3333 15.6667 39.7867 15.6667 36.6667V35.8C15.6667 33.1467 17.8267 31 20.4667 31H39.3334C39.8801 31 40.3334 31.4533 40.3334 32V36.6667C40.3334 39.7867 37.7867 42.3333 34.6667 42.3333ZM20.4667 33C18.9201 33 17.6667 34.2533 17.6667 35.8V36.6667C17.6667 38.6933 19.3067 40.3333 21.3334 40.3333H34.6667C36.6934 40.3333 38.3334 38.6933 38.3334 36.6667V33H20.4667Z" fill="#36373F"/>
<path d="M33.3334 22.3333H22.6667C22.1201 22.3333 21.6667 21.8799 21.6667 21.3333C21.6667 20.7866 22.1201 20.3333 22.6667 20.3333H33.3334C33.8801 20.3333 34.3334 20.7866 34.3334 21.3333C34.3334 21.8799 33.8801 22.3333 33.3334 22.3333Z" fill="#36373F"/>
<path d="M29.3334 27H22.6667C22.1201 27 21.6667 26.5467 21.6667 26C21.6667 25.4533 22.1201 25 22.6667 25H29.3334C29.8801 25 30.3334 25.4533 30.3334 26C30.3334 26.5467 29.8801 27 29.3334 27Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "54"
            },
            height: {
                type: String,
                default: "54" 
            },
        }
}
</script>