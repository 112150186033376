<template>
    
<svg :width="width"
:height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.7334 19.9333L12.2667 22.4C11.7467 22.92 10.92 22.92 10.3867 22.4133C10.24 22.2666 10.0934 22.1333 9.94669 21.9866C8.57335 20.6 7.33335 19.1466 6.22669 17.6266C5.13335 16.1066 4.25335 14.5866 3.61335 13.08C2.98669 11.56 2.66669 10.1066 2.66669 8.71996C2.66669 7.81329 2.82669 6.94663 3.14669 6.14663C3.46669 5.33329 3.97335 4.58663 4.68002 3.91996C5.53335 3.07996 6.46669 2.66663 7.45335 2.66663C7.82669 2.66663 8.20002 2.74663 8.53335 2.90663C8.88002 3.06663 9.18669 3.30663 9.42669 3.65329L12.52 8.01329C12.76 8.34663 12.9334 8.65329 13.0534 8.94663C13.1734 9.22663 13.24 9.50663 13.24 9.75996C13.24 10.08 13.1467 10.4 12.96 10.7066C12.7867 11.0133 12.5334 11.3333 12.2134 11.6533L11.2 12.7066C11.0534 12.8533 10.9867 13.0266 10.9867 13.24C10.9867 13.3466 11 13.44 11.0267 13.5466C11.0667 13.6533 11.1067 13.7333 11.1334 13.8133C11.3734 14.2533 11.7867 14.8266 12.3734 15.52C12.9734 16.2133 13.6134 16.92 14.3067 17.6266C14.44 17.76 14.5867 17.8933 14.72 18.0266C15.2534 18.5466 15.2667 19.4 14.7334 19.9333Z" :fill="fill"/>
<path d="M29.2933 24.44C29.2933 24.8133 29.2267 25.2 29.0933 25.5733C29.0533 25.68 29.0133 25.7866 28.96 25.8933C28.7333 26.3733 28.44 26.8266 28.0533 27.2533C27.4 27.9733 26.68 28.4933 25.8667 28.8266C25.8533 28.8266 25.84 28.84 25.8267 28.84C25.04 29.16 24.1867 29.3333 23.2667 29.3333C21.9067 29.3333 20.4533 29.0133 18.92 28.36C17.3867 27.7066 15.8533 26.8266 14.3333 25.72C13.8133 25.3333 13.2933 24.9466 12.8 24.5333L17.16 20.1733C17.5333 20.4533 17.8667 20.6666 18.1467 20.8133C18.2133 20.84 18.2933 20.88 18.3867 20.92C18.4933 20.96 18.6 20.9733 18.72 20.9733C18.9467 20.9733 19.12 20.8933 19.2667 20.7466L20.28 19.7466C20.6133 19.4133 20.9333 19.16 21.24 19C21.5467 18.8133 21.8533 18.72 22.1867 18.72C22.44 18.72 22.7067 18.7733 23 18.8933C23.2933 19.0133 23.6 19.1866 23.9333 19.4133L28.3467 22.5466C28.6933 22.7866 28.9333 23.0666 29.08 23.4C29.2133 23.7333 29.2933 24.0666 29.2933 24.44Z" fill="#162CA2"/>
</svg>

</template>
<script>
export default {
  name: "date-icon",
  props: {
    width: {
      type: Number,
      default: 32,
    },
    height: {
      type: Number,
      default: 32,
    },
    outline: {
      type: Boolean,
      default: false
    },
    fill: {
      type: String,
      default: "#162CA2"
    }
  },
};
</script>
