<template>
  <div style="margin-top: 63px">
    <div class="hero">
      <div class="hero-ctn">
        <h1>
          Testimoni
        </h1>
        <p>
          Berbagai ulasan alumni dan peserta event seputar program akselerasi digital bersama HiColleagues
        </p>
      </div>
    </div>
    <div style="background-color: #f5f6fa;" class="pb-10">
      <div class="container">
        <div class="card-2" v-for="(item, idx) in testimonies" :key="idx">
          <p class="text-center">{{ item.feedback }}</p>
          <div class="profile mt-5 d-flex" style="align-items: center">
            <div class="avatar">
              <img :src="$utils.loadImage(item.image_url)" alt="" width="60" height="60" class="avatar-image" />
            </div>
            <div class="testi-profile ml-5">
              <div class="name">{{ item.name }}</div>
              <div class="description">
                {{ item.job }} {{ item.place }}
              </div>
              <v-img v-if="item.linkedin" src="/img/home/linkedin.png" alt="" width="23" height="23"
                @click="redirectReload(item.linkedin)" class="linkedin-image cursor-pointer" />
            </div>
          </div>
        </div>
        <div class="btn-stoke-blue" v-if="currentPage < total" @click="nextPrev">
          <span class="font-poppins">MUAT LEBIH BANYAK</span>
          <arrow-long />
        </div>
      </div>
    </div>
    <v-card elevation="0" class="call-center-container">
      <v-row style="padding: 20px 8px">
        <v-col cols="12">
          <div class="call-center-title">
            Wujudkan Mimpi Jadi Talenta Digital Kompeten & #CiptakanMasaDepan Bersama HiColleagues
          </div>
          <div class="call-center-desc">
            Rekomendasi berbagai program Akselerasi Digital dalam Upskilling, New Skilling, & Reskilling yang kredibel
          </div>
        </v-col>
        <v-col cols="12" class="call-center-button" @click="$router.push('/')">
          <v-btn class="btn" elevation="0"> LIHAT PROGRAM </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import ArrowLong from '@/assets/icons/ArrowLong.vue';

export default {
  name: "MobileTestimony",
  components: {
    ArrowLong
  },
  data() {
    return {
      testimonies: [],
      currentPage: 1,
      total: 1,
    }
  },
  methods: {
    async getTestimoni() {
      try {
        const resp = await this.$http.get(`/v1/testimony?page=${this.currentPage}&per_page=20`);
        this.testimonies = this.testimonies.concat(resp.data.data.testimonies);
        if (resp.data.data.total) {
          this.total = parseInt(resp.data.data.total / 20) + 1;
        }
      } catch (err) {
        console.log(err);
      }
    },
    nextPrev() {
      this.currentPage++
      this.getTestimoni()
    }
  },
  mounted() {
    this.testimonies = []
    this.getTestimoni()
  }
}
</script>

<style scoped src="./testimony.css"></style>