<template>
    
<svg :width="width" :height="height" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M32.38 4H15.62C8.34 4 4 8.34 4 15.62V32.36C4 39.66 8.34 44 15.62 44H32.36C39.64 44 43.98 39.66 43.98 32.38V15.62C44 8.34 39.66 4 32.38 4ZM33.76 23.06C33.76 23.84 33.14 24.46 32.36 24.46C31.58 24.46 30.96 23.84 30.96 23.06V22.7L25.52 28.14C25.22 28.44 24.82 28.58 24.4 28.54C23.98 28.5 23.6 28.28 23.38 27.92L21.34 24.88L16.58 29.64C16.3 29.92 15.96 30.04 15.6 30.04C15.24 30.04 14.88 29.9 14.62 29.64C14.08 29.1 14.08 28.22 14.62 27.66L20.58 21.7C20.88 21.4 21.28 21.26 21.7 21.3C22.12 21.34 22.5 21.56 22.72 21.92L24.76 24.96L28.98 20.74H28.62C27.84 20.74 27.22 20.12 27.22 19.34C27.22 18.56 27.84 17.94 28.62 17.94H32.34C32.52 17.94 32.7 17.98 32.88 18.04C33.22 18.18 33.5 18.46 33.64 18.8C33.72 18.98 33.74 19.16 33.74 19.34V23.06H33.76Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "48"
            },
            height: {
                type: String,
                default: "48" 
            },
        }
}
</script>