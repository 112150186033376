<template>
    
<svg :width="width" :height="height" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42.1801 13.9591C40.4801 12.0791 37.6601 11.1391 33.5201 11.1391H33.0401V11.0591C33.0401 7.69906 33.0401 3.53906 25.5201 3.53906H22.4801C14.9601 3.53906 14.9601 7.69906 14.9601 11.0591V11.1391H14.4801C10.3401 11.1391 7.50007 12.0791 5.82008 13.9591C3.84008 16.1791 3.90007 19.1191 4.10007 21.1391L4.12007 21.2791L4.27916 23.135C4.29235 23.289 4.37595 23.4282 4.5056 23.5122C5.13113 23.9177 5.81181 24.3589 6.46007 24.7191C6.74007 24.8991 7.04008 25.0591 7.34008 25.2191C9.60007 26.4591 12.0201 27.4391 14.5001 28.1191C14.5601 33.2991 18.8001 37.4991 24.0001 37.4991C29.2401 37.4991 33.5001 33.2391 33.5001 27.9991V27.9191C36.0201 27.1591 38.4401 26.0991 40.7001 24.7791C40.8201 24.7191 40.9001 24.6591 41.0001 24.5991C41.8542 24.1349 42.7427 23.5156 43.5378 22.9491C43.6538 22.8664 43.7289 22.738 43.7442 22.5963L43.9001 21.1591C43.9201 21.0391 43.9201 20.9391 43.9401 20.7991C44.1001 18.7991 44.0601 16.0391 42.1801 13.9591ZM17.8201 11.0591C17.8201 7.65906 17.8201 6.37906 22.4801 6.37906H25.5201C30.1801 6.37906 30.1801 7.65906 30.1801 11.0591V11.1391H17.8201V11.0591ZM24.0001 34.4991C20.7001 34.4991 17.9601 32.0191 17.5601 28.8191C17.5201 28.5591 17.5001 28.2791 17.5001 27.9991C17.5001 24.4191 20.4201 21.4991 24.0001 21.4991C27.5801 21.4991 30.5001 24.4191 30.5001 27.9991C30.5001 28.2391 30.4801 28.4591 30.4601 28.6791V28.6991C30.1001 31.9591 27.3401 34.4991 24.0001 34.4991Z" fill="#7B7E8C"/>
<path d="M22.8598 32.0607C22.4798 32.0607 22.0998 31.9207 21.7998 31.6207L19.8198 29.6607C19.2398 29.0807 19.2398 28.1207 19.8198 27.5407C20.3998 26.9607 21.3598 26.9607 21.9398 27.5407L22.8998 28.5007L26.1198 25.5407C26.7198 24.9807 27.6798 25.0207 28.2398 25.6207C28.7998 26.2207 28.7598 27.1807 28.1598 27.7407L23.8998 31.6807C23.5798 31.9207 23.2198 32.0607 22.8598 32.0607Z" fill="#7B7E8C"/>
<path d="M42.4034 27.2768C42.766 27.0642 43.2461 27.3656 43.2078 27.7842L42.4631 35.9222C42.0431 39.9222 40.4031 44.0022 31.6031 44.0022H16.3631C7.56312 44.0022 5.92313 39.9222 5.52313 35.9422L4.79342 28.129C4.75561 27.7242 5.19141 27.4456 5.54568 27.6451C5.68439 27.7232 5.81231 27.7941 5.90313 27.8422C7.46313 28.7022 9.08312 29.4622 10.7431 30.0622C11.3631 30.2822 11.8031 30.8022 11.9831 31.4422C13.4831 36.6422 18.3231 40.5022 24.0031 40.5022C29.7831 40.5022 34.6631 36.5622 36.0831 31.1622C36.2431 30.5222 36.6831 30.0022 37.3031 29.7622C38.9831 29.1022 40.5831 28.3222 42.0831 27.4622C42.1358 27.4329 42.2569 27.3626 42.4034 27.2768Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "48"
            },
            height: {
                type: String,
                default: "48" 
            },
        }
}
</script>