<template>
    <div>
        <DekstopAlumniStories v-if="!isMobile" />
        <MobileAlumniStories v-if="isMobile" />
    </div>
  </template>
  
  <script>
  import DekstopAlumniStories from "@/components/alumni-stories/desktop/AlumniStories.vue"
  import MobileAlumniStories from "@/components/alumni-stories/mobile/AlumniStories.vue"
  
  export default {
    components: {
        DekstopAlumniStories,
        MobileAlumniStories,
    },
    data() {
        return {
            isMobile: false,
        };
    },
    methods: {
        handleView() {
            this.isMobile = screen.width <= 990;
        },
    },
    mounted() {
        this.$gtag.pageview(this.$route);
        this.$utils.scrollTop()
    },
    beforeMount() {
        this.handleView();
    },
  };
  </script>