<template>
    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; max-width: 400px; margin: auto">
        <Empty />
        <p>{{ message }}</p>
    </div>
</template>

<script>
import Empty from '@/assets/icons/Empty.vue'
export default {
    name: "EmptyData",
    components: {
        Empty
    },
    props: {
        message: {
            type: String, 
            default: ""
        }
    }
}
</script>