<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 60 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60 26.6543C60 28.4387 59.6283 30.1115 58.8848 31.6729C58.2156 33.2342 57.2862 34.6097 56.0967 35.7993C54.9814 36.9145 53.6431 37.8067 52.0818 38.4758C50.5204 39.145 48.8476 39.4796 47.0632 39.4796C45.2788 39.4796 43.6059 39.145 42.0446 38.4758C40.4833 37.8067 39.1078 36.9145 37.9182 35.7993C36.803 34.6097 35.9108 33.2342 35.2416 31.6729C34.5725 30.1115 34.2379 28.4387 34.2379 26.6543C34.2379 23.6803 34.7212 20.9294 35.6877 18.4015C36.6543 15.7993 37.9554 13.3829 39.5911 11.1524C41.3011 8.92193 43.2342 6.87732 45.3903 5.01859C47.6208 3.15985 49.9257 1.48699 52.3048 0L56.4312 3.56877C55.0186 4.83271 53.7175 6.43123 52.5279 8.36431C51.3383 10.2974 50.5576 12.2305 50.1859 14.1636C53.0112 14.8327 55.3532 16.3569 57.2119 18.7361C59.0706 21.0409 60 23.6803 60 26.6543ZM25.7621 26.6543C25.7621 28.4387 25.3903 30.1115 24.6468 31.6729C23.9777 33.2342 23.0483 34.6097 21.8587 35.7993C20.7435 36.9145 19.368 37.8067 17.7323 38.4758C16.171 39.145 14.4981 39.4796 12.7138 39.4796C10.9294 39.4796 9.25651 39.145 7.69517 38.4758C6.20818 37.8067 4.86989 36.9145 3.6803 35.7993C2.56506 34.6097 1.67286 33.2342 1.00372 31.6729C0.334572 30.1115 0 28.4387 0 26.6543C0 23.6803 0.483271 20.9294 1.44981 18.4015C2.41636 15.7993 3.71747 13.3829 5.35316 11.1524C7.0632 8.92193 8.99628 6.87732 11.1524 5.01859C13.3829 3.15985 15.6877 1.48699 18.0669 0L22.0818 3.56877C20.7435 4.83271 19.4796 6.43123 18.29 8.36431C17.1004 10.2974 16.3197 12.2305 15.948 14.1636C18.7732 14.8327 21.1152 16.3569 22.974 18.7361C24.8327 21.0409 25.7621 23.6803 25.7621 26.6543Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "quote-icon",
  props: {
    width: {
      type: Number,
      default: 50,
    },
    height: {
      type: Number,
      default: 30,
    },
    fill: {
      type: String,
      default: "#7B7E8C",
    },
  },
};
</script>
