<template>
    
<svg :width="width" :height="height" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.5733 16.3933C31.5733 7.89183 24.6815 1 16.18 1C7.67848 1 0.786652 7.89183 0.786652 16.3933C0.786652 24.8948 7.67848 31.7867 16.18 31.7867C24.6815 31.7867 31.5733 24.8948 31.5733 16.3933Z" stroke="#ACAFBF" stroke-width="1.5" stroke-miterlimit="10"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.7802 10.6933C20.2802 9.19331 18.2802 8.39331 16.1802 8.39331C11.7802 8.39331 8.18021 11.9933 8.18021 16.3933C8.18021 17.7933 8.58021 19.1933 9.28021 20.3933L8.18021 24.3933L12.3802 23.2933C13.5802 23.8933 14.8802 24.2933 16.1802 24.2933C20.5802 24.2933 24.1802 20.6933 24.1802 16.2933C24.1802 14.1933 23.2802 12.1933 21.7802 10.6933ZM16.1802 22.9933C14.9802 22.9933 13.7802 22.6933 12.7802 22.0933L12.5802 21.9933L10.0802 22.6933L10.7802 20.2933L10.5802 19.9933C9.8802 18.8933 9.5802 17.6933 9.5802 16.4933C9.5802 12.8933 12.5802 9.89331 16.1802 9.89331C17.9802 9.89331 19.5802 10.5933 20.8802 11.7933C22.1802 13.0933 22.7802 14.6933 22.7802 16.4933C22.7802 19.9933 19.8802 22.9933 16.1802 22.9933ZM19.7802 17.9933C19.5802 17.8933 18.5802 17.3933 18.3802 17.3933C18.1802 17.2933 18.0802 17.2933 17.9802 17.4933C17.8802 17.6933 17.4802 18.0933 17.3802 18.2933C17.2802 18.3933 17.1802 18.3933 16.9802 18.3933C16.7802 18.2933 16.1802 18.0933 15.3802 17.3933C14.7802 16.8933 14.3802 16.1933 14.2802 15.9933C14.1802 15.7933 14.2802 15.6933 14.3802 15.5933C14.4802 15.4933 14.5802 15.3933 14.6802 15.2933C14.7802 15.1933 14.7802 15.0933 14.8802 14.9933C14.9802 14.8933 14.8802 14.7933 14.8802 14.6933C14.8802 14.5933 14.4802 13.5933 14.2802 13.1933C14.1802 12.8933 13.9802 12.8933 13.8802 12.8933C13.7802 12.8933 13.6802 12.8933 13.4802 12.8933C13.3802 12.8933 13.1802 12.8933 12.9802 13.0933C12.7802 13.2933 12.2802 13.7933 12.2802 14.7933C12.2802 15.7933 12.9802 16.6933 13.0802 16.8933C13.1802 16.9933 14.4802 19.0933 16.4802 19.8933C18.1802 20.5933 18.4802 20.3933 18.8802 20.3933C19.2802 20.3933 20.0802 19.8933 20.1802 19.4933C20.3802 18.9933 20.3802 18.5933 20.2802 18.5933C20.1802 18.0933 19.9802 18.0933 19.7802 17.9933Z" fill="#ACAFBF"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "32"
            },
            height: {
                type: String,
                default: "32" 
            },
        }
}
</script>