<template>
    
<svg :width="width" :height="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1350_46222)">
<path d="M31.3933 16C31.3933 7.49852 24.5015 0.606689 16 0.606689C7.4985 0.606689 0.606674 7.49852 0.606674 16C0.606674 24.5015 7.4985 31.3933 16 31.3933C24.5015 31.3933 31.3933 24.5015 31.3933 16Z" stroke="#ACAFBF" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M12.2063 12.5703H9.01921V22.8595H12.2063V12.5703Z" fill="#ACAFBF"/>
<path d="M10.5966 11.2195C10.9732 11.2227 11.3423 11.1139 11.657 10.907C11.9717 10.7001 12.2179 10.4043 12.3643 10.0573C12.5106 9.71024 12.5506 9.32753 12.4791 8.95773C12.4076 8.58793 12.2278 8.24771 11.9626 7.98025C11.6974 7.71278 11.3587 7.53012 10.9896 7.45545C10.6204 7.38078 10.2374 7.41747 9.88907 7.56086C9.54078 7.70425 9.24294 7.94787 9.03334 8.26082C8.82374 8.57376 8.71184 8.94192 8.71182 9.31857C8.71076 9.56714 8.75867 9.81348 8.85281 10.0435C8.94695 10.2736 9.08548 10.4829 9.26049 10.6594C9.43551 10.8359 9.64358 10.9762 9.87282 11.0723C10.1021 11.1684 10.348 11.2184 10.5966 11.2195Z" fill="#ACAFBF"/>
<path d="M17.3023 17.456C17.3023 16.0081 17.9656 15.1506 19.2437 15.1506C20.5217 15.1506 20.9747 15.9595 20.9747 17.456V22.8594H24.1456V16.3882C24.1456 13.6299 22.5844 12.2952 20.4004 12.2952C19.7811 12.2858 19.17 12.4373 18.6269 12.7351C18.0838 13.0329 17.6274 13.4667 17.3023 13.9939V12.5702H14.2447V22.8594H17.3023V17.456Z" fill="#ACAFBF"/>
</g>
<defs>
<clipPath id="clip0_1350_46222">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "32"
            },
            height: {
                type: String,
                default: "32" 
            },
        }
}
</script>