<template>
  <footer>
    <div class="container">
      <div class="logo" style="margin-top: 20px;">
        <LogoNav width="209" height="50"  />
        <p class="mt-6">
          <span style="font-size: 18px; font-weight: bold; font-family: 'Poppins';">PT Generasi Anak Muda Berkarya</span> <br>
            <span style="font-family: 'Poppins'; font-weight: 300 !important;">Kantorkuu Coworking & Office Space Agro Plaza Kuningan,<br />Jl. H.R Rasuna Said, RT 7/ RW 4, Kuningan Timur, Setia Budi, Jakarta 12950</span>
        </p>|
      </div>
      <div class="links">
        <v-row dense >
          <v-col cols="6"  md="4">
            <h4 class="mb-2">Program HiColleagues</h4>
            <div v-for="(link, index) in linkProducts" :key="index">
              <span @click="navigateTo(link.url)">{{ link.name }}</span>
            </div>
          </v-col>
          <v-col cols="6"  md="4">
            <h4 class="mb-2">HiColleagues Bisnis</h4>
            <div v-for="(link, index) in linkInformations" :key="index">
              <span @click="navigateTo(link.url)">{{ link.name }}</span>
            </div>
          </v-col>
          <v-col cols="6"  md="4">
            <h4 class="mb-2">Tentang HiColleagues</h4>
            <div v-for="(link, index) in linkEtcs" :key="index">
              <span @click="navigateTo(link.url)">{{ link.name }}</span>
            </div>
            <div class="ll-1">
            <h4 class="mb-2 mt-5">Lain-lain</h4>
            <div v-for="(link, index) in linkOther" :key="index">
              <span @click="navigateTo(link.url)">{{ link.name }}</span>
            </div>
          </div>
          </v-col>
          <v-col cols="6" class="ll-2">
            <h4 class="mb-2 mt-5">Lain-lain</h4>
            <div v-for="(link, index) in linkOther" :key="index">
              <span @click="navigateTo(link.url)">{{ link.name }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="sosmed">
          <div v-for="(sosmed, index) in soscialMedia" :key="index" class="child" @click="toSosmed(sosmed.link)"
          style="cursor: pointer">
            <component :is="sosmed.img"></component>
          </div>
        </div>
    <hr class="mt-4" />
    <p class="copyright">Copyright © {{ new Date().getFullYear() }} - PT Generasi Anak Muda Berkarya</p>
  </footer>
</template>
<script>
import LogoNav from '../assets/icons/LogoNav.vue';
import Instagram from '@/assets/icons/sosmed/Instagram.vue';
import Facebook from '@/assets/icons/sosmed/Facebook.vue';
import Linkedin from '@/assets/icons/sosmed/Linkedin.vue';
import Tiktok from '@/assets/icons/sosmed/Tiktok.vue';
import Youtube from '@/assets/icons/sosmed/Youtube.vue';

export default {
  name: "Footer",
  components: {
    LogoNav,
    Instagram,
    Facebook,
    Linkedin,
    Tiktok,
    Youtube
  },
  data() {
    return {
      footer: {
        logo: "/img/logo_footer.png",
        address:
          "PT Generasi Anak Muda Berkarya Grand Slipi Tower Jl. Letjen S Parman Kav 22-24, Palmerah, Jakarta Barat, DKI Jakarta, 11480",
      },
      soscialMedia: [
        {
          name: "instagram",
          img: Instagram,
          link: "https://instagram.com/hicolleagues"
        },
        {
          name: "facebook",
          img: Facebook,
          link: "https://www.facebook.com/hicolleagues/"
        },
        {
          name: "linkedin",
          img: Linkedin,
          link: "https://www.linkedin.com/company/hicolleagues?originalSubdomain=id"
        },
        {
          name: "tiktok",
          img: Tiktok,
          link: "https://www.tiktok.com/@hicolleagues"
        },
        {
          name: "youtube",
          img: Youtube,
          link: "https://www.youtube.com/@HiColleagues"
        },
      ],
      linkProducts: [
        {
          name: "Bootcamp",
          url: "/class/bootcamp",
        },
        {
          name: "Public Training",
          url: "/class/public-training",
        },
        {
          name: "Prakerja",
          url: "/prakerja",
        },
        {
          name: "Kampus Merdeka",
          url: "/kampusmerdeka",
        },
        {
          name: "Event",
          url: "/event",
        },
      ],
      linkInformations: [
        {
          name: "Corporate Service",
          url: "/corporate",
        },
        {
          name: "Acceleration Program",
          url: "/corporate/acceleration",
        },
        {
          name: "Corporate Collaboration",
          url: "/corporate/collaboration",
        },
        {
          name: "Corporate Training",
          url: "/corporate/training",
        },
        {
          name: "Talent as a Service",
          url: "/corporate/talent-service",
        },
        {
          name: "Technolgy Service",
          url: "/corporate/services",
        },
      ],
      linkEtcs: [
        {
          name: "Tentang Kami",
          url: "/about",
        },
        {
          name: "Hubungi Kami",
          url: "/contact-us",
        },
        {
          name: "Portofolio",
          url: "/portfolio",
        },
        {
          name: "Artikel",
          url: "/blog",
        },
        {
          name: "FAQ",
          url: "/faq",
        },
      ],
      linkOther: [
        {
          name: "Kebijakan Privasi",
          url: "/privacy-policy",
        },
        {
          name: "Syarat & Ketentuan",
          url: "/term-condition",
        },
      ]
    };
  },
  methods: {
    toSosmed(link) {
      window.open(link, '_blank')
    },
    navigateTo(link) {
      this.$router.push(link)
    }
  }
};
</script>

<style src="./css/footer.css" scoped></style>