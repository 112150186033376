<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#162CA2" />
    <circle cx="14.0002" cy="13.9997" r="4.66667" fill="#FFDE59" />
  </svg>
</template>

<script>
export default {
  name: "CollaborationCircle",
  props: {
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
