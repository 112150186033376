<template>
    
<svg :width="width" :height="height" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="28" fill="#FFDE59"/>
<path d="M34.3197 38.1201H21.693C20.6397 38.1201 19.8797 38.0801 19.2264 37.9867C14.5997 37.4801 13.6797 34.7068 13.6797 30.1068V21.6801C13.6797 17.0801 14.5864 14.3067 19.253 13.8C19.893 13.7067 20.653 13.6667 21.693 13.6667H34.3197C35.3597 13.6667 36.1197 13.7067 36.7864 13.8C41.4264 14.3067 42.333 17.0801 42.333 21.6801V30.1068C42.333 34.7068 41.4264 37.4801 36.7597 37.9867C36.133 38.0801 35.373 38.1201 34.3197 38.1201ZM21.6797 15.6667C20.733 15.6667 20.053 15.7068 19.493 15.7868C16.613 16.1068 15.6664 17.0001 15.6664 21.6934V30.1201C15.6664 34.8001 16.613 35.7068 19.4664 36.0134C20.053 36.0934 20.733 36.1334 21.693 36.1334H34.3197C35.2797 36.1334 35.9464 36.0934 36.5064 36.0134C39.3864 35.6934 40.333 34.8001 40.333 30.1068V21.6801C40.333 17.0001 39.3864 16.0935 36.533 15.7868C35.933 15.7068 35.2664 15.6667 34.3064 15.6667H21.6797Z" fill="#36373F"/>
<path d="M35.0133 24.0938H30.1067C29.56 24.0938 29.1067 23.6404 29.1067 23.0938C29.1067 22.5471 29.56 22.0938 30.1067 22.0938H35.0133C35.56 22.0938 36.0133 22.5471 36.0133 23.0938C36.0133 23.6404 35.5733 24.0938 35.0133 24.0938Z" fill="#36373F"/>
<path d="M25.3333 24.4272C24.6 24.4272 24 23.8272 24 23.0938C24 22.3605 24.5867 21.7605 25.3333 21.7605C26.0667 21.7605 26.6667 22.3605 26.6667 23.0938C26.6667 23.8272 26.08 24.4272 25.3333 24.4272Z" fill="#36373F"/>
<path d="M21.1334 24.4272C20.4 24.4272 19.8 23.8272 19.8 23.0938C19.8 22.3605 20.3867 21.7605 21.1334 21.7605H21.1467C21.88 21.7605 22.48 22.3605 22.48 23.0938C22.48 23.8272 21.8667 24.4272 21.1334 24.4272Z" fill="#36373F"/>
<path d="M35.0137 31.8137H21.0004C20.4537 31.8137 19.9871 31.3604 19.9871 30.8137C19.9871 30.2671 20.427 29.8137 20.9737 29.8137H35.0137C35.5604 29.8137 36.0137 30.2671 36.0137 30.8137C36.0137 31.3604 35.5737 31.8137 35.0137 31.8137Z" fill="#36373F"/>
<path d="M34.6666 42.3333H21.3333C20.7866 42.3333 20.3333 41.8799 20.3333 41.3333C20.3333 40.7866 20.7866 40.3333 21.3333 40.3333H34.6666C35.2133 40.3333 35.6666 40.7866 35.6666 41.3333C35.6666 41.8799 35.2133 42.3333 34.6666 42.3333Z" fill="#36373F"/>
</svg>

</template>
<script>
export default {
    props: {
            width: {
                type: String,
                default: "54"
            },
            height: {
                type: String,
                default: "54" 
            },
        }
}
</script>