<template>
    
<svg :width="width" :height="height" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44 42.5C44 43.32 43.32 44 42.5 44H5.5C4.68 44 4 43.32 4 42.5C4 41.68 4.68 41 5.5 41H42.5C43.32 41 44 41.68 44 42.5Z" fill="#7B7E8C"/>
<path d="M30.7799 9.04002L9.29988 30.52C8.47988 31.34 7.15988 31.34 6.35988 30.52H6.33988C3.55988 27.72 3.55988 23.2 6.33988 20.42L20.6399 6.12002C23.4399 3.32002 27.9599 3.32002 30.7599 6.12002C31.5799 6.90002 31.5799 8.24002 30.7799 9.04002Z" fill="#7B7E8C"/>
<path d="M41.6399 16.9806L35.5399 10.8806C34.7199 10.0606 33.3999 10.0606 32.5999 10.8806L11.1199 32.3606C10.2999 33.1606 10.2999 34.4806 11.1199 35.3006L17.2199 41.4206C20.0199 44.2006 24.5399 44.2006 27.3399 41.4206L41.6199 27.1206C44.4599 24.3206 44.4599 19.7806 41.6399 16.9806ZM25.5199 35.0406L23.0999 37.4806C22.5999 37.9806 21.7799 37.9806 21.2599 37.4806C20.7599 36.9806 20.7599 36.1606 21.2599 35.6406L23.6999 33.2006C24.1799 32.7206 25.0199 32.7206 25.5199 33.2006C26.0199 33.7006 26.0199 34.5606 25.5199 35.0406ZM33.4599 27.1006L28.5799 32.0006C28.0799 32.4806 27.2599 32.4806 26.7399 32.0006C26.2399 31.5006 26.2399 30.6806 26.7399 30.1606L31.6399 25.2606C32.1199 24.7806 32.9599 24.7806 33.4599 25.2606C33.9599 25.7806 33.9599 26.6006 33.4599 27.1006Z" fill="#7B7E8C"/>
</svg>

</template>

<script>
export default {
    props: {
            width: {
                type: String,
                default: "48"
            },
            height: {
                type: String,
                default: "48" 
            },
        }
}
</script>