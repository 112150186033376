<template>
  <div>
    <div class="banner">
      <img src="/img/bootcamp/main2.png" class="img-banner" />
      <div class="body-banner">
        <h2 class="title-banner">
          Wujudkan Mimpimu tanpa Mikirin Biaya dengan Kartu Prakerja
        </h2>
        <span class="sub-title-banner"
          >Kembangkan potensi untuk bekal kerja / usaha dengan ikut Kelas
          Prakerja HiColleagues sebagai Mitra Platform Digital resmi Kartu
          Prakerja dengan layanan pelatihan online & offline.
        </span>
        <v-btn
          depressed
          color="#FFDE59"
          class="button-daftar"
          @click="scrollToClass"
        >
          <span class="text-capitalize font-weight-bold blue-text font-poppins"
            >LIHAT KELAS</span
          >
        </v-btn>
        <v-btn depressed color="white" class="button-daftar" @click="sendToWa">
          <span
            class="text-capitalize font-weight-bold blue-text font-poppins"
            >KONSULTASI GRATIS</span
          >
        </v-btn>
      </div>
    </div>
    <div class="container text-center ctn-bootcamp">
      <h3 class="sub-title-span">
        Bootcamp HiColleagues Pilihan Tepat Akselerasi Skill Digital
      </h3>
      <span style="font-size: 13px"
        >Dapatkan benefit dalam percepatan skill digital melalui intensive
        program Bootcamp HiColleagues</span
      >
      <div v-for="n in benefits" :key="n.id" class="content-card">
        <div class="left-span">
          <img :src="n.image" width="70" height="70" />
        </div>
        <div>
          <div class="title-2" style="text-align: left">{{ n.title }}</div>
          <div class="desc mt-2" style="text-align: left">{{ n.desc }}</div>
        </div>
      </div>
      <h3 class="sub-title-span mt-10">Program Akselarasi Digital</h3>
      <span style="font-size: 13px"
        >Percepat akselerasi skill digital HiColers melalui program Bootcamp
        pilihan!</span
      >
      <div class="card" style="padding-bottom: 60px">
          <div class="items" v-for="(item, idx) in bootcampClass" :key="idx" @click="
        $router.push(`/class/bootcamp/${item.slug_name}`)
        ">
            <div style="height: 160px">
              <img :src="item.small_image_url" alt="" style="width: 100%; height: 100%; border-radius: 20px 20px 0 0" />
            </div>
            <div class="content">
              <div class="category">
                <div style="background-color: #f1f6ff" v-if="item.level">
                  <p>{{ item.level }}</p>
                </div>
                <div style="background-color: #faf6e7" v-if="item.method">
                  <p>{{ toMethode(item.method) }}</p>
                </div>
              </div>
              <h2 style="text-align: left;">{{ item.name }}</h2>
              <div style="height: 60px; margin-bottom: 10px;">
                <p class="description-class">{{ item.description }}</p>
              </div>
              <div style="height: 60px">
                <p style="color: #b80f0a; font-size: 16px; margin: 0; text-align: left;" v-if="item.is_discount">
                  <del>{{ item.price | toCurrency }}</del>
                </p>
                <h3 style="color: #162ca2; margin: 0; text-align: left;" v-if="!item.is_discount">
                  {{ item.price | toCurrency }}
                </h3>
                <h3 style="color: #162ca2; margin: 0; text-align: left;" v-else>
                  {{ toDiscount(item) }}
                </h3>
              </div>
            </div>
            <div class="due-date">
              <DateIc2 />
              <span style="font-size: 15px">{{
        item.registration_close_date | formatDateFull
      }} WIB</span>
            </div>
          </div>
        </div>
      <div class="container bg mt-10">
        <div class="box-content">
          <p class="title" style="font-family: 'Poppins', sans-serif !important;">
            Masih Bingung untuk update skillmu melalui Bootcamp HiColleagues?
          </p>
          <p class="description font-poppins">
            Perdalam minatmu dengan konsultasi bersama HiColleagues
          </p>
        </div>
        <div
          class="d-flex bg-blue justify-center mt-5"
          @click="sendToWa"
        >
          <span class="exhange-voucher" style="font-weight: bolder;">KONSULTASI GRATIS</span>
        </div>
      </div>
      <div class="faqbg">
      <div class="faq">
        <h1>Frequently Asked Question</h1>
        <div class="container">
          <v-expansion-panels flat class="faq-card">
            <v-expansion-panel style="border-radius: 20px; margin-bottom: 10px;" v-for="(item, index) in questions"
              :key="index">
              <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)" style="border-radius: 20px;">
                <h4 style="line-height: 22px">{{ item.title }}</h4>
                <template v-slot:actions>
                  <div class="icon" v-if="item.active" style="margin-right: 20px;">
                    <Arrow />
                  </div>
                  <div v-else style="margin-right: 20px;">
                    <Arrow :down="!item.active" />
                  </div>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="border-radius:0 0 20px 20px;">
                <div v-html="item.description"></div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="btn-stoke-blue cursor-pointer mt-5" @click="$router.push('/faq')" >
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import ArrowLong from '@/assets/icons/ArrowLong.vue';
import Arrow from '@/assets/icons/Arrow.vue';

export default {
  name: "BootcampMobile",
  data() {
    return {
      benefits: [
        {
          title: "Intensive Program",
          desc: "Durasi program 2-3 bulan yang mampu mengakselerasikan skill digital dengan jadwal program yang sesuai kebutuhan peserta & silabus yang ter-update sesuai kebutuhan industri",
          image: "/img/bootcamp/ic_intensive_program.png",
        },
        {
          title: "Competent Trainer",
          desc: "Trainer kompeten dan berpengalaman di bidangnya yang siap menemani belajar dan mempercepat dalam upgrade skill yang diinginkan",
          image: "/img/bootcamp/ic_competent_trainer.png",
        },
        {
          title: "Learning Management System",
          desc: "Didukung Learning Management System (LMS) yang memberikan kemudahan dalam pelatihan seperti Silabus, Akses Materi & Pengumpulan Tugas secara terpusat",
          image: "/img/bootcamp/ic_sertifikat_completion.png",
        },
        {
          title: "E-Certificate",
          desc: "Sertifikat Bootcamp HiColleagues merupakan Bukti peserta telah menyelesaikan program bootcamp & diakui oleh industri  sebagai nilai pendukung dalam berkarir",
          image: "/img/bootcamp/ic_sertifikat_kompetensi.png",
        },
        {
          title: "Job Connector",
          desc: "Update informasi dan perekomendasian peluang kerja di industri nyata bagi alumni peserta Bootcamp",
          image: "/img/bootcamp/ic_job_recommentation.png",
        },
        {
          title: "Installment Option Available",
          desc: "Biaya pelatihan yang terjangkau hingga program pembayaran dengan sistem cicilan untuk mendukung akselerasi skill peserta",
          image: "/img/bootcamp/ic_installment.png",
        },
      ],
      bootcampClass: [],
      questions: []
    };
  },
  components: {
    ArrowLong,
    Arrow
  },
  methods: {
    async getBootcampClass() {
      try {
        const resp = await this.$http.get("/v1/class/category/5");
        this.bootcampClass = resp.data.data.classes;
      } catch (err) {
        console.log(err);
      }
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    toDiscount(item) {
      const total_price = item.price - item.discount;
      if (total_price <= 0) {
        return "FREE";
      } else {
        var formatter = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
        return formatter.format(total_price);
      }
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=BOOTCAMP&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    toMethode(value) {
      if (!value) return;
      if (value.toLowerCase() === "online") {
        return "Daring";
      } else if (value.toLowerCase() === "offline") {
        return "Luring";
      } else {
        return value;
      }
    },
    goToClassBootcamp(slug) {
      this.$router.push("/class/bootcamp/" + slug);
    },
    minimize(text) {
      return text.length > 290 ? text.slice(0, 290) + "..." : text;
    },
    scrollToClass() {
      window.scrollTo(0, 2100);
    },
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
  },
  mounted() {
    this.getBootcampClass();
    this.getFaq()
  },
};
</script>

<style src="./bootcamp.css" scoped></style>