<template>
    <div style="margin-top: 80px;">
        <div class="container">
            <p>ABOUT US</p>
            <h1 style="line-height: 43px;">Bersama HiColleagues <br> #CiptakanMasaDepan Menuju <br>Transformasi Digital
                Berkelanjutan</h1>
        </div>
        <img src="/img/about/main.png" alt="main" class="head-img">
        <div class="container">
            <div style="display: flex; gap: 90px;">
                <p style="font-size: 15px;">HiColleagues merupakan startup edutech terpercaya dan kredibel yang berdiri
                    sejak tahun 2020 di Jakarta, Indonesia. Kami fokus pada bidang IT Services dan Education. Saat ini,
                    teknologi berkembang dan berubah dengan sangat cepat. Salah satu dampaknya adalah hampir setiap
                    sektor perlu beralih dari sistem manual ke sistem digital yang sistematis. Oleh karena itu,
                    HiColleagues menyediakan jasa IT Services serta konsultasi untuk perusahaan dan perorangan agar
                    dapat melakukan penyesuaian teknologi dengan cepat dan efektif.</p>
                <p style="font-size: 15px;">HiColleagues juga menawarkan berbagai pelatihan dan sertifikasi yang dapat
                    diikuti oleh seluruh kalangan masyarakat untuk membantu peningkatan skill “New Skilling, Reskilling,
                    dan Upskilling”, agar tidak kalah bersaing dalam dunia kerja. Peserta akan dilatih oleh trainer yang
                    berkualitas dan berpengalaman di bidangnya. Dengan berbagai produk dan layanan yang ditawarkan,
                    HiColleagues bertujuan untuk memberikan IT Services dan Education yang terbaik, serta menghasilkan
                    alumni pelatihan yang siap berkarir di dunia kerja. Bergabunglah dengan HiColleagues dan jadilah
                    bagian dari transformasi digital yang berkelanjutan!</p>
            </div>

        </div>
        <div class="bg-linear">
            <div class="container">
                <div class="b1" style="padding: 40px 0;">
                    <div class="b2">
                        <h2>Visi</h2>
                        <h4>“Menciptakan generasi baru unggul demi mewujudkan Indonesia Emas 2045”</h4>
                        <h2>Misi</h2>
                        <div v-for="(item, idx) in benefits" :key="idx" class="benefit-list">
                            <div>
                                <complete fill="#162CA2" />
                            </div>
                            <p style="margin: 2px; font-size: 15px;">{{ item }}</p>
                        </div>
                    </div>
                    <div class="b2">
                        <h2>Value</h2>
                        <div class="card-hicol" v-for="(item, idx) in whyHiColl" :key="idx">
                            <div>
                                <img :src="item.img" width="55" alt="Ctn" />
                            </div>
                            <div>
                                <h3 class="mb-3">{{ item.title }}</h3>
                                <p>{{ item.desc }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="b1" style="margin-top: 80px;">
                <div class="b2">
                    <img src="/img/about/m1.png" alt="main">
                </div>
                <div class="b2" style="margin-top: -30px;">
                    <h2 style="font-size: 28px;">Impact</h2>
                    <div v-for="(item, idx) in benefits3" :key="idx" class="benefit-list">
                        <div>
                            <complete fill="#162CA2" />
                        </div>
                        <p style="margin: 2px; font-size: 15px;" v-html="item"></p>
                    </div>
                </div>
            </div>
            <div class="b1" style="margin-top: 80px;">
                <div class="b2" style="margin-top: -30px;">
                    <h2 style="font-size: 28px;">Culture</h2>
                    <div class="card-hicol" v-for="(item, idx) in culture" :key="idx">
                        <div>
                            <img :src="item.img" width="55" alt="Ctn" />
                        </div>
                        <div>
                            <h3 class="mb-3">{{ item.title }}</h3>
                            <p>{{ item.desc }}</p>
                        </div>
                    </div>
                </div>
                <div class="b2">
                    <img src="/img/about/m2.png" alt="main">
                </div>
            </div>
            <div class="b1" style="margin-top: 80px;">
                <div class="b2">
                    <h2 style="font-size: 28px; margin-top: -20px;">Achievement</h2>
                    <p>Sejak berdiri pada tahun 2020, HiColleagues telah menyediakan berbagai pelatihan, sertifikasi,
                        dan layanan IT yang inovatif. Kami berkomitmen untuk menjawab berbagai masalah yang dihadapi
                        customer dan berkontribusi dalam pertumbuhan talenta digital Indonesia yang berkualitas.
                        Beberapa pencapaian HiColleagues adalah:</p>
                    <img src="/img/about/m3.png" alt="main">
                </div>
                <div class="b2">
                    <div v-for="(item, idx) in benefits2" :key="idx" class="benefit-list">
                        <div>
                            <complete fill="#162CA2" />
                        </div>
                        <p style="margin: 2px; font-size: 15px;" v-html="item"></p>
                    </div>
                </div>
            </div>
            <h2 style="font-size: 28px; margin-top: 80px;">Struktur Organisasi HiColleagues</h2>
            <!-- <h2 >Pendiri HiColleagues</h2>
            <div class="box-parent-owner">
                <div class="box-owner">
                    <img src="/img/about/martha.png" style="width: 250px;" alt="Martha">
                    <p class="title">Martha Sutopo</p>
                    <p class="mb-4">Founder & Chief Executive Officer</p>
                    <img src="/img/about/linkedin.png" class="cursor-pointer" @click="directTo('martha')" alt="link">
                </div>
                <div class="box-owner">
                    <img src="/img/about/gilang.png" style="width: 250px;" alt="gilang">
                    <p class="title">Gilang Yudha Prakoso</p>
                    <p class="mb-4">Co-Founder & Chief Commercial Officer</p>
                    <img src="/img/about/linkedin.png" class="cursor-pointer" @click="directTo('gilang')" alt="link">
                </div>
            </div> -->
            <h2 style="margin-top: 60px;">Tim HiColleagues</h2>
            <p>HiColleagues mengedepankan semangat kolaborasi dengan didukung kontribusi talenta-talenta kompeten.
                Positive vibes & growth mindset menjadi pilar mewujudkan profesional tim yang kuat sehingga HiColleagues
                siap melayani sebagai problem solving yang beroirentasi pada produk pelatihan akselerasi digital maupun
                optimasi bisnis mitra perusahaan.</p>
            <h3 style="margin-bottom: 40px;">Bagan Struktur Organisasi HiColleagues</h3>
            <img src="/img/about/hirarki.png" alt="hirarki" style="width: 100%;">
        </div>
        <div style="height: 90px;"></div>
        <div class="footer-head">
            <p>Wujudkan Kolaborasi Menuju Transformasi Digital Berkelanjutan & <br> <b
                    style="font-weight: bold !important;">#CiptakanMasaDepan</b> Gemilang Bersama HiColleagues</p>
            <v-btn depressed color="#FFDE59" class="button-daftar" @click="directTo">
                <span class="text-capitalize font-weight-bold font-poppins">HUBUNGI KAMI</span>
            </v-btn>
        </div>
    </div>
</template>


<script>
import Complete from "@/assets/icons/Complete.vue";

export default {
    name: "DeskAboutUs",
    components: {
        Complete
    },
    data() {
        return {
            benefits: [
                "Menyediakan pelatihan dan sertifikasi yang berkualitas, terjangkau, dan berdampak.",
                "Mengurangi kesenjangan (gap) keterampilan digital yang dibutuhkan dunia pasar kerja dan industri",
                "Mendorong percepatan transformasi digital yang inklusif dan berkelanjutan",
                "Mengembangkan talenta digital yang kompeten dan berkarakter",
                "Menciptakan produk inovasi digital yang kompatibel dan bermanfaat"
            ],
            benefits2: [
                "HiColleagues telah sukses melatih <b style='font-family: Poppins, sans-serif;'>3000+ alumni</b> dengan skill teknologi terkini dan siap bersaing di dunia kerja melalui berbagai program pelatihan dan sertifikasi yang ditawarkan.",
                "HiColleagues sudah membantu lebih dari <b style='font-family: Poppins, sans-serif;'>20+ perusahaan</b> untuk menemukan manpower yang berkualitas dan sesuai dengan kebutuhan perusahaan.",
                "HiColleagues telah menjalin <b style='font-family: Poppins, sans-serif;'>30+ kemitraan</b> dengan perusahaan terkemuka dan institusi pendidikan untuk menghadirkan pelatihan dan sertifikasi berkualitas tinggi.",
                "HiColleagues menerima <b style='font-family: Poppins, sans-serif;'>5.0 bintang pada Google</b>, mencerminkan kepuasan dan kepercayaan yang tinggi dari customer kami.",
            ],
            benefits3: [
                "Meyakini dengan inovasi teknologi dapat memberikan dampak kesejahteraan dan sosial ekonomi bagi Bangsa Indonesia",
                "Menyediakan pelatihan dan layanan teknologi yang dapat diakses seluruh wilayah 3T (Tertinggal, Terdepan, Terluar)",
                "Membuka akses pada pemberdayaan perempuan untuk dapat belajar teknologi dengan kesempatan yang sama",
                "Memberi ruang pada talenta digital perempuan baik sebagai trainer maupun programmer untuk berkontribusi memajukan inovasi teknologi",
                "Mendorong peningkatan pelatihan dan sertifikasi untuk mendapatkan pengakuan kompetensi",
                "Mendorong peningkatan pelatihan dan sertifikasi untuk mendapatkan pengakuan kompetensi"
            ],
            culture: [
                {
                    img: "/img/about/c1.png",
                    title: "Continuous Innovation",
                    desc: "Kami mendorong inovasi berkelanjutan, memungkinkan setiap individu dapat menyalurkan ide kreatif yang relevan dengan kebutuhan terkini",
                },
                {
                    img: "/img/about/c2.png",
                    title: "Collaborative",
                    desc: "Kami mengutamakan kerja sama dan kolaborasi antar tim agar setiap individu dapat berkontribusi maksimal dan menghasilkan solusi yang dapat meningkatkan kinerja HiColleagues",
                },
                {
                    img: "/img/about/c3.png",
                    title: "Integrity & Honesty",
                    desc: "Kami menjunjung tinggi integritas dan kejujuran dalam setiap hal, memastikan transparansi dan kepercayaan dalam setiap langkah kami",
                },
                {
                    img: "/img/about/c4.png",
                    title: "Focus on Customer",
                    desc: "Kami fokus untuk memenuhi kebutuhan customer dengan menawarkan berbagai solusi yang dapat memberikan dampak positif pada seluruh customer",
                },
                {
                    img: "/img/about/c5.png",
                    title: "Growth Mindset",
                    desc: "Kami senantiasa membangun pola pikir inovatif, dinamis, dan tumbuh untuk mendorong transformasi digital yang berkelanjutan",
                },
                {
                    img: "/img/about/c5.png",
                    title: "Lifelong Learning",
                    desc: "Kami membangun kesadaran sejati untuk terus menerus belajar dengan menerapkan konsep learn, unlearn, dan relearn",
                },
            ],
            whyHiColl: [
                {
                    img: "/img/about/c1.png",
                    title: "People",
                    desc: "Berupaya membangun talenta digital yang kompeten dan adaptif demi kemajuan Bangsa Indonesia",
                },
                {
                    img: "/img/about/c2.png",
                    title: "Passion",
                    desc: "Antusias dan bersemangat dalam menjalankan pekerjaan sebagai rasa tanggungjawab",
                },
                {
                    img: "/img/about/c3.png",
                    title: "Purpose",
                    desc: "Memiliki arah tujuan yang jelas untuk menavigasi gerak langkah perusahaan menjadi yang terbaik",
                },
                {
                    img: "/img/about/c4.png",
                    title: "Progress",
                    desc: "Terbuka pada perubahan, memiliki pola pikir tumbuh (growth mindset), dan mengembangkan potensi diri secara terus terus-menerus",
                },
                {
                    img: "/img/about/c5.png",
                    title: "Persistent",
                    desc: "Selalu gigih dan bersungguh-sungguh dalam mengembangkan produk dan layanan dengan standar yang berkualitas",
                },
            ]
        }
    },
    methods: {
        directTo(ctn) {
            if (ctn === "martha") {
                window.open('https://www.linkedin.com/in/martha-sutopo-47339475/')
            } else if (ctn === "gilang") {
                window.open('https://www.linkedin.com/in/gilang-yudha-prakoso-880302110/')
            } else {
                window.open("https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya");
            }
        }
    }
}
</script>

<style src="./aboutUs.css" scoped></style>