<template>

    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.9998 3.4375H16.3123V2.75C16.3123 2.56766 16.2399 2.3928 16.111 2.26386C15.982 2.13493 15.8072 2.0625 15.6248 2.0625C15.4425 2.0625 15.2676 2.13493 15.1387 2.26386C15.0098 2.3928 14.9373 2.56766 14.9373 2.75V3.4375H8.24567V2.75C8.20683 2.60338 8.1206 2.47371 8.0004 2.3812C7.88019 2.28869 7.73277 2.23853 7.58109 2.23853C7.42941 2.23853 7.28198 2.28869 7.16178 2.3812C7.04158 2.47371 6.95534 2.60338 6.9165 2.75V3.4375H5.99984C5.33127 3.4375 4.69009 3.70309 4.21734 4.17583C3.74459 4.64858 3.479 5.28977 3.479 5.95833V16.0417C3.479 16.7102 3.74459 17.3514 4.21734 17.8242C4.69009 18.2969 5.33127 18.5625 5.99984 18.5625H16.9998C17.6684 18.5625 18.3096 18.2969 18.7823 17.8242C19.2551 17.3514 19.5207 16.7102 19.5207 16.0417V5.95833C19.5207 5.28977 19.2551 4.64858 18.7823 4.17583C18.3096 3.70309 17.6684 3.4375 16.9998 3.4375ZM18.1457 16.0417C18.1457 16.3456 18.025 16.637 17.8101 16.8519C17.5952 17.0668 17.3037 17.1875 16.9998 17.1875H5.99984C5.69594 17.1875 5.4045 17.0668 5.18961 16.8519C4.97473 16.637 4.854 16.3456 4.854 16.0417V8.9375H18.1457V16.0417ZM4.854 7.5625V5.95833C4.854 5.65444 4.97473 5.36299 5.18961 5.14811C5.4045 4.93322 5.69594 4.8125 5.99984 4.8125H16.9998C17.3037 4.8125 17.5952 4.93322 17.8101 5.14811C18.025 5.36299 18.1457 5.65444 18.1457 5.95833V7.5625H4.854Z"
            fill="#36373F" />
    </svg>

</template>

<script>
export default {
    name: "Calendar"
}
</script>

<style></style>