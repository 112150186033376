<template>
  <div :style="`color: ${fill}`">
    
<svg :width="width"
:height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.08986 10.1479C9.07486 10.1479 9.05236 10.1479 9.03736 10.1479C9.01486 10.1479 8.98486 10.1479 8.96236 10.1479C7.25986 10.0954 5.98486 8.76785 5.98486 7.13285C5.98486 5.46785 7.34236 4.11035 9.00736 4.11035C10.6724 4.11035 12.0299 5.46785 12.0299 7.13285C12.0224 8.77535 10.7399 10.0954 9.11236 10.1479C9.09736 10.1479 9.09736 10.1479 9.08986 10.1479ZM8.99986 5.22785C7.94986 5.22785 7.10236 6.08285 7.10236 7.12535C7.10236 8.15285 7.90486 8.98535 8.92486 9.02285C8.94736 9.01535 9.02236 9.01535 9.09736 9.02285C10.1024 8.97035 10.8899 8.14535 10.8974 7.12535C10.8974 6.08285 10.0499 5.22785 8.99986 5.22785Z" fill="#7B7E8C"/>
<path d="M8.99963 17.0627C6.98213 17.0627 5.05463 16.3127 3.56213 14.9477C3.42713 14.8277 3.36713 14.6477 3.38213 14.4752C3.47963 13.5827 4.03463 12.7502 4.95713 12.1352C7.19213 10.6502 10.8146 10.6502 13.0421 12.1352C13.9646 12.7577 14.5196 13.5827 14.6171 14.4752C14.6396 14.6552 14.5721 14.8277 14.4371 14.9477C12.9446 16.3127 11.0171 17.0627 8.99963 17.0627ZM4.55963 14.3252C5.80463 15.3677 7.37213 15.9377 8.99963 15.9377C10.6271 15.9377 12.1946 15.3677 13.4396 14.3252C13.3046 13.8677 12.9446 13.4252 12.4121 13.0652C10.5671 11.8352 7.43963 11.8352 5.57963 13.0652C5.04713 13.4252 4.69463 13.8677 4.55963 14.3252Z" fill="#7B7E8C"/>
<path d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z" fill="#7B7E8C"/>
</svg>

  </div>
</template>
<script>
export default {
  name: "SepakerIcon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
