<template>
    <div style="margin-top: 20px;">
        <div class="container">
            <div class="navigation">
                <span @click="$router.push('/alumni-stories')" class="nav-active font-poppins">Cerita Alumni</span>
                <Arrow :width="20" direct="left" />
                <span class="font-poppins">Detail Cerita Alumni</span>
            </div>
            <img loading="lazy" :src="story.image_url" class="img-main-desktop" />
            <h2>{{ story.title }}</h2>
            <div>
                <div style="margin-bottom: 20px">
                    <p>{{ formatDate(story.date_release) }} WIB</p>
                    <div style="display: flex; gap: 10px; align-items: center;">
                        <hi-col />
                        <span>Tim HiColleagues</span>
                    </div>
                </div>
                <div
                    style="display: flex; gap: 10px; align-items: flex-start; padding: 10px 0; border-top: 1px solid #D3D6E1; border-bottom: 1px solid #D3D6E1; margin-bottom: 20px;">
                    <span @click="onShare('ld')">
                        <linkedin />
                    </span>
                    <span @click="onShare('fc')">
                        <facebook />
                    </span>
                    <span @click="onShare('ig')">
                        <instagram />
                    </span>
                    <span @click="onShare('wa')">
                        <whatsapp />
                    </span>
                    <span @click="onShare('cp')">
                        <copy />
                    </span>
                </div>
            </div>
            <div class="text-left mt-5" v-html="story.body"></div>
        </div>
        <v-card elevation="0" class="call-center-container">
            <v-row style="padding: 20px 0px">
                <v-col cols="12">
                    <div class="call-center-title">
                        Wujudkan Kolaborasi Bersinergi untuk Optimasi Performa Bisnis dalam Transformasi Digital
                        Berkelanjutan
                    </div>
                    <div class="call-center-desc">
                        Dapatkan berbagai informasi yang kamu butuhkan melalui layanan konsultasi 24/7 Customer Service
                        HiColleagues
                    </div>
                </v-col>
                <v-col cols="12" class="call-center-button" @click="sendWa">
                    <v-btn class="btn" elevation="0"> HUBUNGI KAMI </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment';

import Arrow from "@/assets/icons/Arrow.vue";
import Facebook from '@/assets/icons/blog/Facebook.vue';
import Linkedin from '@/assets/icons/blog/Linkedin.vue';
import Copy from '@/assets/icons/blog/Copy.vue';
import HiCol from '@/assets/icons/blog/HiCol.vue';
import Instagram from '@/assets/icons/blog/Instagram.vue';
import Whatsapp from '@/assets/icons/blog/Whatsapp.vue';

export default {
    name: "MobileDetailAlumniStories",
    components: {
        Arrow,
        Facebook,
        Copy,
        Linkedin,
        HiCol,
        Instagram,
        Whatsapp
    },
    data() {
        return {
            story: {
                image_url: "",
                title: "",
                caption: "",
                body: "",
                date_release: ""
            }
        }
    },
    watch: {
        show_alert() {
            if (!this.show_alert) return;
            setTimeout(() => {
            this.show_alert = false;
            }, 3000);
        },
    },
    methods: {
        sendWa() {
            window.open("https://wa.me/6281234567890", "_blank");
        },
        formatDate(date) {
            return moment(date).format("dddd, DD MMMM YYYY - hh:mm");
        },
        onShare(type) {
            let url = ""
            if (type === 'ld') url = `https://www.linkedin.com/company/hicolleagues/?originalSubdomain=id`;
            else if (type === 'fc') url = `https://www.facebook.com/hicolleagues/`;
            else if (type === 'ig') url = `https://www.instagram.com/hicolleagues/`
            else if (type === 'wa') url = "https://wa.me/6281234567890"
            else if (type === 'cp') {
                this.onCopyUrl()
                return
            }
            window.open(url);
        },
        onCopyUrl() {
            const clipboardData =
                event.clipboardData ||
                window.clipboardData ||
                event.originalEvent?.clipboardData ||
                navigator.clipboard;
            clipboardData.writeText(window.location.href);
            this.show_alert = true;
        },
        async getAlumniStoriesDetail() {
            try {
                const resp = await this.$http.get(`/v1/alumni-stories/${this.$route.params.id}`)
                this.story = resp.data.data.stories
            } catch (err) {
                console.log(err);
            }
        }
    },
    mounted() {
        this.getAlumniStoriesDetail()
    }
}
</script>
<style scoped src="./detail.css"></style>