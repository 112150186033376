import { render, staticRenderFns } from "./KampusMerdeka.vue?vue&type=template&id=5df2bccb&scoped=true&"
import script from "./KampusMerdeka.vue?vue&type=script&lang=js&"
export * from "./KampusMerdeka.vue?vue&type=script&lang=js&"
import style0 from "./kampusMerdeka.css?vue&type=style&index=0&id=5df2bccb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df2bccb",
  null
  
)

export default component.exports