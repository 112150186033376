<template>
    <!-- Testimoni -->
    <div class="testimony">
        <div class="content">
            <div class="header text-center">
                <h1>{{ title }}</h1>
                <div class="cards">
                    <div class="testimony-card">
                    <div class="testimony-container">
                        <div class="col-lg-4" v-for="(data, index) in testimonies" :key="index">
                            <div class="card">
                                <div>
                                    <div class="testimoni text-center">{{ data.feedback }}</div>
                                </div>
                                <div class="profile mt-5 d-flex" style="align-items: center">
                                    <div class="avatar">
                                        <img :src="$utils.loadImage(data.image_url)" alt="" width="60" height="60"
                                            class="avatar-image" />
                                    </div>
                                    <div class="testi-profile ml-5">
                                        <div class="name">{{ data.name }}</div>
                                        <div class="description">{{ data.job }}, {{ data.place }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <div style="display: flex; align-items: center;justify-content: flex-end;gap: 5px;margin-top: 15px;">
                        <div class="slider">
                            <div class="indicator" :style="{width: `${sliderIndicatorTesti}%`,transform: `translateX(${indicatorValueTesti}%)`,transition: `transform 0.5s`,}"></div>
                        </div>
                        <div>
                            <img style="cursor: pointer" src="/img/other/corporate-arrow-left.png" alt="Button left"
                                @click="scrollLeftTestimony" class="arrow-button" />
                            <img style="cursor: pointer" src="/img/other/corporate-arrow-right.png" alt="Button right"
                                @click="scrollRightTestimony" class="arrow-button" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TestimonyComponent",
    props: {
        testimonies: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            sliderIndicatorTesti: 0,
            indicatorValueTesti: 0
        }
    },
    watch: {
        testimonies() {
            this.sliderIndicatorTesti = (1 / (this.testimonies.length / 3)) * 100;
            if (this.sliderIndicatorTesti >= 100) {
                this.sliderIndicatorTesti = 100
            }
        }
    },
    methods: {
        scrollRightTestimony() {
            const container = this.$el.querySelector(".testimony-container");
            const cardWidth = this.$el.querySelector(".testimony-card").offsetWidth;
            container.scrollLeft += cardWidth * 2.09;
            this.indicatorValueTesti += this.sliderIndicatorTesti + 50;
            if (this.indicatorValueTesti > 100) {
                this.indicatorValueTesti = 100
            }
        },
        scrollLeftTestimony() {
            const container = this.$el.querySelector(".testimony-container");
            const cardWidth = this.$el.querySelector(".testimony-card").offsetWidth;
            container.scrollLeft -= cardWidth * 2.09;
            this.indicatorValueTesti -= this.indicatorValueTesti;
            if (this.indicatorValueTesti < 0) {
                this.indicatorValueTesti= 0
            }
        },
    }
}
</script>

<style src="./css/testimony.css" scoped></style>