<template>
    
<svg :width="width" :height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25.0002" r="25" fill="#FFDE59"/>
<path d="M34.3875 17.3127L26.9625 13.0252C25.75 12.3252 24.25 12.3252 23.025 13.0252L15.6125 17.3127C14.4 18.0127 13.65 19.3127 13.65 20.7252V29.2752C13.65 30.6752 14.4 31.9752 15.6125 32.6877L23.0375 36.9752C24.25 37.6752 25.75 37.6752 26.975 36.9752L34.4 32.6877C35.6125 31.9877 36.3625 30.6877 36.3625 29.2752V20.7252C36.35 19.3127 35.6 18.0252 34.3875 17.3127ZM25 19.1752C26.6125 19.1752 27.9125 20.4752 27.9125 22.0877C27.9125 23.7002 26.6125 25.0002 25 25.0002C23.3875 25.0002 22.0875 23.7002 22.0875 22.0877C22.0875 20.4877 23.3875 19.1752 25 19.1752ZM28.35 30.8252H21.65C20.6375 30.8252 20.05 29.7002 20.6125 28.8627C21.4625 27.6002 23.1125 26.7502 25 26.7502C26.8875 26.7502 28.5375 27.6002 29.3875 28.8627C29.95 29.6877 29.35 30.8252 28.35 30.8252Z" fill="#36373F"/>
</svg>

</template>
<script>
export default {
    props: {
            width: {
                type: String,
                default: "45"
            },
            height: {
                type: String,
                default: "45" 
            }
        }
}
</script>