<template>
  <div>
    <div style="background: #f1f6ff; padding: 80px 16px">
      <div class="text-center">
        <h2 class="sub-title-span">JELAJAHI KELAS</h2>
        <span style="margin-top: 20px; font-size: 14px">Temukan kelas terbaik yang cocok untuk meningkatkan kompetensi mu di era teknologi digital. Mulai dari sekarang!</span>
      </div>

      <div class="scrolling-wrapper d-flex">
        <div class="d-flex justify-center card" style="margin-top: 41px">
          <div class="inactive" @click="techMenu" :class="{ active: layout === 'technology' }">
            <span>TEKNOLOGI</span>
          </div>
          <div class="inactive" @click="languageMenu" :class="{ active: layout === 'language' }">
            <span>BAHASA</span>
          </div>
          <div class="inactive" @click="softSkillMenu" :class="{ active: layout === 'soft-skill' }">
            <span>SOFT SKILL</span>
          </div>
        </div>
      </div>
    </div>

    <TechnologyCategory v-if="layout == 'technology'" class="bg-white-radius" />
    <LanguageCategory v-if="layout == 'language'" class="bg-white-radius" />
    <SoftSkillCategory v-if="layout == 'soft-skill'" class="bg-white-radius" />
  </div>
</template>

<script>
import TechnologyCategory from '@/components/public-training/mobile/TechnologyCategory';
// import BootcampCategory from "@/components/public-training/mobile/BootcampCategory";
import LanguageCategory from '@/components/public-training/mobile/LanguageCategory';
import SoftSkillCategory from '@/components/public-training/mobile/SoftSkillCategory';
export default {
  components: {
    TechnologyCategory,
    // BootcampCategory,
    LanguageCategory,
    SoftSkillCategory,
  },
  data() {
    return {
      layout: 'technology',
    };
  },
  methods: {
    techMenu() {
      this.layout = 'technology';
    },
    languageMenu() {
      this.layout = 'language';
    },
    softSkillMenu() {
      this.layout = 'soft-skill';
    },
  },
};
</script>

<style src="./css/publicTraining.css" scoped></style>
