<template>
    <div class="container-size">
        <div class="text-center mb-10" style="margin-top:150px"><img src="/img/other/ic_success.png" height="300"></div>
        <div class="text-center font-weight-bold mt-10"><span style="font-size:2.0rem">Berhasil!!</span></div>
        <div class="text-center mt-5 mb-10"><span style="font-size:1.0rem">Anda telah terdaftar. Jika ada pertanyaan lebih lanjut, kamu dapat menghubungi kami melalui:<br><b>email hello@hicolleagues.com</b><br><b>Whatsapp: +62 821 2727 4632</b></span></div>
        <div class="text-center mt-5 mb-15">
            <v-btn depressed color="#000080" class="pa-5" @click="goToHomePage">
                <span class="text-capitalize white--text font-weight-bold">Kembali ke halaman beranda</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToHomePage() {
            window.location.href = '/';
        }
    }
}
</script>