<template>
    <event-detail-mobile v-if="isMobile" />
    <event-detail-dekstop v-else />
</template>
<script>
  import EventDetailDekstop from "@/components/event/desktop/EventDetail.vue";
  import EventDetailMobile from "@/components/event/mobile/EventDetail.vue";

export default {
    name:"EventDetailPage",
    components:{
        EventDetailDekstop,
        EventDetailMobile
    },
    data() {
      return {
        isMobile: false,
      };
    },
    methods: {
      handleView() {
        this.isMobile = screen.width <= 990;
      },
    },
    mounted() {
      this.$gtag.pageview(this.$route);
      this.$utils.scrollTop()
    },
    beforeMount() {
      this.handleView();
    }
}
</script>