<template>
  <div class="background-loading">
    <div
      :style="`background-image: url(/loading/step-${state}.svg)`"
      class="image"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: 0,
      out: 0,
      interval: null,
    };
  },
  methods: {
    looping() {
      this.interval = setInterval(() => {
        this.state++;
        if (this.state > 9) {
          this.state = 9;
          this.out++;
          if (this.out === 1) {
            setTimeout(() => {
              this.state = 0;
              this.out = 0;
            }, 1000);
          }
        }
      }, 200);
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted() {
    this.looping();
  },
};
</script>

<style scoped>
.background-loading {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.4);
}
.image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  /* transition: background-image 1s; */
  width: 200px;
  height: 200px;
}
@media only screen and (max-width: 990px) {
  .image {
    width: 120px;
    height: 120px;
  }
}
</style>
