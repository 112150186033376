<template>
    <div>
        <DekstopContactUs v-if="!isMobile" />
        <MobileContactUs v-if="isMobile" />
    </div>
</template>

<script>
import DekstopContactUs from "@/components/contact-us/desktop/ContactUs.vue"
import MobileContactUs from "@/components/contact-us/mobile/ContactUs.vue"

export default {
    components: {
        DekstopContactUs,
        MobileContactUs,
    },
    data() {
        return {
            isMobile: false,
        };
    },
    methods: {
        handleView() {
            this.isMobile = screen.width <= 990;
        },
    },
    mounted() {
        this.$gtag.pageview(this.$route);
        this.$utils.scrollTop()
    },
    beforeMount() {
        this.handleView();
    },
};
</script>