<template>
    
<svg :width="width" :height="height"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.69 11.7098C21.6521 11.6206 21.5978 11.5392 21.53 11.4698L14.53 4.46985C14.3878 4.33737 14.1998 4.26524 14.0055 4.26867C13.8112 4.2721 13.6258 4.35081 13.4884 4.48822C13.351 4.62564 13.2723 4.81102 13.2688 5.00532C13.2654 5.19963 13.3375 5.38767 13.47 5.52985L19.19 11.2498H3C2.80109 11.2498 2.61032 11.3289 2.46967 11.4695C2.32902 11.6102 2.25 11.8009 2.25 11.9998C2.25 12.1988 2.32902 12.3895 2.46967 12.5302C2.61032 12.6708 2.80109 12.7498 3 12.7498H19.19L13.47 18.4698C13.3295 18.6105 13.2507 18.8011 13.2507 18.9998C13.2507 19.1986 13.3295 19.3892 13.47 19.5298C13.6106 19.6703 13.8012 19.7492 14 19.7492C14.1988 19.7492 14.3894 19.6703 14.53 19.5298L21.53 12.5298C21.5978 12.4605 21.6521 12.3791 21.69 12.2898C21.7296 12.1983 21.7501 12.0996 21.7501 11.9998C21.7501 11.9001 21.7296 11.8014 21.69 11.7098Z" :fill="fill"/>
</svg>

</template>

<script>
export default {
  name: "ArrowLong",
  props: {
    width: {
      type: String,
      default: "24",
    },
    height: {
      type: String,
      default: "24",
    },
    fill: {
        type: String,
        default: "#162CA2"
    }
  },
};
</script>
