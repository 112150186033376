import { render, staticRenderFns } from "./AccelerationProgram.vue?vue&type=template&id=34bd729c&scoped=true&"
import script from "./AccelerationProgram.vue?vue&type=script&lang=js&"
export * from "./AccelerationProgram.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34bd729c",
  null
  
)

export default component.exports