<template>
<svg :width="width" :height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" rx="25" fill="#FFDE59"/>
    <path d="M35 17.8497V29.7397C35 30.7097 34.21 31.5997 33.24 31.7197L32.93 31.7597C31.29 31.9797 28.98 32.6597 27.12 33.4397C26.47 33.7097 25.75 33.2197 25.75 32.5097V18.5997C25.75 18.2297 25.96 17.8897 26.29 17.7097C28.12 16.7197 30.89 15.8397 32.77 15.6797H32.83C34.03 15.6797 35 16.6497 35 17.8497Z" fill="#36373F"/>
    <path d="M23.7102 17.7097C21.8802 16.7197 19.1102 15.8397 17.2302 15.6797H17.1602C15.9602 15.6797 14.9902 16.6497 14.9902 17.8497V29.7397C14.9902 30.7097 15.7802 31.5997 16.7502 31.7197L17.0602 31.7597C18.7002 31.9797 21.0102 32.6597 22.8702 33.4397C23.5202 33.7097 24.2402 33.2197 24.2402 32.5097V18.5997C24.2402 18.2197 24.0402 17.8897 23.7102 17.7097ZM18.0002 20.7397H20.2502C20.6602 20.7397 21.0002 21.0797 21.0002 21.4897C21.0002 21.9097 20.6602 22.2397 20.2502 22.2397H18.0002C17.5902 22.2397 17.2502 21.9097 17.2502 21.4897C17.2502 21.0797 17.5902 20.7397 18.0002 20.7397ZM21.0002 25.2397H18.0002C17.5902 25.2397 17.2502 24.9097 17.2502 24.4897C17.2502 24.0797 17.5902 23.7397 18.0002 23.7397H21.0002C21.4102 23.7397 21.7502 24.0797 21.7502 24.4897C21.7502 24.9097 21.4102 25.2397 21.0002 25.2397Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
  name: "BootcampIcon",
  props: {
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    }
  },
};
</script>