<template>
    <div>
        <MobileKampusMerdeka v-if="isMobile" />
        <DesktopKampusMerdeka v-if="!isMobile" />
    </div>
</template>

<script>
import DesktopKampusMerdeka from '@/components/kampus-merdeka/desktop/KampusMerdeka'
import MobileKampusMerdeka from '@/components/kampus-merdeka/mobile/KampusMerdeka'

export default {
  components: {
    MobileKampusMerdeka,
    DesktopKampusMerdeka,
  },
  metaInfo() {
      const thisPath = window.location.href
      return {
        title: this.$route.meta.title,
        htmlAttrs: {
          lang: "id",
          amp: true,
        },
        meta: [
          { charset: "utf-8" },
          { name: "viewport", content: "width=device-width, initial-scale=1" },
          { name:"keywords", content: "Kampus Merdeka,MSIB HiColleagues, MSIB, Magang HiColleagues"},
          { name: "description", content: this.$route.meta.description},
        ],
        link: [
          {
            rel: 'canonical',
            href: thisPath
          }
        ],
        script: [
          {
            type: 'application/ld+json',
            json: {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": this.$route.meta.title,
              "url": thisPath,
              "potentialAction": {
                "@type": "SearchAction",
                "target": thisPath
              }
            }
          }
        ]
      };
    },
  data(){
      return{
          isMobile: false
      }
  },
  methods: {
      handleView() {
          this.isMobile = window.innerWidth <= 990
      }
  },
  mounted() {
    this.$utils.scrollTop()
  },
  beforeMount() {
      this.handleView()
  }
}
</script>