<template>
    <button :class="`${!disabled ? 'button' : 'button-disabled'} font-poppins`" :style="`max-width: ${width}; height: ${height}; width: 100%;`" @click="onSubmit" :title="title">
        <span v-if="icon" style="margin-right: 10px;">{{ icon }}</span>
        {{ name }}
      </button>
</template>

<script>
export default {
    name: "ButtonComponent",
    props: {
        icon: {
            type: [String, HTMLElement]
        },
        name: {
            type: String,
            default: ""
        },
        width: {
            type: String,
            default: "150px"
        },
        height: {
            type: String,
            default: "45px"
        },
        param: {
            type: [String, Number]
        },
        title: {
          type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
      onSubmit() {
        if (this.disabled) return
        this.$emit('click', this.param)
      }
    }
}
</script>

<style scoped>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: bold;
  cursor: pointer;
  font-size: 17px;
  color: #575966;
  border: 1px solid #ACAFBF;
}
.button:hover {
    background-color: #F1F6FF;
}
.button:active {
    border: 1px solid #162CA2;
    background-color: #F1F6FF;
    color: #162CA2;
}
.button-disabled {
  background-color: #ACAFBF !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 12px;
  font-weight: 400 !important;
  cursor: pointer;
  font-size: 17px;
  color: white;
}

.button-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  font-weight: 400 !important;
  cursor: pointer;
  font-size: 17px;
  border: 1px solid #7B7E8C;
}
.button-outline:hover {
    background-color: #E5C750;
}
.button-outline:active {
    background-color: #CCB147;
}
.button-outline-disabled {
  border: 1px solid #D3D6E1;
  color: #ACAFBF;
}
</style>