<template>
  <div :style="`color: ${fill}`">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.14681 13H5.98014C5.68099 13 5.43848 13.2425 5.43848 13.5417V14.625C5.43848 14.9242 5.68099 15.1667 5.98014 15.1667H8.14681C8.44596 15.1667 8.68848 14.9242 8.68848 14.625V13.5417C8.68848 13.2425 8.44596 13 8.14681 13Z"
        fill="currentColor"
      />
      <path
        d="M14.0833 13H11.9167C11.6175 13 11.375 13.2425 11.375 13.5417V14.625C11.375 14.9242 11.6175 15.1667 11.9167 15.1667H14.0833C14.3825 15.1667 14.625 14.9242 14.625 14.625V13.5417C14.625 13.2425 14.3825 13 14.0833 13Z"
        fill="currentColor"
      />
      <path
        d="M20.0306 13H17.8639C17.5648 13 17.3223 13.2425 17.3223 13.5417V14.625C17.3223 14.9242 17.5648 15.1667 17.8639 15.1667H20.0306C20.3298 15.1667 20.5723 14.9242 20.5723 14.625V13.5417C20.5723 13.2425 20.3298 13 20.0306 13Z"
        fill="currentColor"
      />
      <path
        d="M8.14681 16.9219H5.98014C5.68099 16.9219 5.43848 17.1644 5.43848 17.4635V18.5469C5.43848 18.846 5.68099 19.0885 5.98014 19.0885H8.14681C8.44596 19.0885 8.68848 18.846 8.68848 18.5469V17.4635C8.68848 17.1644 8.44596 16.9219 8.14681 16.9219Z"
        fill="currentColor"
      />
      <path
        d="M14.0833 16.9219H11.9167C11.6175 16.9219 11.375 17.1644 11.375 17.4635V18.5469C11.375 18.846 11.6175 19.0885 11.9167 19.0885H14.0833C14.3825 19.0885 14.625 18.846 14.625 18.5469V17.4635C14.625 17.1644 14.3825 16.9219 14.0833 16.9219Z"
        fill="currentColor"
      />
      <path
        d="M20.0306 16.9219H17.8639C17.5648 16.9219 17.3223 17.1644 17.3223 17.4635V18.5469C17.3223 18.846 17.5648 19.0885 17.8639 19.0885H20.0306C20.3298 19.0885 20.5723 18.846 20.5723 18.5469V17.4635C20.5723 17.1644 20.3298 16.9219 20.0306 16.9219Z"
        fill="currentColor"
      />
      <path
        d="M6.03418 3.63005V5.69922C6.03418 6.14795 6.39795 6.51172 6.84668 6.51172C7.29541 6.51172 7.65918 6.14795 7.65918 5.69922V3.63005C7.65918 3.18132 7.29541 2.81755 6.84668 2.81755C6.39795 2.81755 6.03418 3.18132 6.03418 3.63005Z"
        fill="currentColor"
      />
      <path
        d="M18.2974 3.63005V5.69922C18.2974 6.14795 18.6611 6.51172 19.1099 6.51172C19.5586 6.51172 19.9224 6.14795 19.9224 5.69922V3.63005C19.9224 3.18132 19.5586 2.81755 19.1099 2.81755C18.6611 2.81755 18.2974 3.18132 18.2974 3.63005Z"
        fill="currentColor"
      />
      <path
        d="M21.1252 4.21484C20.9815 4.21484 20.8438 4.27191 20.7422 4.37349C20.6406 4.47508 20.5835 4.61285 20.5835 4.75651V5.60151C20.5522 5.96347 20.3862 6.30052 20.1185 6.54609C19.8507 6.79165 19.5006 6.92789 19.1373 6.92789C18.774 6.92789 18.4239 6.79165 18.1561 6.54609C17.8883 6.30052 17.7224 5.96347 17.691 5.60151V4.76734C17.691 4.62368 17.634 4.48591 17.5324 4.38433C17.4308 4.28275 17.293 4.22568 17.1494 4.22568H8.82936C8.68657 4.22845 8.5504 4.28641 8.44942 4.3874C8.34843 4.48838 8.29047 4.62455 8.2877 4.76734V5.62318C8.23457 5.96658 8.06003 6.27953 7.79578 6.50517C7.53153 6.73082 7.1951 6.8542 6.84762 6.85289C6.50014 6.85158 6.16465 6.72566 5.90211 6.49803C5.63957 6.27039 5.4674 5.95613 5.41686 5.61234V4.76734C5.41834 4.69581 5.40534 4.62471 5.37864 4.55832C5.35194 4.49194 5.31209 4.43164 5.2615 4.38104C5.2109 4.33045 5.1506 4.2906 5.08422 4.2639C5.01783 4.2372 4.94673 4.2242 4.8752 4.22568C4.09548 4.23706 3.35156 4.55481 2.80422 5.11026C2.25689 5.6657 1.95011 6.41421 1.9502 7.19401V20.194C1.95007 20.9748 2.2565 21.7245 2.80354 22.2817C3.35058 22.8389 4.0945 23.159 4.8752 23.1732H21.1252C21.5164 23.1732 21.9038 23.0961 22.2653 22.9464C22.6267 22.7967 22.9551 22.5772 23.2318 22.3006C23.5084 22.024 23.7279 21.6955 23.8776 21.3341C24.0273 20.9726 24.1044 20.5852 24.1044 20.194V7.19401C24.1044 6.80278 24.0273 6.41538 23.8776 6.05393C23.7279 5.69248 23.5084 5.36406 23.2318 5.08742C22.9551 4.81078 22.6267 4.59134 22.2653 4.44162C21.9038 4.2919 21.5164 4.21484 21.1252 4.21484ZM22.4794 20.194C22.4794 20.5532 22.3367 20.8976 22.0827 21.1516C21.8288 21.4055 21.4843 21.5482 21.1252 21.5482H4.8752C4.51605 21.5482 4.17161 21.4055 3.91765 21.1516C3.6637 20.8976 3.52103 20.5532 3.52103 20.194V10.444H22.4794V20.194Z"
        fill="currentColor"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: "DateIcon",
  props: {
    width: {
      type: Number,
      default: 26,
    },
    height: {
      type: Number,
      default: 26,
    },
    fill: {
      type: String,
      default: "#36373F",
    },
  },
};
</script>
