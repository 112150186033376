import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import moment from "moment";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import utils from "./utils/utils";
// import setHeader from '@/utils/auth.js'
Vue.config.productionTip = false;

Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_API_CORE_URL,
});
Vue.prototype.$crm_http = axios.create({
  baseURL: process.env.VUE_APP_API_DASHBOARD_URL,
});


// if(localStorage.getItem("user")) {
//   var data = JSON.parse(localStorage.getItem("user"))
//   setHeader(data.token)
// } else {
//   setHeader(false)
// }

const minimizeText = (text, limit = 260) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    return text.slice(0, limit) + (text.length > limit ? "..." : "");
  }
  return text;
};

Vue.prototype.$minimizeText = minimizeText;
Vue.prototype.$utils = utils;

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

Vue.use(VueGtag, {
  config: { id: "G-2995Z51SVV" },
}, router);

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(value);
});

Vue.filter("toMethode", function (value) {
  if (!value) return;
  if (value.toLowerCase() === "online") {
    return "Daring (via Zoom)";
  } else if (value.toLowerCase() === "offline") {
    return "Luring";
  } else {
    return value;
  }
});

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD MMM YYYY");
  }
});

Vue.filter("formatDateFull", function (value) {
  if (value) {
    return moment(String(value)).format("DD MMM YYYY hh:mm");
  }
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");
