<template>
    <div class="container-size mb-10">
        <v-container>
            <v-row>
                <v-col class="body-privacy">
                    <h1>Kebijakan Privasi</h1>
                    <div v-for="(section, index) in privacyPolicySections" :key="index">
                        <h2>{{ section.title }}</h2>
                        <div v-html="section.description"></div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            privacyPolicySections: [],
        };
    },
    methods: {
        async fetchPrivacyPolicy() {
            try {
                const response = await this.$http.get("/v1/privacy-policy/kebijakan-privasi");
                const responseData = response.data.data;
                const content = responseData.privacy_policy.content
                if (content) {
                    this.privacyPolicySections = content;
                } else {
                    throw new Error("Invalid privacy policy content");
                }
            } catch (error) {
                console.error("Error fetching privacy policy:", error);
            }
        },
    },
    mounted() {
        this.$gtag.pageview(this.$route);
        this.fetchPrivacyPolicy();
    },
};
</script>

<style>
.body-privacy {
    margin-top: 5px;
}

p {
    margin-bottom: 30px;
}

h1,
h2 {
    margin-top: 30px;
    margin-bottom: 30px;
}

@media screen and (max-width: 900px) {
    .body-privacy {
        margin-top: 10px;
    }
}
</style>