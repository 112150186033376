<template>
    <div :style="`color: ${fill}`">
<svg :width="width"
:height="height" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.86992 8.715C6.84742 8.715 6.83242 8.715 6.80992 8.715C6.77242 8.7075 6.71992 8.7075 6.67492 8.715C4.49992 8.6475 2.85742 6.9375 2.85742 4.83C2.85742 2.685 4.60492 0.9375 6.74992 0.9375C8.89492 0.9375 10.6424 2.685 10.6424 4.83C10.6349 6.9375 8.98492 8.6475 6.89242 8.715C6.88492 8.715 6.87742 8.715 6.86992 8.715ZM6.74992 2.0625C5.22742 2.0625 3.98242 3.3075 3.98242 4.83C3.98242 6.33 5.15242 7.5375 6.64492 7.59C6.68992 7.5825 6.78742 7.5825 6.88492 7.59C8.35492 7.5225 9.50992 6.315 9.51742 4.83C9.51742 3.3075 8.27242 2.0625 6.74992 2.0625Z" fill="#7B7E8C"/>
<path d="M12.4048 8.8125C12.3823 8.8125 12.3598 8.8125 12.3373 8.805C12.0298 8.835 11.7148 8.6175 11.6848 8.31C11.6548 8.0025 11.8423 7.725 12.1498 7.6875C12.2398 7.68 12.3373 7.68 12.4198 7.68C13.5148 7.62 14.3698 6.72 14.3698 5.6175C14.3698 4.4775 13.4473 3.555 12.3073 3.555C11.9998 3.5625 11.7448 3.3075 11.7448 3C11.7448 2.6925 11.9998 2.4375 12.3073 2.4375C14.0623 2.4375 15.4948 3.87 15.4948 5.625C15.4948 7.35 14.1448 8.745 12.4273 8.8125C12.4198 8.8125 12.4123 8.8125 12.4048 8.8125Z" fill="#7B7E8C"/>
<path d="M6.87721 16.9125C5.40721 16.9125 3.92971 16.5375 2.81221 15.7875C1.76971 15.0975 1.19971 14.1525 1.19971 13.125C1.19971 12.0975 1.76971 11.145 2.81221 10.4475C5.06221 8.955 8.70721 8.955 10.9422 10.4475C11.9772 11.1375 12.5547 12.0825 12.5547 13.11C12.5547 14.1375 11.9847 15.09 10.9422 15.7875C9.81721 16.5375 8.34721 16.9125 6.87721 16.9125ZM3.43471 11.3925C2.71471 11.8725 2.32471 12.4875 2.32471 13.1325C2.32471 13.77 2.72221 14.385 3.43471 14.8575C5.30221 16.11 8.45221 16.11 10.3197 14.8575C11.0397 14.3775 11.4297 13.7625 11.4297 13.1175C11.4297 12.48 11.0322 11.865 10.3197 11.3925C8.45221 10.1475 5.30221 10.1475 3.43471 11.3925Z" fill="#7B7E8C"/>
<path d="M13.7546 15.5625C13.4921 15.5625 13.2596 15.3825 13.2071 15.1125C13.1471 14.805 13.3421 14.5125 13.6421 14.445C14.1146 14.3475 14.5496 14.16 14.8871 13.8975C15.3146 13.575 15.5471 13.17 15.5471 12.7425C15.5471 12.315 15.3146 11.91 14.8946 11.595C14.5646 11.34 14.1521 11.16 13.6646 11.0475C13.3646 10.98 13.1696 10.68 13.2371 10.3725C13.3046 10.0725 13.6046 9.87751 13.9121 9.94501C14.5571 10.0875 15.1196 10.3425 15.5771 10.695C16.2746 11.22 16.6721 11.9625 16.6721 12.7425C16.6721 13.5225 16.2671 14.265 15.5696 14.7975C15.1046 15.1575 14.5196 15.42 13.8746 15.5475C13.8296 15.5625 13.7921 15.5625 13.7546 15.5625Z" fill="#7B7E8C"/>
</svg>

    </div>
  </template>
  <script>
  export default {
    name: "people-icon",
    props: {
      width: {
        type: Number,
        default: 26,
      },
      height: {
        type: Number,
        default: 26,
      },
      fill: {
        type: String,
        default: "#36373F",
      },
    },
  };
  </script>
  