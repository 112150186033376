<template>
  <div>
      <DekstopAboutUs v-if="!isMobile" />
      <MobileAboutUs v-if="isMobile" />
  </div>
</template>

<script>
import DekstopAboutUs from "@/components/about-us/desktop/AboutUs.vue"
import MobileAboutUs from "@/components/about-us/mobile/AboutUs.vue"

export default {
  components: {
      DekstopAboutUs,
      MobileAboutUs,
  },
  data() {
      return {
          isMobile: false,
      };
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990;
      },
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
      this.handleView();
  },

};
</script>