<template>
  <div style="margin-top: 60px">
    <div class="head-class" ref="ctn_head">
      <div class="left">
        <div class="d-flex">
          <div class="btn-white-oval">
            <img src="/img/class/prakerja.png" width="80px" />
          </div>
          <div class="btn-white-oval align-items-center">Webinar</div>
        </div>
        <p class="text-banner">{{ classDetail.name }}</p>
        <div class="description-class">
          <div>
            <BarIc />
            <span>{{ classDetail.level }}</span>
          </div>
          <div>
            <ScreenIc />
            <span>{{ classDetail.method | toMethode }}</span>
          </div>
        </div>
        <v-btn depressed color="#FFDE59" @click="goToCheckoutPage" class="button-daftar mt-10">
          <span class="text-capitalize font-weight-bold blue-text" @click="goToCheckoutPage">DAFTAR KELAS</span>
        </v-btn>
      </div>
      <img :src="classDetail.image_url" class="img-banner" />
    </div>
    <div class="box-radius">
      <div v-for="n in menuPublicClass" :key="n.id" class="menu-detail" @click="clickActiveMenu(n.layout, n.position)">
        <span style="font-family: 'Poppins'">{{ n.name }}</span>
        <div :class="{ linesmall: layout === n.layout }"></div>
      </div>

      <v-btn depressed color="#162CA2" @click="goToCheckoutPage" class="btn-radiuss">
        <span class="text-capitalize font-weight-bold white--text">DAFTAR SEKARANG</span>
      </v-btn>
    </div>

    <div class="container-parent" style="margin-top: -310px" ref="info_kelas">
      <div class="container-detail">
        <span class="addional">Info Kelas</span>
        <h5 class="title-2">Deskripsi Program</h5>
        <p class="desc">{{ classDetail.description }}</p>
      </div>
      <div class="container-detail" style="margin-top: 50px;">
        <h5 class="title-2">Jadwal Kelas</h5>
        <div class="timeline mt-5" v-for="(item, idx) in classDetail.schedules" :key="idx">
          <div class="timeline-box">
            <div class="ctn">
              <DateIc2 />
              <span class="font-poppins">{{ formatDate(item.start_date) }} - {{ formatDate(item.end_date) }}</span>
            </div>
            <span class="dot"></span>
            <div class="ctn">{{ item.hours }} WIB</div>
          </div>
          <div>
            <span style="color: #01A368" class="font-poppins">Tersedia</span>
          </div>
        </div>
        <EmptyData v-if="!classDetail.schedules || !classDetail.schedules.length" message="Belum ada Jadwal saat ini" />
      </div>
      <div class="container-detail">
        <div class="box-steps" style="border-radius: 20px; border: 1px #d3d6e1 solid; margin-top: 50px;">
          <div class="box-steps-ctn py-4" style="display: flex;" @click="isDetailClass = !isDetailClass">
            <h2>Detail Kelas</h2>
            <ArrowIc :down="!isDetailClass" />
          </div>
          <div class="box-steps-ctn border-top pa-5" v-if="isDetailClass">
            <p v-html="classDetail.learning_objective"></p>
            <h3 class="my-3">Sasaran Peserta</h3>
            <ul>
              <li v-for="(item, idx) in classDetail.target_audience" :key="idx" class="font-poppins">{{ item.level }}
              </li>
            </ul>
            <h3 class="my-3">Metode Penyampaian</h3>
            <p v-html="classDetail.learning_method"></p>
            <h3 class="my-3">Perangkat Evaluasi</h3>
            <ul>
              <li v-for="(item, idx) in evaluasi" :key="idx" class="font-poppins">{{ item }}</li>
            </ul>
            <h3 class="my-3">Syarat & Ketentuan Mengikuti Kelas</h3>
            <ul>
              <li v-for="(item, idx) in classDetail.requirements" :key="idx" class="font-poppins">{{ item.name }}</li>
            </ul>
            <div class="btn-hide" @click="isDetailClass = !isDetailClass">
              <arrow-ic />
              <span>SEMBUNYIKAN</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-container" style="margin-bottom: 50px; background: #f1f6ff" ref="materi_silabus">
      <div class="container-detail" style="margin-top: 45px; padding: 60px 0px">
        <span class="font-poppins">Materi Pembelajaran</span>
        <h5 class="title-2">Apa saja yang Peserta Pelajari</h5>
        <div v-for="(item, idx) in classDetail.syllabuses" :key="idx" class="box-steps">
          <div class="box-steps-ctn pb-3" style="display: flex;" @click="item.show = !item.show">
            <div>
              <span class="font-poppins">Sesi {{ idx + 1 }}</span>
              <h3 v-for="(item2, idx2) in item.name" :key="idx2">{{ item2 }}</h3>
            </div>
            <ArrowIc :down="!item.show" />
          </div>
          <div class="box-steps-ctn border-top pa-5" v-if="item.show">
            <div class="list-art" v-if="idx === 0">
              <img src="/img/icons/file.png" alt="File">
              <div class="info-ars">
                <p>Pre-Test</p>
                <p style="color: #7B7E8C">Initial Exam</p>
              </div>
            </div>
            <div class="list-art">
              <img src="/img/icons/easel.png" alt="File">
              <div class="info-ars">
                <p>Live Session : {{ item.name[0] }}</p>
                <p style="color: #7B7E8C">Live & Share Deck</p>
              </div>
            </div>
            <div class="list-art">
              <img src="/img/icons/file.png" alt="File">
              <div class="info-ars">
                <p>Formative Test</p>
                <p style="color: #7B7E8C">Quiz {{ idx + 1 }}</p>
              </div>
            </div>
            <div class="list-art" v-if="idx < 4">
              <img src="/img/icons/file.png" alt="File">
              <div class="info-ars">
                <p>Tugas Praktik Mandiri {{ idx + 1 }}</p>
                <p style="color: #7B7E8C">Individual Assignment</p>
              </div>
            </div>
            <div class="list-art" v-if="idx >= classDetail.syllabuses.length - 1">
              <img src="/img/icons/file.png" alt="File">
              <div class="info-ars">
                <p>Post-Test</p>
                <p style="color: #7B7E8C">Final Exam</p>
              </div>
            </div>
            <div class="list-art" v-if="idx >= classDetail.syllabuses.length - 1">
              <img src="/img/icons/file.png" alt="File">
              <div class="info-ars">
                <p>Unjuk Keterampilan</p>
                <p style="color: #7B7E8C">Individual Assignment</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-parent" style="padding: 20px 0px" ref="benefit">
      <div class="container-detail">
        <h5 class="title-2">Apa Saja yang Peserta Dapatkan</h5>
        <BenefitPrakerja style="margin: 50px 0" />
      </div>
    </div>

    <div class="container-parent" style="margin-bottom: 70px;" ref="instuktur">
      <div class="container-detail">
        <span class="font-poppins">Instruktur</span>
        <h5 class="title-2">Tentang Instruktur</h5>
        <div class="narasumber">
          <div>
            <img :src="$utils.loadImage(instructor.image_url)" alt="Profile" class="narasumber-img" />
          </div>
          <div>
            <div>
              <h2>{{ instructor.name }}</h2>
              <span class="sub-title-narasumber">{{ instructor.position }} {{ instructor.company ? `/
                ${instructor.company}` : "" }}</span>
            </div>
            <p class="description-2">{{ instructor.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import goTo from "vuetify/lib/services/goto";
import BenefitPrakerja from "@/components/prakerja/BenefitPrakerja";
import BarIc from "@/assets/icons/BarIc.vue";
import ScreenIc from "@/assets/icons/ScreenIc.vue";
import DateIc2 from "@/assets/icons/DateIc2.vue";
import EmptyData from "../../others/EmptyData.vue";
import ArrowIc from "@/assets/icons/Arrow.vue";
import moment from "moment";

export default {
  components: {
    BenefitPrakerja,
    BarIc,
    ScreenIc,
    DateIc2,
    ArrowIc,
    EmptyData
  },
  data() {
    return {
      publicClass: [
        { message: "Lebih dari satu peserta (max 10)" },
        { message: "Jadwal kelas telah ditentukan" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      privateClass: [
        { message: "Hanya satu peserta" },
        { message: "Dapat menentukan jadwal kelas" },
        { message: "Diajarkan langsung oleh instruktur" },
        { message: "Gratis Survival Kit" },
        { message: "Sertifikat" },
      ],
      evaluasi: [
        "Pre-Test",
        "Kuis",
        "Tugas Praktik Mandiri",
        "Post-Test",
        "Uji Keterampilan"
      ],
      faq: [
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
        {
          description:
            "Memiliki laptop / PC yang dilengkapi webcam dan tambahan headset + mic untuk menunjang pembelajaran.",
          title: "Apa yang harus dipersiapkan untuk mengikuti pelatihan?",
        },
      ],
      menuPublicClass: [
        { name: "Info Kelas", id: 1, position: 0, layout: "info-kelas" },
        {
          name: "Materi Pembelajaran",
          id: 2,
          position: 0,
          layout: "materi-silabus",
        },
        { name: "Benefit", id: 3, position: 0, layout: "benefit" },
        {
          name: "Instruktur",
          id: 5,
          position: 0,
          layout: "instuktur",
        },
      ],
      classDetail: {
        requrements: [],
      },
      instructor: {},
      isDetailClass: true,
      layout: "info-kelas",
    };
  },
  methods: {
    clickActiveMenu(e, p) {
      this.layout = e;
      console.log(p);
      goTo(Number(p + 10));
    },
    goToCheckoutPage() {
      var classType = "PUBLIC_CLASS";
      if (this.$route.query.tipe_kelas == "prakerja") {
        classType = "PRAKERJA_CLASS";
      } else if (this.$route.query.tipe_kelas == "public-bootcamp") {
        classType = "Kelas Bootcamp";
      }

      const req = JSON.stringify({
        class_id: this.classDetail.class_id,
        class_name: this.classDetail.name,
        price: this.classDetail.price,
        level: this.classDetail.level,
        session: this.classDetail.session_total,
        hour: this.classDetail.hours,
        image_url: this.classDetail.small_image_url,
        class_type: classType,
        days: this.classDetail.days,
        methode: this.classDetail.method,
        category_id: this.classDetail.category_id,
      });

      localStorage.clear();
      localStorage.setItem("checkout", req);
      window.location.href = "/checkout";
    },
    goToCustomerService() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi Kak HiColleagues, saya mau bertanya";
    },
    formatDate(date) {
      return moment(date).format("dddd, DD/MM/YYYY");
    },
    mappingHeightOfElement() {
      let point = this.$refs.ctn_head.clientHeight;
      this.menuPublicClass[0].position = point;
      point += this.$refs.info_kelas.clientHeight;
      this.menuPublicClass[1].position = point;
      point += this.$refs.materi_silabus.clientHeight;
      this.menuPublicClass[2].position = point;
      point += this.$refs.benefit.clientHeight;
      this.menuPublicClass[3].position = point;
      point += this.$refs.instuktur.clientHeight;
    },
    async getDetailClass() {
      // this.spin_load.val = true
      await this.$http
        .get("/v1/class-detail/" + this.$route.params.slugname)
        .then((response) => {
          this.classDetail = {
            ...response.data.data.class_detail,
            syllabuses: response.data.data.class_detail.syllabuses.map(item => ({
              ...item,
              name: item.description.split("<li>").map(item2 => item2.replace("</ul>", "").replace("</li>", "")),
              show: true,
            }))
          };
          this.classDetail.syllabuses = this.classDetail.syllabuses.map(item => {
            item.name.splice(0, 1)
            return {
              ...item,
              name: item.name
            }
          })
          this.instructor = response.data.data.instructor
          setTimeout(() => {
            this.mappingHeightOfElement();
          }, 1000);
        })
        .catch(() => {
          this.$router.push('/prakerja')
        });
      // .finally(() => this.spin_load.val = false)
    },
    handleScroll() {
      this.menuPublicClass.forEach((item) => {
        if (item.position <= window.scrollY) {
          this.layout = item.layout;
        }
      });
    },
  },
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.getDetailClass();
  },
};
</script>

<style src="./detailClass.css" scoped></style>
