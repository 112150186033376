<template>
    
<svg :width="width" :height="height" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1350_46232)">
<path d="M31.9666 16C31.9666 7.49852 25.0748 0.606689 16.5733 0.606689C8.07179 0.606689 1.17996 7.49852 1.17996 16C1.17996 24.5015 8.07179 31.3933 16.5733 31.3933C25.0748 31.3933 31.9666 24.5015 31.9666 16Z" stroke="#ACAFBF" stroke-width="1.5" stroke-miterlimit="10"/>
<path d="M19.914 12.724C20.031 12.8966 20.083 13.1051 20.0607 13.3125C20.0385 13.5198 19.9436 13.7125 19.7927 13.8565L14.4216 19.2114C14.2791 19.3685 14.0805 19.4634 13.8686 19.4755C13.6568 19.4876 13.4487 19.416 13.2892 19.2761C12.9737 19.0253 13.0384 18.5238 13.4186 18.1436L18.7169 12.8453C18.7838 12.7537 18.8693 12.6772 18.9678 12.6208C19.0662 12.5643 19.1755 12.5293 19.2884 12.5178C19.4013 12.5064 19.5154 12.5188 19.6232 12.5544C19.7309 12.5899 19.83 12.6477 19.914 12.724ZM23.2629 9.31855C21.8069 7.87063 19.7927 7.55516 18.7169 8.6229L15.8776 11.4621C15.182 12.1497 15.2467 13.541 15.6916 14.6087C16.0071 14.2933 16.3872 13.8565 16.7674 13.4197C16.8321 13.1608 16.7674 12.724 16.9535 12.538L19.7927 9.70683C20.4155 9.07589 21.6289 9.26193 22.5025 10.0789C23.3761 10.8959 23.5783 12.2306 22.9474 12.8534L20.1082 15.6927C19.914 15.8221 19.4772 15.6927 19.2265 15.7574C18.7816 16.1376 18.3367 16.5177 18.0293 16.8251C19.097 17.27 20.456 17.3994 21.1112 16.7038L23.9585 13.8646C25.0344 12.8534 24.7108 10.7746 23.2629 9.32664V9.31855ZM16.2578 19.5268L13.4186 22.3014C12.7876 22.997 11.5258 22.7463 10.7007 21.9293C9.87561 21.1123 9.62485 19.7857 10.2639 19.1548L13.1031 16.3155C13.2892 16.1295 13.912 16.1861 14.1709 16.1295C14.6157 15.6846 15.0606 15.3691 15.3761 15.0536C14.2356 14.6087 12.7229 14.6087 12.0354 15.3044L9.18804 18.1436C8.1203 19.1548 8.42768 21.2336 9.8837 22.6815C11.3397 24.1295 13.4186 24.4449 14.4944 23.3772L17.2689 20.538C18.0293 19.8504 17.8999 18.4591 17.455 17.3914C17.1395 17.7068 16.8321 18.0789 16.3872 18.5238C16.3225 18.8393 16.4439 19.2761 16.2578 19.5268Z" fill="#ACAFBF"/>
</g>
<defs>
<clipPath id="clip0_1350_46232">
<rect width="32" height="32" fill="white" transform="translate(0.573303)"/>
</clipPath>
</defs>
</svg>

</template>
<script>
export default {
    props: {
            width: {
                type: String,
                default: "32"
            },
            height: {
                type: String,
                default: "32" 
            },
        }
}
</script>