<template>
<svg :width="width"
:height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#FFDE59"/>
<path d="M37.2623 21.8625V22.7875L32.8373 20.225C31.1623 19.2625 28.8248 19.2625 27.1623 20.225L22.7373 22.8V21.8625C22.7373 19.05 24.2748 17.5 27.0873 17.5H32.9123C35.7248 17.5 37.2623 19.05 37.2623 21.8625Z" fill="#36373F"/>
<path d="M37.3 24.9625L37.125 24.875L35.425 23.9L31.9 21.8625C30.825 21.2375 29.175 21.2375 28.1 21.8625L24.575 23.8875L22.875 24.8875L22.65 25C20.4625 26.475 20.3125 26.75 20.3125 29.1125V34.7625C20.3125 37.125 20.4625 37.4 22.7 38.9125L28.1 42.025C28.6375 42.35 29.3125 42.4875 30 42.4875C30.675 42.4875 31.3625 42.3375 31.9 42.025L37.35 38.875C39.55 37.4 39.6875 37.1375 39.6875 34.7625V29.1125C39.6875 26.75 39.5375 26.475 37.3 24.9625ZM33.4875 31.875L32.725 32.8125C32.6 32.95 32.5125 33.2125 32.525 33.4L32.6 34.6C32.65 35.3375 32.125 35.7125 31.4375 35.45L30.325 35C30.15 34.9375 29.8625 34.9375 29.6875 35L28.575 35.4375C27.8875 35.7125 27.3625 35.325 27.4125 34.5875L27.4875 33.3875C27.5 33.2 27.4125 32.9375 27.2875 32.8L26.5125 31.875C26.0375 31.3125 26.25 30.6875 26.9625 30.5L28.125 30.2C28.3125 30.15 28.525 29.975 28.625 29.825L29.275 28.825C29.675 28.2 30.3125 28.2 30.725 28.825L31.375 29.825C31.475 29.9875 31.7 30.15 31.875 30.2L33.0375 30.5C33.75 30.6875 33.9625 31.3125 33.4875 31.875Z" fill="#36373F"/>
</svg>

</template>

<script>
export default {
  name: "BootcampIcon",
  props: {
    width: {
      type: Number,
      default: 90,
    },
    height: {
      type: Number,
      default: 90,
    }
  },
};
</script>