<template>
  <div class="container-size" style="margin-top: 63px">
    <!-- HERO SECTION -->
    <popup :message="alertErrMessage" v-if="isAlertErr" :isError="isError" @close="isAlertErr = false" />
    <div class="hero">
      <div class="content">
        <h1 style="font-size: 40px">Wujudkan Transformasi Digital melalui Tim IT Profesional</h1>
        <p>
          Perluas skala bisnis dalam dinamika transformasi digital dengan Upskilling &/ New Skilling talenta perusahaan
          melalui layanan Corporate Training HiColleagues sebagai mitra terpercaya & kredibel
        </p>
        <div class="btn-cta">
          <button class="now" @click="scrollToSection('tech_ser')">KOLABORASI SEKARANG</button>
          <button class="call" @click="sendToWa">HUBUNGI KAMI</button>
        </div>
      </div>
      <div class="card-img">
        <img src="/img/corporate/tech-main.png" width="520" height="" alt="" />
      </div>
    </div>
    <div class="container-fade">
      <div class="left-fade"></div>
      <div class="right-fade"></div>
    </div>
    <!-- Logo -->
    <div class="logo">
      <h1>HiColleagues telah Dipercaya Perusahaan Ternama</h1>
      <div class="card" style="overflow-x: none !important">
        <div v-for="(partner, index) in partners" :key="index">
          <div class="logo-card-partner-2">
            <!-- <img src="/img/partner/astrapay.png" width="160" /> -->
            <img :src="partner.img_url_web" width="160" />
          </div>
        </div>
      </div>
    </div>


    <div class="container text-center" style="margin-top: 80px">
      <h1 style="margin: 0">Layanan Profesional dalam Transformasi Digital Berkelanjutan</h1>
      <p style="margin: 0">Perluas kerjasama kemitraan melalui layanan profesional tim IT HiColleagues dan berikan
        dampak ke peningkatan performa bisnis Anda</p>
      <div class="card-box">
        <div class="card-1" v-for="(desc1, idx) in descs1" :key="idx">
          <img :src="desc1.img" class="img-2" />
          <h3>{{ desc1.tittle }}</h3>
          <p>{{ desc1.desc }}</p>
        </div>
      </div>
    </div>
    <div class="usp">
      <div class="card-usp">
        <h1>Mengapa Memilih HiColleagues sebagai Mitra Technology Service</h1>
        <p>
          HiColleagues telah membuktikan melalui <b>5+ layanan kerjasama kemitraan perusahaan yang telah
            dilaksanakan</b> dan memberi dampak positif terhadap akselerasi performa bisnis mitra
        </p>
        <div class="card-img">
          <img src="/img/corporate/tech/main-2.png" alt="" />
        </div>
      </div>
      <div class="usp-container">
        <div class="card-usp" v-for="(usp, index) in usps" :key="index">
          <div class="title-container">
            <img class="svg-comment" :src="usp.icon" width="60" height="60" alt="" />
            <h3>{{ usp.title }}</h3>
          </div>
          <div class="content">
            <p>{{ usp.desc }}</p>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <!-- Portfolio Event Collaboration -->
    <portofolio title="Portfolio Proyek HiColleagues" :portfolios="portfolios" />
    <div class="bg-linear">
      <StepCollaboration title="Langkah Kolaboratif Kemitraan" :steps="steps" />
      <h1 class="mb-4 text-center">Solusi Terbaik dalam Inovasi Teknologi & Kolaborasi</h1>
      <div class="inovation">
        <div class="inovation-child" v-for="(item, idx) in inovation" :key="idx">
          <img :src="item.img" alt="IconData">
          <p style="height: 20px; line-height: 16px; font-size: 13px; margin-top: 3px">{{ item.name }}</p>
        </div>
      </div>
    </div>



    <!-- Form Pendaftaran Kolaborasi -->
    <div class="form-konsultasi" id="tech_ser">
      <div class="header text-center">
        <h1>Formulir Pendaftaran Konsultasi</h1>
      </div>
      <div class="sub-heading mt-1 text-center">
        <p>
          Langkah tepat optimasi bisnis melalui layanan Corporate Service
          bersama HiColleagues
        </p>
        <v-form class="text-start" style="margin-top: 60px">
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="company">Nama Perusahaan<span
                  style="color: rgba(184, 15, 10, 1)">*</span></label>
              <v-text-field class="form-input text-form" name="company" v-model="form.corporate_name" outlined
                placeholder="Masukkan Nama Perusahaan"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="website">Website Perusahaan</label>
              <v-text-field class="form-input text-form" v-model="form.web_url" name="website" outlined
                placeholder="Masukkan Alamat Website Perusahaan">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="pic-name">Nama Lengkap PIC<span
                  style="color: rgba(184, 15, 10, 1)">*</span></label>
              <v-text-field class="form-input text-form" name="pic-name" v-model="form.full_name" outlined
                placeholder="Masukkan Nama Lengkap PIC"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="occupation">Okupasi PIC</label>
              <v-text-field class="form-input text-form" name="occupation" v-model="form.job_pic" outlined
                placeholder="Masukkan Okupasi PIC">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <label class="form-label" for="email">Email PIC<span style="color: rgba(184, 15, 10, 1)">*</span></label>
              <v-text-field class="form-input text-form" name="email" v-model="form.email" outlined
                placeholder="Masukkan Email PIC"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="form-label" for="phone-number">No. Whatsapp PIC<span
                  style="color: rgba(184, 15, 10, 1)">*</span></label>
              <v-text-field class="form-input text-form" name="phone-number" outlined
                placeholder="Masukkan No. Whatsapp PIC" type="number" v-model="form.phone_number" hide-spin-buttons>
                <template v-slot:prepend-inner>
                  <v-row class="d-flex align-center mt-0 ml-7">
                    <img src="/img/other/flag-indonesia.png" alt="" width="30" height="20"
                      style="box-shadow: 2px 2px 3px 1px rgba(13, 28, 113, 0.1)" />
                    <span style="color: #575966" class="mx-3">+62</span>
                  </v-row>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="form-collab">
              <label class="form-label" for="collaboration">Proyeksi Kolaborasi/Kemitraan</label>
              <v-text-field class="form-input text-form" solo flat background-color="rgba(247, 249, 253, 1)"
                placeholder="Tech Service" disabled />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-3">
            <v-checkbox class="form-input custom-checkbox" v-model="is_confirm">
              <template v-slot:label>
                <p class="label-checkbox">
                  Dengan mengirim formulir ini saya menyetujui<a href="/term-condition">
                    Syarat & Ketentuan </a>untuk melakukan kemitraan/kolaborasi bersama HiColleagues.
                </p>
              </template>
            </v-checkbox>
          </v-row>
          <v-row class="d-flex justify-center align-center mt-6">
            <v-btn elevation="0" :class="`btn-kirim ${is_confirm ? 'confirm' : 'reject'}`" @click="onSubmit">KIRIM
            </v-btn>
          </v-row>
        </v-form>
      </div>
    </div>
    <div class="faq" style="margin-top: 60px;">
      <div class="content">
        <div class="row">
          <div class="col-lg-3">
            <div class="header">
              <h1>Frequently Asked Question</h1>
            </div>
          </div>
          <div class="col-lg-9" style="margin-top: 20px;">
            <v-expansion-panels flat class="faq-card" style="padding: 10px; background-color: #F1F6FF;">
              <v-expansion-panel v-for="(item, index) in questions" :key="index"
                style="border-radius: 20px; padding: 0; margin-bottom: 10px;">
                <v-expansion-panel-header disable-icon-rotate @click="openFaq(index)">
                  <h2 style="margin: 25px 0; font-size: 18px;">{{ item.title }}</h2>
                  <template v-slot:actions>
                    <div class="icon" v-if="item.active">
                      <Arrow />
                    </div>
                    <div v-else>
                      <Arrow :down="!item.active" />
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div style="margin-left: -10px;">
              <div class="btn-stoke-blue" @click="$router.push('/faq')">
                <span class="font-poppins">LIHAT SELENGKAPNYA</span>
                <ArrowLong />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from "@/components/others/Popup.vue";
import StepCollaboration from '../../StepCollaboration.vue';
import Portofolio from '../../Portofolio.vue';
import Arrow from '@/assets/icons/Arrow.vue';
import ArrowLong from '@/assets/icons/ArrowLong.vue';

export default {
  components: {
    Popup,
    StepCollaboration,
    ArrowLong,
    Portofolio,
    Arrow
  },
  name: "TechnologyServiceDesktop",
  data() {
    return {
      portofolio: 0,
      portofolioLength: 4,
      partnership: 0,
      partnershipLength: 3,
      images1: [
        { img: "/img/corporate/telkomsat.png" },
        { img: "/img/corporate/astra.png" },
        { img: "/img/corporate/pru.png" },
        { img: "/img/corporate/bank.png" },
        { img: "/img/corporate/astrapay.png" },
        { img: "/img/corporate/solusi.png" },
      ],

      descs1: [
        {
          img: "/img/corporate/tech-4.png",
          tittle: "IT Consulting",
          desc: "HiColleagues dapat membantu Anda untuk menentukan dan mengembangkan strategi IT dalam semua lini bisnis melalui konsultan inovatif kami. Tim ahli kami siap membantu dalam menganalisis kebutuhan teknologi bisnis Anda, merancang strategi IT yang efektif, dan memberikan solusi yang disesuaikan untuk meningkatkan efisiensi operasional dan daya saing bisnis.",
        },
        {
          img: "/img/corporate/tech-3.png",
          tittle: "IT Sourcing",
          desc: "HiColleagues memberikan layanan maksimal dengan menghadirkan talenta-talenta digital kompeten sesuai dengan kebutuhan pengembangan produk digital dalam bisnis Anda. Pemenuhan kebutuhan talenta digital ini berjalan efisien dan komprehensif, melalui proses seleksi yang baik untuk menghubungkannya sesuai kualifikasi yang dibutuhkan",
        },
        {
          img: "/img/corporate/tech-2.png",
          tittle: "IT Implementation",
          desc: "Mengimplementasikan dan mengkonfigurasi solusi IT sesuai dengan kebutuhan dan spesifikasi Anda, melakukan pengujian dan validasi menyeluruh untuk memastikan solusi IT berfungsi dengan baik. Dari perencanaan hingga eksekusi, tim kami memastikan setiap tahap implementasi berjalan sesuai dengan kebutuhan bisnis Anda.",
        },
        {
          img: "/img/corporate/tech-1.png",
          tittle: "IT Support",
          desc: "Dapatkan dukungan teknologi yang handal dan responsif setiap saat. Tim kami siap membantu mengatasi berbagai masalah IT, mulai dari troubleshooting, maintenance, hingga upgrade sistem. Pastikan operasional bisnis Anda berjalan tanpa gangguan dengan dukungan penuh dari kami.",
        },
      ],
      inovation: [
        {
          img: "/img/corporate/tech/w1.png",
          name: "Finansial & Perbankan"
        },
        {
          img: "/img/corporate/tech/w2.png",
          name: "Kesehatan"
        },
        {
          img: "/img/corporate/tech/w3.png",
          name: "Pendidikan"
        },
        {
          img: "/img/corporate/tech/w4.png",
          name: "Manufaktur"
        },
        {
          img: "/img/corporate/tech/w5.png",
          name: "Energi & Sumber Daya"
        },
        {
          img: "/img/corporate/tech/w6.png",
          name: "Pemerintahan"
        },
        {
          img: "/img/corporate/tech/w7.png",
          name: "Media"
        },
        {
          img: "/img/corporate/tech/w8.png",
          name: "Pariwisata"
        },
      ],
      images2: [
        {
          img: "/img/corporate/banner-2.png",
        },
      ],
      usps: [
        {
          icon: "/img/corporate/tech/c-1.png",
          title: "Solusi Teknologi Terdepan",
          desc: "Tingkatkan efisiensi dan produktivitas bisnis Anda dengan beragam solusi teknologi terdepan dari HiColleagues.",
        },
        {
          icon: "/img/corporate/tech/c-2.png",
          title: "Tim IT yang Ahli & Profesional",
          desc: "Tim terbaik kami memiliki pengalaman yang profesional dalam mengimplementasikan solusi teknologi yang kompleks agar bisnis Anda tetap unggul di era digital.",
        },
        {
          icon: "/img/corporate/tech/c-3.png",
          title: "Fleksibilitas & Skalabilitas",
          desc: "Pelayanan HiColleagues dirancang untuk dapat disesuaikan dengan berbagai ukuran tim dan skala bisnis. Kami memastikan setiap industri mendapatkan dukungan IT yang tepat dan efisien.",
        },
        {
          icon: "/img/corporate/tech/c-4.png",
          title: "Keamanan dan Privasi Data yang Terjamin",
          desc: "Layanan HiColleagues mengutamakan keamanan dan privasi data setiap Klien tanpa khawatir tentang risiko keamanan dan terlindungi dari ancaman eksternal.",
        },
        {
          icon: "/img/corporate/tech/c-5.png",
          title: "Mengembangkan Transformasi Digital Berkelanjutan",
          desc: "Layanan HiColleagues berfokus pada pengembangan transformasi digital yang berkelanjutan untuk bisnis Anda.",
        },
        {
          icon: "/img/corporate/tech/c-6.png",
          title: "Optimasi Kinerja Bisnis",
          desc: "Layanan teknologi kami dirancang untuk meningkatkan efisiensi operasional dan produktivitas bisnis Anda.",
        },
      ],
      descs3: [
        {
          img: "/img/corporate/kolab-img.png",
          tittle: "Observasi & Identifikasi Kebutuhan",
          desc: "Pencarian informasi yang aktual melalui proses interview secara mendalam, dengan ini kebutuhan dan ekspektasi mitra dapat diidentifikasi dengan jelas untuk memudahkan kami dalam memberikan solusi yang tepat sesuai kebutuhan.",
        },
        {
          img: "/img/corporate/kolab-img.png",
          tittle: "Perancangan Konsep Pelatihan",
          desc: "Setelah mengidentifikasi & memetakan kebutuhan, kami akan mulai merancang konsep pelatihan sebagai strategi untuk mewujudkan hasil yang maksimal bagi peserta pelatihan. Silabus, mekanisme, & segala hal pendukung pelaksanaan pelatihan.",
        },
      ],
      descs4: [
        { title: "python", desc: "for data science" },
        { title: "python", desc: "for data science" },
        { title: "python", desc: "for data science" },
        { title: "python", desc: "for data science" },
        { title: "python", desc: "for data science" },
      ],
      showed: [],
      partners: [],
      steps: [
        {
          image_url: "/img/corporate/tech/bn1.png",
          title: "Analisis Kebutuhan",
          description: "HiColleagues memulai dengan melakukan sesi konsultasi untuk memahami kebutuhan dan tujuan bisnis Anda. Tim kami akan menganalisis tantangan yang Anda hadapi dan mengidentifikasi area yang memerlukan dukungan teknologi.",
        },
        {
          image_url: "/img/corporate/tech/bn2.png",
          title: "Rekomendasi Strategi & Solusi",
          description: "Kami menyusun rekomendasi solusi teknologi yang paling sesuai dengan kebutuhan Anda. Rekomendasi ini mencakup pilihan layanan seperti IT Consulting, IT Sourcing, IT Implementation, hingga IT Support.",
        },
        {
          image_url: "/img/corporate/tech/bn3.png",
          title: "Strategi, Timeline, & Achievement",
          description: "Setelah rekomendasi disetujui, tim kami bekerja sama dengan Anda untuk merancang solusi yang detail dan disesuaikan. Ini termasuk penyusunan rencana implementasi dan perancangan teknologi yang optimal.",
        },
        {
          image_url: "/img/corporate/tech/bn4.png",
          title: "IT Implementation",
          description: "Pengimplementasian dan menjalankan rencana yang telah disusun. HiColleagues memastikan setiap komponen teknologi diimplementasikan dengan baik, sesuai dengan jadwal dan anggaran yang telah ditentukan.",
        },
        {
          image_url: "/img/corporate/tech/bn5.png",
          title: "Evaluasi & Optimalisasi",
          description: "Pasca proyek, HiColleagues melakukan evaluasi untuk menilai kinerja solusi yang telah diimplementasikan. memastikan operasional teknologi berjalan tanpa hambatan. Hal ini seperti maintenance rutin, troubleshooting, dan pembaruan sistem.",
        },
      ],
      portfolios: [],
      questions: [],
      testimonies: [],
      alertErrMessage: "",
      isAlertErr: false,
      is_confirm: false,
      isError: false,
      form: {
        full_name: "",
        email: "",
        phone_number: "",
        gender: "",
        corporate_name: "",
        company_field: "",
        program: "",
        total_student: 0,
        notes: "",
        category: "SERVICES",
        web_url: "",
        job_pic: ""
      },
    };
  },
  methods: {
    sendToWa() {
      window.open(
        "https://api.whatsapp.com/send/?phone=6282127274632&text=Hi%20Kak%20HiColleagues,%20saya%20mau%20bertanya"
      );
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
    async getClientLogo() {
      try {
        const resp = await this.$http.get("/v1/logo?category=SERVICES")
        this.partners = resp.data.data.logos
        this.duptlicatePartners();
      } catch (err) {
        console.log(err);
      }
    },
    duptlicatePartners() {
      this.partners = this.partners.concat(this.partners, this.partners, this.partners);
    },
    async getTestimoni() {
      try {
        const resp = await this.$http.get('/v1/testimony?category=SERVICES&page=1&per_page=9');
        this.testimonies = resp.data.data.testimonies;
      } catch (err) {
        console.log(err);
      }
    },

    async getPortofolio() {
      try {
        const resp = await this.$http.get('/v1/project-portfolio?category=SERVICES');
        this.portfolios = resp.data.data.project_portfolios;
      } catch (err) {
        console.log(err);
      }
    },
    openFaq(idx) {
      const valDef = this.questions[idx].active
      this.questions.forEach(e => {
        e.active = false
      });
      this.questions[idx].active = !valDef
    },
    async getFaq() {
      this.questions = []
      try {
        const resp = await this.$http.get("/v1/faq?category=SERVICES&is_main=true")
        this.questions = resp.data.data.faq.map((item) => ({
          description: item.answer[0],
          title: item.question,
          active: false
        }))
      } catch (err) {
        console.log(err);
      }
    },
    async onSubmit() {
      if (!this.is_confirm) {
        return
      }
      if (!this.form.corporate_name || !this.form.full_name || !this.form.job_pic || !this.form.email || !this.form.phone_number || !this.form.category || !this.form.web_url) {
        this.alertErrMessage = "Harap isi semua form yang tersedia"
        this.isAlertErr = true
        this.isError = true
        return
      }
      try {
        await this.$http.post('/v1/corporate-registration', this.form)
        this.alertErrMessage = "Success"
        this.isAlertErr = true
        this.isError = false
        this.clearForm()
      } catch {
        this.alertErrMessage = "Error saat submit data"
        this.isAlertErr = true
        this.isError = true
      }
    },
    clearForm() {
      this.is_confirm = false
      this.form.full_name = ""
      this.form.email = ""
      this.form.phone_number = ""
      this.form.corporate_name = ""
      this.form.category = ""
      this.form.web_url = ""
      this.form.job_pic = ""
    },
  },
  mounted() {
    this.getClientLogo()
    this.getTestimoni()
    this.getPortofolio()
    this.getFaq()
  }
};
</script>

<style src="./technologyService.css" scoped></style>