<template>
  <div>
    <div
      style="
        background: white;
        width: 100%;
        height: 100vh;
        z-index: 10;
        top: 0;
        position: fixed;
      "
      v-if="isActiveHamburgerMenu"
    >
      <div
        class="d-flex"
        style="height: 50px; align-items: center; margin-left: 16px"
      >
        <img
          src="/img/logo.png"
          style="width: 170px; height: 90px"
          @click="goToHomePage"
        />
        <div
          style="
            justify-content: right;
            display: inline-flex;
            margin-right: 16px;
            width: 100%;
          "
        >
          <img
            src="/img/other/ic_menu.png"
            style="width: 24px; height: 24px; cursor: pointer"
            @click="closeMenu"
          />
        </div>
      </div>

      <div style="background: #f1f1f1; width: 100%; height: 1px"></div>
      <HamburgerMenu v-if="isActiveHamburgerMenu" />
    </div>

    <div
      class="text-center header-mobile-custom"
      v-if="
        mobileView &&
        this.$route.path != '/' &&
        this.$route.path != '/services' &&
        this.$route.path != '/corporate'
      "
    >
      <img
        src="/img/logo.png"
        style="width: 150px; height: 100px"
        @click="goToHomePage"
      />
      <div
        style="
          justify-content: right;
          display: inline-flex;
          margin-right: 16px;
          width: 100%;
        "
      >
        <img
          src="/img/other/ic_menu.png"
          style="width: 24px; height: 24px; cursor: pointer"
          @click="openMenu"
        />
      </div>
    </div>
    <HeaderFAQ v-if="isShow && !isCustomHeader" />
    <router-view></router-view>
    <FooterCorporate  />
  </div>
</template>

<script>
import HeaderFAQ from "@/components/HeaderFAQ.vue";
import FooterCorporate from "@/components/FooterCorporate.vue";
import HamburgerMenu from "@/components/HamburgerCorporate";

export default {
  name: "CorporateTemplate",
  components: {
    HeaderFAQ,
    HamburgerMenu,
    FooterCorporate
  },
  data() {
    return {
      isShow: true,
      isNavMobileShow: true,
      mobileView: false,
      isCustomHeader: false,
      isActiveHamburgerMenu: false,
    };
  },
  methods: {
    handleView() {
      // this.mobileView = window.innerWidth <= 990
      if (screen.width <= 990) {
        this.mobileView = true;
        this.isShow = false;
      }
    },
    specialCaseView() {
      if (
        this.$route.path == "/login" ||
        this.$route.name == "PrivacyPolicyMobile" ||
        this.$route.name == "TermConditionMobile"
      ) {
        this.isShow = false;
        this.mobileView = false;
        this.isNavMobileShow = false;
      }

      // if(this.$route.path == "/" ) {
      //   this.isShow = false
      //   this.mobileView = true
      //   this.isNavMobileShow = true
      // }
    },
    customHeader() {
      if (this.$route.path == "/") {
        this.isCustomHeader = true;
      }
    },
    openMenu() {
      this.isActiveHamburgerMenu = true;
    },
    closeMenu() {
      this.isActiveHamburgerMenu = false;
    },
    isPageFound() {
      if (this.$route.matched[0].path == "*") {
        this.isShow = false;
      }
    },
    goToHomePage() {
      window.location.href = "/";
    },
  },
  mounted() {
    this.handleView();
    this.customHeader();
    this.isPageFound();
  },
};
</script>

<style scoped>
.header-mobile-custom {
  display: flex;
  padding-left: 16px;
  align-items: center;
  background-color: white;
  top: 0;
  width: 100%;
  height: 50px;
  position: fixed !important;
  z-index: 9;
  box-shadow: 1px 3px 5px 1px rgba(13, 28, 113, 0.1) !important;
  cursor: pointer;
}
</style>