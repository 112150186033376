<template>
    <div>
        <v-list>
            <v-list-item v-for="n in menus" :key="n.id" @click="redirectReload(n.link)">
                <v-list-item-title >
                    {{n.name}}
                </v-list-item-title>
            </v-list-item>
        </v-list>

       <div>
            <v-btn depressed color="#FFDE59" @click="redirectReload('/login')" style="width:100%;" class="btn-radius">
                <span class="text-capitalize font-weight-bold" style="color:#162CA2">MASUK</span>
            </v-btn>
       </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            menus:[
                {id: 1, name: 'Acceleration Program', link: '/corporate/acceleration'},
                {id: 2, name: 'Corp Collaboration', link: '/corporate/collaboration'},
                {id: 3, name: 'Corp Training', link: '/corporate/training'},
                {id: 4, name: 'Talent as a Service', link: '/corporate/talent-service'},
                {id: 5, name: 'Tech Service', link: '/corporate/services'},
            ],
        }
    },
    methods: {
        redirectReload(e) {
            this.$router
                .push({ path: e })
                .then(() => { this.$router.go() })
        },
    }
}
</script>