<template>
  <DesktopTalentAsService v-if="!isMobile" />
  <MobileTalentAsService v-else />
</template>
<script>
import DesktopTalentAsService from "@/components/corporate/talent-as-service/desktop/Index";
import MobileTalentAsService from "@/components/corporate/talent-as-service/mobile/Index";
export default {
  name: "TalentAsService",
  data() {
    return {
      isMobile: false,
    };
  },
  components: {
    DesktopTalentAsService,
    MobileTalentAsService,
  },
  methods: {
    handleView() {
      this.isMobile = screen.width <= 990;
    },
  },
  mounted() {
    this.$gtag.pageview(this.$route);
    this.$utils.scrollTop()
  },
  beforeMount() {
    this.handleView();
  }
};
</script>
